/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import images from 'images';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterFeedbackComponent from './components/filter-feedback.component';
import useFeedbackService from './feedback.service';
import { FilterFeedback, ProjectNewsInfo, UserInfoFeedBack } from './feedback.type';

const { Text, Link } = Typography;

function FeedbackScreen(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterFeedback>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    childRealEstateId: undefined,
    type: undefined,
  });

  const { onGetFeedBack, dataFeedBack, totalFeedBack, loading, deleteArray } = useFeedbackService();

  useEffect(() => {
    onGetFeedBack(filter);
  }, [filter]);

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Mã tin đăng',
      dataIndex: 'projectNewsInfo',
      render: (projectNewsInfo: ProjectNewsInfo) => {
        return (
          <>
            <Link href={ROUTER_PATH.SALES_AND_RENTAL_EDIT + projectNewsInfo._id} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {projectNewsInfo?.code}
              </Text>
            </Link>
          </>
        );
      },
    },
    {
      title: 'Tên dự án',
      dataIndex: 'projectNewsInfo',
      render: (projectNewsInfo: ProjectNewsInfo) => {
        return <>{projectNewsInfo?.projectName}</>;
      },
    },
    {
      title: 'Tên BĐS',
      dataIndex: 'projectNewsInfo',
      render: (projectNewsInfo: ProjectNewsInfo) => {
        return <>{projectNewsInfo?.landName}</>;
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoFeedBack) => {
        return (
          <>
            {userInfo ? (
              <div>
                <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo._id} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {userInfo?.name}
                  </Text>
                </Link>
              </div>
            ) : (
              'Khách vãng lai'
            )}
          </>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoFeedBack) => {
        return <span>{userInfo?.phone}</span>;
      },
    },
    {
      title: 'Nội dung',
      dataIndex: 'content',
      render: (content: string) => {
        return <span>{content || ''}</span>;
      },
      width: '20%',
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm
  const { run } = useDebounceFn(
    (search: string) => {
      setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: search });
    },
    {
      wait: 500,
    },
  );

  // Loại kí gửi
  const onType = (type: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, type: type });
  };

  // Loại BĐS
  const onRealEstates = (data: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, childRealEstateId: data });
  };

  // Action Xóa nhiều
  const [dataDelete, setDataDelete] = useState<string[]>([]);

  const onDeleteAll = () => {
    deleteArray(dataDelete);
    setDataDelete([]);
  };

  useEffect(() => {
    setFilter({ ...filter, page: 1 });
  }, [dataDelete]);

  const rowSelection = {
    selectedRowKeys: dataDelete,
    onChange: (selectedRowKeys: string[]) => {
      const arrDelete = dataDelete.filter(_id => !dataFeedBack.find(item => _id === item._id));
      setDataDelete(_.uniq([...arrDelete, ...selectedRowKeys]));
    },
  };

  return (
    <>
      <FilterFeedbackComponent onSearch={(data: string) => run(data)} onType={onType} onRealEstates={onRealEstates} />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
              {dataDelete && dataDelete.length > 0 && (
                <Popconfirm
                  placement="top"
                  title="Bạn có chắc muốn xóa"
                  onConfirm={() => onDeleteAll()}
                  okText={translate('action.yes')}
                  cancelText={translate('action.no')}
                >
                  <img className="img-style" src={images.ic_delete} alt="" />
                  <span
                    style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                  >
                    Xóa nhiều
                  </span>
                </Popconfirm>
              )}
              <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                Đã chọn {dataDelete.length || 0}
              </span>
            </div>

            <Table
              rowKey="_id"
              dataSource={dataFeedBack || []}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalFeedBack}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FeedbackScreen;
