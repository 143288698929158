/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/order */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Drawer, Form, Modal, Pagination, Row, Select, Table, Tag } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { translate } from 'translate/language';
import { FilterHistory } from './history-types';
import useHistoryService from './history.service';
import { formatNumberPrice } from 'helpers/FormatPrice';
import DateRangePickerScreen from 'components/DateRangePicker';
import { endOfMonth, startOfYear } from 'date-fns';
import { BarsOutlined, EyeOutlined } from '@ant-design/icons';
import { truncateString } from 'helpers/String';

const { Option } = Select;

function HistoryPAGScreen(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [dateRangeOptions, setDateRangeOptions] = useState({
    startDate: startOfYear(new Date('2020')),
    endDate: endOfMonth(new Date()),
    key: 'selection',
    color: 'purple',
  });
  const [filter, setFilter] = useState<FilterHistory>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    minDate: undefined,
    maxDate: undefined,
  });
  const [selectedItem, setSelectedItem] = useState<any>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isProjectTransaction, setIsProjectTransaction] = useState(true);

  const { onGetPagHistory, dataPagHistory, totalPagHistory, loading, calPagHistory } = useHistoryService();

  useEffect(() => {
    onGetPagHistory(filter);
  }, [filter]);

  const handleClickItem = (item: any) => {
    setSelectedItem(item);
    setIsDrawerOpen(true);
    let is = true;
    if (item) {
      if (item.type === 'COMMISSION_RETAIL_REAL_ESTATE_PAG') {
        is = false;
      } else {
        is = true;
      }
    } else {
      is = false;
    }
    setIsProjectTransaction(is);
  };

  const handleCancel = () => {
    setIsDrawerOpen(false);
    setSelectedItem(undefined);
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Thời gian nhận',
      dataIndex: 'receivedDate',
      render: (receivedDate: moment.Moment) => {
        return <Tag color="purple">{moment(receivedDate).format('HH:mm DD/MM/YYYY')}</Tag>;
      },
    },
    {
      title: 'Số tiền',
      dataIndex: 'money',
      render: (money: number) => {
        return <>{formatNumberPrice(money)}</>;
      },
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case 'COMMISSION_RETAIL_REAL_ESTATE_PAG':
            return 'Hoa hồng BĐS lẻ';
          case 'PAG_COMMISSION_FROM_PROJECT':
            return 'Hoa hồng dự án';
          default:
            return '';
        }
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'PENDING_APPROVED':
            return 'Chờ duyệt';
          case 'APPROVED':
            return 'Đã duyệt';
          case 'CANCEL':
            return 'Đã huỷ';
          case 'PENDING_PAYMENT':
            return 'Chờ chi trả';
          default:
            return '';
        }
      },
    },
    {
      title: 'Giao dịch',
      key: '_id',
      render: (item: any) => {
        return (
          <>
            {item.type === 'COMMISSION_RETAIL_REAL_ESTATE_PAG' && item.transactionProjectNewsInfo ? (
              <a target="blank" href={`/real-estate-manager/detail-related/${item.transactionProjectNewsInfo._id}`}>
                {truncateString(item.transactionProjectNewsInfo.projectNewsInfo.landName, 30)}
              </a>
            ) : (
              <a target="blank" href={`/project-manager/customer_project/${item.transactionInfo._id}`}>
                {truncateString(item.transactionInfo.projectInfo.name, 40)}
              </a>
            )}
          </>
        );
      },
    },
    {
      title: <BarsOutlined />,
      key: '_id',
      render: (item: any) => {
        return (
          <Button onClick={(): any => handleClickItem(item)} size="small" type="primary" icon={<EyeOutlined />}>
            Chi tiết
          </Button>
        );
      },
    },
  ];

  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  const onSelectDate = (ranges: any) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, minDate: ranges.startDate.toISOString(), maxDate: ranges.endDate.toISOString() });
    setDateRangeOptions({
      ...dateRangeOptions,
      startDate: ranges.startDate,
      endDate: ranges.endDate,
    });
  };

  const allTimeHighNumber = () => {
    setFilter({
      ...filter,
      minDate: undefined,
      maxDate: undefined,
      type: undefined,
    });

    setDateRangeOptions({
      ...dateRangeOptions,
      startDate: startOfYear(new Date('2020')),
      endDate: endOfMonth(new Date()),
    });
  };

  const onChangeType = (type: string) => {
    setFilter({
      ...filter,
      type,
    });
  };

  return (
    <>
      <Row>
        <Col span={10} style={{ width: '100%' }}>
          <DateRangePickerScreen dateRange={dateRangeOptions} handleSelect={onSelectDate} />
        </Col>
        <Col span={14}>
          <Row
            justify="space-between"
            style={{
              background: '#9c27b0',
              color: '#fff',
              gap: 2,
              padding: '10px',
            }}
          >
            <div>
              Tổng hoa hồng dự án:{' '}
              <b>{calPagHistory ? formatNumberPrice(Math.floor(calPagHistory.moneyFromProject)) : 0}</b>
            </div>
            <div>
              Tổng hoa hồng BĐS lẻ:{' '}
              <b>{calPagHistory ? formatNumberPrice(Math.floor(calPagHistory.moneyFromProjectNews)) : 0}</b>
            </div>
            <div>
              Tổng hoa hồng: <b>{calPagHistory ? formatNumberPrice(Math.floor(calPagHistory.total)) : 0}</b>
            </div>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              <Form.Item label="Loại hoa hồng">
                <Select
                  placeholder="Chọn loại hoa hồng"
                  onChange={(value: string) => onChangeType(value)}
                  style={{ width: '100%' }}
                  allowClear
                >
                  <Option value="PAG_COMMISSION_FROM_PROJECT">Từ dự án</Option>
                  <Option value="COMMISSION_RETAIL_REAL_ESTATE_PAG">Từ BĐS lẻ</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Button
              type="dashed"
              onClick={allTimeHighNumber}
              block
              style={{
                color: '#673ab7',
                borderColor: '#673ab7',
              }}
            >
              Xoá bộ lọc
            </Button>
          </Row>
        </Col>
      </Row>
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataPagHistory || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}

        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalPagHistory}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
      {selectedItem && (
        <Drawer
          width={900}
          title={`Mã giao dịch: ${
            !isProjectTransaction ? selectedItem.transactionProjectNewsInfo.code : selectedItem.transactionInfo.code
          }`}
          placement="left"
          onClose={handleCancel}
          visible={isDrawerOpen}
        >
          <div style={{ textAlign: 'center' }}>
            Tên dự án/BĐS lẻ:
            <h3 style={{ textTransform: 'uppercase' }}>
              {isProjectTransaction
                ? selectedItem.transactionInfo.projectInfo.name
                : selectedItem.transactionProjectNewsInfo.projectNewsInfo.landName}
            </h3>
          </div>
          <p>
            Khách hàng:{' '}
            <a
              target="blank"
              style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
              href={`/account/edit-customer/${
                isProjectTransaction
                  ? selectedItem.transactionInfo.customer._id
                  : selectedItem.transactionProjectNewsInfo.userInfo._id
              }`}
            >
              {isProjectTransaction
                ? `${selectedItem.transactionInfo.customer.phone} - ${selectedItem.transactionInfo.customer.name}`
                : `${selectedItem.transactionProjectNewsInfo.userInfo.phone} - ${selectedItem.transactionProjectNewsInfo.userInfo.name}`}
            </a>
          </p>
          <p>
            CTV giới thiệu:{' '}
            <a
              target="blank"
              style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
              href={`/account/edit-customer/${
                isProjectTransaction
                  ? selectedItem.transactionInfo.customer._id
                  : selectedItem.transactionProjectNewsInfo.userInfo._id
              }`}
            >
              {isProjectTransaction
                ? `${selectedItem.transactionInfo.introducerInfo.phone} - ${selectedItem.transactionInfo.introducerInfo.name}`
                : `${selectedItem.transactionProjectNewsInfo.introducerInfo.phone} - ${selectedItem.transactionProjectNewsInfo.introducerInfo.name}`}
            </a>
          </p>
          <p>
            CTV hỗ trợ:{' '}
            <a
              target="blank"
              style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
              href={`/account/edit-customer/${
                isProjectTransaction
                  ? selectedItem.transactionInfo.customer._id
                  : selectedItem.transactionProjectNewsInfo.userInfo._id
              }`}
            >
              {isProjectTransaction
                ? `${selectedItem.transactionInfo.supporterInfo.phone} - ${selectedItem.transactionInfo.supporterInfo.name}`
                : `${selectedItem.transactionProjectNewsInfo.supporterInfo.phone} - ${selectedItem.transactionProjectNewsInfo.supporterInfo.name}`}
            </a>
          </p>
          {isProjectTransaction ? (
            <>
              <p>
                Mã căn hộ:{' '}
                <a
                  target="blank"
                  style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                  href={`/project-manager/project-house-lots/edit/${selectedItem.transactionInfo.houseInfo._id}`}
                >
                  {selectedItem.transactionInfo.houseInfo.code}
                </a>
              </p>
              <p>
                Giá bán:{' '}
                <a target="blank" style={{ textTransform: 'uppercase', fontWeight: 'bold' }} href="#">
                  {formatNumberPrice(selectedItem.transactionInfo.houseInfo.price)}
                </a>
              </p>
            </>
          ) : (
            ''
          )}
          {!isProjectTransaction ? (
            <>
              <p>
                Giá bán/cho thuê:{' '}
                <a target="blank" style={{ textTransform: 'uppercase', fontWeight: 'bold' }} href="#">
                  {formatNumberPrice(selectedItem.transactionProjectNewsInfo.projectNewsInfo.price)}
                </a>
              </p>
              <p>
                Tổng hoa hồng:{' '}
                <a target="blank" style={{ textTransform: 'uppercase', fontWeight: 'bold' }} href="#">
                  {formatNumberPrice(selectedItem.transactionProjectNewsInfo.projectNewsInfo.commissionFee)}
                </a>
              </p>
            </>
          ) : (
            ''
          )}
        </Drawer>
      )}
    </>
  );
}

export default HistoryPAGScreen;
