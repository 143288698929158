/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, DatePicker, Form, Input, Row, Select, Image, Modal, message } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import PaginateList from 'constants/PaginateList';
import { StatusType } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import useAddAdmin from 'hooks/Admin/useAddAdmin';
import useDetailAdmin from 'hooks/Admin/useDetailAdmin';
import useUpdateAdmin from 'hooks/Admin/useUpdateAdmin';
import useGetPermission from 'hooks/Permission/useGetPermission';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import moment from 'moment';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import './css/index.scss';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';

const { Option } = Select;
export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

interface Props {}
function FormAdminPage(props: Props) {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_ADMIN);
  const [onUpload, resUpload, messageError] = useUploadMulti();
  // Hình ảnh đại diện
  const [ImageAvatar, setImageAvatar] = useState<Media[]>([]);
  // Hiển thị hình ảnh + url hình ảnh
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);

  // Thêm mới dữ liệu
  const [onAddAdmin, loading] = useAddAdmin();
  // Lấy chi tiết Admin
  const [useGetDetailAdmin, detailAdmin] = useDetailAdmin();
  // Cập nhật dữ liệu
  const [onUpdateAdmin] = useUpdateAdmin();

  // Get danh sách các quyền
  const [onFetchListPermission, listPermission, loadingPermission] = useGetPermission();
  useEffect(() => {
    onFetchListPermission({
      page: PaginateList.PAGE_DEFAULT,
      status: 'ACTIVE',
    });
  }, []);

  useEffect(() => {
    if (!isEdit && listPermission && listPermission.data && listPermission.data.length > 0) {
      listPermission.data.map(e => {
        form.setFieldsValue({ customer_type: listPermission.data[0]._id });
      });
    }
  }, [form, listPermission]);

  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      useGetDetailAdmin(params.id);
    } else {
      form.setFieldsValue({
        full_name: cacheForm.full_name,
        birthday: moment(cacheForm.birthday),
        email: cacheForm.email,
        number_cmnd: cacheForm.number_cmnd,
        status: cacheForm.status,
        phone_number: cacheForm.phone_number,
        customer_type: cacheForm.customer_type,
      });
    }
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailAdmin) {
      form.setFieldsValue({
        full_name: detailAdmin.name,
        email: detailAdmin.email,
        customer_type: detailAdmin.group?._id,
        status: detailAdmin.status,
        phone_number: detailAdmin.phone,
        number_cmnd: detailAdmin?.identify,
        birthday: moment(detailAdmin?.dateOfBirth),
      });
      // Hình ảnh đại diện
      if (detailAdmin.avatarId) {
        setImageAvatar([detailAdmin.avatar]);
      }
    }
  }, [detailAdmin, form]);

  const handleSubmit = () => {
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: StatusType.ACTIVE });
    form.validateFields().then(values => {
      const data = {
        name: values.full_name,
        email: values.email,
        password: values.password,
        group: values.customer_type,
        status: values.status,
        phone: values.phone_number,
        dateOfBirth: moment(values?.birthday).format('YYYY/MM/DD'),
        identify: values?.number_cmnd,
        avatarId: ImageAvatar && ImageAvatar.length > 0 ? ImageAvatar[0]._id : null,
      };

      if (isEdit) {
        const dataUpdate = { ...data, _id: params.id };
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_ADMIN);
        onUpdateAdmin(params.id, dataUpdate);
      } else {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_ADMIN);
        onAddAdmin(data);
      }
    });
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_ADMIN, form.getFieldsValue());
  };

  const dateFormatBirthday = ['DD/MM/YYYY'];

  // Validate dữ liệu
  const validateForm = {
    full_name: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('admin.full_name'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 100,
        message: translate('admin.full_name_invalid'),
      },
    ],
    password: !detailAdmin
      ? [
          {
            whitespace: true,
            required: true,
            message: translate('require_field', {
              label: translate('admin.password'),
            }),
          },
          {
            transform: (value: string) => {
              return value?.trim();
            },
            min: 6,
            message: translate('admin.password_invalid'),
          },
        ]
      : [
          {
            transform: (value: string) => {
              return value?.trim();
            },
            min: 6,
            message: translate('admin.password_invalid'),
          },
        ],
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_ADMIN);
    history.back();
  };

  // Xem chi tiết hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal hiển thị
  const onCancelImage = () => {
    setModalVisible(false);
  };

  const onChangeFiles = (fileList: any) => {
    let image: Media[] = [];
    image = ImageAvatar;

    const newData: any = [...image, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    setImageAvatar(newData);

    return onUpload(data);
  };

  // Xóa ảnh Avatar
  const onRemoveFiles = (item: Media | any) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => {
        const newArrImg = ImageAvatar.filter(data => {
          return (data as Media)._id !== (item as Media)._id;
        });
        setImageAvatar(newArrImg);
        message.success('Xoá ảnh thành công');
      },
    });
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      const newfileList = [...ImageAvatar].filter(e => e._id);
      setImageAvatar([...newfileList, ...resUpload]);
    }
  }, [resUpload]);

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).read ? (
        <>
          <Modal title="" visible={isModalVisible} onCancel={onCancelImage} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
          <Form onFieldsChange={updateCacheForm} form={form}>
            <Row>
              <Col span={20} offset={3}>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item name="avatarId" label="Hình ảnh" labelCol={{ span: 7 }} labelAlign="left">
                        <UploadButtonImages
                          accept=".gif, .jpg, .png, .webp"
                          files={ImageAvatar}
                          onPreview={handlePreview}
                          onChange={(fileList: any) => onChangeFiles(fileList)}
                          onRemove={(item: any) => onRemoveFiles(item)}
                          imageLength={1}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="customer_type"
                      label={translate('admin.role')}
                      labelCol={{ sm: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          whitespace: true,
                          required: true,
                          message: translate('require_field', {
                            label: translate('admin.role'),
                          }),
                        },
                      ]}
                    >
                      {/* defaultValue={translate('customer.account_user')} */}
                      <Select placeholder={translate('admin.role')}>
                        {listPermission && listPermission.data
                          ? listPermission.data.map((data: any, key: any) => (
                              <Option value={data._id} key={data._id}>
                                {data.name}
                              </Option>
                            ))
                          : ''}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="full_name"
                        label={translate('admin.full_name')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={validateForm.full_name}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="email"
                        label={translate('admin.email')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            type: 'email',
                            message: translate('admin.email_invalid'),
                          },
                          {
                            whitespace: true,
                            required: true,
                            message: translate('require_field', {
                              label: translate('admin.email'),
                            }),
                          },
                        ]}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="phone_number"
                        label={translate('admin.phone_number')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('admin.phone_number'),
                            }),
                          },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="birthday"
                        label={translate('admin.birthday')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (typeof value !== 'undefined' && value != null) {
                                const getYearBirthday: number = moment(getFieldValue('birthday')).year();
                                const getYearNow: number = moment().year();
                                const age = 20;
                                if (getYearNow - getYearBirthday >= age) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(`${translate('admin.birthday_invalid')}`);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <DatePicker placeholder="Ngày sinh" style={{ width: '100%' }} format={dateFormatBirthday} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="number_cmnd"
                        label={translate('admin.number_cmnd')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: 'Số CMND không được để trống',
                          },
                        ]}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="password"
                        label={translate('customer.password')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={validateForm.password}
                      >
                        <Input.Password style={{ padding: '0px 12px' }} placeholder="******" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="status"
                      label={translate('status.name')}
                      labelCol={{ sm: 7 }}
                      labelAlign="left"
                      initialValue={StatusType.ACTIVE}
                    >
                      <Select
                        placeholder={translate('admin.status_active')}
                        // defaultValue={StatusType.ACTIVE}
                      >
                        <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                        <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={closeButton}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).create ||
                      getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).edit ? (
                        <Col>
                          <Form.Item>
                            <Button type="primary" onClick={handleSubmit} htmlType="submit">
                              {isEdit ? translate('action.update') : translate('action.save')}
                            </Button>
                          </Form.Item>
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormAdminPage;
