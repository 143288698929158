/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ConstructorType, NAME_CONSTRUCTOR } from 'constants/ConstructorType';
import { PERMISSION_NAME } from 'constants/PermissionName';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

export interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status: string) => void;
  onChangeConstructorType: (status: string) => void;
}
function FilterHeaderPage(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, onChangeStatus, onChangeConstructorType } = props;
  const history = useHistory();

  // Tìm kiếm theo Tên
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
  };

  // Loại đối tác
  const handChangeConstructorType = (value: string) => {
    onChangeConstructorType(value);
  };

  // Thêm mới đối tác xây dựng
  const addNew = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_CONSTRUCTOR);
    history.push(ROUTER_PATH.ADD_CONSTRUCTOR);
  };
  return (
    <div>
      <Form form={form}>
        <Row gutter={20}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder={translate('constructor.name')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={5}>
            <div>
              <Form.Item name="type">
                <Select placeholder={translate('constructor.type')} onChange={handChangeConstructorType}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value={ConstructorType.INVESTOR}>
                    {translate(NAME_CONSTRUCTOR[ConstructorType.INVESTOR])}
                  </Option>
                  <Option value={ConstructorType.DESIGN}>{translate(NAME_CONSTRUCTOR[ConstructorType.DESIGN])}</Option>
                  <Option value={ConstructorType.DEVELOPER}>
                    {' '}
                    {translate(NAME_CONSTRUCTOR[ConstructorType.DEVELOPER])}
                  </Option>
                  <Option value={ConstructorType.CONTRACTOR}>
                    {translate(NAME_CONSTRUCTOR[ConstructorType.CONTRACTOR])}
                  </Option>
                  <Option value={ConstructorType.MARKETING}>
                    {translate(NAME_CONSTRUCTOR[ConstructorType.MARKETING])}
                  </Option>
                  <Option value={ConstructorType.OPERATOR}>
                    {translate(NAME_CONSTRUCTOR[ConstructorType.OPERATOR])}
                  </Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={5}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('status.name')} onChange={handChangeStatus}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value="ACTIVE">{translate('status.show')}</Option>
                  <Option value="INACTIVE">{translate('status.hidden')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={4}></Col>
          <Col className="gutter-row" span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).create ? (
              <div>
                <Button
                  style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}
                  type="primary"
                  onClick={addNew}
                >
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FilterHeaderPage;
