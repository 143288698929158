/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastError, ToastSuccess } from './../../components/Toast/index';
import { StatusResponse } from './../../constants/Status';
import { translate } from './../../translate/language';
import { FilterRealEstateNew, RealEstateNewBody, RealEstateRes } from './real-estate-new.types';

const LIST = '/project-news/buy-real-estate-news';
const DETAIL_DELETE = '/project-news/detail-buy-real-estate-news';
const UPDATE = '/project-news/buy-real-estate-news';

function useRealEstateNewService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDel, setLoadingDel] = useState<boolean>(false);
  const [dataRealEstateNew, setDataRealEstateNew] = useState<RealEstateRes[]>([]);
  const [detailRealEstateNew, setDetailRealEstateNew] = useState<RealEstateRes>();
  const [totalData, setTotalData] = useState<number>();
  const history = useHistory();

  const onListRealEstateNew = async (params: FilterRealEstateNew) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch<FilterRealEstateNew, any>(LIST, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataRealEstateNew(res.data);
        setTotalData(res.total);
        setLoading(false);
      } else {
        setDataRealEstateNew([]);
        setLoading(false);
      }
    } catch (error) {
      setDataRealEstateNew([]);
      setLoading(false);
    }
  };

  const onDeleteRealEstateNew = async (id: string) => {
    try {
      setLoadingDel(true);
      const res: any = await ApiHelper.delete(`${DETAIL_DELETE}?_id=${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoadingDel(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      }
    } catch (error) {
      setLoadingDel(false);
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  const onDetailRealEstateNew = async (data: { _id: string }) => {
    const res: any = await ApiHelper.fetch(DETAIL_DELETE, data);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDetailRealEstateNew(res.data);
    }
  };

  const onUpdateRealEstateNew = async (data: RealEstateNewBody) => {
    try {
      const res: any = await ApiHelper.put<RealEstateNewBody, any>(UPDATE, data);
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  const deleteArray = async (id: string[]) => {
    const res: any = await ApiHelper.delete('project-news/delete-many/buy-real-estate-news', { ids: id });
    if (res && res.status === 'DOCUMENT_NOT_EXIST') {
      return ToastError({
        message: translate('message_type.success'),
        description: 'Có tin đăng không hợp lệ',
      });
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.delete_success'),
      });
    } else {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return {
    onListRealEstateNew,
    dataRealEstateNew,
    totalData,
    loading,
    onDeleteRealEstateNew,
    loadingDel,
    onDetailRealEstateNew,
    detailRealEstateNew,
    onUpdateRealEstateNew,
    deleteArray,
  };
}

export default useRealEstateNewService;
