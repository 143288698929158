/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { Col, ConfigProvider, DatePicker, Form, Input, Row } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import 'moment/locale/vi';
import React from 'react';

const { RangePicker } = DatePicker;

export interface Props {
  onSearch: (data?: string) => void;
  onDateRanger: (data?: [string, string]) => void;
}

function FilterHistoryComponent(props: Props): JSX.Element {
  const { onSearch, onDateRanger } = props;
  const [form] = Form.useForm();

  // Tìm kiếm
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value || undefined);
  };

  const onChangeDate = (dates: [any, any], dateStrings: [string, string]) => {
    onDateRanger(dateStrings || undefined);
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <Form form={form}>
          <Row gutter={[16, 16]} className="header_filer">
            <Col span={6}>
              <div>
                <Form.Item name="search">
                  <Input size="small" placeholder="Tên, SĐT" onChange={handleSearch} suffix={<SearchOutlined />} />
                </Form.Item>
              </div>
            </Col>
            <Col span={18}>
              <Input.Group size="large">
                <Row gutter={8}>
                  <Col span={3} style={{ textAlign: 'center', position: 'relative', top: 15 }}>
                    Thời gian nhận từ
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <RangePicker
                        onChange={onChangeDate}
                        picker="month"
                        placeholder={['Tháng/Năm', 'Tháng/Năm']}
                        format="MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            </Col>
          </Row>
        </Form>
      </ConfigProvider>
    </>
  );
}

export default FilterHistoryComponent;
