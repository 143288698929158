import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

function useAddArea() {
  const [loadingBlock, setLoading] = useState(false);
  const history = useHistory();

  const onAddBlockArea = async (request: any) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.post(BASE_URL.ADD_BLOCK_AREA, request);
      if (res && res.status === 'BLOCK_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tên block đã tồn tại',
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        history.push(ROUTER_PATH.BLOCKS_AREA);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
      } else {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.add_new_failed'),
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return { onAddBlockArea, loadingBlock };
}

export default useAddArea;
