/* eslint-disable consistent-return */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { DownloadOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Spin, Table, Upload } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import Container from 'components/ContentLayout/Container';
import { ToastError, ToastSuccess } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusResponse, StatusType } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { setTimeout } from 'timers';
import { translate } from 'translate/language';

const { Option } = Select;

interface Props {
  onSearch: (search: string) => void;
  onChangeType: (type: string) => void;
  onChangeStatus: (status: string) => void;
}

export interface FilterExcel {
  search?: string;
  status?: string;
  customerType?: string;
}

function FilterHeaderCustomer(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, onChangeType, onChangeStatus } = props;
  const history = useHistory();
  const [filterExcel, setFilterExcel] = useState<FilterExcel>({
    search: '',
    status: '',
    customerType: '',
  });

  // Tìm kiếm theo Tên,ID,Số điện thoại
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
    setFilterExcel({ ...filterExcel, search: e.target.value });
  };

  // export file excel Customer
  const exportExcel = async () => {
    const res: any = await ApiHelper.post(BASE_URL.EXPORT_CUSTOMER, filterExcel, true);
    if (res.status === StatusResponse.SUCCESS) {
      window.open(getUrlExport(res.data));
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.error'),
      });
    }
  };

  // Select loại tài khoản
  const handChangeType = (value: string) => {
    onChangeType(value);
    setFilterExcel({ ...filterExcel, customerType: value });
  };
  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
    setFilterExcel({ ...filterExcel, status: value });
  };
  // Thêm mới người dùng
  const addNewCustomer = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_CUSTOMER);
    history.push(ROUTER_PATH.ADD_CUSTOMER);
  };

  const [fileExcel, setFileExel] = useState<any[]>([]);
  const [checkModal, setCheckModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleDownloadTemplate = async () => {
    const res: any = await ApiHelper.fetch('/user/download-import-template', null, true);
    window.open(getUrlExport(res.data));
  };

  const onRemove = () => {
    setFileExel([]);
    setCheckExcel(false);
  };

  const closeModal = () => {
    setCheckModal(false);
    setIsModalVisible(false);
    setCheckExcel(false);
  };

  // Nhập Excel
  const showModal = () => {
    setIsModalVisible(true);
  };

  const [updateExisting, setUpdateExisting] = useState<string>('false');
  const [errorExcel, setErrorExcel] = useState<boolean>(false);
  const [totalError, setTotalError] = useState<number>(0);
  const [totalSuccess, setTotalSuccess] = useState<number>(0);
  const [checkExcel, setCheckExcel] = useState<boolean>(false);
  const [dataError, setDataError] = useState<any[]>([]);

  const onChangeExcel = (e: any) => {
    if (e.target.checked) {
      setUpdateExisting('true');
    } else {
      setUpdateExisting('false');
    }
  };

  const handleSubmitUpload = async (e: any) => {
    e.preventDefault();
    if (fileExcel.length === 0) {
      setCheckModal(true);
      return;
    }

    setCheckModal(false);
    const data = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    if (fileExcel?.length > 0) {
      data.append('file', fileExcel[0].originFileObj);
      data.append('updateExisting', updateExisting);
      const res: any = await ApiHelper.postFormData('/user/importCustomers', data, headers);
      if (res && res.status === 'FILE_IS_NOT_FORMULATED') {
        return ToastSuccess({
          message: 'Thất bại',
          description: 'File không đúng định dạng',
        });
      }
      setFileExel([]);
      setCheckModal(false);
      setCheckExcel(true);

      if (res && res.data && res.data.fail > 0) {
        setErrorExcel(true);
        setTotalError(res.data.fail);
        setDataError(res.data.errors);
        return false;
      }
      if (res && res.status === StatusResponse.SUCCESS && res.data) {
        setTotalSuccess(res.data.success);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.import_success'),
        });
      }
    }
  };

  const handleChangeFileUpload = ({ fileList }: any) => {
    if (fileList && fileList.length > 0) {
      fileList.map((e: any) => {
        e.status = 'done';
        return e;
      });
      setFileExel(fileList);
      setCheckModal(false);
    } else {
      setCheckModal(true);
    }
    setCheckExcel(false);
  };

  const columns = [
    {
      title: 'STT',
      render: (_: any, data: any, index: number) => {
        return index++;
      },
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'angentId',
      render: (angentId: string) => {
        return angentId;
      },
    },
    {
      title: 'Agent ID',
      dataIndex: 'customerType',
      render: (customerType: string) => {
        return customerType === 'COMPANY' ? 'Doanh nghiệp' : 'Cá nhân';
      },
    },
    {
      title: 'Lỗi',
      dataIndex: 'message',
      render: (message: string) => {
        return message;
      },
    },
  ];

  return (
    <>
      <Modal
        title="Nhập từ excel"
        visible={isModalVisible}
        onCancel={closeModal}
        cancelText="Đóng"
        okText="Tải lên"
        onOk={handleSubmitUpload}
      >
        <Container>
          <Row>
            <Col sm={24}>
              <Form.Item label="Tải xuống mẫu" labelCol={{ sm: 8 }} labelAlign="left">
                <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleDownloadTemplate} />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Tải lên file excel"
                labelAlign="left"
                labelCol={{ sm: 8 }}
                name="upload_file_excel"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleChangeFileUpload}
                  fileList={fileExcel}
                  onRemove={onRemove}
                >
                  {fileExcel && fileExcel.length > 0 ? '' : <Button icon={<UploadOutlined />}>Tải lên</Button>}
                </Upload>
              </Form.Item>
              {checkModal ? (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div>Vui lòng chọn File tải lên </div>
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Checkbox onChange={onChangeExcel}>Cập nhật người dùng có sẵn</Checkbox>
            </Col>
            {checkExcel && (
              <>
                <Col span={12}>Thành công : {totalSuccess}</Col>
                <Col span={12}>Thất bại : {totalError}</Col>
              </>
            )}

            {errorExcel && (
              <Col span={24}>
                <Table dataSource={dataError || []} columns={columns} pagination={false} />
              </Col>
            )}
          </Row>
        </Container>
      </Modal>

      <Form form={form}>
        <Row gutter={20}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder={translate('customer.search_name_id_phone')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div>
              <Form.Item name="customer_type">
                <Select placeholder={translate('customer.type_account')} onChange={handChangeType}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value="PRIVATE">{translate('customerType.PRIVATE')}</Option>
                  <Option value="COMPANY">{translate('customerType.COMPANY')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('status.name')} onChange={handChangeStatus}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                  <Option value={StatusType.ACTIVE_NOT_VERIFIED}>{translate('status.ACTIVE_NOT_VERIFIED')}</Option>
                  <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).export ? (
              <div>
                <Button style={{ width: '100%', textAlign: 'center' }} type="primary" onClick={exportExcel}>
                  <span style={{ marginLeft: -20 }}>{translate('customer.export_excel')}</span>
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className="gutter-row" span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).export ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={showModal}>
                  Nhập Excel
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className="gutter-row" span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={addNewCustomer}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterHeaderCustomer;
