/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { Col, ConfigProvider, DatePicker, Form, Input, Row } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import 'moment/locale/vi';
import React from 'react';

export interface Props {
  onSearch: (data?: string) => void;
  onRangePicker: (data?: [string, string]) => void;
}

const { RangePicker } = DatePicker;

const FilterPointPlusComponent = (props: Props): JSX.Element => {
  const { onSearch, onRangePicker } = props;

  const [form] = Form.useForm();

  // Tìm kiếm
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value || undefined);
  };

  // Tháng/Năm
  const onChangeRangePicker = (dates: [any, any], dateStrings: [string, string]) => {
    onRangePicker(dateStrings);
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <Form form={form}>
          <Row gutter={24} className="marginBottom">
            <Col span={6}>
              <Form.Item name="search">
                <Input
                  size="small"
                  suffix={<SearchOutlined />}
                  onChange={onChangeSearch}
                  placeholder="Tên, SĐT, Số CMND, Email"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <RangePicker
                picker="month"
                style={{ width: '100%' }}
                placeholder={['Tháng/Năm', 'Tháng/Năm']}
                format="MM/YYYY"
                onChange={onChangeRangePicker}
              />
            </Col>
          </Row>
        </Form>
      </ConfigProvider>
    </>
  );
};

export default FilterPointPlusComponent;
