/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import moment from 'moment';
// @ts-ignore
import renderHTML from 'react-render-html';
import useNotificationService from './notification.service';

const { Link, Text } = Typography;

const DetailNotification = () => {
  const params: { id: string } = useParams();
  const history = useHistory();
  const { onDetailNotification, dataDetail } = useNotificationService();

  useEffect(() => {
    onDetailNotification(params.id);
  }, []);

  return (
    <div>
      {dataDetail && (
        <Row gutter={24}>
          <Col span={20} style={{ margin: '0 auto' }}>
            <Row className="mb-2">
              <Text style={{ fontSize: '24px' }} underline>
                Chi tiết thông báo
              </Text>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Tên khách hàng</Col>
              <Col span={16}>
                <Link href={ROUTER_PATH.EDIT_CUSTOMER + dataDetail.userId || '0'} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {(dataDetail.userInfomation && dataDetail.userInfomation.name) || '--'}
                  </Text>
                </Link>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Số điện thoại</Col>
              <Col span={16}>{(dataDetail.userInfomation && dataDetail.userInfomation.phone) || '--'}</Col>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Số CMND</Col>
              <Col span={16}>{(dataDetail.userInfomation && dataDetail.userInfomation.identify) || '--'}</Col>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Ngày nhận thông báo</Col>
              <Col span={16}>{moment(dataDetail.createdAt).format('HH:mm DD/MM/YYYY')}</Col>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Số BĐS giao dịch thành công</Col>
              <Col span={16}>{(dataDetail.payload && dataDetail.payload.curentTransactionSuccess) || '--'}</Col>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Số thanh viên đã giới thiệu</Col>
              <Col span={16}>{(dataDetail.payload && dataDetail.payload.numberUserIntroduceOfRule) || '--'}</Col>
            </Row>
            <Row className="mb-2">
              <Col span={8}>Nội dung thông báo</Col>
              <Col span={16}>{dataDetail.body ? renderHTML(dataDetail.body) : '--'}</Col>
            </Row>
            <Row className="mb-2">
              <div
                style={{ padding: '5px 10px', background: 'red', color: 'white', cursor: 'pointer' }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Quay lại
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default DetailNotification;
