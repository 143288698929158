export const MAX_FILE_SIZE = {
  VIDEO: 500000000,
  FILE: 500000000,
  IMAGE_VIDEO: 10000000,
};

export enum TYPE_UPLOAD {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  FILE = 'FILE',
}
export enum TYPE_UPLOAD_PROJECT {
  uploadImagefileListScaleIds = 'uploadImagefileListScaleIds',
  uploadImagefileListLocationDetailIds = 'uploadImagefileListLocationDetailIds',
  uploadImagefileListOverviewIds = 'uploadImagefileListOverviewIds',
  uploadImagePolicySaleIds = 'uploadImagePolicySaleIds',
  uploadImagePolicyBankIds = 'uploadImagePolicyBankIds',
  uploadImageOverviewIds = 'uploadImageOverviewIds',
  uploadImageAreaIds = 'uploadImageAreaIds',
  uploadImageLocationIds = 'uploadImageLocationIds',
  uploadImageProjectIds = 'uploadImageProjectIds',
  uploadImageOrtherIds = 'uploadImageOrtherIds',
  uploadImageUtility = 'uploadImageUtility',
  uploadImagePaymentMethod = 'uploadImagePaymentMethod',
  uploadImagefloorPlan = 'uploadImagefloorPlan',
}
export enum TYPE_DELETE_PROJECT {
  fileListScale = 'fileListScale',
  fileListLocationDetail = 'fileListLocationDetail',
  fileListOverview = 'fileListOverview',
  fileListPolicySale = 'fileListPolicySale',
  fileListPolicyBank = 'fileListPolicyBank',
  fileListUtility = 'fileListUtility',
  fileListpaymentMethod = 'fileListpaymentMethod',
  fileListfloorPlan = 'fileListfloorPlan',
}

export const NAME_UPLOAD: any = {
  [TYPE_UPLOAD.PHOTO]: 'ảnh',
  [TYPE_UPLOAD.VIDEO]: 'video',
  [TYPE_UPLOAD.FILE]: 'file',
};
