/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table, Tag } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject, StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useLitsProject from 'hooks/Project/useLitsProject';
import images from 'images';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHeaderHouseTemplate from './components/FilterHeaderHouseTemplate';
import { HouseTemplateRes, RealestateId } from './types/house-template.types';
import useLitsHouseTemplate from './useHouseTemplatehooks/useHouseTemplatehooks';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Filter {
  status: string;
  search: string;
  page: number;
  projectId?: string;
  limit: number;
}
interface FilterProject {
  page: number;
  limit: number;
  status?: string;
}
function HouseTemplatepage(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const query: any = useQuery();
  const [filter, setFilter] = useState<Filter>({
    status: '',
    search: '',
    page: PaginateList.PAGE_DEFAULT,
    limit: PaginateList.LIMIT_DEFAULT,
    projectId: query.get('idProject') ? query.get('idProject') : undefined,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterProject, setFilterProject] = useState<FilterProject>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
    status: StatusType.ACTIVE,
  });
  const {
    onHouseTemplate,
    listHouseTemplate,
    total,
    loading,
    onDeleteHouseTemplate,
    loadingDelete,
  } = useLitsHouseTemplate();
  const { onListProject, listProject } = useLitsProject();

  useEffect(() => {
    onHouseTemplate(filter);
  }, [filter, loadingDelete]);

  useEffect(() => {
    onListProject(filterProject);
  }, [filterProject]);

  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search });
    },
    {
      wait: 500,
    },
  );

  const onDelete = (_id: string) => () => {
    onDeleteHouseTemplate(_id);
  };

  const onChangeStatus = (status: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status });
  };
  const handChangeProject = (projectId: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, projectId });
  };

  // Action phân trang
  // eslint-disable-next-line no-shadow
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page });
  };

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).edit && (
          <Link to={ROUTER_PATH.EDIT_PROJECTS_HOUSETEMPLATE + _id}>
            <img className="img-style" src={images.ic_edit} alt="edit" style={{ marginRight: '10px' }} />
          </Link>
        )}
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="delete" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  // Render Column Table
  const renderColumns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: HouseTemplateRes, index: any) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tên căn hộ mẫu',
      key: '_id',
      render: (item: HouseTemplateRes) => {
        return <span>{item.name}</span>;
      },
    },
    {
      title: 'Loại căn hộ',
      key: '_id',
      render: (item: HouseTemplateRes) => {
        return <span>{(item.bedroom && item.bedroom.name) || ''}</span>;
      },
    },
    {
      title: translate('project.name'),
      key: '_id',
      render: (item: HouseTemplateRes) => {
        return <span>{(item.projectId && item.projectId.name) || ''}</span>;
      },
    },
    {
      title: translate('property.type'),
      key: '_id',
      dataIndex: 'realestateId',
      render: (data: RealestateId[]) => {
        return (
          <span>
            {data &&
              data.length > 0 &&
              data.map((item: RealestateId) => {
                return (
                  <Tag style={{ marginBottom: '5px' }} key={item._id}>
                    {item.name}
                  </Tag>
                );
              })}
          </span>
        );
      },
    },
    {
      title: translate('area'),
      key: '_id',
      render: (item: HouseTemplateRes) => {
        return <span>{item.area}</span>;
      },
    },
    {
      title: translate('status.name'),
      key: '_id',
      render: (item: HouseTemplateRes) => {
        return (
          <span>
            {item.status === BaseStatusProject.FOR_SALE ? translate('status.show') : translate('status.hidden')}
          </span>
        );
      },
    },
    {
      title: translate('custome'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
    },
  ];

  return (
    <>
      <FilterHeaderHouseTemplate
        onSearch={(value: string) => run(value)}
        dataProject={(listProject && listProject.data) || []}
        onChangeStatus={onChangeStatus}
        onChangeProject={handChangeProject}
      />
      <Row>
        {loading || loadingDelete ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            {listHouseTemplate && (
              <Table
                key={1}
                dataSource={listHouseTemplate || []}
                columns={renderColumns}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
              ></Table>
            )}
          </Col>
        )}
        {listHouseTemplate && (
          <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div className="d-flex justify-content-end pagination">
              <Pagination
                showSizeChanger={false}
                total={total}
                current={page}
                defaultCurrent={page}
                defaultPageSize={PaginateList.LIMIT_DEFAULT}
                onChange={onPageChange}
              ></Pagination>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
export default HouseTemplatepage;
