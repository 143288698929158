import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { InforAdminResponse } from 'types/response/AdminResponseRes';

function useUpdateArea(): [(id: string, request: any) => Promise<void>] {
  const history = useHistory();
  const onUpdateArea = async (id: string, request: any) => {
    try {
      const res: any = await ApiHelper.put<any, InforAdminResponse>(BASE_URL.UPDATE_AREA + `${id}`, request);
      if (res && res.status === 'BLOCK_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tên block đã tồn tại',
        });
      }

      if (res && res.status === StatusResponse.BLOCK_DO_NOT_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.data_do_not_exists'),
        });
        history.push(ROUTER_PATH.BLOCKS_AREA);
        return;
      }
      if (res && res.status == StatusResponse.BLOCK_ALREADY_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('Area.Area_ALREADY_EXISTS'),
        });
        return;
      }
      if (res && res.status == StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
        history.push(ROUTER_PATH.BLOCKS_AREA);
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };
  return [onUpdateArea];
}

export default useUpdateArea;
