/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import images from 'images';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import _ from 'lodash';
import FilterNotificationComponent from './components/filter-notification.component';
import useNotificationService from './notification.service';
import { NotificationFilter, NotificationRes, ProjectInfo } from './notification.type';

function NotificationScreen(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<NotificationFilter>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    status: undefined,
    search: undefined,
    projectId: undefined,
    typeOfRecipient: undefined,
  });

  const {
    onGetNotification,
    dataNotification,
    totalNotification,
    loading,
    onDeleteNotification,
    loadingDel,
  } = useNotificationService();

  useEffect(() => {
    onGetNotification(filter);
  }, [filter, loadingDel]);

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.EDIT_NOTIFICATION_SYSTEM + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={() => onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'title',
      render: (title: string) => {
        return <span>{title}</span>;
      },
      width: '35%',
    },
    {
      title: 'Người nhận',
      dataIndex: 'typeOfRecipient',
      render: (typeOfRecipient: string, data: NotificationRes) => {
        if (data.payload.dataBody) {
          if (JSON.parse(data.payload.dataBody).allUser) {
            return <span>Tất cả</span>;
          } else {
            switch (typeOfRecipient) {
              case 'ALL_USER':
                return <>Tất cả</>;
              case 'PRIVATE':
                return <>Cá nhân</>;
              case 'COMPANY':
                return <>Doanh nghiệp</>;
              default:
                break;
            }
          }
        }
      },
    },
    {
      title: 'Dự án',
      dataIndex: 'projectInfo',
      render: (projectInfo: ProjectInfo, data: NotificationRes) => {
        if (data.payload.dataBody) {
          if (JSON.parse(data.payload.dataBody).allUser) {
            return <span>Tất cả</span>;
          } else {
            return <span>{projectInfo ? projectInfo.name : 'Tất cả'}</span>;
          }
        }
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        return <span>{status === StatusType.ACTIVE ? 'Hoạt động' : 'Không hoạt động'}</span>;
      },
    },
    {
      title: 'Tùy chọn',
      dataIndex: '_id',
      render: renderAction,
    },
  ];

  const onDelete = (_id: string) => {
    onDeleteNotification([_id]);
  };

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm theo tên gói
  const { run } = useDebounceFn(
    (search: string) => {
      setFilter({ ...filter, search: search });
    },
    {
      wait: 500,
    },
  );

  // Thay đổi trạng thái
  const onStatus = (data: string) => {
    setFilter({ ...filter, status: data });
  };

  // Thay đổi dự án
  const onProject = (data: string) => {
    setFilter({ ...filter, projectId: data });
  };

  // Loại tài khoản
  const onTypeOfRecipient = (data: string) => {
    setFilter({ ...filter, typeOfRecipient: data });
  };

  // Action Xóa nhiều
  const [dataDelete, setDataDelete] = useState<string[]>([]);

  const onDeleteAll = () => {
    onDeleteNotification(dataDelete);
    setDataDelete([]);
  };

  const rowSelection = {
    selectedRowKeys: dataDelete,
    onChange: (selectedRowKeys: string[]) => {
      const arrDelete = dataDelete.filter(_id => !dataNotification.find(item => _id === item._id));
      setDataDelete(_.uniq([...arrDelete, ...selectedRowKeys]));
    },
  };

  return (
    <>
      <FilterNotificationComponent
        onSearch={(data: string) => run(data)}
        onStatus={onStatus}
        onProject={onProject}
        onTypeOfRecipient={onTypeOfRecipient}
      />
      <Row>
        {loading || loadingDel ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
              {dataDelete && dataDelete.length > 0 && (
                <Popconfirm
                  placement="top"
                  title="Bạn có chắc muốn xóa"
                  onConfirm={() => onDeleteAll()}
                  okText={translate('action.yes')}
                  cancelText={translate('action.no')}
                >
                  <img className="img-style" src={images.ic_delete} alt="" />
                  <span
                    style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                  >
                    Xóa nhiều
                  </span>
                </Popconfirm>
              )}
              <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                Đã chọn {dataDelete.length || 0}
              </span>
            </div>

            <Table
              rowKey="_id"
              dataSource={dataNotification || []}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              pagination={false}
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalNotification}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default NotificationScreen;
