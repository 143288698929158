/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';

export interface ListAdminData {
  total: number;
  data: any;
}

export interface ListAdminReq {
  status?: string;
  page?: number;
  limit?: string | number;
  search?: string;
}

function useListAdmin(): [(request: ListAdminReq) => Promise<void>, ListAdminData | undefined, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [listAdmin, getListAdmin] = useState<ListAdminData>();

  const onFetchListAdmin = async (request: ListAdminReq) => {
    setLoading(true);
    try {
      const res = await ApiHelper.fetch<ListAdminReq, any>(BASE_URL.LIST_ADMIN, request);
      setLoading(false);
      if (res && res.status === StatusResponse.SUCCESS) {
        getListAdmin({ data: res.data, total: res.total });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return [onFetchListAdmin, listAdmin, loading];
}

export default useListAdmin;
