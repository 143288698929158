import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { ToastSuccess, ToastError } from 'components/Toast';
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { StatusResponse } from './../../../constants/Status';
import { FilterWithDraw, WithDrawRes, WithDrawReq } from './with-draw.type';

const URL_WITHDRAW = '/project/withdrawal-commission-cms';
const URL_WITHDRAW_DEL = 'project/withdrawal-commission/';
const URL_WITHDRAW_DETAIL = '/project/detail-commission/';

function useWithDrawService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [dataWithDraw, setDataWithDraw] = useState<WithDrawRes[]>([]);
  const [totalWithDraw, setTotalWithDraw] = useState<number>();
  const [detailWithDraw, setDetailWithDraw] = useState<any>(undefined);
  const history = useHistory();

  // Danh sách yêu cầu rút tiền
  const onGetWithDraw = async (param: FilterWithDraw) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL_WITHDRAW, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalWithDraw(res.total);
        setDataWithDraw(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDataWithDraw([]);
    }
  };

  // Xóa yêu cầu rút tiền
  const onDeleteWithDraw = async (id: string) => {
    setLoadingDelete(true);
    const res: any = await ApiHelper.delete(`${URL_WITHDRAW_DEL}${id}`);
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.delete_success'),
      });
      setLoadingDelete(false);
    }
    try {
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
      setLoadingDelete(false);
    }
  };

  // Chi tiết yêu cầu rút tiền
  const onGetDetailWithDraw = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(`${URL_WITHDRAW_DETAIL}${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailWithDraw(res.data);
      } else {
        setDetailWithDraw(undefined);
      }
    } catch (error) {
      setDetailWithDraw(undefined);
    }
  };

  // Cập nhật trạng thái yêu cầu rút tiền
  const onUpdateWithDraw = async (id: string, body: WithDrawReq) => {
    const res: any = await ApiHelper.put(`${URL_WITHDRAW_DEL}${id}`, body);
    if (res && res.status === StatusResponse.SUCCESS) {
      history.goBack();
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.update_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: res.message || translate('messages.update_failed'),
      });
    }
  };

  return {
    onGetWithDraw,
    dataWithDraw,
    totalWithDraw,
    loading,
    onDeleteWithDraw,
    loadingDelete,
    onGetDetailWithDraw,
    detailWithDraw,
    onUpdateWithDraw,
  };
}

export default useWithDrawService;
