/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

export interface Props {
  onSearch: (data?: string) => void;
  onStatus: (data: string) => void;
  onProject: (data?: string) => void;
  onTypeOfRecipient: (data?: string) => void;
}

function FilterNotificationComponent(props: Props): JSX.Element {
  const { onSearch, onStatus, onProject, onTypeOfRecipient } = props;
  const history = useHistory();
  const [form] = Form.useForm();

  // Danh sách dự án
  const { listProject, onListProject } = useLitsHouseTemplate();

  useEffect(() => {
    onListProject({ status: STATUS_TYPE.ACTIVE });
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value ? event.target.value : undefined);
  };

  // Thay đổi trạng thái
  const handChangeStatus = (value: any) => {
    onStatus(value !== '' ? value : undefined);
  };

  // Select dự án
  const handChangeProject = (value: string) => {
    onProject(value !== '' ? value : undefined);
  };

  // Loại tài khoản
  const onChangeTypeOfRecipient = (value: string) => {
    onTypeOfRecipient(value !== '' ? value : undefined);
  };

  // Thêm mới
  const addNew = () => {
    history.push(ROUTER_PATH.ADD_NOTIFICATION_SYSTEM);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item name="search">
              <Input size="small" placeholder="Tiêu đề" onChange={handleSearch} suffix={<SearchOutlined />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="customer_type">
              <Select placeholder="Người nhận" onChange={onChangeTypeOfRecipient} allowClear>
                <Option value="ALL_USER">Tất cả</Option>
                <Option value="PRIVATE">Cá nhân</Option>
                <Option value="COMPANY">Doanh nghiệp</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="projectId">
              <Select placeholder="Dự án" onChange={handChangeProject}>
                <Option value="">Tất cả</Option>
                {listProject &&
                  listProject.length > 0 &&
                  listProject.map(data => {
                    return (
                      <Option value={data._id}>
                        {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="status">
              <Select placeholder="Trạng thái" onChange={handChangeStatus}>
                <Option value="">Tất cả</Option>
                <Option value={StatusType.ACTIVE}>Hoạt động</Option>
                <Option value={StatusType.INACTIVE}>Không hoạt động</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).create ? (
              <div>
                <Button style={{ display: 'block', float: 'right' }} type="primary" onClick={addNew}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterNotificationComponent;
