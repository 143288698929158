/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { formatNumber } from 'helpers/Utils';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import './with-draw.scss';
import useWithDrawService from './with-draw.service';
import { WithDrawReq } from './with-draw.type';

const { Text, Link } = Typography;

function WithDrawDetailScreen(): JSX.Element {
  const [form] = Form.useForm();
  const history = useHistory();

  const param: { id: string } = useParams();
  const isEdit = param && param.id;

  const { onGetDetailWithDraw, detailWithDraw, onUpdateWithDraw } = useWithDrawService();

  useEffect(() => {
    if (isEdit) {
      onGetDetailWithDraw(param.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (detailWithDraw) {
      form.setFieldsValue({ status: detailWithDraw.status, noteCMS: detailWithDraw.noteCMS });
    }
  }, [detailWithDraw]);

  const closeButton = () => {
    history.goBack();
  };

  const onFinish = (value: WithDrawReq) => {
    if (isEdit) {
      value.noteCMS = value.noteCMS ? value.noteCMS : undefined;
      onUpdateWithDraw(param.id, value);
    }
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_WITHDRAW).edit ? (
        <Form form={form} className="formWithDraw" onFinish={onFinish}>
          <Row>
            <Col span={20} offset={3}>
              <Row>
                {/* Họ và tên */}
                <Col span={16}>
                  <Form.Item label="Họ và tên" labelCol={{ span: 7 }} labelAlign="left">
                    <>
                      <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailWithDraw?.userInfo?._id} target="_blank">
                        <Text underline className="fontW18" style={{ color: '#12a0c0' }}>
                          {detailWithDraw?.userInfo?.name}
                        </Text>
                      </Link>
                    </>
                  </Form.Item>
                </Col>
                {/* Số điện thoại */}
                <Col span={16}>
                  <Form.Item label="Số điện thoại" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{detailWithDraw?.userInfo?.phone}</Text>
                  </Form.Item>
                </Col>
                {/* Email */}
                <Col span={16}>
                  <Form.Item label="Email" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{detailWithDraw?.userInfo?.email}</Text>
                  </Form.Item>
                </Col>
                {/* Số CMND */}
                <Col span={16}>
                  <Form.Item label="Số CMND" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{detailWithDraw?.userInfo?.identify}</Text>
                  </Form.Item>
                </Col>
                {/* Tên ngân hàng */}
                <Col span={16}>
                  <Form.Item label="Tên ngân hàng" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{detailWithDraw?.userInfo?.bankInfo?.name || ''}</Text>
                  </Form.Item>
                </Col>
                {/* Số tài khoản */}
                <Col span={16}>
                  <Form.Item label="Số tài khoản" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{detailWithDraw?.userInfo?.bankAccountNum}</Text>
                  </Form.Item>
                </Col>
                {/* Số hoa hồng khả dụng */}
                <Col span={16}>
                  <Form.Item label="Số hoa hồng khả dụng" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{formatNumber(detailWithDraw?.totalCommission)}</Text>
                  </Form.Item>
                </Col>
                {/* Số tiền yêu cầu */}
                <Col span={16}>
                  <Form.Item label="Số tiền yêu cầu" labelCol={{ span: 7 }} labelAlign="left">
                    <Text className="fontW18">{formatNumber(Math.abs(detailWithDraw?.money) || 0)}</Text>
                  </Form.Item>
                </Col>
                {/* Trạng thái */}
                <Col span={16}>
                  <Form.Item name="status" label="Trạng thái" labelCol={{ span: 7 }} labelAlign="left">
                    <Select>
                      <Select.Option value="PENDING_PAYMENT">Chờ thanh toán</Select.Option>
                      <Select.Option value="PENDING_APPROVED">Chờ duyệt</Select.Option>
                      <Select.Option value="APPROVED">Đã thanh toán</Select.Option>
                      <Select.Option value="CANCEL">Từ chối thanh toán</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* Ghi chú */}
                <Col span={16}>
                  <Form.Item name="noteCMS" label="Ghi chú" labelCol={{ span: 7 }} labelAlign="left">
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt20">
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {translate('action.update')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default WithDrawDetailScreen;
