/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { ReactElement } from 'react';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { translate } from 'translate/language';
import { Option } from 'antd/lib/mentions';
import styles from '../../HouseTemplate/css/house-template-create.module.scss';
import styles2 from '../css/sale-and-rent-create.module.scss';

interface SelectProps {
  label: string;
  data: any[];
  name?: string;
  istrans?: boolean;
  fistSelect?: string;
  styles?: React.CSSProperties;
  rules?: any[];
  multiple?: boolean;
  onPopupScroll?: any;
  styleCol?: React.CSSProperties;
  onChange?: any;
  initialValueSelect?: string;
}
interface InputProps {
  label: string;
  name?: string;
  onChange?: any;
  value?: string;
  fistSelect?: string;
  disabled?: boolean;
  styles?: React.CSSProperties;
  rules?: any[];
  styleCol?: React.CSSProperties;
  initialValueSelect?: string;
  max?: number | undefined;
}

export function InputSelect(props: SelectProps): ReactElement {
  const {
    label,
    data,
    multiple,
    name,
    istrans,
    fistSelect,
    styleCol = {},
    rules,
    initialValueSelect,
    onChange,
  } = props;
  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      rules={
        rules && [
          {
            required: true,
            message: translate(label) + ' ' + translate('require'),
          },
        ]
      }
      labelCol={{ md: 8, xl: 8, xs: 24 }}
      wrapperCol={{ md: 12, xs: 24 }}
      style={{ justifyContent: 'center', display: 'flex' }}
      initialValue={initialValueSelect}
    >
      <Select size="middle" {...props} mode={multiple ? 'multiple' : undefined} onChange={onChange}>
        {fistSelect && <Option>{fistSelect}</Option>}
        {data &&
          data.map((e: any) => {
            return <Option key={e._id}>{!istrans ? e.name || e.child : translate(e.name)}</Option>;
          })}
      </Select>
    </Form.Item>
  );
}

export function SelectInputCOl(props: SelectProps) {
  const {
    label,
    data,
    multiple,
    name,
    fistSelect,
    styleCol = {},
    rules,
    onChange,
    onPopupScroll,
    initialValueSelect,
  } = props;

  return (
    <Col md={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 24 }}>
      <Row justify="center">
        <div className={rules ? styles.nameLabel : styles.nameLabelNotValid}>{translate(label)}</div>
      </Row>

      <Form.Item
        name={name}
        style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
        rules={
          rules && [
            {
              required: true,
              message: translate(label) + ' ' + translate('require'),
            },
          ]
        }
        initialValue={initialValueSelect}
      >
        <Select size="middle" {...props} style={{ width: '100%' }} mode={multiple ? 'multiple' : undefined}>
          {fistSelect && <Option>{fistSelect}</Option>}
          {data &&
            data.map((e: any) => {
              return <Option key={e._id}>{e.name || e.child}</Option>;
            })}
        </Select>
      </Form.Item>
    </Col>
  );
}
export function InputCol(props: InputProps) {
  const { label, name, fistSelect, styleCol = {}, rules, initialValueSelect } = props;

  return (
    <Col md={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 24 }}>
      <Row justify="center">
        <div className={rules ? styles.nameLabel : styles.nameLabelNotValid}>{translate(label)}</div>
      </Row>

      <Form.Item
        name={name}
        style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
        rules={
          rules && [
            {
              required: true,
              message: translate(label) + ' ' + translate('require'),
            },
          ]
        }
        initialValue={initialValueSelect}
      >
        <Input />
      </Form.Item>
    </Col>
  );
}
export function InputColNumber(props: InputProps) {
  const { label, name, fistSelect, styleCol = {}, onChange, rules, initialValueSelect } = props;

  return (
    <Col md={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 24 }}>
      <Row justify="center">
        <div className={rules ? styles.nameLabel : styles.nameLabelNotValid}>{translate(label)}</div>
      </Row>

      <Form.Item
        name={name}
        style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
        rules={
          rules && [
            {
              required: true,
              message: translate(label) + ' ' + translate('require'),
            },
          ]
        }
        initialValue={initialValueSelect}
      >
        <InputNumber
          onChange={onChange}
          style={{ width: '100%' }}
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value: any) => value.replace(/(\,*)/g, '')}
          step={1000}
          min={0}
        />
      </Form.Item>
    </Col>
  );
}

export function InputText(props: InputProps): ReactElement {
  const { label, name, disabled, fistSelect, styleCol = {}, rules, initialValueSelect, onChange } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      style={{ justifyContent: 'center', display: 'flex' }}
      rules={
        rules && [
          {
            required: true,
            message: translate(label) + ' ' + translate('require'),
          },
          ...rules,
        ]
      }
      labelCol={{ md: 8, xl: 8, xs: 24 }}
      wrapperCol={{ md: 12, xs: 24 }}
      initialValue={initialValueSelect}
    >
      <Input {...props} style={{ background: 'white', color: 'black' }} disabled={disabled} onChange={onChange} />
    </Form.Item>
  );
}
export function InputTextNumber(props: InputProps): ReactElement {
  const { label, name, disabled, fistSelect, styleCol = {}, rules, initialValueSelect, onChange, max } = props;
  console.log('🚀 ~ file: InputSelect.tsx:217 ~ InputTextNumber ~ initialValueSelect:', initialValueSelect);
  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      style={{ justifyContent: 'center', display: 'flex' }}
      rules={
        rules && [
          {
            required: true,
            message: translate(label) + ' ' + translate('require'),
          },
        ]
      }
      labelCol={{ md: 8, xl: 8, xs: 24 }}
      wrapperCol={{ md: 12, xs: 24 }}
      initialValue={initialValueSelect}
    >
      <InputNumber
        onChange={onChange}
        style={{ width: '100%' }}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={(value: any) => value.replace(/(\,*)/g, '')}
        step={1}
        min={0}
        max={max || undefined}
        disabled={disabled || false}
      />
    </Form.Item>
  );
}

export function InputTextArea(props: InputProps): ReactElement {
  const { label, name, fistSelect, styleCol = {}, rules, initialValueSelect } = props;
  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      style={{ justifyContent: 'center', display: 'flex' }}
      rules={
        rules && [
          {
            required: true,
            message: translate(label) + ' ' + translate('require'),
          },
        ]
      }
      labelCol={{ md: 8, xl: 8, xs: 24 }}
      wrapperCol={{ md: 12, xs: 24 }}
      initialValue={initialValueSelect}
    >
      <Input.TextArea style={{ resize: 'none' }} rows={4} />
    </Form.Item>
  );
}

export function InputShowText(props: InputProps): ReactElement {
  const { label, name, disabled, fistSelect, styleCol = {}, rules, initialValueSelect } = props;
  return (
    <Form.Item
      name={name}
      label={label}
      labelAlign="left"
      style={{ justifyContent: 'center', display: 'flex' }}
      rules={
        rules && [
          {
            required: true,
            message: translate(label) + ' ' + translate('require'),
          },
        ]
      }
      labelCol={{ md: 8, xl: 8, xs: 24 }}
      wrapperCol={{ md: 12, xs: 24 }}
      initialValue={initialValueSelect}
    >
      <div className={styles2.DivNotText}>{props.value}</div>
    </Form.Item>
  );
}
