/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { DownloadOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Table, Upload } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import Modal from 'antd/lib/modal/Modal';
import Container from 'components/ContentLayout/Container';
import { ToastError, ToastSuccess } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject, StatusResponse } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useListApartment from 'hooks/Apartment/useListApartment';
import useListArea, { BlockStatus } from 'hooks/BlockArea/useListArea';
import ApiHelper from 'network/ApiClient';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import '../house.scss';

const { Option } = Select;
const EXPORT_HOUSE = '/house/export';

export interface Props {
  onChangeProjectH: (value: string) => void;
  onChangeAreaH: (value: string) => void;
  onChangeSearchH: (value: string) => void;
  onChangeApartmentH: (value: string) => void;
  onChangeStatusH: (value: string) => void;
  onCheckImport: (data: string) => void;
}

export interface FilterExcel {
  project?: string;
  block?: string;
  search?: string;
  bedroom?: string;
  status?: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function FilterHouse(props: Props): JSX.Element {
  const {
    onChangeProjectH,
    onChangeAreaH,
    onChangeSearchH,
    onChangeApartmentH,
    onChangeStatusH,
    onCheckImport,
  } = props;
  const [form] = Form.useForm();
  const { listProject, onListProject } = useLitsHouseTemplate();
  const [onFetchListArea, listArea] = useListArea();
  const [onFetchListApartment, listApartment] = useListApartment();
  const query: any = useQuery();
  const [filterExcel, setFilterExcel] = useState<FilterExcel>({
    project: undefined,
    block: undefined,
    search: undefined,
    bedroom: undefined,
    status: undefined,
  });
  const [updateExisting, setUpdateExisting] = useState<string>('false');
  const [fileExcel, setFileExel] = useState<any[]>([]);
  const [checkModal, setCheckModal] = useState(false);
  const [checkExcel, setCheckExcel] = useState<boolean>(false);
  const [errorExcel, setErrorExcel] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataError, setDataError] = useState<any[]>([]);
  const [totalError, setTotalError] = useState<number>(0);
  const [totalSuccess, setTotalSuccess] = useState<number>(0);

  useEffect(() => {
    onListProject({ status: STATUS_TYPE.ACTIVE });
    onFetchListApartment({});
  }, []);

  const onChangeProject = (value: string) => {
    onChangeProjectH(value);
    form.setFieldsValue({
      blockId: '',
    });
    onFetchListArea({
      project: value,
      status: BlockStatus.ACTIVE,
    });
    setFilterExcel({ ...filterExcel, project: value });
  };
  const onChangeArea = (value: string) => {
    onChangeAreaH(value);
    setFilterExcel({ ...filterExcel, block: value });
  };
  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearchH(event.target.value);
    setFilterExcel({ ...filterExcel, search: event.target.value });
  };
  const onChangeApartment = (value: string) => {
    onChangeApartmentH(value);
    setFilterExcel({ ...filterExcel, bedroom: value });
  };
  const onChangeStatus = (value: string) => {
    onChangeStatusH(value);
    setFilterExcel({ ...filterExcel, status: value });
  };

  const exportExcel = async () => {
    const res = await ApiHelper.post(EXPORT_HOUSE, filterExcel, true);
    if (res.status === StatusResponse.SUCCESS) {
      window.open(getUrlExport(res.data));
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.error'),
      });
    }
  };

  // Nhập Excel
  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setCheckModal(false);
    setIsModalVisible(false);
    setCheckExcel(false);
  };

  const onChangeExcel = (e: any) => {
    if (e.target.checked) {
      setUpdateExisting('true');
    } else {
      setUpdateExisting('false');
    }
  };

  const handleDownloadTemplate = async () => {
    const res: any = await ApiHelper.fetch('/house/download-template', null, true);
    window.open(getUrlExport(res.data));
  };

  const handleSubmitUpload = async (e: any) => {
    e.preventDefault();
    if (fileExcel.length === 0) {
      setCheckModal(true);
      return;
    }
    setCheckModal(false);
    setCheckExcel(true);
    const data = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    if (fileExcel?.length > 0) {
      data.append('updateExisting', updateExisting);
      data.append('file', fileExcel[0].originFileObj);
      const res: any = await ApiHelper.postFormData('/house/import', data, headers);
      if (res && res.status === StatusResponse.FILE_IS_NOT_FORMULATED) {
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.FILE_IS_NOT_FORMULATED'),
        });
      }

      if (res && res.status === 'CODE_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Mã căn hộ đã tồn tại',
        });
      }

      if (res && res.status === 'FILE_IS_NOT_CORRECT_FORMAT') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'File không đúng định dạng',
        });
      }

      if (res && res.status === 'FLOOR_IS_NOT_EMPTY') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Số tầng không được bỏ trống',
        });
      }

      if (res && res.status === 'CODE_CANNOT_BE_BLANK') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Mã căn không được bỏ trống',
        });
      }

      if (res && res.status === 'AREA_CANNOT_BE_BLANK') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Diện tích không được bỏ trống',
        });
      }

      if (res && res.status === 'PRICE_CANNOT_BE_BLANK') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Giá không được bỏ trống',
        });
      }

      if (res && res.status === 'PROJECT_DO_NOT_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Dự án không khả dụng',
        });
      }

      if (res && res.status === 'BLOCK_DO_NOT_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Block không khả dụng',
        });
      }

      if (res && res.status === 'DIRECTION_DO_NOT_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Hướng nhà không khả dụng',
        });
      }

      if (res && res.status === 'STATUS_CANNOT_BE_CHANGED') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Không thể thay đổi trạng thái',
        });
      }

      if (res && res.status === StatusResponse.SUCCESS) {
        setDataError(res.data.errors);
        setTotalError(res.data.fail);
        setTotalSuccess(res.data.success);
        if (res.data.fail > 0) {
          setFileExel([]);
          setErrorExcel(true);
          if (res.data.errors && res.data.errors.length > 0 && res.data.errors[0].error === 'HOUSE_ALREADY_EXISTS') {
            return ToastError({
              message: translate('message_type.failed'),
              description: 'Tên căn hộ/lô đất đã tồn tại',
            });
          }
          return ToastError({
            message: translate('message_type.failed'),
            description: translate('messages.import_error'),
          });
        }

        closeModal();

        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.import_success'),
        });
      }
      setFileExel([]);
      onCheckImport('success');
    }
  };

  const handleChangeFileUpload = ({ fileList }: any) => {
    if (fileList && fileList.length > 0) {
      fileList.map((e: any, index: number) => {
        e.status = 'done';
        return e;
      });
      setFileExel(fileList);
      setCheckModal(false);
    } else {
      setCheckModal(true);
    }
  };

  const onRemove = (file: any) => {
    setFileExel([]);
    setCheckExcel(false);
  };

  const columns = [
    {
      title: 'STT',
      render: (_: any, data: any, index: number) => {
        return index + 1;
      },
    },
    {
      title: 'Mã căn',
      dataIndex: 'code',
      render: (code: string) => {
        return code;
      },
    },
    {
      title: 'Lỗi',
      dataIndex: 'message',
      render: (message: string) => {
        return message;
      },
    },
  ];

  return (
    <>
      <Modal
        title="Nhập từ excel"
        visible={isModalVisible}
        onCancel={closeModal}
        cancelText="Đóng"
        okText="Tải lên"
        onOk={handleSubmitUpload}
      >
        <Container>
          <Row>
            <Col sm={24}>
              <Form.Item label="Tải xuống mẫu" labelCol={{ sm: 8 }} labelAlign="left">
                <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleDownloadTemplate} />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Tải lên file excel"
                labelAlign="left"
                labelCol={{ sm: 8 }}
                name="upload_file_excel"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleChangeFileUpload}
                  fileList={fileExcel}
                  onRemove={onRemove}
                >
                  {fileExcel && fileExcel.length > 0 ? '' : <Button icon={<UploadOutlined />}>Tải lên</Button>}
                </Upload>
              </Form.Item>
              {checkModal ? (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div>Vui lòng chọn File tải lên </div>
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Checkbox onChange={onChangeExcel}>Cập nhật giỏ hàng có sẵn</Checkbox>
            </Col>
            {checkExcel && (
              <>
                <Col span={12}>Thành công : {totalSuccess}</Col>
                <Col span={12}>Thất bại : {totalError}</Col>
                <Col span={24}>
                  <Table dataSource={dataError || []} columns={columns} pagination={false} />
                </Col>
              </>
            )}
          </Row>
        </Container>
      </Modal>
      <Form form={form}>
        <Row gutter={24} className="marginBottom">
          {/* Tên dự án */}
          <Col span={3}>
            <Select
              defaultValue={query.get('idProject')}
              className="width100"
              placeholder={translate('house.projectId')}
              onChange={onChangeProject}
              allowClear
            >
              {listProject &&
                listProject.length > 0 &&
                listProject.map(data => {
                  return (
                    <Option value={data._id}>
                      {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          {/* Tên block khu đất */}
          <Col span={3}>
            <Form.Item name="blockId">
              <Select
                defaultValue={query.get('idBlock')}
                className="width100"
                placeholder={translate('house.blockId')}
                onChange={onChangeArea}
                allowClear
              >
                {listArea.data &&
                  listArea.data.length > 0 &&
                  listArea.data.map((data: any) => {
                    return <Option value={data?._id}>{data?.name}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="search">
              <Input
                size="small"
                suffix={<SearchOutlined />}
                onChange={onChangeSearch}
                placeholder={translate('house.search_house')}
              />
            </Form.Item>
          </Col>
          {/* Số phòng ngủ */}
          <Col span={3}>
            <Select
              className="width100"
              placeholder={translate('house.bedroom')}
              onChange={onChangeApartment}
              allowClear
            >
              {listApartment.data &&
                listApartment.data.length > 0 &&
                listApartment.data.map((data: any) => {
                  return (
                    <Option value={data._id}>
                      {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col span={3}>
            <Select placeholder={translate('house.status')} className="width100" onChange={onChangeStatus} allowClear>
              <Option value={BaseStatusProject.FOR_SALE}>{translate('BaseStatusProject.FOR_SALE')}</Option>
              <Option value={BaseStatusProject.NOT_FOR_SALE}>{translate('BaseStatusProject.NOT_FOR_SALE')}</Option>
              <Option value={BaseStatusProject.SOLD}>{translate('BaseStatusProject.SOLD')}</Option>
              <Option value={BaseStatusProject.DEPOSITED}>{translate('BaseStatusProject.DEPOSITED')}</Option>
              <Option value={BaseStatusProject.PAYMENT}>{translate('BaseStatusProject.PAYMENT')}</Option>
            </Select>
          </Col>
          <Col span={2}>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).create ? (
              <Button className="width100 dFlex" type="primary" onClick={showModal}>
                Nhập Excel
              </Button>
            ) : (
              ''
            )}
          </Col>
          <Col span={2}>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).export ? (
              <Button className="width100 dFlex" type="primary" onClick={exportExcel}>
                Xuất Excel
              </Button>
            ) : (
              ''
            )}
          </Col>
          <Col span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).create ? (
              <div>
                <Button className="width100 dFlex" type="primary">
                  <Link to={ROUTER_PATH.ADD_HOUSE_LOTS}>
                    <span>Thêm mới</span>
                  </Link>
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterHouse;
