export interface Memmber {
  _id: string;
  name: string;
  role?: string[];
  avatarId: IAvatarId | null;
  color?: string;
}

export interface IRoleChat {
  client: string;
  introducer: string;
  owner: string;
  supporter: string;
}

export interface MessageUpdateInput {
  transactionId: string;
  idMessage: string;
  isHide: boolean;
}
export interface MessageViewInput {
  transactionId: string;
  userId: string;
}

export const ROLE_INDIVIDUAL: IRoleChat = {
  client: 'KHÁCH HÀNG',
  introducer: 'NGƯỜI GIỚI THIỆU',
  owner: 'NGƯỜI ĐĂNG TIN',
  supporter: 'CTV HỖ TRỢ',
};

export const ROLE_PAG: IRoleChat = {
  client: 'Khách hàng',
  introducer: 'CTV giới thiệu',
  owner: 'Phụ trách dự án',
  supporter: 'CTV hỗ trợ',
};

export interface IDataListMember {
  client: Memmber;
  introducer: Memmber;
  owner: Memmber;
  supporter: Memmber;
}

export interface IAvatarId {
  thumbnail: string;
  thumbnail2x: string;
  url: string;
  _id: string;
}
