/* eslint-disable import/no-useless-path-segments */
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastError, ToastSuccess } from './../../../components/Toast/index';
import { StatusResponse } from './../../../constants/Status';
import { translate } from './../../../translate/language';

export interface RelatedType {
  _id: string;
  introducerId: string;
  status: string;
  userId: string;
  createdAt: moment.Moment;
  updatedAt: moment.Moment;
  userInfo: UserInfoRelated;
  introducerInfo: UserInfoRelated;
}

export interface UserInfoRelated {
  _id: string;
  name: string;
}

export interface HistoryTransaction {
  _id: string;
  deletedAt: moment.Moment | null;
  note: string;
  projectNewsTransactionId: string;
  status: string;
  createdAt: moment.Moment;
  __v: number;
}

const URL_RELATED = '/project-news/transaction/related/';
const URL_HISTORY = '/project-news/transaction/detail-cms/';
const URL_UPDATE_RELATED = '/project-news/transaction/status/';
const URL_H = '/project-news/transaction/history/';

function useRelatedHook() {
  const [dataRelated, setDataRelated] = useState<RelatedType[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const [dataHistory, setDataHistory] = useState<any>({});
  const history = useHistory();
  const [historyTransaction, setHistoryTransaction] = useState<HistoryTransaction[]>([]);

  const onGetRelated = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(URL_RELATED + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataRelated(res.data);
      }
    } catch (error) {
      setDataRelated([]);
    }
  };

  // Chi tiết giao dịch BĐS lẻ
  const onDetailHistory = async (id: string) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL_HISTORY + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataHistory(res.data);
        setLoading(false);
      } else {
        setDataHistory({});
        setLoading(false);
      }
    } catch (error) {
      setDataHistory({});
      setLoading(false);
    }
  };

  const updateViewMessageNews = async (body: any) => {
    try {
      const res: any = await ApiHelper.post('/project-news/transaction/update-view-message', body);
      if (res.status === StatusResponse.SUCCESS) {
        if (res.data._id === dataHistory!._id) {
          setDataHistory({ ...dataHistory!, areView: res.data.areView });
        }
      }
    } catch (error) {}
  };

  // Cập nhật chi tiết BĐS lẻ
  const onUpdateRelated = async (
    data: {
      note: string;
      status: string;
    },
    id: string,
  ) => {
    try {
      const res: any = await ApiHelper.put(URL_UPDATE_RELATED + id, data);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  // Lịch sử giao dịch
  const onHistoryTransaction = async (id: string) => {
    const res: any = await ApiHelper.fetch(URL_H + id);
    if (res && res.status === StatusResponse.SUCCESS) {
      setHistoryTransaction(res.data);
    } else {
      setHistoryTransaction([]);
    }
  };

  return {
    onGetRelated,
    dataRelated,
    onDetailHistory,
    dataHistory,
    loading,
    onUpdateRelated,
    onHistoryTransaction,
    historyTransaction,
    updateViewMessageNews,
  };
}

export default useRelatedHook;
