import React, { useEffect, useState } from 'react';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { ReqCity, ReqDistrict, ReqWard } from '../modelSaleAndRental/SaleAndRental.type';
import { City, District, Ward } from 'types/type/countryType';
import { BaseResponse } from 'types/response/BaseResponse';

function useCityDistrictWard() {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState<number>(0);
  const [dataCity, setDataCity] = useState<City[]>([]);
  const [stopLoadmore, setStoploadmore] = useState<boolean>(false);

  const [dataDistrict, setDataDistrict] = useState<District[]>([]);
  const [dataWard, setDataWard] = useState<Ward[]>([]);
  const getListCity = async (request: ReqCity) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<any, BaseResponse>(BASE_URL.CITY, request, true);
      if (res && res.status === StatusResponse.SUCCESS) {
        const newData = request.page! > 1 ? [...dataCity, ...res.data] : res.data;
        setDataCity(newData);
        if (request.page! > 1 && res.data.length === 0) {
          setStoploadmore(true);
          setTimeout(() => {
            setStoploadmore(false);
          }, 1000);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getListDistrict = async (request: ReqDistrict) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<any, BaseResponse>(BASE_URL.DISTRICT, request, true);
      if (res && res.status === StatusResponse.SUCCESS) {
        const newData = request.page! > 1 ? [...dataDistrict, ...res.data] : res.data;
        setDataDistrict(newData);
        if (request.page! > 1 && res.data.length === 0) {
          setStoploadmore(true);
          setTimeout(() => {
            setStoploadmore(false);
          }, 1000);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getListWard = async (request: ReqWard) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<any, BaseResponse>(BASE_URL.WARD, request, true);
      if (res && res.status === StatusResponse.SUCCESS) {
        const newData = request.page! > 1 ? [...dataWard, ...res.data] : res.data;
        setDataWard(newData);
        if (request.page! > 1 && res.data.length === 0) {
          setStoploadmore(true);
          setTimeout(() => {
            setStoploadmore(false);
          }, 1000);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return {
    getListCity,
    getListDistrict,
    getListWard,
    dataCity,
    dataDistrict,
    stopLoadmore,
    dataWard,
    setDataDistrict,
    setDataWard,
  };
}
export default useCityDistrictWard;
