/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';

function useDetailProject() {
  const [detailProject, setDetailProject] = useState<any | undefined>();
  const [messageProject, setMessageProject] = useState(false);

  const onDetailProject = async (id: string) => {
    try {
      setMessageProject(false);
      const res = await ApiHelper.fetch<string, any>(BASE_URL.DETAIL_PROJECT + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailProject(res?.data);
      } else {
        setMessageProject(false);
      }
    } catch (error) {
      setMessageProject(false);
    }
  };

  return { onDetailProject, detailProject };
}

export default useDetailProject;
