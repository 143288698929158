import { ToastError } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { InforAdminResponse } from 'types/response/AdminResponseRes';
import { User } from 'types/response/UserResponse';

function useDetailDirection(): [(request: string) => Promise<void>, any | undefined] {
    const [detailDirection, getDirection] = useState<any | undefined>();
    const history = useHistory();
    const useGetDetailDirection = async (request: string) => {
        try {
            const res = await ApiHelper.fetch<string, InforAdminResponse>(
                BASE_URL.DETAIL_DIRECTION + `${request}`
            )
            if (res && res.status == StatusResponse.DIRECTION_DO_NOT_EXISTS) {
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.data_do_not_exists"),
                });
                history.push(ROUTER_PATH.DIRECTION)
                return
            }
            if (res && res.status == StatusResponse.SUCCESS) {
                getDirection(res.data)
            }
        } catch (error) {
            getDirection(undefined)
        }
    }
    return [useGetDetailDirection, detailDirection];
}

export default useDetailDirection;