/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Col, Image, Typography } from 'antd';
import { getUrlImage, getUrlImageChat } from 'helpers/getImageUrl';
import moment from 'moment';
import React, { useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { Message } from '../MessageType';
import styles from './item.module.scss';

const { Text, Link } = Typography;

function InputChatItem(props: { item: Message; onUpdateMessage: (id: string, isHide: boolean) => void }) {
  const [view, setView] = useState(props.item.isHide);

  const onClickView = () => {
    setView(!view);
    if (props.item) {
      props.onUpdateMessage(props.item._id, !props.item.isHide);
    }
  };
  return (
    <Col span={24}>
      <div className={styles.container}>
        <div className={styles.inputChat}>
          <img
            className={styles.imageAvatar}
            src={
              props.item.creator && props.item.creator.length > 0 && props.item.creator[0].avatar
                ? getUrlImage(props.item.creator[0].avatar)
                : `${process.env.REACT_APP_API_DOMAIN}/statics/images/default-avatar.jpg`
            }
          />
        </div>
        <div>
          <div style={{ color: 'gray', fontSize: '13px' }}>
            {moment(props.item.createdAt).format('HH:mm DD-MM-YYYY ')}
          </div>
          <div>
            <Link
              href={`${ROUTER_PATH.EDIT_CUSTOMER}${props.item.creator && props.item.creator[0]._id}`}
              target="_blank"
              className="fontSize18"
            >
              <Text underline style={{ color: '#12a0c0' }}>
                {(props.item.creator && props.item.creator[0].name) || 'Admin'}
              </Text>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        {props.item.type === 'TEXT' && <div className="border-comment">{props.item.text}</div>}
        {props.item.type === 'MEDIA' &&
          props.item.medias &&
          props.item.medias.length > 0 &&
          props.item.medias.map(e => (
            <div className="border-comment" style={{ marginRight: 10 }}>
              <Image width={200} height={200} src={getUrlImageChat(e)} />
            </div>
          ))}
        <div style={{ marginLeft: 10 }} className="view">
          {!view ? (
            <span className="view" onClick={onClickView}>
              <EyeOutlined />
            </span>
          ) : (
            <span className="view" onClick={onClickView}>
              <EyeInvisibleOutlined />
            </span>
          )}
        </div>
      </div>
    </Col>
  );
}

export default InputChatItem;
