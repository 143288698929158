/* eslint-disable react-native/no-inline-styles */
import { Row, Col, Typography } from 'antd';
import { formatNumber } from 'helpers/Utils';
import React, { useEffect } from 'react';
import useAnalysisService from '../analysis.service';

const { Title, Text } = Typography;

function PropertyComponent(): JSX.Element {
  const { onGetDataEstate, dataRetailRealEstate } = useAnalysisService();

  useEffect(() => {
    onGetDataEstate();
  }, []);

  return (
    <>
      <Row>
        <Col span={23} offset={1}>
          <Title level={2}>Bất động sản lẻ</Title>
          <Row style={{ marginTop: '15px' }}>
            <Col span={9}>
              <Text strong style={{ fontSize: '20px' }}>
                Tổng số người quan tâm tới các BĐS lẻ (môi giới)
              </Text>
            </Col>
            <Col span={12}>
              <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
                {formatNumber(
                  dataRetailRealEstate?.totalUserCareRetailRealEstateAgency
                    ? dataRetailRealEstate?.totalUserCareRetailRealEstateAgency
                    : 0,
                )}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col span={9}>
              <Text strong style={{ fontSize: '20px' }}>
                Tổng số người quan tâm tới các BĐS lẻ (chính chủ)
              </Text>
            </Col>
            <Col span={12}>
              <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
                {formatNumber(
                  dataRetailRealEstate?.totalUserCareRetailRealEstateOwner
                    ? dataRetailRealEstate?.totalUserCareRetailRealEstateOwner
                    : 0,
                )}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col span={9}>
              <Text strong style={{ fontSize: '20px' }}>
                Tổng số người quan tâm tới các BĐS lẻ (miễn trung gian)
              </Text>
            </Col>
            <Col span={12}>
              <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
                {formatNumber(
                  dataRetailRealEstate?.totalUserCareRetailRealEstateNoAgency
                    ? dataRetailRealEstate?.totalUserCareRetailRealEstateNoAgency
                    : 0,
                )}
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col span={9}>
              <Text strong style={{ fontSize: '20px' }}>
                Tổng số người quan tâm tới các BĐS lẻ (Phúc An Gia)
              </Text>
            </Col>
            <Col span={12}>
              <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
                {formatNumber(
                  dataRetailRealEstate?.totalUserCareRetailRealEstatePag
                    ? dataRetailRealEstate?.totalUserCareRetailRealEstatePag
                    : 0,
                )}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default PropertyComponent;
