export const formatNumber = (number: number) => {
  return new Intl.NumberFormat('vi').format(number);
};

export const validateLinkYoutube = (link: string) => {
  const str = link;
  const patt = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/;
  const result = str.match(patt);
  if (result) {
    return true;
  }
  return false;
};
