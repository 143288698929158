import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import SocketIOClient from 'socket.io-client';
import { BASE_URL } from './BaseURL';

const dataStorage = LocalStorageHelper.getObject(StorageKey.USER_SESSION) || {};
const authorization = dataStorage.token ? `Bearer ${dataStorage.token}` : null;
const socketIOClient = SocketIOClient(
  process.env.REACT_APP_API_DOMAIN + BASE_URL.CMS_PERMISSION || 'ws://localhost:4000',
  { transports: ['polling'], query: { authorization }, forceNew: true },
);
console.log(socketIOClient);
export default socketIOClient;
