/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';

export enum BlockStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ACTIVE_NOT_VERIFIED = 'ACTIVE_NOT_VERIFIED',
  FOR_SALE = 'FOR_SALE',
  NOT_FOR_SALE = 'NOT_FOR_SALE',
}

export interface ListAreaReq {
  page?: number;
  limit?: string | number;
  search?: string;

  status?: string | BlockStatus;
  project?: string;
}

function useListArea(): [(request: ListAreaReq) => Promise<void>, any, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [listArea, getListArea] = useState([]);

  const onFetchListArea = async (request: ListAreaReq) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<ListAreaReq, any>(BASE_URL.LIST_AREA, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        getListArea(res);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return [onFetchListArea, listArea, loading];
}

export default useListArea;
