import { CaretDownOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, Row, Spin } from 'antd';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetLogin } from 'redux/actions';
import { translate } from 'translate/language';
import HeaderDropdown from '../HeaderDropdown';

const AvatarDropdown = (props: any) => {
  const {
    currentUser = {
      avatar: 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png',
      name: 'John Does',
    },
    menu,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const onMenuClick = (event: any) => {
    const { key } = event;

    if (key === 'logout') {
      // Logout
      return;
    }
    history.push(`/account/${key}`);
  };

  // Đăng xuất CMS
  const logout = () => {
    dispatch(resetLogin());
    LocalStorageHelper.remove(StorageKey.TOKEN);
    LocalStorageHelper.remove(StorageKey.USER_SESSION);
  };

  const user = useSelector((state: any) => {
    const user = state.userReducer;
    return user;
  });

  const menuHeaderDropdown = (
    <Menu className="menu" selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="logout" onClick={e => logout()}>
        <LogoutOutlined />
        {translate('logout')}
      </Menu.Item>
    </Menu>
  );

  return user ? (
    <HeaderDropdown overlay={menuHeaderDropdown} placement="bottomRight">
      <Row className={`${'action'} ${'account'}`}>
        <span>
          <Avatar size="large" className="avatar" src={currentUser.avatar} alt="avatar" />
        </span>
        <span className="avatar_name">{user?.name}</span>
        <CaretDownOutlined />
      </Row>
    </HeaderDropdown>
  ) : (
    <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
  );
};

export default AvatarDropdown;
