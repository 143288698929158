/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { translate } from 'translate/language';
import blockWorksService from './forbidden-keywords.service';

function ForbiddenKeywordsScreen(): JSX.Element {
  const [form] = Form.useForm();

  const { onUpdateBlockKeyWord, onGetBlockWork, dataBlockWork } = blockWorksService();

  useEffect(() => {
    onGetBlockWork();
  }, []);

  useEffect(() => {
    if (dataBlockWork) {
      // setContent(dataBlockWork.content);
      form.setFieldsValue({ content: dataBlockWork.content });
    }
  }, [dataBlockWork]);

  const onFinish = (value: { content: string }) => {
    onUpdateBlockKeyWord(value);
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="content"
              label={'Từ khóa không được dùng trên App Bất động sản 4.0:'}
              labelCol={{ span: 12 }}
              labelAlign="left"
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {translate('action.update')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ForbiddenKeywordsScreen;
