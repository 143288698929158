/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Col, Form, Input, Row } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useDetailProperty from 'hooks/PropertyType/useDetailProperty';
import useUpdateProperty from 'hooks/PropertyType/useUpdateProperty';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';

function FormEditParent(): JSX.Element {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;

  // Lấy chi tiết loại bất động sản
  const [useGetDetailProperty, detailProperty] = useDetailProperty();
  // Cập nhật dữ liệu
  const [onUpdateProperty] = useUpdateProperty();

  useEffect(() => {
    useGetDetailProperty(params.id);
  }, []);

  useEffect(() => {
    if (detailProperty) {
      form.setFieldsValue({
        name: detailProperty.name,
      });
    }
  }, [detailProperty]);

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const data = {
        name: values.name,
        status: StatusType.ACTIVE,
      };
      onUpdateProperty(params.id, data);
    });
  };

  // Validate dữ liệu
  const validateForm = {
    name: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('property.name'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('property.name_validate'),
      },
    ],
  };

  // Action quay lại
  const closeButton = () => {
    history.back();
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).edit ? (
        <Form form={form}>
          <Row>
            <Col span={20} offset={3}>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="name"
                      label={translate('property.name')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={validateForm.name}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" onClick={handleSubmit} htmlType="submit">
                          {isEdit ? translate('action.update') : translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormEditParent;
