/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { useDebounce } from 'ahooks';
import { Button, Col, Form, Image, Input, InputNumber, message, Row, Select, Switch, Upload } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getUrlImage } from 'helpers/getImageUrl';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useListCustomer from 'hooks/Customer/useListCustomer';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import ApiHelper from 'network/ApiClient';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useRef, useState } from 'react';
import { translate } from 'translate/language';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import useConfigOtherService from './config-other.service';

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

function ConfigOtherScreen(): JSX.Element {
  // Hidden form
  const [hidden, setHidden] = useState(true);

  const [form] = Form.useForm();
  const [dataName, setDataName] = useState<any[]>([]);
  const [valueName, setValueName] = useState<string>();
  const debouncedValue = useDebounce(valueName, { wait: 500 });

  const ref = useRef<string | undefined>(undefined);
  const [dataCTV, setDataCTV] = useState<any[]>([]);

  const [onFetchListCustomer, listCustomer] = useListCustomer();
  const [onUpload, resUpload] = useUploadMulti();

  const { onGetConfigOther, dataConfigOther, onUpdateSetting } = useConfigOtherService();

  // Hiển thị hình ảnh + url hình ảnh
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const flag = useRef('');

  const [fileListData, setFileListData] = useState<any[]>([]);
  const [lengthFile, setLengthFile] = useState<number>(0);

  const [content, setContent] = useState<string>('');

  useEffect(() => {
    onGetConfigOther();
  }, []);

  useEffect(() => {
    if (dataConfigOther) {
      form.setFieldsValue({
        defaultSupporter: dataConfigOther.defaultSupporter,
        maximumWithdrawalAmountToday: dataConfigOther.maximumWithdrawalAmountToday,
        defaultIntroducerId: dataConfigOther.defaultIntroducerId,
        defaultEmailAdminReceiveContract: dataConfigOther.defaultEmailAdminReceiveContract,
        accountAgeLimit: dataConfigOther.accountAgeLimit,
        project: dataConfigOther?.defaultContentMessage?.project,
        documentRankingPDF: dataConfigOther?.documentRankingPDF,
        retailRealEstate: dataConfigOther?.defaultContentMessage?.retailRealEstate,
        allowCollaboratorSendMessage: dataConfigOther.allowCollaboratorSendMessage,
        conditionReceiveCommissionSystem: dataConfigOther.conditionReceiveCommissionSystem,
        defaultLogoProject: dataConfigOther.defaultLogoProject,
        lifeCycle: dataConfigOther.rules && dataConfigOther.rules.lifeCycle,
        networkExpansion: dataConfigOther.rules && dataConfigOther.rules.networkExpansion,
        transactionSuccess: dataConfigOther.rules && dataConfigOther.rules.transactionSuccess,
        notyForRule: dataConfigOther.rules && dataConfigOther.rules.notyForRule,
      });

      setContent((dataConfigOther.rules && dataConfigOther.rules.notyForRule) || '');

      if (dataConfigOther.rules) {
        setCheckedSwitch(true);
      }

      if (dataConfigOther?.userInfo) {
        setDataCTV([
          {
            _id: dataConfigOther?.userInfo._id,
            name: dataConfigOther?.userInfo.name,
          },
        ]);
      }
      // Set dữ liệu người giới thiệu mặc định
      if (dataConfigOther?.userInfo) {
        setDataName([
          {
            _id: dataConfigOther?.defaultIntroducer?._id,
            name: dataConfigOther?.defaultIntroducer?.name,
          },
        ]);
      }
      // Set file
      if (dataConfigOther.documentRankingPdfInfo) {
        setFileListData([
          {
            uid: dataConfigOther.documentRankingPdfInfo._id,
            name: dataConfigOther.documentRankingPdfInfo.name,
            status: 'done',
            url: `${process.env.REACT_APP_API_DOMAIN}/${dataConfigOther.documentRankingPdfInfo.url}`,
          },
        ]);
        setLengthFile(1);
      }
      if (dataConfigOther.defaultLogoProjectInfo) {
        setLogo([dataConfigOther.defaultLogoProjectInfo]);
      }
    }
  }, [dataConfigOther]);

  // Hình ảnh đại diện
  const [logo, setLogo] = useState<Media[]>([]);
  const [imgContent, setImgContent] = useState<Media[]>([]);

  // Hình ảnh
  const onChangeFiles = (fileList: any, type: string) => {
    let image: Media[] = [];
    if (type === 'logo') {
      image = logo;
    }
    if (type === 'img_content') {
      image = imgContent;
    }

    const newData: any = [...image, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    if (type === 'logo') {
      setLogo(newData);
      flag.current = 'logo';
    }
    if (type === 'img_content') {
      setImgContent(newData);
      flag.current = 'img_content';
    }
    onUpload(data);
  };

  // Xóa hình ảnh
  const onRemoveFiles = (item: Media | any, type: string) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => {
        if (type === 'logo') {
          const newArrImg = logo.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setLogo([]);
        }
        if (type === 'img_content') {
          const newArrImg = imgContent.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImgContent(newArrImg);
        }
        message.success('Xoá ảnh thành công');
      },
    });
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      if (flag.current === 'logo') {
        const newfileList = [...logo].filter(e => e._id);
        setLogo([...newfileList, ...resUpload]);
      }
      if (flag.current === 'img_content') {
        const newfileList = [...imgContent].filter(e => e._id);
        setImgContent([...newfileList, ...resUpload]);
      }
    }
  }, [resUpload]);

  // Xem hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal hiển thị
  const onCancelImage = () => {
    setModalVisible(false);
  };

  //   Danh sách khách hàng
  useEffect(() => {
    if (debouncedValue) {
      onFetchListCustomer({ search: debouncedValue });
    }
  }, [debouncedValue]);

  // Gán dữ liệu khách hàng
  useEffect(() => {
    if (listCustomer && ref.current) {
      if (ref.current === 'Name') {
        setDataName(listCustomer.data);
      }
      if (ref.current === 'CTV') {
        setDataCTV(listCustomer.data);
      }
    }
  }, [listCustomer]);

  // Khi tìm kiếm ở select
  const handleSearchName = (value: string) => {
    ref.current = 'Name';
    setValueName(value);
  };

  // Tìm kiếm CTV
  const handleSearchCTV = (value: string) => {
    ref.current = 'CTV';
    setValueName(value);
  };

  const [checkedSwitch, setCheckedSwitch] = useState<boolean>(false);

  // Button Lưu
  const onFinish = (value: any) => {
    const dataSwitch = {
      lifeCycle: value.lifeCycle,
      networkExpansion: value.networkExpansion,
      transactionSuccess: value.transactionSuccess,
      notyForRule: value.notyForRule,
    };

    const data = {
      defaultSupporter: value.defaultSupporter,
      defaultIntroducerId: value.defaultIntroducerId,
      maximumWithdrawalAmountToday: value.maximumWithdrawalAmountToday,
      defaultEmailAdminReceiveContract: value.defaultEmailAdminReceiveContract,
      documentRankingPDF: value.documentRankingPDF || null,
      conditionReceiveCommissionSystem: value.conditionReceiveCommissionSystem,
      defaultContentMessage: {
        project: value.project,
        retailRealEstate: value.retailRealEstate,
      },
      accountAgeLimit: value.accountAgeLimit,
      defaultLogoProject: logo && logo.length > 0 ? logo[0]._id : null,
      allowCollaboratorSendMessage: value.allowCollaboratorSendMessage,
      rules: checkedSwitch ? dataSwitch : null,
    };
    onUpdateSetting(data);
  };

  const handleChange = (info: any) => {
    if (info.file.flag) {
      return;
    }
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        file.url = `${process.env.REACT_APP_API_DOMAIN}/${file.response.data[0].url}`;
      }
      form.setFieldsValue({
        documentRankingPDF: file?.response?.data[0]?._id,
      });
      flag.current = '';
      return file;
    });
    if (fileList && fileList.length > 0) {
      setLengthFile(fileList.length);
    }
    setFileListData(fileList);
    if (flag.current === 'remove') {
      form.setFieldsValue({
        documentRankingPDF: null,
      });
    }
  };

  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_API_DOMAIN}/media/file`,
    headers: ApiHelper.getHeader(),
    beforeUpload: (file: any) => {
      if (file.type !== 'application/pdf') {
        message.error(`${file.name} không đúng định dạng`);
        file.flag = true;
      }
      return true;
    },
    onRemove: () => {
      setFileListData([]);
      setLengthFile(0);
      flag.current = 'remove';
    },
    onChange: handleChange,
  };

  const onChangeSwitch = (checked: boolean) => {
    setCheckedSwitch(checked);
  };

  const handleChangeContent = (event: any, editor: any) => {
    form.setFieldsValue({
      notyForRule: editor.getData(),
    });
  };

  return (
    <>
      <Modal title="" visible={isModalVisible} onCancel={onCancelImage} footer={false}>
        <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
      </Modal>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={20} offset={3}>
            {hidden ? (
              <Row>
                {/* Số tiền rút */}
                <Col span={20}>
                  <Form.Item
                    name="maximumWithdrawalAmountToday"
                    label="Số tiền được rút tối đa mỗi ngày"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      parser={(value: any) => value.replace(/(\.*)/g, '')}
                      step={1}
                      min={0}
                    />
                  </Form.Item>
                </Col>

                {/* Email admin nhận hợp đồng */}
                <Col span={20}>
                  <Form.Item
                    name="defaultEmailAdminReceiveContract"
                    label="Email admin nhận hợp đồng"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        type: 'email',
                        message: 'Email không đúng định dạng',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* Người giới thiệu */}
                <Col span={20}>
                  <Form.Item
                    name="defaultIntroducerId"
                    label="Người giới thiệu mặc định"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                  >
                    <Select
                      showSearch
                      placeholder="Tìm kiếm tên, Số điện thoại"
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={handleSearchName}
                      notFoundContent={null}
                      suffixIcon={<SearchOutlined />}
                    >
                      {dataName.map((data: any, index: number) => (
                        <Select.Option value={data._id} key={index}>
                          {data.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {/* CTV hỗ trợ mặc định */}
                <Col span={20}>
                  <Row gutter={24}>
                    <Col span={7}>CTV hỗ trợ mặc định cho tin đăng bất động sản lẻ của Phúc An Gia</Col>
                    <Col span={17} style={{ paddingLeft: '5px' }}>
                      <Form.Item name="defaultSupporter">
                        <Select
                          style={{ width: '100%' }}
                          showSearch
                          placeholder="Tìm kiếm tên, Số điện thoại, Agent ID"
                          defaultActiveFirstOption={false}
                          filterOption={false}
                          onSearch={handleSearchCTV}
                          notFoundContent={null}
                          suffixIcon={<SearchOutlined />}
                        >
                          {dataCTV.map((data: any, index: number) => (
                            <Select.Option value={data._id} key={index}>
                              {data.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Điều kiện nhận hoa hồng */}
                <Col span={20}>
                  <Row gutter={24}>
                    <Col span={7}>Điều kiện để nhận hoa hồng hệ thống, cần giới thiệu tối thiểu (người/tháng)</Col>
                    <Col span={17} style={{ paddingLeft: '5px' }}>
                      <Form.Item name="conditionReceiveCommissionSystem">
                        <InputNumber style={{ width: '100%' }} step={1} min={0} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Tài liệu */}
                <Col span={20} style={{ marginBottom: '15px' }}>
                  <Row gutter={24}>
                    <Col span={7}>Tài liệu thể lệ BXH (dạng PDF)</Col>
                    <Col span={17} style={{ paddingLeft: '5px' }}>
                      <Form.Item name="documentRankingPDF">
                        <Upload {...props} fileList={fileListData}>
                          {lengthFile <= 0 && <Button icon={<UploadOutlined />}>Tải lên PDF</Button>}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Giới hạn độ tuổi tài khoản */}
                <Col span={20}>
                  <Row gutter={24}>
                    <Col span={7}>Giới hạn độ tuổi tài khoản</Col>
                    <Col span={17} style={{ paddingLeft: '5px' }}>
                      <Form.Item name="accountAgeLimit">
                        <InputNumber style={{ width: '100%' }} step={1} max={100} min={0} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Logo mặc định dự án */}
                <Col span={20}>
                  <Row>
                    <Col span={7}>Logo mặc định dự án</Col>
                    <Col span={17}>
                      <UploadButtonImages
                        accept={'.gif, .jpg, .png, .webp'}
                        files={logo}
                        onPreview={handlePreview}
                        onChange={(fileList: any) => onChangeFiles(fileList, 'logo')}
                        onRemove={(item: any) => onRemoveFiles(item, 'logo')}
                        imageLength={1}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* CTV có thể gửi tin nhắn */}
                <Col span={20}>
                  <Row>
                    <Col span={7}>CTV giới thiệu có thể gửi tin nhắn chat</Col>
                    <Col span={17}>
                      <Form.Item name="allowCollaboratorSendMessage" valuePropName="checked">
                        <Switch />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Nội dung tin nhắn */}
                <Col span={20}>
                  <div style={{ marginBottom: '10px' }}>Nội dung tin nhắn chat tự động</div>
                  <Form.Item name="project" label="Dự án" labelCol={{ span: 7 }} labelAlign="left">
                    <Input.TextArea rows={5} />
                  </Form.Item>
                  <Form.Item name="retailRealEstate" label="BĐS lẻ" labelCol={{ span: 7 }} labelAlign="left">
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>

                {/* Trong vòng thời gian */}
                <Col span={20}>
                  <Form.Item label="Cài đặt luật chơi" labelCol={{ span: 7 }} labelAlign="left">
                    <Switch onChange={onChangeSwitch} checked={checkedSwitch} />
                  </Form.Item>
                </Col>
                {checkedSwitch && (
                  <>
                    <Col span={20}>
                      <Form.Item
                        label="Trong vòng thời gian (ngày)"
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        name="lifeCycle"
                        rules={[{ required: true, message: 'Thời gian không được để trống' }]}
                      >
                        <InputNumber style={{ width: '100%' }} step={1} min={0} />
                      </Form.Item>
                    </Col>

                    <Col span={20}>
                      <Form.Item
                        label="Mở rộng mạng lưới (người)"
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        name="networkExpansion"
                        rules={[{ required: true, message: 'Mạng lưới không được để trống' }]}
                      >
                        <InputNumber style={{ width: '100%' }} step={1} min={0} />
                      </Form.Item>
                    </Col>

                    <Col span={20}>
                      <Row>
                        <Col span={7}>
                          <span style={{ color: 'red' }}>*</span> Bán ít nhất số BĐS (giai đoạn thành công)
                        </Col>
                        <Col span={17}>
                          <Form.Item
                            labelCol={{ span: 7 }}
                            labelAlign="left"
                            name="transactionSuccess"
                            rules={[{ required: true, message: 'Số BĐS không được để trống' }]}
                          >
                            <InputNumber style={{ width: '100%' }} step={1} min={0} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    {/* Nội dung tin nhắn */}
                    <Col span={20} style={{ marginTop: '20px' }}>
                      <div style={{ marginBottom: '10px' }}>Nội dung thông báo mà chưa đáp ứng luật chơi trên</div>
                      <Form.Item
                        label="Thông báo nhắc nhở trước 2 ngày hết hạn"
                        labelCol={{ span: 10 }}
                        labelAlign="left"
                        name="notyForRule"
                        rules={[{ required: true, message: 'Nội dung không được để trống' }]}
                      >
                        <CKEEditor
                          onChange={handleChangeContent}
                          data={content}
                          MentionEmail={['@Người mở rộng', '@Số giao dịch', '@Số tiền']}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            ) : (
              <Row>
                {/* Khoảng thời gian */}
                <Col span={20}>
                  <Row gutter={24}>
                    <Col span={7}>Khoảng thời gian gửi thông báo cho tin đăng miễn phí (ngày)</Col>
                    <Col span={17} style={{ paddingLeft: '5px' }}>
                      <Form.Item name="2">
                        <InputNumber
                          style={{ width: '100%' }}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                          parser={(value: any) => value.replace(/(\.*)/g, '')}
                          step={1}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Ảnh chia sẻ */}
                <Col span={20}>
                  <Row>
                    <Col span={7}>Ảnh gắn với nội dung chia sẻ mã giới thiệu</Col>
                    <Col span={17}>
                      <UploadButtonImages
                        accept={'.gif, .jpg, .png, .webp'}
                        files={imgContent}
                        onPreview={handlePreview}
                        onChange={(fileList: any) => onChangeFiles(fileList, 'img_content')}
                        onRemove={(item: any) => onRemoveFiles(item, 'img_content')}
                        imageLength={1}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {getCheckPermissionUser(PERMISSION_NAME.CONFIG_GENERAL).edit ? (
              <Row gutter={10}>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ConfigOtherScreen;
