/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Image, Input } from 'antd';
import { getUrlImage } from 'helpers/getImageUrl';
import React, { useEffect, useRef, useState } from 'react';
import './css/FormProject.scss';

interface IUpload {
  files?: any[];
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  onRemove?: (item: any, index: any, type?: string | undefined) => void;
  type?: string | undefined;
  onPreview?: (item: any) => void;
  multiple?: boolean;
  imageLength?: number;
}

function UploadButtonImages(props: IUpload) {
  const [files, setFile] = useState<any[]>([]);

  const refFileInput = useRef<Input>(null);
  useEffect(() => {
    setFile(props.files || []);
    refFileInput.current && refFileInput.current.setValue('');
  }, [props.files]);

  const { onChange, accept, onRemove, multiple, onPreview, type, imageLength } = props;
  const onReMoveItem = (item: any, i: number) => () => {
    onRemove && onRemove(item, i, type);
  };
  const onPreviewImage = (item: any) => () => {
    onPreview && onPreview(item);
  };

  const uploadButton = (
    <div className="ant-upload ant-upload-select ant-upload-select-picture-card">
      <span className="ant-upload">
        <div className="d-flex justify-content-center">
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Tải lên</div>
          </div>
          <Input
            type="file"
            className="InPutHover"
            title=""
            ref={refFileInput}
            multiple={multiple}
            accept={accept || ''}
            onChange={onChange}
          />
        </div>
      </span>
    </div>
  );

  const objectUrl = (tem: any) => {
    const newE = tem;
    delete newE.uid;
    return URL.createObjectURL(newE);
  };

  return (
    <>
      <div className="ant-form-item-control-input-content">
        <span className="ant-upload-picture-card-wrapper">
          <div className="ant-upload-list ant-upload-list-picture-card">
            {files &&
              files.length > 0 &&
              files.map((e: any, i: number) => {
                return (
                  <div className="ant-upload-list-picture-card-container" key={i}>
                    <div className="ant-upload-list-item ant-upload-list-item-list-type-picture-card">
                      <div className="ant-upload-list-item-info">
                        <span>
                          <a className="ant-upload-list-item-thumbnail" rel="noopener noreferrer">
                            <Image
                              src={e && e._id ? getUrlImage(e) : objectUrl(e)}
                              className="ant-upload-list-item-image"
                            />
                          </a>
                          <span className="ant-upload-list-item-actions">
                            <a target="_blank" rel="noopener noreferrer" title="Xem ảnh" onClick={onPreviewImage(e)}>
                              <EyeOutlined />
                            </a>
                            <button
                              title="Xóa ảnh"
                              type="button"
                              className="ant-btn ant-upload-list-item-card-actions-btn ant-btn-text ant-btn-sm ant-btn-icon-only"
                              onClick={onReMoveItem(e, i)}
                            >
                              <DeleteOutlined />
                            </button>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            {imageLength ? (files && files.length >= 1 ? '' : uploadButton) : uploadButton}
          </div>
        </span>
      </div>
    </>
  );
}

export default UploadButtonImages;
