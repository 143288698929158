import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ResPoint {
    "_id": string,
    "USER_FIRST_UPDATED_POINT": number,
    "VIEW_SELLER_INFO_POINT": number,
    "NEW_CHILD_POINT": number,
    "__v"?: number
}
function useGetPoint() {
    const [loading, setLoading] = useState(false)
    const [detailPoint, setDetailPoint] = useState<ResPoint>();

    const onGetPoint = async (): Promise<void> => {
        try {
            setLoading(true)
            const res = await ApiHelper.fetch(
                BASE_URL.DETAIL_POINT
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false)
                setDetailPoint(res.data)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    return { onGetPoint, detailPoint, loading }
}

export default useGetPoint;