/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { translate } from 'translate/language';
import { useHistory } from 'react-router-dom';
import { ToastError, ToastSuccess } from 'components/Toast';
import { ReqCreateRealEstate } from '../modelSaleAndRental/SaleAndRental.type';

function useLitsCustomerRealestate() {
  const [loading, setLoading] = useState(false);
  const [listCustomer, setListCustomer] = useState<any[]>([]);
  const [detailSr, setDetailSR] = useState<any>();
  const [total, setTotal] = useState<number>(0);
  const history = useHistory();
  const onGetListCustomer = async (projectId: string) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<any, any>(`/project/${projectId}/customer/`, null);
      console.log('TCL: res', res);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        setListCustomer(res.data);
        setTotal(res.total);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const onCreateSaleAndRent = async (request: ReqCreateRealEstate) => {
    try {
      const res = await ApiHelper.post<any, any>(BASE_URL.PROJECT_NEWS, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('real_estate.create_success'),
        });
        history.goBack();
      } else {
        ToastError({
          message: translate('message_type.success'),
          description: translate('real_estate.create_err'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.success'),
        description: translate('real_estate.create_err'),
      });
    }
  };

  const onGetDetailSaleAndRent = async (id: string) => {
    try {
      const res = await ApiHelper.fetch<any, any>(BASE_URL.PROJECT_NEWS + `/${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailSR(res.data);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: 'Lỗi',
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: error as string,
      });
      console.log('TCL: error', error);
    }
  };
  const onUpdateDetailSaleAndRent = async (id: string, body: ReqCreateRealEstate) => {
    try {
      const res = await ApiHelper.put<any, any>(BASE_URL.PROJECT_NEWS + `/${id}`, body);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('real_estate.update_success'),
        });
        history.goBack();
      } else {
        ToastError({
          message: translate('message_type.success'),
          description: translate('real_estate.update_err'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.success'),
        description: translate('real_estate.update_err'),
      });
    }
  };

  return {
    onGetListCustomer,
    listCustomer,
    total,
    loading,
    onCreateSaleAndRent,
    onGetDetailSaleAndRent,
    detailSr,
    onUpdateDetailSaleAndRent,
  };
}
export default useLitsCustomerRealestate;
