/* eslint-disable object-shorthand */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { ToastError } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusBuy, StatusResponse } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import ApiHelper from 'network/ApiClient';
import React, { useEffect, useState } from 'react';
import { translate } from 'translate/language';
import '../real-estate-new.scss';
import { FilterRealEstateNew } from '../real-estate-new.types';

const { Option } = Select;
export interface Props {
  onSearch: (data: string) => void;
  onType: (data: string) => void;
  onRealEstates: (data: string) => void;
  onStatus: (status: string) => void;
  onMinArea: (data?: string | number) => void;
  onMaxArea: (data?: string | number) => void;
  onMinPrice: (data?: string | number) => void;
  onMaxPrice: (data?: string | number) => void;
}

function FilterRealEstateNewComponent(props: Props): JSX.Element {
  const { onSearch, onType, onRealEstates, onStatus, onMinArea, onMaxArea, onMinPrice, onMaxPrice } = props;
  const [filterExcel, setFilterExcel] = useState<FilterRealEstateNew>({
    search: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    minArea: undefined,
    maxArea: undefined,
    childRealEstateId: undefined,
    type: undefined,
    status: undefined,
  });
  const [form] = Form.useForm();
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();

  useEffect(() => {
    // Loại bất động sản
    onFetchListParentChildRealeState();
  }, []);

  // Tìm kiếm theo người đăng tin
  const handleSearch = (data?: any) => {
    onSearch(data.target.value);
    setFilterExcel({ ...filterExcel, search: data.target.value });
  };

  // Select loại kí gửi
  const onChangeType = (typeChange: string) => {
    onType(typeChange);
    setFilterExcel({ ...filterExcel, type: typeChange });
  };

  // Select loại BĐS
  const onChangeRealEstates = (type: string) => {
    onRealEstates(type);
    setFilterExcel({ ...filterExcel, childRealEstateId: type });
  };

  // Select loại BĐS
  const onChangeStatus = (status: string) => {
    onStatus(status);
    setFilterExcel({ ...filterExcel, status: status });
  };

  // Diện tích
  const handleMinArea = (value: number | string | undefined) => {
    if (value) {
      onMinArea(value);
      setFilterExcel({ ...filterExcel, minArea: value });
    } else {
      onMinArea(undefined);
      setFilterExcel({ ...filterExcel, minArea: undefined });
    }
  };

  const handleMaxArea = (value: number | string | undefined) => {
    if (value) {
      onMaxArea(value);
      setFilterExcel({ ...filterExcel, maxArea: value });
    } else {
      onMaxArea(undefined);
      setFilterExcel({ ...filterExcel, maxArea: undefined });
    }
  };

  // Giá
  const handleMinPrice = (value: number | string | undefined) => {
    if (value) {
      onMinPrice(value);
      setFilterExcel({ ...filterExcel, minPrice: value });
    } else {
      onMinPrice(undefined);
      setFilterExcel({ ...filterExcel, minPrice: undefined });
    }
  };

  const handleMaxPrice = (value: number | string | undefined) => {
    if (value) {
      onMaxPrice(value);
      setFilterExcel({ ...filterExcel, maxPrice: value });
    } else {
      onMaxPrice(undefined);
      setFilterExcel({ ...filterExcel, maxPrice: undefined });
    }
  };

  // Xuất Excel

  const exportExcel = async () => {
    const res: any = await ApiHelper.post('/project-news/export-buy', filterExcel, true);
    if (res && res.status === StatusResponse.NO_PROJECT_NEWS_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('Không tồn tại dữ liệu nào để xuất'),
      });
    }
    window.open(getUrlExport(res.data));
  };

  return (
    <Form form={form}>
      {/* Tìm kiếm người đăng tin   */}
      <Row gutter={16}>
        <Col className="gutter-row" xl={7} xxl={4}>
          <Form.Item name="search" style={{ width: '100%' }}>
            <Input size="small" placeholder="Người đăng tin" onChange={handleSearch} suffix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        {/* Loại kí gửi */}
        <Col className="gutter-row" xl={4} xxl={3}>
          <Form.Item>
            <Select placeholder="Loại kí gửi" onChange={onChangeType} allowClear>
              <Option value="BUY">Mua</Option>
              <Option value="RENTAL">Thuê</Option>
            </Select>
          </Form.Item>
        </Col>
        {/* Loại BĐS */}
        <Col className="gutter-row" xl={4} xxl={3}>
          <Form.Item>
            <Select placeholder="Loại BĐS" onChange={onChangeRealEstates} allowClear>
              {ListParentChildRealeState
                ? ListParentChildRealeState.map((data: any) => {
                    return (
                      <Option value={data._id} key={data._id} title={data.child}>
                        {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                      </Option>
                    );
                  })
                : ''}
            </Select>
          </Form.Item>
        </Col>
        {/* Diện tích */}
        <Col className="gutter-row" xxl={4}>
          <div className="absoluteInput">Diện tích</div>
          <Input.Group size="large">
            <Row gutter={4}>
              <Col span={12}>
                <InputNumber
                  className="width100"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  step={1}
                  min={0}
                  placeholder="từ"
                  onChange={handleMinArea}
                />
              </Col>
              <Col span={12}>
                <InputNumber
                  className="width100"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  step={1}
                  min={0}
                  placeholder="đến"
                  onChange={handleMaxArea}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
        {/* Giá */}
        <Col className="gutter-row" xxl={4}>
          <div className="absoluteInput">Giá</div>
          <Input.Group size="large">
            <Row gutter={4}>
              <Col span={12}>
                <InputNumber
                  className="width100"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  step={1}
                  min={0}
                  placeholder="từ"
                  onChange={handleMinPrice}
                />
              </Col>
              <Col span={12}>
                <InputNumber
                  className="width100"
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  step={1}
                  min={0}
                  placeholder="đến"
                  onChange={handleMaxPrice}
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>

        {/* Trạng thái */}
        <Col className="gutter-row" xl={4} xxl={3}>
          <Form.Item>
            <Select placeholder="Trạng thái" onChange={onChangeStatus} allowClear>
              <Option value={StatusBuy.PENDING}>{translate('StatusBuy.PENDING')}</Option>
              <Option value={StatusBuy.SUPPORTING}>{translate('StatusBuy.SUPPORTING')}</Option>
              <Option value={StatusBuy.SUPPORTED}>{translate('StatusBuy.SUPPORTED')}</Option>
            </Select>
          </Form.Item>
        </Col>
        {/* Xuất Excel */}
        <Col className="gutter-row" xxl={3}>
          {/* Xuất Excel */}
          {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).export ? (
            <div>
              <Button style={{ width: '100%' }} type="primary" onClick={exportExcel}>
                {translate('customer.export_excel')}
              </Button>
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </Form>
  );
}

export default FilterRealEstateNewComponent;
