import { REHYDRATE } from 'redux-persist';
import { LOGIN, RESET } from '../actions/actionType';

const INITIAL_STATE = {};

export const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        ...action.payload && action.payload.userReducer
      };
    case LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case RESET:
      return null;
    default:
      return state;
  }
};
