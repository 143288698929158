import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { InforAdminResponse } from 'types/response/AdminResponseRes';

function useUpdateDirection(): [(id: string, request: any) => Promise<void>] {
    const history = useHistory();
    const onUpdateDirection = async (id: string, request: any) => {
        try {
            const res = await ApiHelper.put<any, InforAdminResponse>(
                BASE_URL.UPDATE_DIRECTION + `${id}`,
                request
            )
            if (res && res.status == StatusResponse.DIRECTION_ALREADY_EXISTS) {
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("direction.DIRECTION_ALREADY_EXISTS"),
                });
                return
            }
            if (res && res.status == StatusResponse.DIRECTION_DO_NOT_EXISTS) {
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.data_do_not_exists"),
                });
                history.push(ROUTER_PATH.DIRECTION)
                return
            }
            if (res && res.status == StatusResponse.SUCCESS) {
                ToastSuccess({
                    message: translate("message_type.success"),
                    description: translate("messages.update_success"),
                });
                history.push(ROUTER_PATH.DIRECTION)
            }
        } catch (error) {
            ToastError({
                message: translate("message_type.failed"),
                description: translate("messages.update_failed"),
            });
        }
    }
    return [onUpdateDirection];
}

export default useUpdateDirection;