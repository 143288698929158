/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { FilterNews, NewsReq, NewsRes } from './news.type';

const NEW_CREATE = '/news/create';
const NEW_LIST = '/news/cms/list';
const DELETE_NEW = '/news/delete';
const DETAIL_NEW = '/news/cms/detail/';
const UPDATE_NEW = '/news/update';

function useNewService() {
  const history = useHistory();
  const [dataNew, setDataNew] = useState<NewsRes[]>([]);
  const [totalNew, setTotalNew] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [detailNew, setDetailNew] = useState<NewsRes | undefined>(undefined);

  // Danh sách tin tức
  const onGetDetailNew = async (params: FilterNews) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(NEW_LIST, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataNew(res.data);
        setTotalNew(res.total);
        setLoading(false);
      } else {
        setDataNew([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Thêm mới tin tức
  const onCreateNew = async (data: NewsReq) => {
    const res: any = await ApiHelper.post(NEW_CREATE, data);
    try {
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.add_new_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
    }
  };

  // Get chi tiết tin tức
  const onDetailNew = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(`${DETAIL_NEW}${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailNew(res.data);
      }
    } catch (error) {
      setDetailNew(undefined);
    }
  };

  // Cập nhật tin tức
  const onUpdateNew = async (data: NewsReq) => {
    const res: any = await ApiHelper.put(UPDATE_NEW, data);
    try {
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.update_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  // Xóa tin tức
  const onDeleteNew = async (id: string[]) => {
    try {
      setLoadingDel(true);
      const res: any = await ApiHelper.delete(DELETE_NEW, { ids: id });
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoadingDel(false);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
        setLoadingDel(false);
      }
    } catch (error) {
      setLoadingDel(false);
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return {
    onGetDetailNew,
    dataNew,
    totalNew,
    loading,
    onCreateNew,
    onDeleteNew,
    loadingDel,
    onDetailNew,
    detailNew,
    onUpdateNew,
  };
}

export default useNewService;
