import { BaseStatusCollab } from '../types/ctv.types';

export interface CtvOption {
  type: BaseStatusCollab;
  name: string;
  color: string;
}

export const CtvOptions: CtvOption[] = [
  {
    type: BaseStatusCollab.PENDING,
    name: 'ctv.pending',
    color: '#ff9931',
  },
  {
    type: BaseStatusCollab.APPROVED,
    name: 'ctv.approved',
    color: '#11A1FD',
  },
  {
    type: BaseStatusCollab.REFUSE,
    name: 'ctv.refuse',
    color: '#A6ADB4',
  },
  {
    type: BaseStatusCollab.PENDING_SUPPORTER,
    name: 'ctv.pendingSupported',
    color: '#ff9931',
  },
  {
    type: BaseStatusCollab.APPROVED_SUPPORTER,
    name: 'ctv.approvedSupported',
    color: '#11A1FD',
  },
  {
    type: BaseStatusCollab.REFUSE_SUPPORTER,
    name: 'ctv.refuseSupported',
    color: '#A6ADB4',
  },
];

export const getCtvStatusColor = (type?: BaseStatusCollab): string | undefined => {
  const data = CtvOptions.find(e => e.type === type);

  return data ? data.color : undefined;
};
