import { ToastError } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { InforAdminResponse } from 'types/response/AdminResponseRes';
import { User } from 'types/response/UserResponse';

function useDetailBank(): [(request: string) => Promise<void>, any | undefined] {
    const [detailBank, getBank] = useState<any | undefined>();
    const history = useHistory();
    const useGetDetailBank = async (request: string) => {
        try {
            const res = await ApiHelper.fetch<string, InforAdminResponse>(
                BASE_URL.DETAIL_BANK + `${request}`
            )
            if (res && res.status == StatusResponse.BANK_DO_NOT_EXISTS) {
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.data_do_not_exists"),
                });
                history.push(ROUTER_PATH.BANK)
                return
            }
            if (res && res.status == StatusResponse.SUCCESS) {
                getBank(res.data)
            }
        } catch (error) {
            getBank(undefined)
        }
    }
    return [useGetDetailBank, detailBank];
}

export default useDetailBank;