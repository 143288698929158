/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { CopyOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { Checkbox, Col, Pagination, Popconfirm, Row, Table, Tag, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject, StatusResponse } from 'constants/Status';
import { formatNumberPrice } from 'helpers/FormatPrice';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import images from 'images';
import _ from 'lodash';
import ApiHelper from 'network/ApiClient';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHouse from './components/filter-house.components';
import useHouseService from './house.service';
import { Bedroom, Direction, FilterHouseReq, HouseTemplate } from './types/house.type';

const { Text } = Typography;

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HouseScreen(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const query: any = useQuery();
  const [employeeIdSelected, setEmployeeIdSelected] = useState<string[]>([]);

  const [filter, setFilter] = useState<FilterHouseReq>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    project: query.get('idProject') ? query.get('idProject') : undefined,
    block: query.get('idBlock') ? query.get('idBlock') : undefined,
  });
  const history = useHistory();

  const { onFetchHouse, dataHouse, loading, onDeleteHouse, loadingDelete, totalHouse } = useHouseService();

  useEffect(() => {
    onFetchHouse(filter);
  }, [filter, loadingDelete]);

  // Xóa nhiều giỏ hàng
  const onDeleteAll = () => {
    onDeleteHouse(employeeIdSelected);
    setEmployeeIdSelected([]);
  };

  // Action xóa
  const onDelete = (_id: string) => {
    onDeleteHouse([_id]);
  };

  // Action Coppy
  const copyNewHouse = (_id: string) => {
    history.push(`${ROUTER_PATH.EDIT_HOUSE_LOTS + _id}?type=copy`);
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).edit ? (
          <Link to={ROUTER_PATH.EDIT_HOUSE_LOTS + _id}>
            <img className="img-style" src={images.ic_edit} alt="" />
          </Link>
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).create ? (
          <span className="margin10" onClick={() => copyNewHouse(_id)}>
            <CopyOutlined />
          </span>
        ) : (
          ''
        )}

        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={() => onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer_project.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: translate('house.code'),
      dataIndex: 'code',
      render: (code: string) => {
        return <span>{code || ''}</span>;
      },
    },
    {
      title: translate('house.name'),
      dataIndex: 'name',
      render: (name: string) => {
        return <span title={name || ''}>{name ? (name.length > 50 ? `${name.substring(0, 20)} ...` : name) : ''}</span>;
      },
    },
    {
      title: translate('house.floor'),
      dataIndex: 'floor',
      render: (floor: string) => {
        return <span>{floor || ''}</span>;
      },
    },
    {
      title: translate('house.area'),
      dataIndex: 'area',
      render: (area: number) => {
        return <span>{area}</span>;
      },
    },
    {
      title: translate('house.builtupArea'),
      dataIndex: 'builtupArea',
      render: (builtupArea: number) => {
        return <span>{builtupArea || 0}</span>;
      },
    },
    {
      title: translate('house.carpetArea'),
      dataIndex: 'carpetArea',
      render: (carpetArea: number) => {
        return <span>{carpetArea || 0}</span>;
      },
    },
    {
      title: translate('house.direction'),
      dataIndex: 'directionId',
      render: (data: Direction[]) => {
        return (
          <span>
            {data &&
              data.length > 0 &&
              data.map((e: Direction) => {
                return (
                  <Tag style={{ marginBottom: '5px' }}>
                    {e.name.length > 20 ? `${e.name.substring(0, 15)} ...` : e.name}
                  </Tag>
                );
              })}
          </span>
        );
      },
    },
    {
      title: translate('house.bedroom'),
      dataIndex: 'bedroom',
      render: (data: Bedroom) => {
        return (
          <span title={data?.name}>
            {data?.name ? (data.name.length > 50 ? `${data.name.substring(0, 30)} ...` : data.name) : ''}
          </span>
        );
      },
    },
    {
      title: translate('house.bathroom'),
      dataIndex: 'bathroom',
      render: (bathroom: number) => {
        return <span>{bathroom || 0}</span>;
      },
      align: 'center' as 'center',
      width: '5',
    },
    {
      title: translate('house.price'),
      dataIndex: 'price',
      render: (price: number) => {
        return <span>{formatNumberPrice(price)}</span>;
      },
    },
    {
      title: translate('house.houseTemplate'),
      dataIndex: 'houseTemplateId',
      render: (data: HouseTemplate) => {
        if (data) {
          return (
            <Link to={ROUTER_PATH.EDIT_PROJECTS_HOUSETEMPLATE + data._id} title={data?.name}>
              <Text underline style={{ color: '#12a0c0' }}>
                {data?.name ? (data.name.length > 30 ? `${data.name.substring(0, 20)} ...` : data.name) : ''}
              </Text>
            </Link>
          );
        }
      },
    },
    {
      title: translate('house.status'),
      dataIndex: 'status',
      width: '8',
      render: (status: string) => {
        if (status === BaseStatusProject.FOR_SALE) {
          return translate('BaseStatusProject.FOR_SALE');
        }
        if (status === BaseStatusProject.NOT_FOR_SALE) {
          return translate('BaseStatusProject.NOT_FOR_SALE');
        }
        if (status === BaseStatusProject.SOLD) {
          return translate('BaseStatusProject.SOLD');
        }
        if (status === BaseStatusProject.DEPOSITED) {
          return translate('BaseStatusProject.DEPOSITED');
        }
        if (status === BaseStatusProject.PAYMENT) {
          return translate('BaseStatusProject.PAYMENT');
        }
      },
    },
    {
      title: translate('house.action'),
      dataIndex: '_id',
      width: '8%',
      render: renderAction,
    },
  ];

  const onChangeProjectH = (value: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, project: value });
  };
  const onChangeAreaH = (value: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, block: value });
  };
  const { run } = useDebounceFn(
    (search: string) => {
      setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: search });
    },
    {
      wait: 500,
    },
  );
  const onChangeApartmentH = (value: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, bedroom: value });
  };
  const onChangeStatusH = (value: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, status: value });
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  const onCheckImport = (data: string) => {
    if (data === 'success') {
      setFilter({ ...filter });
    }
  };

  const rowSelection = {
    selectedRowKeys: employeeIdSelected,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const arrEmployeeIdNotInPage = employeeIdSelected.filter(_id => !dataHouse.find(item => _id === item._id));
      setEmployeeIdSelected(_.uniq([...arrEmployeeIdNotInPage, ...selectedRowKeys]));
    },
  };

  return (
    <div>
      <FilterHouse
        onChangeProjectH={onChangeProjectH}
        onChangeAreaH={onChangeAreaH}
        onChangeSearchH={(value: string) => run(value)}
        onChangeApartmentH={onChangeApartmentH}
        onChangeStatusH={onChangeStatusH}
        onCheckImport={onCheckImport}
      />
      {loading || loadingDelete ? (
        <PageLoading />
      ) : (
        <>
          {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).delete ? (
            <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
              {employeeIdSelected && employeeIdSelected.length > 0 && (
                <Popconfirm
                  placement="top"
                  title="Bạn có chắc muốn xóa"
                  onConfirm={() => onDeleteAll()}
                  okText={translate('action.yes')}
                  cancelText={translate('action.no')}
                >
                  <img className="img-style" src={images.ic_delete} alt="" />
                  <span
                    style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                  >
                    Xóa nhiều
                  </span>
                </Popconfirm>
              )}
              <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                Đã chọn {employeeIdSelected.length || 0} căn hộ
              </span>
            </div>
          ) : (
            ''
          )}

          <Table
            rowKey="_id"
            dataSource={dataHouse || []}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={columns}
            pagination={false}
          />
        </>
      )}
      <Row>
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalHouse}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default HouseScreen;
