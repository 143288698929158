import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { ToastError, ToastSuccess } from 'components/Toast';
import { SupportBody } from './support.type';
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { StatusResponse } from './../../constants/Status';
import { SupportRes } from './support.type';

export interface Filter {
  page: number | string;
  limit: number | string;
}

const URL = '/user/support/list';
const URL_ADD = '/user/support';
const URL_DELETE = '/user/support/';

function useSupportService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSupport, setDataSupport] = useState<SupportRes[]>([]);
  const [totalSupport, setTotalSupport] = useState<number>();
  const history = useHistory();
  const [loadingDel, setLoadingDel] = useState<boolean>(false);
  const [detailSupport, setDetailSupport] = useState<SupportRes | undefined>();

  const onGetSupport = async (param: Filter) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalSupport(res.total);
        setDataSupport(res.data);
        setLoading(false);
      }
    } catch (error) {
      setDataSupport([]);
      setLoading(false);
    }
  };

  // Thêm mới liên hệ hỗ trợ
  const onCreateSupport = async (body: SupportBody) => {
    try {
      const res: any = await ApiHelper.post(URL_ADD, body);
      if (res && res.status === 'PHONE_NUMBER_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Số điện thoại đã tồn tại',
        });
      }

      if (res && res.status === 'EMAIL_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Địa chỉ Email đã tồn tại',
        });
      }

      if (res && res.status === 'USER_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tên liên hệ đã tồn tại',
        });
      }

      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
        history.goBack();
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
    }
  };

  const onUpdateSupport = async (id: string, body: SupportBody) => {
    try {
      const res: any = await ApiHelper.put(URL_DELETE + id, body);
      if (res && res.status === 'PHONE_NUMBER_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Số điện thoại đã tồn tại',
        });
      }

      if (res && res.status === 'EMAIL_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Địa chỉ Email đã tồn tại',
        });
      }

      if (res && res.status === 'USER_ALREADY_EXISTS') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tên liên hệ đã tồn tại',
        });
      }

      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
        history.goBack();
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  const onDetailSupport = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(URL_DELETE + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailSupport(res.data);
      }
    } catch (error) {
      setDetailSupport(undefined);
    }
  };

  // Xóa người dùng hỗ trợ
  const onDeleteSupport = async (id: string) => {
    setLoadingDel(true);
    try {
      const res: any = await ApiHelper.delete(URL_DELETE + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoadingDel(false);
      }
    } catch (error) {
      setLoadingDel(false);
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return {
    onGetSupport,
    dataSupport,
    totalSupport,
    loading,
    onCreateSupport,
    onDeleteSupport,
    loadingDel,
    onDetailSupport,
    detailSupport,
    onUpdateSupport,
  };
}

export default useSupportService;
