/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useDeleteAdmin from 'hooks/Admin/useDeleteAdmin';
import useListAdmin from 'hooks/Admin/useListAdmin';
import images from 'images';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterAdminCustomer from './components/FilterHeaderAdmin';
import './css/index.scss';

export interface Filter {
  status?: string;
  search?: string;
  page: number;
  limit: number;
}

const AdminPage: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<Filter>({
    status: '',
    search: '',
    page: PaginateList.PAGE_DEFAULT,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  // Danh sách tài khoản Admin
  const [onFetchListAdmin, listAdmin, loading] = useListAdmin();
  // Xóa tài khoản Admin
  const [onDeleteAdmin, onDeleteListEmployees, loadingDelete] = useDeleteAdmin();

  // danh sách nhân viện chọn để xóa
  const [employees, setEmployees] = useState<string[]>([]);
  useEffect(() => {
    onFetchListAdmin(filter);
  }, [filter, loadingDelete]);

  // Tìm kiếm Tên,SĐT,ID
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search });
    },
    {
      wait: 500,
    },
  );
  // Select trạng thái
  const onChangeStatus = (status: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status });
  };

  // Action xóa
  const onDelete = (_id: string) => () => {
    onDeleteAdmin({ id: _id });
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.EDIT_ADMIN + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  // Action phân trang
  // eslint-disable-next-line no-shadow
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page });
  };
  // Xóa nhiều nhân viên
  const onDeleteAll = () => {
    onDeleteListEmployees(employees);
    setEmployees([]);
  };
  // Render Column Table
  const renderColumns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: translate('admin.name_admin'),
      key: '_id',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: translate('admin.phone'),
      key: '_id',
      dataIndex: 'phone',
      render: (phone: string) => {
        return <span>{phone}</span>;
      },
    },
    {
      title: translate('admin.email'),
      key: '_id',
      dataIndex: 'email',
      render: (email: string) => {
        return <span>{email}</span>;
      },
    },
    {
      title: translate('status.name'),
      key: '_id',
      dataIndex: 'status',
      render: (status: string) => {
        return <span>{status === StatusType.ACTIVE ? translate('status.show') : translate('status.hidden')}</span>;
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
    },
  ];
  const rowSelection = {
    selectedRowKeys: employees,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const arrEmployeeIdNotInPage = employees.filter(
        _id => !listAdmin?.data.find((item: { _id: string }) => _id === item._id),
      );
      setEmployees(_.uniq([...arrEmployeeIdNotInPage, ...selectedRowKeys]));
    },
  };
  return (
    <>
      <FilterAdminCustomer onSearch={(value: string) => run(value)} onChangeStatus={onChangeStatus} />
      <Row>
        {loading || loadingDelete ? (
          <PageLoading />
        ) : (
          <>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).delete ? (
              <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
                {employees && employees.length > 0 && (
                  <Popconfirm
                    placement="top"
                    title="Bạn có chắc muốn xóa"
                    onConfirm={() => onDeleteAll()}
                    okText={translate('action.yes')}
                    cancelText={translate('action.no')}
                  >
                    <img className="img-style" src={images.ic_delete} alt="" />
                    <span
                      style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                    >
                      Xóa nhiều
                    </span>
                  </Popconfirm>
                )}
                <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                  Đã chọn {employees.length || 0} nhân viên
                </span>
              </div>
            ) : (
              ''
            )}
            <Col span={24}>
              {listAdmin && (
                <Table
                  key={1}
                  dataSource={listAdmin?.data || []}
                  columns={renderColumns}
                  pagination={false}
                  scroll={{ x: 'auto' }}
                  rowKey="_id"
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                ></Table>
              )}
            </Col>
          </>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={listAdmin?.total}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AdminPage;
