import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

function useAddProperty() {
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const onAddProperty = async (request: any) => {
        try {
            setLoading(true);
            const res = await ApiHelper.postFormData(
                BASE_URL.CREATE_PROPERTY,
                request
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false);
                history.push(ROUTER_PATH.PROPERTY_TYPE)
                ToastSuccess({
                    message: translate("message_type.success"),
                    description: translate("messages.add_new_success"),
                });
            }
        } catch (error) {
            setLoading(false);
            ToastError({
                message: translate("message_type.failed"),
                description: translate("messages.add_new_failed"),
            });
        }
    }

    return { onAddProperty, loading };
}

export default useAddProperty;