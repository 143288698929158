/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { DownloadOutlined, FileExcelOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Container from 'components/ContentLayout/Container';
import { ToastError, ToastSuccess } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject, StatusResponse } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import ApiHelper from 'network/ApiClient';
import React, { ChangeEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status: string) => void;
  onCheckImport: (data: string) => void;
}

export interface FilterExcel {
  search?: string;
  status?: string;
}

function FilterHeaderBlockArea(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, onChangeStatus, onCheckImport } = props;
  const history = useHistory();
  const [fileExcel, setFileExel] = useState<any[]>([]);
  const [checkModal, setCheckModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filterExcel, setFilterExcel] = useState<FilterExcel>({
    search: '',
    status: '',
  });
  const query: any = useQuery();

  const clickExport = async () => {
    const res: any = await ApiHelper.post('/block/export', filterExcel, true);
    if (res && res.status === StatusResponse.NO_BLOCKS_EXIS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('Không tồn tại dữ liệu nào để xuất'),
      });
    }
    window.open(getUrlExport(res.data));
  };

  // Tìm kiếm theo Tên,ID,Số điện thoại
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
    setFilterExcel({ ...filterExcel, search: e.target.value });
  };

  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
    setFilterExcel({ ...filterExcel, status: value });
  };
  // Thêm mới dự án
  const addNew = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_BLOCK_AREA);
    history.push(ROUTER_PATH.ADD_BLOCKS_AREA);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setCheckModal(false);
    setIsModalVisible(false);
  };

  const handleDownloadTemplate = async () => {
    const res: any = await ApiHelper.fetch('/block/download-template', null, true);
    window.open(getUrlExport(res.data));
  };

  const handleSubmitUpload = async (e: any) => {
    e.preventDefault();
    if (fileExcel.length === 0) {
      setCheckModal(true);
      return;
    }
    setCheckModal(false);
    const data = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    if (fileExcel?.length > 0) {
      data.append('file', fileExcel[0].originFileObj);
      const res: any = await ApiHelper.postFormData('/block/import', data, headers);
      if (res && res.status === StatusResponse.FILE_IS_NOT_FORMULATED) {
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.FILE_IS_NOT_FORMULATED'),
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        if (res.data.fail > 0) {
          console.log(res.data.errors[0].error, 'bababbabba');
          setIsModalVisible(false);
          setFileExel([]);
          if (
            res.data.errors &&
            res.data.errors.length > 0 &&
            res.data.errors[0].error === 'THIS_FIELD_LENGTH_CAN_NOT_GREATER_THAN_100_CHARACTERS'
          ) {
            return ToastError({
              message: translate('message_type.failed'),
              description: 'Tên Block lớn hơn 100 kí tự',
            });
          }
          if (res.data.errors && res.data.errors.length > 0 && res.data.errors[0].error === 'NAME_CAN_NOT_BE_BLANK') {
            return ToastError({
              message: translate('message_type.failed'),
              description: 'Tên Block không được rỗng',
            });
          }
          return ToastError({
            message: translate('message_type.failed'),
            description: translate('messages.import_error'),
          });
        }
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.import_success'),
        });
      }
      setFileExel([]);
      onCheckImport('success');
      setIsModalVisible(false);
    }
  };

  const handleChangeFileUpload = ({ fileList }: any) => {
    if (fileList && fileList.length > 0) {
      fileList.map((e: any, index: number) => {
        e.status = 'done';
        return e;
      });
      setFileExel(fileList);
      setCheckModal(false);
    } else {
      setCheckModal(true);
    }
  };

  const onRemove = (file: any) => {
    setFileExel([]);
  };
  return (
    <>
      <Modal
        title="Nhập từ excel"
        visible={isModalVisible}
        onCancel={closeModal}
        cancelText="Đóng"
        okText="Tải lên"
        onOk={handleSubmitUpload}
      >
        <Container>
          <Row>
            <Col sm={24}>
              <Form.Item label="Tải xuống mẫu" labelCol={{ sm: 8 }} labelAlign="left">
                <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleDownloadTemplate} />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Tải lên file excel"
                labelAlign="left"
                labelCol={{ sm: 8 }}
                name="upload_file_excel"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleChangeFileUpload}
                  fileList={fileExcel}
                  onRemove={onRemove}
                >
                  {fileExcel && fileExcel.length > 0 ? '' : <Button icon={<UploadOutlined />}>Tải lên</Button>}
                </Upload>
              </Form.Item>
              {checkModal ? (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div>Vui lòng chọn File tải lên </div>
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Container>
      </Modal>

      <Form form={form}>
        <Row gutter={20}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search" initialValue={query.get('name') ? query.get('name') : ''}>
                <Input
                  size="small"
                  placeholder={translate('block_area.name_search')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>

          <Col className="gutter-row" span={5}>
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={showModal}>
                  {translate('customer.import_excel')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className="gutter-row" span={5}>
            {/* Xuất Excel */}
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).export ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={clickExport}>
                  {translate('customer.export_excel')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className="gutter-row" span={4}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('status.name')} onChange={handChangeStatus}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value={BaseStatusProject.INACTIVE}>{translate('BaseStatusProject.INACTIVE')}</Option>
                  <Option value={BaseStatusProject.NOT_FOR_SALE}>{translate('BaseStatusProject.NOT_FOR_SALE')}</Option>
                  <Option value={BaseStatusProject.FOR_SALE}>{translate('BaseStatusProject.FOR_SALE')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={3}>
            {/* Thêm mới */}
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={addNew}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterHeaderBlockArea;
