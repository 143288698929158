export const PERMISSION_NAME = {
  STATISTICS: 'STATISTICS', // Thống kê
  ACCOUNTS_CMS: 'ACCOUNTS_CMS', // Quản lý tài khoản > Tài khoản Nhân viên
  ACCOUNTS_APP: 'ACCOUNTS_APP', // Quản lý tài khoản > Tài khoản người dùng APP
  ACCOUNTS_PERMISSIONS: 'ACCOUNTS_PERMISSIONS', // Quản lý tài khoản > Nhóm phân quyền
  PROJECTS: 'PROJECTS', // Quản lý dự án > Dự án
  PROJECTS_BLOCKS_AREA: 'PROJECTS_BLOCKS_AREA', // Quản lý dự án > Block, khu đất
  PROJECTS_APARTMENTS_LAND: 'PROJECTS_APARTMENTS_LAND', // Quản lý dự án > Căn hộ, lô đất
  PROJECTS_HOUSETEMPLATES: 'PROJECTS_HOUSETEMPLATES', // Quản lý dự án > Căn hộ mẫu
  PROJECTS_VOLUNTEERS: 'PROJECTS_VOLUNTEERS', // Quản lý dự án > Cộng tác viên
  PROJECTS_CUSTOMERS_BOOKING: 'PROJECTS_CUSTOMERS_BOOKING', // Quản lý dự án > Khách hàng book căn
  PROJECTS_CUSTOMERS_SEQUENCE: 'PROJECTS_CUSTOMERS_SEQUENCE', // Quản lý dự án > Khách hàng xếp số
  REALESTATE_SELL: 'REALESTATE_SELL', // Quản lý BĐS lẻ > Tin bán/cho thuê
  REALESTATE_BUY: 'REALESTATE_BUY', // Quản lý BĐS lẻ > Tin cần mua/ cần thuê
  COMMISSION_HISTORY: 'COMMISSION_HISTORY', // Quản lý Hoa hồng > Lịch sử nhận hoa hồng
  COMMISSION_WITHDRAW: 'COMMISSION_WITHDRAW', // Quản lý Hoa hồng > Yêu cầu rút tiền
  POINT_PACKAGE_ADD_POINT: 'POINT_PACKAGE_ADD_POINT', // Quản lý Gói điểm > Cộng vào ví điểm
  POINT_PACKAGES: 'POINT_PACKAGES', // Quản lý Gói điểm > Gói điểm
  POINT_PACKAGES_BUY_HISTORY: 'POINT_PACKAGES_BUY_HISTORY', // Quản lý Gói điểm > Lịch sử mua gói điểm
  CONTENT_NEWS: 'CONTENT_NEWS', // Quản lý nội dung > Tin tức, sự kiện
  CONTENT_NOTIFICATIONS: 'CONTENT_NOTIFICATIONS', // Quản lý nội dung > Thông báo hệ thống
  CONTENT_INTRO_TERMS: 'CONTENT_INTRO_TERMS', // Quản lý nội dung > Giới thiệu và điều khoản sử dụng
  CONTENT_GUIDE: 'CONTENT_GUIDE', // Quản lý nội dung > Hướng dẫn sử dụng
  CONTENT_CONTACT: 'CONTENT_CONTACT', // Quản lý nội dung > Liên hệ hỗ trợ
  FORBIDDEN_KEYWORDS: 'FORBIDDEN_KEYWORDS', // Quản lý nội dung > Từ khóa cấm
  CATEGORY_REALESTATE: 'CATEGORY_REALESTATE', // Quản lý Danh mục > Loại bất động sản
  CATEGORY_APARTMENT: 'CATEGORY_APARTMENT', // Quản lý Danh mục > Loại căn hộ/phòng ngủ
  CATEGORY_HOUSE_DIRECTIONS: 'CATEGORY_HOUSE_DIRECTIONS', // Quản lý Danh mục > Hướng nhà
  CATEGORY_CONSTRUCTOR: 'CATEGORY_CONSTRUCTOR', // Quản lý Danh mục > Đối tác xây dựng
  CATEGORY_BANKS: 'CATEGORY_BANKS', // Quản lý Danh mục > Ngân hàng
  CONFIG_AUTO_NOTIFICATIONS: 'CONFIG_AUTO_NOTIFICATIONS', // Quản lý Cấu hình > Thông báo tự động
  CONFIG_INTERACTIVE_POINTS: 'CONFIG_INTERACTIVE_POINTS', // Quản lý Cấu hình > Điểm tương tác
  CONFIG_EMAILS_CONTRACTS: 'CONFIG_EMAILS_CONTRACTS', // Quản lý Cấu hình > Email/hợp đồng
  CONFIG_COMMISSIONS_PROJECT: 'CONFIG_COMMISSIONS_PROJECT', // Quản lý Cấu hình > Hoa hồng dự án
  CONFIG_COMMISSIONS_REALESTATE: 'CONFIG_COMMISSIONS_REALESTATE', // Quản lý Cấu hình > Hoa hồng bất động sản lẻ
  CONFIG_GENERAL: 'CONFIG_GENERAL', // Quản lý Cấu hình > Cấu hình khac
  SYSTEM_TREE: 'SYSTEM_TREE', // Quản lý Cấu hình > Cây hệ thống
  COMMISSION_ADD: 'COMMISSION_ADD', // Cộng hoa hồng
  CONFIG_NEWS_METHOD: 'CONFIG_NEWS_METHOD', // Hình thức đăng tin
};
