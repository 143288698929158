/* eslint-disable consistent-return */
import { ToastSuccess } from 'components/Toast';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { ToastError } from '../../components/Toast/index';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BASE_URL } from '../../network/BaseURL';
import { DirectionHouse, FilterHouseReq, HouseReq, HouseRes } from './types/house.type';

const LIST_HOUSE = '/house/list';
const DELETE_HOUSE = '/house/delete';
const CREATE_HOUSE = '/house/create';
const DETAIL_HOUSE = '/house/detail/';
const UPDATE_HOUSE = '/house/update/';

function useHouseService() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [dataHouse, setDataHouse] = useState<HouseRes[]>([]);
  const [totalHouse, setTotalHouse] = useState<number>();
  // Hướng nhà
  const [dataDirection, setDataDirection] = useState<DirectionHouse[]>([]);
  const [dataDetailHouse, setDateDetailHouse] = useState<HouseRes>();

  // Danh sách căn hộ - lô đất
  const onFetchHouse = async (params: FilterHouseReq) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<FilterHouseReq, any>(LIST_HOUSE, params);
      if (res.data && res.status === StatusResponse.SUCCESS) {
        setDataHouse(res.data);
        setTotalHouse(res.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Thêm mới danh sách căn hộ - lô đất
  const onCreateHouse = async (data: HouseReq) => {
    const res = await ApiHelper.post<HouseReq, any>(CREATE_HOUSE, data);
    if (res && res.status === StatusResponse.HOUSE_ALREADY_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Tên căn hộ/lô đất đã tồn tại',
      });
    }
    if (res && res.status === StatusResponse.HOUSE_CODE_ALREADY_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Mã căn hộ/lô đất đã tồn tại',
      });
    }

    if (res && res.status === StatusResponse.NAME_ON_THE_FLOOR_ALREADY_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Tên căn hộ đã tồn tại ở tầng này',
      });
    }
    if (res.data && res.status === StatusResponse.SUCCESS) {
      history.push(ROUTER_PATH.HOUSE_LOTS);
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.add_new_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
    }
  };

  // Cập nhật căn hộ - lô đất
  const onUpdateHouse = async (id: string, data: HouseReq) => {
    const res = await ApiHelper.put<HouseReq, any>(UPDATE_HOUSE + id, data);
    if (res && res.status === StatusResponse.HOUSE_ALREADY_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Tên căn hộ/lô đất đã tồn tại',
      });
    }
    if (res && res.status === StatusResponse.HOUSE_CODE_ALREADY_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Mã căn hộ/lô đất đã tồn tại',
      });
    }
    if (res && res.status === StatusResponse.NAME_ON_THE_FLOOR_ALREADY_EXISTS) {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Tên căn hộ đã tồn tại ở tầng này',
      });
    }
    if (res.data && res.status === StatusResponse.SUCCESS) {
      history.push(ROUTER_PATH.HOUSE_LOTS);
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.update_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  // Chi tiết 1 căn hộ - lô đất
  const onDetailHouse = async (id: string) => {
    const res: any = await ApiHelper.fetch(DETAIL_HOUSE + id);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDateDetailHouse(res.data);
    } else {
      setDateDetailHouse(undefined);
    }
  };

  // Xóa danh dách căn hộ - lô đất
  const onDeleteHouse = async (id: string[]) => {
    try {
      setLoadingDelete(true);
      const res = await ApiHelper.delete(DELETE_HOUSE, { _ids: id });
      if (res && res.status === 'THIS_HOUSE_WAS_AVAILABLE_IN_A_TRANSACTION') {
        setLoadingDelete(false);
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('Giao dịch đã có người giữ chỗ'),
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoadingDelete(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      }
    } catch (error) {
      setLoadingDelete(false);
      ToastSuccess({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  // Lấy danh sách dự án
  const onFetchDirection = async () => {
    const res: any = await ApiHelper.fetch(BASE_URL.LIST_DIRECTION);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataDirection(res.data);
    } else {
      setDataDirection([]);
    }
  };

  return {
    onFetchHouse,
    dataHouse,
    loading,
    onDeleteHouse,
    loadingDelete,
    onFetchDirection,
    dataDirection,
    onCreateHouse,
    onDetailHouse,
    dataDetailHouse,
    onUpdateHouse,
    totalHouse,
  };
}

export default useHouseService;
