export enum StatusResponse {
  SUCCESS = 'SUCCESS',
  USER_DO_NOT_EXISTS = 'USER_DO_NOT_EXISTS',
  USER_STATUS_DO_NOT_EXISTS = 'USER_STATUS_DO_NOT_EXISTS',
  PHONE_NUMBER_ALREADY_EXISTS = 'PHONE_NUMBER_ALREADY_EXISTS',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  UNIT_ALREADY_EXISTS = 'UNIT_ALREADY_EXISTS',
  IDENTIFY_ALREADY_EXISTS = 'IDENTIFY_ALREADY_EXISTS',
  PASSWORD_INCORRECT = 'PASSWORD_INCORRECT',
  AGENT_ID_ALREADY_EXISTS = 'AGENT_ID_ALREADY_EXISTS',
  // Phân quyền
  PERMISSION_DO_NOT_EXISTS = 'PERMISSION_DO_NOT_EXISTS', // Không tồn tại dữ liệu ( Đã bị xóa )
  USER_ALREADY_EXISTS_IN_GROUP = 'USER_ALREADY_EXISTS_IN_GROUP', // Quyền đã gán cho user
  PERMISSION_ALREADY_EXISTS = 'PERMISSION_ALREADY_EXISTS', // Quyền trung tên,
  CONSTRUCTOR_DO_NOT_EXISTS = 'CONSTRUCTOR_DO_NOT_EXISTS',
  APARTMENT_DO_NOT_EXISTS = 'APARTMENT_DO_NOT_EXISTS',
  APARTMENT_ALREADY_EXISTS = 'APARTMENT_ALREADY_EXISTS',
  BANK_DO_NOT_EXISTS = 'BANK_DO_NOT_EXISTS',
  BANK_ALREADY_EXISTS = 'BANK_ALREADY_EXISTS',
  DIRECTION_DO_NOT_EXISTS = 'DIRECTION_DO_NOT_EXISTS',
  DIRECTION_ALREADY_EXISTS = 'DIRECTION_ALREADY_EXISTS',
  TAX_CODE_ALREADY_EXISTS = 'TAX_CODE_ALREADY_EXISTS',
  PROJECT_DO_NOT_EXISTS = 'PROJECT_DO_NOT_EXISTS',
  REFERRAL_CODE_DO_NOT_EXISTS = 'REFERRAL_CODE_DO_NOT_EXISTS',
  // Dự án
  PROJECT_ALREADY_EXISTS = 'PROJECT_ALREADY_EXISTS',
  BLOCK_DO_NOT_EXISTS = 'AREA_DO_NOT_EXISTS',
  BLOCK_ALREADY_EXISTS = 'AREA_ALREADY_EXISTS',

  // cộng tác viên
  COLLABORATOR_IS_NOT_INTRODUCER = 'COLLABORATOR_IS_NOT_INTRODUCER',

  // Căn hộ mẫu
  NOT_AVAILABLE_TO_DELETE = 'NOT_AVAILABLE_TO_DELETE',
  HOUSETEMPLATE_ALREADY_EXISTS = 'HOUSETEMPLATE_ALREADY_EXISTS',

  // Căn hộ/lô đất
  HOUSE_ALREADY_EXISTS = 'HOUSE_ALREADY_EXISTS',
  HOUSE_CODE_ALREADY_EXISTS = 'HOUSE_CODE_ALREADY_EXISTS',
  SUM_OF_ELEMENTS_IN_ROW_MUST_BE_100 = 'SUM_OF_ELEMENTS_IN_ROW_MUST_BE_100',
  FILE_IS_NOT_FORMULATED = 'FILE_IS_NOT_FORMULATED',
  NO_BLOCKS_EXIS = 'NO_BLOCKS_EXIS',
  NAME_ALREADY_EXIST = 'NAME_ALREADY_EXIST',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  NO_PROJECT_NEWS_EXISTS = 'NO_PROJECT_NEWS_EXISTS',
  NAME_ON_THE_FLOOR_ALREADY_EXISTS = 'NAME_ON_THE_FLOOR_ALREADY_EXISTS',
}
export enum StatusType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ACTIVE_NOT_VERIFIED = 'ACTIVE_NOT_VERIFIED',
}

export enum BaseStatusProject {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_FOR_SALE = 'NOT_FOR_SALE',
  FOR_SALE = 'FOR_SALE',
  ACTIVE_NOT_VERIFIED = 'ACTIVE_NOT_VERIFIED',
  SOLD = 'SOLD', //  đã bán
  DEPOSITED = 'DEPOSITED', //  đã đặt cọc
  PAYMENT = 'PAYMENT', //  chờ thanh toán
}

export const NameStatus: any = {
  [BaseStatusProject.INACTIVE]: 'BaseStatusProject.INACTIVE',
  [BaseStatusProject.NOT_FOR_SALE]: 'BaseStatusProject.NOT_FOR_SALE',
  [BaseStatusProject.FOR_SALE]: 'BaseStatusProject.FOR_SALE',
  [BaseStatusProject.ACTIVE]: 'BaseStatusProject.ACTIVE',
  [BaseStatusProject.ACTIVE_NOT_VERIFIED]: 'BaseStatusProject.ACTIVE_NOT_VERIFIED',
};

export enum HouseType {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export enum StatusProject {
  YES = 'YES',
  NO = 'NO',
}

// Trạng thái giao dịch
export enum StatusTransactions {
  INTRODUCED = 'INTRODUCED', //  giới thiệu
  FOUND_SUPPORTER = 'FOUND_SUPPORTER', //  tìm người hỗ trợ
  CONFIRMED_INFO = 'CONFIRMED_INFO', //  xác minh thông tin
  SUPPORTED = 'SUPPORTED', //  được tư vấn/hỗ trợ
  BOOKING = 'BOOKING', //  yêu cầu đặt chỗ
  BOOKED = 'BOOKED', //  đặt chỗ thành công
  DEPOSIT_PENDING = 'DEPOSIT_PENDING', //  chờ thanh toán (yeeu cau)
  DEPOSIT = 'DEPOSIT', //  đặt cọc thành công
  SUCCESS = 'SUCCESS', //  thành công
  CANCEL_BOOKING = 'CANCEL_BOOKING', //  Huỷ Đặt chỗ
  CANCEL_DEPOSIT = 'CANCEL_DEPOSIT', //  Huỷ Đặt cọc
  CUSTOMER_CANCEL = 'CUSTOMER_CANCEL', // Nguoi dung huỷ
}

export enum StatusBuy {
  PENDING = 'PENDING',
  SUPPORTING = 'SUPPORTING',
  SUPPORTED = 'SUPPORTED',
}
