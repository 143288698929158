/* eslint-disable consistent-return */
export enum StorageKey {
  USER_SESSION = 'USER_SESSION',
  USER_ACCOUNT = 'USER_ACCOUNT',
  CACHE_FORM_CREATE_ADMIN = 'CACHE_FORM_CREATE_ADMIN',
  CACHE_FORM_CREATE_CUSTOMER = 'CACHE_FORM_CREATE_CUSTOMER',
  TOKEN = 'TOKEN',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  CACHE_FORM_CREATE_PERMISSION = 'CACHE_FORM_CREATE_PERMISSION',
  CACHE_FORM_CREATE_BANK = 'CACHE_FORM_CREATE_BANK',
  CACHE_FORM_CREATE_PROPERTY = 'CACHE_FORM_CREATE_PROPERTY',
  CACHE_FORM_CREATE_APARTMENT = 'CACHE_FORM_CREATE_APARTMENT',
  CACHE_FORM_CREATE_DIRECTION = 'CACHE_FORM_CREATE_DIRECTION',
  CACHE_FORM_CREATE_CONSTRUCTOR = 'CACHE_FORM_CREATE_CONSTRUCTOR',
  CACHE_FORM_CREATE_PROJECT = 'CACHE_FORM_CREATE_PROJECT',
  CACHE_FORM_CREATE_BLOCK_AREA = 'CACHE_FORM_CREATE_BLOCK_AREA',
}

function save(key: StorageKey, value: string): void {
  localStorage.setItem(key, value);
}

function get(key: StorageKey): string | null {
  return localStorage.getItem(key);
}

function saveObject(key: StorageKey, object: any): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(object));
    return true;
  } catch (error) {
    return false;
  }
}

function getObject(key: StorageKey): any {
  try {
    return JSON.parse(localStorage.getItem(key)?.toString() || '{}');
  } catch (error) {
    return {};
  }
}

function remove(key: StorageKey): void {
  localStorage.removeItem(key);
}

function clear(): void {
  localStorage.clear();
}

function getPermission(): [] | undefined {
  const sessionStr = localStorage.getItem(StorageKey.USER_SESSION) || '';
  if (!sessionStr) return undefined;
  const session = JSON.parse(sessionStr);
  if (session.user.group && session.user.group.permission) return session.user.group.permission;
}

export default {
  save,
  get,
  saveObject,
  getObject,
  remove,
  clear,
  getPermission,
};
