import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { translate } from 'translate/language';

function useDeleteArea(): [(id: string) => Promise<void>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);

  const onDeleteArea = async (id: string) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete<string, any>(BASE_URL.DELETE_AREA + `${id}`, id);
      if (res && res.status === StatusResponse.BLOCK_DO_NOT_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.data_do_not_exists'),
        });
        setLoading(false);
        return;
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoading(false);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return [onDeleteArea, loading];
}

export default useDeleteArea;
