/* eslint-disable consistent-return */
import { ToastError, ToastSuccess } from 'components/Toast';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { CommissionPlusFilter, CommissionPlusReq, CommissionPlusRes } from './commission-plus.type';

const COMMISSION_URL = '/project/cms-give-commission';
const COMMISSION_POINT = 'project/total-commission/';

function useCommissionPlusService() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCommission, setDataCommission] = useState<CommissionPlusRes[]>([]);
  const [detailCommission, setDetailCommission] = useState<CommissionPlusRes | undefined>(undefined);

  const [totalCommission, setTotalCommission] = useState<number>();
  // Hoa hồng khả dụng
  const [totalCommissionPoint, setTotalCommissionPoint] = useState<number>(0);
  // Danh sách người cộng điểm
  const onGetCommissionPlus = async (params: CommissionPlusFilter) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(COMMISSION_URL, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalCommission(res.total);
        setDataCommission(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Lấy hoa hồng khả dụng
  const onGetCommissionPoint = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(COMMISSION_POINT + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalCommissionPoint(res.totalCommission);
      } else {
        setTotalCommissionPoint(0);
      }
    } catch (error) {
      setTotalCommissionPoint(0);
    }
  };

  // Thêm mới cộng hoa hồng
  const onCreateCommissionPoint = async (body: CommissionPlusReq) => {
    try {
      const res: any = await ApiHelper.post(COMMISSION_URL, body);
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.add_new_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
    }
  };

  // Chi tiết cộng hoa hồng
  const onDetailCommission = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(`${COMMISSION_URL}/${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailCommission(res.data);
      } else {
        setDetailCommission(undefined);
      }
    } catch (error) {
      setDetailCommission(undefined);
    }
  };

  // Cập nhật cộng điểm
  const onUpdateCommission = async (id: string, body: CommissionPlusReq) => {
    try {
      const res: any = await ApiHelper.put(`${COMMISSION_URL}/${id}`, body);
      if (res && res.status === 'CANNOT_MODIFIER') {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Bản ghi không thể sửa đổi có trạng thái là Đã phê duyệt',
        });
      }

      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.update_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return {
    onGetCommissionPlus,
    dataCommission,
    totalCommission,
    loading,
    onGetCommissionPoint,
    totalCommissionPoint,
    onCreateCommissionPoint,
    onDetailCommission,
    detailCommission,
    onUpdateCommission,
  };
}

export default useCommissionPlusService;
