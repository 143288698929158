import { LockOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, DatePicker, Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import './css/login.scss';
import axios from 'axios';
import { ToastError, ToastSuccess } from 'components/Toast';
import moment from 'moment';
import Styled from './indexStyled';

const STEPS = 0;

interface Props {}
const LoginFormImpl: React.FC<Props> = (props: Props) => {
  const errorMessages: any[] = [];
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [data, setData] = useState<any>(null);
  const [dateStart, setDateStart] = useState<string>(moment('2022-01-01').format('YYYY-MM-DD'));
  const [dateEnd, setDateEnd] = useState<string>(moment().format('YYYY-MM-DD'));
  const [dateStartMoment, setDateStartMoment] = useState<any>(moment('2022-01-01'));
  const [dateEndMoment, setDateEndMoment] = useState<any>(moment());

  useEffect(() => {
    // dispatch(resetLogin());
    // onLoginFail()
  }, []);

  const handleSubmit = (e: any = {}, dateStart: any = moment('2022-01-01'), dateEnd: any = moment()) => {
    axios
      .post(`${process.env.REACT_APP_API_DOMAIN}/statistic`, {
        username: form.getFieldValue('username'),
        password: form.getFieldValue('password'),
        dateStart: dateStart || undefined,
        dateEnd: dateEnd || undefined,
      })
      .then(function(response) {
        if (response.data.status) {
          ToastError({
            message: translate('login.error'),
            description: `Tài khoản không đúng`,
          });
        } else {
          setData(response.data);
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const validateFormLogin = {
    username: [
      {
        whitespace: true,
        required: true,
        message: `Tên đăng nhập không được để trống`,
      },
    ],
    password: [
      {
        whitespace: true,
        required: true,
        message: translate('login.err_pass'),
      },
    ],
  };

  const onChangeDate = (date: any, dateString: any) => {
    if (date && dateString) {
      const dateStart = date[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      const dateEnd = date[1].set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
      setDateStart(dateString[0]);
      setDateEnd(dateString[1]);
      setDateStartMoment(dateStart);
      setDateEndMoment(dateEnd);
    } else {
      setDateStart(moment('2022-01-01').format('YYYY-MM-DD'));
      setDateEnd(moment().format('YYYY-MM-DD'));
      setDateStartMoment(moment('2022-01-01'));
      setDateEndMoment(moment());
    }
  };

  const onApply = (e: any) => {
    handleSubmit(e, dateStartMoment, dateEndMoment);
  };

  const onCancel = (e: any) => {
    setDateStart(moment('2022-01-01').format('YYYY-MM-DD'));
    setDateEnd(moment().format('YYYY-MM-DD'));
    setDateStartMoment(moment('2022-01-01'));
    setDateEndMoment(moment());
    handleSubmit(e);
  };

  return (
    <>
      {!data ? (
        <>
          <Styled.Container>
            <div className="container" style={{}}>
              <h3 className="title">{translate('login.login')}</h3>
              <ul className="text-danger">
                {errorMessages.map((el, i) => {
                  return <li key={i}>{el}</li>;
                })}
              </ul>
              <Form className="login-form" form={form}>
                <Form.Item initialValue="" name="username" rules={validateFormLogin.username}>
                  <Input
                    type="text"
                    autoFocus={true}
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={`Tên đăng nhập`}
                    style={{ padding: '0px 12px' }}
                  />
                </Form.Item>
                <Form.Item name="password" initialValue="" rules={validateFormLogin.username}>
                  <Input.Password
                    style={{ padding: '0px 12px' }}
                    prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder={translate('login.password')}
                  />
                </Form.Item>
                <Button onClick={handleSubmit} type="primary" htmlType="submit" className="login-form-button">
                  {translate('login.login')}
                </Button>
              </Form>
            </div>
          </Styled.Container>
        </>
      ) : (
        <>
          <Styled.Container2>
            <div className="container" style={{}}>
              <h3 className="title">Thống kê</h3>
              <DatePicker.RangePicker
                onChange={onChangeDate}
                className="datepicker"
                value={[dateStartMoment, dateEndMoment]}
                allowClear={false}
              />
              <Button type="primary" className="apply" onClick={onApply}>
                Áp dụng
              </Button>
              <Button type="link" onClick={onCancel}>
                Hủy
              </Button>
              <table className="statistic">
                <tr>
                  <th>Các trường báo cáo</th>
                  <th>Hạng mục con báo cáo</th>
                  <th>Số liệu báo cáo</th>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <b>Số người truy cập</b>
                  </td>
                  <td>Tổng số người</td>
                  <td>{data.soLuongTruyCap}</td>
                </tr>
                <tr>
                  <td>Số người mới</td>
                  <td>{data.soLuongTruyCapMoi}</td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <b>Số thành viên</b>
                  </td>
                  <td>Số thành viên mới</td>
                  <td>{data.soNguoiBan}</td>
                </tr>
                <tr>
                  <td>Số người mới</td>
                  <td>{data.soNguoiBanMoi}</td>
                </tr>
                <tr>
                  <td rowSpan={2}>
                    <b>Số tin đăng</b>
                  </td>
                  <td>Tổng số tin đăng</td>
                  <td>{data.tongSoSanPham}</td>
                </tr>
                <tr>
                  <td>Số tin mới</td>
                  <td>{data.soSanPhamMoi}</td>
                </tr>
              </table>
            </div>
          </Styled.Container2>
        </>
      )}
    </>
  );
};

const LoginPage = LoginFormImpl;
export default React.memo(LoginPage);
