/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Button, Col, Pagination, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import images from 'images';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import useSupportService from './support.service';

function SupportScreen(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<any>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  const { onGetSupport, dataSupport, totalSupport, loading, onDeleteSupport, loadingDel } = useSupportService();

  useEffect(() => {
    onGetSupport(filter);
  }, [filter, loadingDel]);

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.DETAIL_SUPPORT + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={() => onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
      width: '45%',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone: string) => {
        return <span>{phone}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email: string) => {
        return <span>{email}</span>;
      },
    },
    {
      title: 'Tùy chọn',
      dataIndex: '_id',
      render: renderAction,
    },
  ];

  const onDelete = (_id: string) => {
    onDeleteSupport(_id);
  };

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  const addNew = () => {
    history.push(ROUTER_PATH.ADD_SUPPORT);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col className="gutter-row" span={24}>
          {getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).create ? (
            <div>
              <Button style={{ display: 'block', float: 'right' }} type="primary" onClick={addNew}>
                {translate('action.add_new')}
              </Button>
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
      <Row>
        {loading || loadingDel ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataSupport || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalSupport}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default SupportScreen;
