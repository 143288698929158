/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable object-shorthand */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Table,
  Typography,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import confirm from 'antd/lib/modal/confirm';
import { RadioChangeEvent } from 'antd/lib/radio';
import { UploadFile } from 'antd/lib/upload/interface';
import Container from 'components/ContentLayout/Container';
import GPlace from 'components/Maps/GPlace';
import { ToastError } from 'components/Toast';
import PaginateList from 'constants/PaginateList';
import { StatusResponse } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import useListCustomer from 'hooks/Customer/useListCustomer';
import useListDirection from 'hooks/Direction/useListDirection';
import useSelectMaps from 'hooks/Maps/useSelectMaps';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import moment from 'moment';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import useConfigOtherService from 'pages/ConfigurationManagerPage/ConfigOther/config-other.service';
import { Media } from 'pages/House/house-detail.screen';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import InputAddLinkVideo from 'pages/ProjectPage/InputAddLinkVideo';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { BaseResponse } from 'types/response/BaseResponse';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import {
  InputCol,
  InputColNumber,
  InputSelect,
  InputText,
  InputTextArea,
  InputTextNumber,
  SelectInputCOl,
} from '../components/InputSelect';
import SelectContactComponent from '../components/SelectContactComponent';
import SelectHostInfo from '../components/SelectHostInfo';
import styles from '../css/sale-and-rent-create.module.scss';
import {
  configurations,
  formCreate,
  listDepositeType,
  ListStatusSR,
  ReqCreateRealEstate,
} from '../modelSaleAndRental/SaleAndRental.type';
import useCityDistrictWard from '../useSaleAndRentalhooks/useCityDistrictWardHooks';
import useLitsCustomerRealestate from '../useSaleAndRentalhooks/useCustomerListHooks';
import useRelatedHook, { UserInfoRelated } from '../useSaleAndRentalhooks/useRelatedHook';

const { Text, Link } = Typography;

interface Props {}
export interface FilterDirection {
  search?: string;
  page: number;
  limit: number;
}

export interface InputAutoCompleteRef {
  valueMapObject: any;
  setValue: any;
  setValueMapObject: any;
}

const CreateElstate: React.FC<Props> = () => {
  const refSelectHostInfo = useRef<any | undefined>();
  const refSelectcontact = useRef<any | undefined>();
  const [FilterDirection, setFilterDirection] = useState<FilterDirection>({
    search: '',
    page: 1,
    limit: 200,
  });
  const { id } = useParams<any>();
  const [onUpload, resUpload] = useUploadMulti();
  const [dataSetting, setDataSetting] = useState<any[]>([]);
  const [type, setType] = useState<string>('BUY');
  const {
    onCreateSaleAndRent,
    onGetDetailSaleAndRent,
    detailSr,
    onUpdateDetailSaleAndRent,
  } = useLitsCustomerRealestate();

  const [valueMap, setValueMap] = useState<string | undefined>(undefined);
  const [data, setData] = useState<any[]>([]);
  const [dataLatLng, setDataLatLng] = useState<any>(undefined);

  let timeout: any;
  let currentValue: any;

  const [imageFile, setImageFile] = useState<Media[]>([]);
  const [valueTypeRealEstate, setValueTypeRealEstate] = useState<any>();
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();
  const [onFetchListDirection, listDirection, loading, listDirectionData, stopLoad] = useListDirection();
  const [form] = useForm<formCreate>();
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const { stopLoadmore } = useCityDistrictWard();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);

  const [validateCheck, setValidateCheck] = useState<boolean>(true);
  const history = useHistory();
  const detailRelated = (idRelated: string) => {
    if (id) {
      history.push(ROUTER_PATH.DETAIL_RELATED + idRelated);
    }
  };

  const { onFetchListApartment, listApartment } = useLitsHouseTemplate();
  useEffect(() => {
    onFetchListApartment({
      page: PaginateList.PAGE_DEFAULT,
    });
  }, []);

  // Get CTV hỗ trợ
  const { onGetConfigOther, dataConfigOther } = useConfigOtherService();

  useEffect(() => {
    onGetConfigOther();
  }, []);

  useEffect(() => {
    if (!id && dataConfigOther) {
      form.setFieldsValue({ defaultSupporter: dataConfigOther.defaultSupporter });
    }
  }, [dataConfigOther]);

  const getDetailConfig = async () => {
    const res = await ApiHelper.fetch<any, BaseResponse>(BASE_URL.SETTINGS_NEW_CONFIGS, null, true);
    if (res && res.status === StatusResponse.SUCCESS && res.data && res.data.length > 0) {
      setDataSetting(res.data);
    }
  };

  useEffect(() => {
    onFetchListParentChildRealeState();
    if (id) {
      getDetailConfig();
      onGetDetailSaleAndRent(id);
    }
  }, []);

  const [valueChecked, setValueChecked] = useState<string | undefined>(undefined);

  const textStatus = (status: string, deleteAt: moment.Moment) => {
    if (deleteAt === null) {
      return '( Đã xóa )';
    }
    if (status === 'ACTIVE') {
      return '( Hoạt động )';
    }
    if (status === 'ACTIVE_NOT_VERIFIED') {
      return '( Hoạt động - Chưa kích hoạt )';
    }
    if (status === 'INACTIVE') {
      return '( Không hoạt động )';
    }
    return '';
  };

  // const isPAGArticle = detailSr && detailSr.createdBy && detailSr.createdBy.type === 'CMS';
  const isPAGArticle: boolean = !id || (detailSr && detailSr.isAdmin);

  useEffect(() => {
    if (detailSr) {
      setType(detailSr.type);
      form.setFieldsValue({
        status: detailSr.status,
        type: detailSr.type,
        childRealEstateId: detailSr.childRealEstateId,
        projectName: detailSr.projectName,
        landName: detailSr.landName,
        addressName: detailSr.location.addressName,
        commissionFee: detailSr.commissionFee,
        description: detailSr.description,
        price: detailSr.price,
        namePost: detailSr.createdBy?.name,
        phonePost: detailSr.createdBy?.phone,
        reverseCommission: detailSr.reverseCommission
          ? !isNaN(detailSr.reverseCommission)
            ? detailSr.reverseCommission
            : 0
          : 0,
        defaultSupporter: detailSr?.defaultSupporter?._id,
        days: detailSr?.days,
      });

      if (detailSr.ownerType === 'CMS' || detailSr.ownerType === 'AGENCY') {
        setValidateCheck(true);
      } else {
        setValidateCheck(false);
      }

      if (detailSr.contacts) {
        form.setFieldsValue({
          contacts: detailSr.contacts.map((e: any) => e._id),
        });
        refSelectcontact.current.onHandChangeContact(detailSr.contacts.map((e: any) => e._id));
      }

      if (detailSr.ownerId && detailSr.owner) {
        form.setFieldsValue({
          ownerId: `${detailSr.owner.name} ${textStatus(detailSr.owner.status, detailSr.owner.deleteAt)}`,
        });
        refSelectHostInfo.current.onHandChangeContact(detailSr.ownerId);
      }
      // refSelectcontact..current.onHandChangeContact(detailSr.ownerId);
      if (detailSr.videoUrl && detailSr.videoUrl.length > 0) {
        setfileListVideoIds(detailSr.videoUrl);
      }
      if (detailSr.parentRealEstate) {
        setValueTypeRealEstate({ parent: detailSr.parentRealEstate });
      }
      if (detailSr.props) {
        // if (detailSr.props.block) {
        form.setFieldsValue({
          block: detailSr.props.block,
        });
        // }
        // if (detailSr.props.floor) {
        form.setFieldsValue({
          floor: detailSr.props.floor,
        });
        // }
        // if (detailSr.props.area) {
        form.setFieldsValue({
          area: detailSr.props.area,
        });
        // }

        form.setFieldsValue({
          bathroomNumber: detailSr.props.bathroomNumber,
        });

        // if (detailSr.props.width) {
        form.setFieldsValue({
          width: detailSr.props.width,
        });
        // }

        // if (detailSr.props.height) {
        form.setFieldsValue({
          height: detailSr.props.height,
        });
        // }
        // if (detailSr.props.hight) {
        form.setFieldsValue({
          hight: detailSr.props.hight,
        });
        // }
        // if (detailSr.props.apartmentCode) {
        form.setFieldsValue({
          apartmentCode: detailSr.props.apartmentCode,
        });
        // }
        // if (detailSr.props.bedroomNumber) {
        form.setFieldsValue({
          bedroomNumber: detailSr.props.bedroomNumber,
        });
        // }
      }
      if (detailSr.medias) {
        setImageFile(detailSr.medias);
      }
      if (detailSr.directionId) {
        form.setFieldsValue({
          directionId: detailSr.directionId,
        });
      }

      // Vị trí dự án
      if (detailSr.location) {
        setValueMap(detailSr.location.addressName);
        setDataLatLng({
          lat: detailSr.location.coordinates[1] || 0,
          lng: detailSr.location.coordinates[0] || 0,
        });
      }

      // Hình thức đăng tin
      if (detailSr.createdBy && detailSr.createdBy.type === 'CUSTOMERS') {
        setValueChecked(detailSr.newsConfigType);
      }

      // Chủ nhà
      if (detailSr.customOwner) {
        if (detailSr.customOwner.name) {
          form.setFieldsValue({
            customOwnerName: detailSr.customOwner.name,
          });
        }
        if (detailSr.customOwner.phone) {
          form.setFieldsValue({
            customOwnerPhone: detailSr.customOwner.phone,
          });
        }
        if (detailSr.customOwner.email) {
          form.setFieldsValue({
            customOwnerEmail: detailSr.customOwner.email,
          });
        }
        if (detailSr.customOwner.identify) {
          form.setFieldsValue({
            customOwnerIdentify: detailSr.customOwner.identify,
          });
        }
      }
    }
    return () => {};
  }, [detailSr]);

  useEffect(() => {
    onFetchListDirection(FilterDirection);
  }, [FilterDirection]);

  // const onPopUpscroll = (e: any) => {
  //   if (stopLoadmore || stopLoad) return;
  //   if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
  //     return setFilterDirection({ ...FilterDirection, page: FilterDirection.page + 1 });
  //   }
  // };

  const [ownerIdValue, setOwnerIdValue] = useState<string | undefined>(undefined);
  const onChangeHouse = (data: string) => {
    setOwnerIdValue(data);
  };

  const [customOwnerName, setCustomOwnerName] = useState<string | undefined>(undefined);
  const onChangeCustomOwnerName = (data: string) => {
    setCustomOwnerName(data);
  };

  const [customOwnerPhone, setCustomOwnerPhone] = useState<string | undefined>(undefined);
  const onChangeCustomOwnerPhone = (data: string) => {
    setCustomOwnerPhone(data);
  };

  const [customOwnerEmail, setCustomOwnerEmail] = useState<string | undefined>(undefined);
  const onChangeCustomOwnerEmail = (data: string) => {
    setCustomOwnerEmail(data);
  };

  const [customOwnerIdentify, setCustomOwnerIdentify] = useState<string | undefined>(undefined);
  const onChangeCustomOwnerIdentify = (data: string) => {
    setCustomOwnerIdentify(data);
  };

  const [fileListVideoIds, setfileListVideoIds] = useState<UploadFile<any>[] | any>([]);

  const onChangeInputFileVideo = (value: any) => {
    const newData = [value, ...fileListVideoIds];
    setfileListVideoIds(newData);
  };

  const onRemoveItem = (item: any) => {
    confirm({
      title: translate('delete_video'),
      onOk: () => {
        const newFileVideo = [...fileListVideoIds].filter(e => e !== item);
        setfileListVideoIds(newFileVideo);
      },
      onCancel: () => {},
    });
  };

  const handleSubmit = (value: formCreate) => {
    const body: ReqCreateRealEstate = {
      ...(isPAGArticle
        ? {
            customOwner: {
              name: value.customOwnerName,
              phone: value.customOwnerPhone,
              email: value.customOwnerEmail,
              identify: value.customOwnerIdentify,
            },
          }
        : {
            ownerId: ownerIdValue,
          }),
      directionId: value.directionId,
      contacts: value.contacts,
      commissionFee: value.commissionFee,
      reverseCommission: value.reverseCommission,
      parentRealEstateId: (valueTypeRealEstate && valueTypeRealEstate.parent && valueTypeRealEstate.parent._id) || '',
      childRealEstateId: value.childRealEstateId,
      type: value.type,
      location: {
        addressName: valueMap || '',
        lat: dataLatLng.lat || 0,
        lng: dataLatLng.lng || 0,
      },
      status: value.status,
      videoUrl: (fileListVideoIds && fileListVideoIds.map((x: any) => x)) || [],
      mediaIds: imageFile.map(e => e._id),
      description: value.description,
      price: value.price,
      landName: value.landName,
      props: {
        area: value.area,
        width: value.width,
        height: value.height,
        hight: value.hight,
        floor: value.floor,
        apartmentCode: value.apartmentCode,
        block: value.block,
        bathroomNumber: value.bathroomNumber,
        bedroomNumber: value.bedroomNumber,
      },
      projectName: value.projectName,
      defaultSupporter: value.defaultSupporter,
      days: value.days,
      newsConfigType: valueChecked,
    };

    if (id) {
      onUpdateDetailSaleAndRent(id, body);
      return;
    }
    onCreateSaleAndRent(body);
  };

  const fetchData = (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;

    function fetchPlaces() {
      ApiHelper.fetch('project-news/googlePlace', {
        search: value,
      })
        .then((response: any) => {
          return response.data?.googleData;
        })
        .then(predictions => {
          if (predictions && currentValue === value) {
            if (predictions.length > 0) {
              const dataSelect = predictions.map((data: any) => {
                return {
                  value: data.address,
                  text: data.address,
                  lat: data.location.lat || 0,
                  lng: data.location.lng || 0,
                };
              });
              callback(dataSelect);
            } else {
              callback([]);
            }
          }
        });
    }

    timeout = setTimeout(fetchPlaces, 500);
  };

  const handleSearchMap = (value: any) => {
    if (value) {
      fetchData(value, (data: any) => {
        setData(data);
      });
    } else {
      setData([]);
    }
  };

  const handleChangeMap = (value: any, option: any) => {
    setValueMap(value);
    if (option && option.data) {
      setDataLatLng(option.data);
    }
  };

  const onChangeTypeEstate = (id: any) => {
    const index = ListParentChildRealeState.findIndex((e: any) => {
      return e._id === id;
    });
    if (index > -1) {
      setValueTypeRealEstate(ListParentChildRealeState[index]);
    }
  };

  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  const onChangeFiles = (fileList: any) => {
    const newData: any = [...imageFile, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh thứ ${index} vượt quá dung lượng`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    setImageFile(newData);
    onUpload(data);
  };

  const deleteImage = (item: Media | any) => {
    const newArrImg = imageFile.filter(data => {
      return (data as Media)._id !== (item as Media)._id;
    });
    setImageFile(newArrImg);
    message.success('Xoá ảnh thành công');
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      const newfileListScaleDetail = [...imageFile].filter(e => e._id);
      setImageFile([...newfileListScaleDetail, ...resUpload]);
    }
  }, [resUpload]);

  const onCancel = () => {
    setModalVisible(false);
  };

  const onPriceChanged = (text: number) => {
    if (typeof text === 'string') return;
    const price = text;
    const reverseCommission = Number(form.getFieldValue('reverseCommission') || 0);
    const totalComission = (reverseCommission * price) / 100;
    form.setFieldsValue({
      commissionFee: totalComission,
    });
  };

  const onReverseCommissionChanged = (text: string) => {
    if (typeof text === 'string') return;
    const reverseCommission = text;
    const price = Number(form.getFieldValue('price'));
    const commission = Math.round((price * reverseCommission) / 100);
    form.setFieldsValue({ commissionFee: Number(commission) });
  };

  const onCommissionChanged = (text: string) => {
    if (typeof text === 'string') return;
    const commission = Number(text);
    const price = Number(form.getFieldValue('price'));
    let reverseCommission = Number(form.getFieldValue('reverseCommission') || 0);
    reverseCommission = (commission / price) * 100;
    form.setFieldsValue({
      reverseCommission: reverseCommission,
    });
  };

  const getOwnerType = (type: string) => {
    if (type && type === 'AGENCY') {
      return 'Môi giới';
    }
    if (type && type === 'OWNER') {
      return 'Chính chủ';
    }
    if (type && type === 'NO_AGENCY') {
      return 'Miễn trung gian';
    }
    return null;
  };

  const [onFetchListCustomer, listCustomer] = useListCustomer();
  const { onGetRelated, dataRelated } = useRelatedHook();

  useEffect(() => {
    onFetchListCustomer({});
    if (id) {
      onGetRelated(id);
    }
  }, []);

  const columns = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoRelated) => {
        return (
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo?._id} target="_blank">
            {userInfo?.name}
          </Link>
        );
      },
    },
    {
      title: 'CTV giới thiệu',
      dataIndex: 'introducerInfo',
      render: (introducerInfo: UserInfoRelated) => {
        return (
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + introducerInfo?._id} target="_blank">
            {introducerInfo.name}
          </Link>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'INTRODUCED':
            return translate('transaction.INTRODUCED');
          case 'FOUND_SUPPORTER':
            return translate('transaction.FOUND_SUPPORTER');
          case 'CONFIRMED_INFO':
            return translate('transaction.CONFIRMED_INFO');
          case 'SUPPORTED':
            return translate('transaction.SUPPORTED');
          case 'BOOKING':
            return translate('transaction.BOOKING');
          case 'BOOKED':
            return translate('transaction.BOOKED');
          case 'DEPOSIT_PENDING':
            return translate('transaction.DEPOSIT_PENDING');
          case 'DEPOSIT':
            return translate('transaction.DEPOSIT');
          case 'SUCCESS':
            return translate('transaction.SUCCESS');
          case 'CANCEL_BOOKING':
            return translate('transaction.CANCEL_BOOKING');
          case 'CANCEL_DEPOSIT':
            return translate('transaction.CANCEL_DEPOSIT');
          case 'CUSTOMER_CANCEL':
            return translate('transaction.CUSTOMER_CANCEL');
          default:
            return '';
        }
      },
    },
    {
      title: 'Ngày được set',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <Text>{moment(createdAt).format('HH:mm DD-MM-YYYY ')}</Text>;
      },
    },
    {
      dataIndex: '_id',
      render: (id: string) => {
        return (
          <div onClick={() => detailRelated(id)}>
            <Text underline style={{ color: '#3a8edf', cursor: 'pointer' }}>
              Chi tiết
            </Text>
          </div>
        );
      },
    },
  ];

  const onChangeChecked = (e: RadioChangeEvent) => {
    setValueChecked(e.target.value);
  };

  const selectType = (value: string) => {
    setType(value);
  };

  const handleChangeStatus = (value: any) => {
    console.log('🚀 ~ file: form-create-realestate.tsx:698 ~ handleChangeStatus ~ value:', value);
  };

  return (
    <Container>
      {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).create ||
      getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).edit ? (
        <>
          <Form style={{ padding: 20 }} form={form} onFinish={handleSubmit}>
            <InputSelect
              onChange={handleChangeStatus}
              rules={[]}
              istrans
              name="status"
              label="Trạng thái"
              data={ListStatusSR}
            />
            <InputSelect
              rules={[]}
              istrans
              name="type"
              label="Loại ký gửi"
              data={listDepositeType}
              onChange={selectType}
            />
            <InputSelect
              rules={[]}
              onChange={onChangeTypeEstate}
              name="childRealEstateId"
              label="Loại bất động sản"
              data={ListParentChildRealeState}
            />
            {/* Tên dự án */}
            <Form.Item
              name="projectName"
              label="Tên dự án"
              labelAlign="left"
              style={{ justifyContent: 'center', display: 'flex' }}
              rules={[
                {
                  whitespace: true,
                  max: 100,
                  message: 'Tên dự án tối đa 100 kí tự',
                },
              ]}
              labelCol={{ md: 8, xl: 8, xs: 24 }}
              wrapperCol={{ md: 12, xs: 24 }}
            >
              <Input style={{ background: 'white', color: 'black' }} />
            </Form.Item>

            {/* Tên bất động sản */}
            <Form.Item
              name="landName"
              label="Tiêu đề"
              labelAlign="left"
              style={{ justifyContent: 'center', display: 'flex' }}
              rules={[
                { whitespace: true, required: true, message: 'Tiêu đề không được để trống' },
                {
                  max: 100,
                  message: 'Tiêu đề tối đa 100 kí tự',
                },
              ]}
              labelCol={{ md: 8, xl: 8, xs: 24 }}
              wrapperCol={{ md: 12, xs: 24 }}
            >
              <Input style={{ background: 'white', color: 'black' }} />
            </Form.Item>

            {/* Địa chỉ */}
            <Row gutter={24} style={{ marginBottom: '20px' }}>
              <Col span={8} offset={2}>
                {translate('project.location_project')}
              </Col>
              <Col span={12} style={{ paddingLeft: '0px' }}>
                <Select
                  showSearch
                  value={valueMap}
                  placeholder="Tìm kiếm chọn địa chỉ"
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={handleSearchMap}
                  onChange={handleChangeMap}
                  notFoundContent={null}
                  style={{ width: '100%' }}
                >
                  {data &&
                    data.length > 0 &&
                    data.map((d: any, index: number) => {
                      return (
                        <Select.Option key={index + 1} value={d.value} data={d}>
                          {d.text}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>

            <div className={styles.StylesCenterDrop}>
              <Row justify="space-between" align="middle" style={{ width: '84%', flexWrap: 'wrap' }}>
                {valueTypeRealEstate && (
                  <SelectInputCOl label="Hướng nhà" name="directionId" rules={[]} data={listDirectionData || []} />
                )}

                {/* Số phòng ngủ */}
                {valueTypeRealEstate &&
                  valueTypeRealEstate.parent &&
                  (valueTypeRealEstate!.parent.note === 'NHAPHO' ||
                    valueTypeRealEstate!.parent.note === 'CHUNGCU' ||
                    valueTypeRealEstate!.parent.note === 'BIETTHU') && (
                    <Col md={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 24 }}>
                      <Row justify="center">
                        <div className={styles.nameLabel}>
                          <span style={{ color: 'red' }}>*</span> Số phòng ngủ
                        </div>
                      </Row>
                      <Form.Item
                        name="bedroomNumber"
                        style={{ width: '100%', justifyContent: 'center', display: 'flex' }}
                        rules={[
                          {
                            required: true,
                            message: 'Số phòng ngủ là bắt buộc',
                          },
                        ]}
                      >
                        <Select>
                          {listApartment && listApartment.length > 0
                            ? listApartment.map((data: any, key: any) => (
                                <Select.Option value={data._id}>{data.name}</Select.Option>
                              ))
                            : ''}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                {valueTypeRealEstate &&
                  valueTypeRealEstate.parent &&
                  configurations[valueTypeRealEstate!.parent.note].map((e: any) => {
                    if (e.isNumber) return <InputColNumber name={e.key} rules={e.rules} label={e.name} />;
                    return <InputCol name={e.key} rules={e.rules} label={e.name} />;
                  })}
              </Row>
            </div>
            <InputTextNumber
              rules={[]}
              onChange={onPriceChanged}
              name="price"
              label={type === 'RENT' ? 'Giá cho thuê' : 'Giá bán'}
            />
            <InputTextNumber
              rules={[]}
              max={100}
              onChange={onReverseCommissionChanged}
              name="reverseCommission"
              label="Tổng hoa hồng %"
            />
            <InputTextNumber
              onChange={onCommissionChanged}
              rules={[]}
              disabled
              name="commissionFee"
              label="Tổng hoa hồng (VNĐ)"
            />
            <Form.Item
              labelAlign="left"
              className={styles.StylesCenter}
              label="Ảnh căn hộ"
              name="mediaIds"
              labelCol={{ md: 8, xl: 8, xs: 24 }}
              wrapperCol={{ md: 12, xs: 24 }}
            >
              <UploadButtonImages
                accept={'.gif, .jpg, .png, .webp'}
                onPreview={handlePreview}
                onChange={(fileList: any) => onChangeFiles(fileList)}
                onRemove={(item: any) => deleteImage(item)}
                // imageLength={1}
                multiple
                files={imageFile}
              />
            </Form.Item>

            {/* Video minh hoạ */}
            <Row>
              <Col span={20} offset={2}>
                <Form.Item
                  name="videoUrl"
                  label="Video minh hoạ"
                  labelAlign="left"
                  labelCol={{ md: 9 }}
                  wrapperCol={{ md: 15 }}
                >
                  <InputAddLinkVideo
                    files={fileListVideoIds}
                    onRemove={onRemoveItem}
                    onChange={onChangeInputFileVideo}
                  />
                </Form.Item>
              </Col>
            </Row>

            <InputTextArea name="description" label="Thông tin mô tả" />

            {detailSr && detailSr.createdBy && detailSr.createdBy.type === 'CUSTOMERS' && (
              <>
                <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                  <Row justify="space-between" align="middle" style={{ width: '84%' }}>
                    <Radio.Group onChange={onChangeChecked} value={valueChecked}>
                      {dataSetting.map((e: any) => {
                        return (
                          <Radio style={{ width: '400px' }} value={e.type}>
                            {e.name}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </Row>
                </div>
                {dataSetting && dataSetting.length > 0 && (
                  <>
                    {valueChecked === 'PAID_METHOD_1' && (
                      <Form.Item
                        name="days"
                        label="Số ngày đăng"
                        labelAlign="left"
                        style={{ justifyContent: 'center', display: 'flex' }}
                        labelCol={{ md: 8, xl: 8, xs: 24 }}
                        wrapperCol={{ md: 12, xs: 24 }}
                        rules={[
                          {
                            required: true,
                            message: 'Số ngày đăng không được để trống',
                          },
                          () => ({
                            validator(rule, value) {
                              if (typeof value !== 'undefined' && value != null) {
                                const day = dataSetting[0].showingDays;
                                if (value >= day) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  `Thời hạn đăng tin lớn hơn hoặc bằng ${day} ngày đăng trong cấu hình`,
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber size="large" step={1} min={0} style={{ width: '100%' }} />
                      </Form.Item>
                    )}
                    {valueChecked === 'PAID_METHOD_2' && (
                      <Form.Item
                        name="days"
                        label="Số ngày đăng"
                        labelAlign="left"
                        style={{ justifyContent: 'center', display: 'flex' }}
                        labelCol={{ md: 8, xl: 8, xs: 24 }}
                        wrapperCol={{ md: 12, xs: 24 }}
                        rules={[
                          {
                            required: true,
                            message: 'Số ngày đăng không được để trống',
                          },
                          () => ({
                            validator(rule, value) {
                              if (typeof value !== 'undefined' && value != null) {
                                const day = dataSetting[1].showingDays;
                                if (value >= day) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  `Thời hạn đăng tin lớn hơn hoặc bằng ${day} ngày đăng trong cấu hình`,
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber size="large" step={1} min={0} style={{ width: '100%' }} />
                      </Form.Item>
                    )}
                  </>
                )}
                {valueChecked === 'FREE_METHOD' && (
                  <Form.Item
                    label="Số ngày đăng"
                    labelAlign="left"
                    style={{ justifyContent: 'center', display: 'flex' }}
                    labelCol={{ md: 8, xl: 8, xs: 24 }}
                    wrapperCol={{ md: 12, xs: 24 }}
                  >
                    <InputNumber size="large" step={1} min={0} style={{ width: '100%' }} disabled />
                  </Form.Item>
                )}
              </>
            )}

            <div className={styles.StyleView2} />
            <Row justify="space-between" style={{ padding: '0px 40px 0px 40px', marginBottom: '10px' }}>
              <div style={{ fontSize: 25, textDecoration: 'underline', fontWeight: 'bold' }}>Thông tin người đăng</div>
              <div style={{ fontSize: 25, color: 'orange', fontWeight: 'bold' }}>
                {isPAGArticle ? 'Phúc An Gia' : getOwnerType(detailSr?.ownerType)}
              </div>
            </Row>

            {/* CTV Hỗ trợ */}
            {isPAGArticle ? (
              <Form.Item
                labelCol={{ md: 8, xl: 8, xs: 24 }}
                wrapperCol={{ md: 12, xs: 24 }}
                name="defaultSupporter"
                style={{ justifyContent: 'center', display: 'flex', marginTop: 20 }}
                label="CTV hỗ trợ"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn CTV hỗ trợ',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="CTV hỗ trợ"
                  maxTagTextLength={50}
                  optionFilterProp="children"
                  filterOption={(input: string, option: any | undefined) =>
                    option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {listCustomer &&
                    listCustomer.data &&
                    listCustomer.data.map((data: any) => {
                      return (
                        <Select.Option value={data._id} key={data._id}>
                          {data.name}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            ) : (
              ''
            )}

            {detailSr && detailSr.createdBy && detailSr.createdBy.type !== 'CMS' && (
              <InputText name="namePost" disabled label="Tên người đăng" />
            )}
            {detailSr && detailSr.createdBy && detailSr.createdBy.type !== 'CMS' && (
              <InputText name="phonePost" disabled label="Số điện thoại" />
            )}

            {/* Thông tin người đăng */}
            <SelectContactComponent ref={refSelectcontact} className={styles.SelectContact} />

            <div className={styles.StyleView2} />
            <Row justify="space-between" style={{ padding: '0px 40px 0px 40px', marginBottom: 20 }}>
              <div style={{ fontSize: 25, textDecoration: 'underline', fontWeight: 'bold' }}>Thông tin chủ nhà</div>
            </Row>
            <SelectHostInfo
              ref={refSelectHostInfo}
              validateCheck={validateCheck}
              onChangeHouse={onChangeHouse}
              onChangeCustomOwnerName={onChangeCustomOwnerName}
              onChangeCustomOwnerPhone={onChangeCustomOwnerPhone}
              onChangeCustomOwnerEmail={onChangeCustomOwnerEmail}
              onChangeCustomOwnerIdentify={onChangeCustomOwnerIdentify}
              dataHouse={detailSr?.owner || detailSr?.customOwner}
              isPAG={isPAGArticle}
            />

            {/* Giao dịch liên quan */}
            <div className={styles.StyleView2} />
            <Row
              justify="space-between"
              style={{ padding: '0px 40px 0px 40px', marginBottom: 20, textAlign: 'center' }}
            >
              <div
                style={{
                  fontSize: 25,
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                  width: ' 100%',
                  marginBottom: '20px',
                }}
              >
                Giao dịch liên quan
              </div>
              <Col span={24}>
                <Table dataSource={dataRelated || []} columns={columns} pagination={false} />
              </Col>
            </Row>

            <Row>
              <Col span={22}>
                <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Col>
                    <Form.Item>
                      <Button type="default" onClick={() => history.goBack()}>
                        {translate('action.close')}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button type="primary" style={{ backgroundColor: 'rgb(58, 200, 110)' }} htmlType={'submit'}>
                        {id ? translate('action.update') : translate('action.save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Modal title="" visible={isModalVisible} onCancel={onCancel} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
        </>
      ) : (
        'Permission Denied'
      )}
    </Container>
  );
};

export default CreateElstate;
