/* eslint-disable consistent-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { Row, Form, Col, Input, Button, Select } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import { StatusType } from 'constants/Status';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useNotificationService from './notification.service';

function NotificationDetailScreen(): JSX.Element {
  const param: { id: string } = useParams();
  const isEdit = param && param.id;

  const [form] = Form.useForm();
  const history = useHistory();
  const [checked, setChecked] = useState<boolean>(true);

  const {
    onCreateNotification,
    onDetailNotification,
    detailNotification,
    onUpdateNotification,
  } = useNotificationService();

  // Danh sách dự án
  const { listProject, onListProject } = useLitsHouseTemplate();
  const [checkProject, setCheckProject] = useState<boolean | undefined>(true);

  // Trường hợp sửa thông báo
  useEffect(() => {
    if (isEdit) {
      onDetailNotification(param.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (detailNotification) {
      form.setFieldsValue({
        title: detailNotification.title,
        content: detailNotification.body,
        status: detailNotification.status,
        receiver: detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).allUser,
        projectId: detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).projectId,
        customerType:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).customerType,
        popup:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).popup === 'SHOW'
            ? true
            : false,
        checked_project:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).projectId
            ? true
            : undefined,
        checked_customer:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).customerType
            ? true
            : undefined,
        customer:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).customer
            ? true
            : undefined,
        supporter:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).supporter
            ? true
            : undefined,
        collaborator:
          detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).collaborator
            ? true
            : undefined,
      });
      if (detailNotification.payload.dataBody) {
        setChecked(JSON.parse(detailNotification.payload.dataBody).allUser);
      }
      setCheckProject(
        detailNotification.payload.dataBody && JSON.parse(detailNotification.payload.dataBody).projectId
          ? undefined
          : true,
      );
      setContent(detailNotification.body);
    }
  }, [detailNotification]);

  useEffect(() => {
    onListProject({ status: STATUS_TYPE.ACTIVE });
  }, []);

  // Nội dung thông báo
  const [content, setContent] = useState<string>('');

  const handleChangeContent = (event: any, editor: any) => {
    form.setFieldsValue({
      content: editor.getData(),
    });
  };

  const onFinish = (value: any) => {
    if (checked) {
      const data = {
        title: value.title,
        body: value.content,
        status: value.status,
        popup: value.popup ? 'SHOW' : 'HIDE',
        allUser: checked,
      };
      if (isEdit && detailNotification) {
        return onUpdateNotification(param.id, data);
      }
      onCreateNotification(data);
    }

    if (!checked) {
      const data = {
        title: value.title,
        body: value.content,
        status: value.status,
        popup: value.popup ? 'SHOW' : 'HIDE',
        allUser: checked,
        customerType: value.checked_customer ? value.customerType : undefined,
        projectId: value.checked_project ? value.projectId : undefined,
        customer: bolProject && value.customer ? value.customer : undefined,
        supporter: bolProject && value.supporter ? value.supporter : undefined,
        collaborator: bolProject && value.collaborator ? value.collaborator : undefined,
      };
      if (isEdit && detailNotification) {
        return onUpdateNotification(param.id, data);
      }
      onCreateNotification(data);
    }
  };

  const onChangeReceiver = (e: any) => {
    setChecked(e.target.checked);
  };

  const [bolProject, setBolProject] = useState<boolean>(false);
  const onChangeProject = (data: any) => {
    setCheckProject(!data.target.checked);
    setBolProject(data.target.checked);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).create ||
      getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).edit ? (
        <Form form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={20} offset={3}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="title"
                    label="Tiêu đề"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Tiêu đề không được để trống',
                      },
                      {
                        whitespace: true,
                        min: 2,
                        max: 200,
                        message: 'Tiêu đề bao gồm 2- 200 ký tự',
                      },
                    ]}
                  >
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>

                {/* Nội dung */}
                <Col span={20}>
                  <Row>
                    <Col span={7}>
                      <div>
                        <span style={{ color: 'red' }}>*</span> Nội dung
                      </div>
                    </Col>
                    <Col span={17}>
                      <Form.Item
                        name="content"
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: 'Nội dung không được để trống',
                          },
                        ]}
                      >
                        <CKEEditor onChange={handleChangeContent} data={content} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                {/* Trạng thái */}
                <Col span={20}>
                  <Form.Item
                    name="status"
                    label="Trạng thái"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Trạng thái không được để trống',
                      },
                    ]}
                  >
                    <Select placeholder="Trạng thái">
                      <Select.Option value={StatusType.ACTIVE}>Hoạt động</Select.Option>
                      <Select.Option value={StatusType.INACTIVE}>Không hoạt động</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                {/* Người nhận */}
                <Col span={20}>
                  <Form.Item name="receiver" label="Người nhận" labelCol={{ span: 7 }} labelAlign="left">
                    <Checkbox checked={checked} onChange={onChangeReceiver}>
                      Tất cả
                    </Checkbox>
                  </Form.Item>
                </Col>

                {!checked && (
                  <>
                    {/* Loại  tài khoản */}
                    <Col span={20}>
                      <Row>
                        <Col span={7}>
                          <Form.Item
                            name="checked_customer"
                            label=""
                            labelCol={{ span: 7 }}
                            labelAlign="left"
                            valuePropName="checked"
                          >
                            <Checkbox>Loại tài khoản</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={17}>
                          <Form.Item
                            name="customerType"
                            label=""
                            labelCol={{ span: 7 }}
                            labelAlign="left"
                            initialValue="ALL"
                          >
                            <Select placeholder="Loại tài khoản">
                              <Select.Option value="ALL">Tất cả</Select.Option>
                              <Select.Option value="PRIVATE">Cá nhân</Select.Option>
                              <Select.Option value="COMPANY">Doanh nghiệp</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    {/* Dự án */}
                    <Col span={20}>
                      <Row>
                        <Col span={7}>
                          <Form.Item
                            name="checked_project"
                            label=""
                            labelCol={{ span: 7 }}
                            labelAlign="left"
                            valuePropName="checked"
                          >
                            <Checkbox onChange={onChangeProject}>Dự án</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col span={17}>
                          <Form.Item
                            name="projectId"
                            label=""
                            labelCol={{ span: 7 }}
                            labelAlign="left"
                            initialValue="ALL"
                          >
                            <Select placeholder="Dự án">
                              <Select.Option value="ALL">Tất cả</Select.Option>
                              {listProject &&
                                listProject.length > 0 &&
                                listProject.map(data => {
                                  return (
                                    <Select.Option value={data._id}>
                                      {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                                    </Select.Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    {/* CTV giới thiệu */}
                    <Col span={20}>
                      <Form.Item
                        name="collaborator"
                        valuePropName="checked"
                        wrapperCol={{
                          span: 16,
                          offset: 7,
                        }}
                      >
                        <Checkbox disabled={checkProject}>CTV giới thiệu</Checkbox>
                      </Form.Item>
                    </Col>

                    {/* CTV hỗ trợ */}
                    <Col span={20}>
                      <Form.Item
                        name="supporter"
                        valuePropName="checked"
                        wrapperCol={{
                          span: 16,
                          offset: 7,
                        }}
                      >
                        <Checkbox disabled={checkProject}>CTV hỗ trợ</Checkbox>
                      </Form.Item>
                    </Col>

                    {/* Khách hàng */}
                    <Col span={20}>
                      <Form.Item
                        name="customer"
                        valuePropName="checked"
                        wrapperCol={{
                          span: 16,
                          offset: 7,
                        }}
                      >
                        <Checkbox disabled={checkProject}>Khách hàng</Checkbox>
                      </Form.Item>
                    </Col>
                  </>
                )}

                {/* Có hiển thị Pop up */}
                <Col span={20}>
                  <Form.Item name="popup" label="" labelCol={{ span: 7 }} labelAlign="left" valuePropName="checked">
                    <Checkbox>Có hiển thị Pop up</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={() => history.goBack()}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default NotificationDetailScreen;
