import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { InforAdminResponse } from 'types/response/AdminResponseRes';

function useDetailProperty(): [(request: string) => Promise<void>, any | undefined] {
    const [detailProperty, getProperty] = useState<any | undefined>();

    const useGetDetailProperty = async (request: string) => {
        try {
            const res = await ApiHelper.fetch<string, InforAdminResponse>(
                BASE_URL.DETAIL_PROPERTY + `${request}`
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                getProperty(res.data)
            }
        } catch (error) {
            getProperty(undefined)
        }
    }
    return [useGetDetailProperty, detailProperty];
}

export default useDetailProperty;