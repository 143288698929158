/* eslint-disable consistent-return */
import { ROUTER_PATH } from 'routes/Path';
import { useHistory } from 'react-router-dom';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import { ListApartmentReq } from 'hooks/Apartment/useListApartment';
import { ListProjectReq } from 'hooks/Project/useLitsProject';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { translate } from 'translate/language';
import { HouseTemplateReq } from '../types/house-template.types';

export interface ListProject {
  _id: string;
  name: string;
}

export interface Reqdirection {
  limit: number;
  page: number;
}

function useLitsHouseTemplate() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [listHouseTemplate, setListHouseTemplate] = useState<any[]>([]);
  const [listProject, setListProject] = useState<ListProject[]>([]);
  const [ListParentChildRealeState, getListParentChildRealeState] = useState([]);
  const [ListDirection, setListDirection] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [listApartment, setListApartment] = useState([]);
  const [stopLoadmore, setStoploadmore] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [dataDetailHouse, setDataDetailHouse] = useState<any>(undefined);
  // danh sách căn hộ mẫu
  const onHouseTemplate = async (request: any) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<any, any>(BASE_URL.LIST_HOUSE_TEMPLATE, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setListHouseTemplate(res.data);
        setTotal(res.total);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // loại danh sách dự án
  const onListProject = async (request: ListProjectReq) => {
    try {
      const res = await ApiHelper.fetch<ListProjectReq, any>(BASE_URL.LIST_PROJECT, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        const newData = request.page! > 1 ? [...listProject, ...res.data] : res.data;
        setListProject(newData);
        if (request.page! > 1 && res.data.length === 0) {
          setStoploadmore(true);
          setTimeout(() => {
            setStoploadmore(false);
          }, 1000);
        }
      } else {
      }
    } catch (error) {}
  };
  // loại bất động sản

  const onFetchListParentChildRealeState = async () => {
    try {
      const res = await ApiHelper.fetch(BASE_URL.LIST_PARENT_CHILD);
      if (res && res.status === StatusResponse.SUCCESS) {
        getListParentChildRealeState(res.data);
      }
    } catch (error) {
      getListParentChildRealeState([]);
    }
  };

  // lấy danh sách hướng nhà
  const onFetchListDirectionRealeState = async (params: Reqdirection) => {
    try {
      const res = await ApiHelper.fetch(BASE_URL.LIST_DIRECTION, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        if (params.page > 1 && res.data.length === 0) {
          setStoploadmore(true);
          setTimeout(() => {
            setStoploadmore(false);
          }, 1000);
        }
        const newData = params.page > 1 ? [...ListDirection, ...res.data] : res.data;
        setListDirection(newData);
      }
    } catch (error) {
      setListDirection([]);
    }
  };
  // danh sách căn hộ mẫu

  const onFetchListApartment = async (request: ListApartmentReq) => {
    try {
      const res = await ApiHelper.fetch(BASE_URL.LIST_APARTMENT, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        if (request.page! > 1 && res.data.length === 0) {
          setStoploadmore(true);
          setTimeout(() => {
            setStoploadmore(false);
          }, 1000);
        }
        const newData = request.page! > 1 ? [...listApartment, ...res.data] : res.data;
        setListApartment(newData);
      }
    } catch (error) {}
  };

  // Tạo mới căn hộ mẫu
  const onCreateHouseTemplate = async (data: HouseTemplateReq) => {
    try {
      const res = await ApiHelper.post<HouseTemplateReq, any>(BASE_URL.HOUSE_TEMPLATE, data);
      if (res && res.status === StatusResponse.HOUSETEMPLATE_ALREADY_EXISTS) {
        return ToastSuccess({
          message: translate('message_type.failed'),
          description: 'Tên căn hộ đã tồn tại',
        });
      }
      if (res.data && res.status === StatusResponse.SUCCESS) {
        history.push(ROUTER_PATH.PROJECTS_HOUSETEMPLATE);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.add_new_failed'),
        });
      }
    } catch (error) {}
  };

  // Xóa căn hộ mẫu
  const onDeleteHouseTemplate = async (id: string) => {
    try {
      setLoadingDelete(true);
      const res = await ApiHelper.delete<string, any>(BASE_URL.DELETE_HOUSE_TEMPLATE + id);
      if (res && res.status === StatusResponse.NOT_AVAILABLE_TO_DELETE) {
        setLoadingDelete(false);
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('house_template.NOT_AVAILABLE_TO_DELETE'),
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoadingDelete(false);
      } else {
        setLoadingDelete(false);
      }
    } catch (error) {
      setLoadingDelete(false);
    }
  };

  // Chi tiết căn hộ mẫu
  const onDetailHouseTemplate = async (id: string) => {
    try {
      const res = await ApiHelper.fetch<string, any>(BASE_URL.DETAIL_HOUSE_TEMPLATE + id);
      if (res.data && res.status === StatusResponse.SUCCESS) {
        setDataDetailHouse(res.data);
      } else {
        setDataDetailHouse(undefined);
      }
    } catch (error) {
      setDataDetailHouse(undefined);
    }
  };

  // Cập nhật căn hộ mẫu
  const onUpdateHouseTemplate = async (id: string, data: HouseTemplateReq) => {
    const res = await ApiHelper.put<HouseTemplateReq, any>(BASE_URL.UPDATE_HOUSE_TEMPLATE + id, data);
    if (res && res.status === StatusResponse.HOUSETEMPLATE_ALREADY_EXISTS) {
      return ToastSuccess({
        message: translate('message_type.failed'),
        description: 'Tên căn hộ đã tồn tại',
      });
    }
    if (res.data && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.update_success'),
      });
      history.push(ROUTER_PATH.PROJECTS_HOUSETEMPLATE);
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: res.message || translate('messages.update_failed'),
      });
    }
  };

  return {
    onHouseTemplate,
    listHouseTemplate,
    total,
    loading,
    listProject,
    onListProject,
    ListParentChildRealeState,
    onFetchListParentChildRealeState,
    ListDirection,
    stopLoadmore,
    onFetchListDirectionRealeState,
    onFetchListApartment,
    listApartment,
    onCreateHouseTemplate,
    onDeleteHouseTemplate,
    loadingDelete,
    onDetailHouseTemplate,
    dataDetailHouse,
    onUpdateHouseTemplate,
  };
}

export default useLitsHouseTemplate;
