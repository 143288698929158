/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Row, Table, Typography, Divider, Tag } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHistoryComponent from './components/filter-history.component';
import { FilterHistory, UserInfoHistoryCommission } from './history-types';
import useHistoryService from './history.service';

const { Text, Link } = Typography;

const right: any = 'right';

function HistoryScreen(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterHistory>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    minDate: undefined,
    maxDate: undefined,
  });

  const { onGetHistory, dataHistory, totalHistory, loading } = useHistoryService();

  useEffect(() => {
    onGetHistory(filter);
  }, [filter]);

  //   Chi tiết lịch sử hoa hồng
  const detailHistory = (id: string) => {
    history.push(ROUTER_PATH.DETAIL_COMMISSION_HISTORY + id);
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tên người nhận',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoHistoryCommission) => {
        return (
          <>
            {userInfo ? (
              [
                <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo?._id} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {userInfo?.name || 'Phúc An Gia'}
                  </Text>
                </Link>,
                <Tag color="#108ee9">{userInfo?.phone}</Tag>,
              ]
            ) : (
              <Text>Phúc An Gia</Text>
            )}
          </>
        );
      },
    },
    {
      title: 'Thời gian nhận gần nhất',
      dataIndex: 'receivedDate',
      render: (receivedDate: moment.Moment) => {
        return <Tag color="purple">{moment(receivedDate).format('HH:mm DD/MM/YYYY')}</Tag>;
      },
    },
    {
      title: 'Hoa hồng hệ thống',
      dataIndex: 'system',
      render: (system: number) => {
        return <span>{formatNumber(system)}</span>;
      },
    },
    {
      title: 'Hoa hồng KH',
      dataIndex: 'projectCustomer',
      render: (projectCustomer: number) => {
        return <span>{formatNumber(projectCustomer)}</span>;
      },
    },
    {
      title: 'Hoa hồng CTV giới thiệu',
      dataIndex: 'projectIntroducer',
      render: (projectIntroducer: number) => {
        return <span>{formatNumber(projectIntroducer)}</span>;
      },
    },
    {
      title: 'Hoa hồng CTV hỗ trợ',
      dataIndex: 'projectSupporter',
      render: (projectSupporter: number) => {
        return <span>{formatNumber(projectSupporter)}</span>;
      },
    },
    {
      title: 'Hoa hồng BĐS lẻ',
      dataIndex: 'retailRealEstate',
      render: (retailRealEstate: number) => {
        return <span>{formatNumber(retailRealEstate)}</span>;
      },
    },
    {
      title: 'Tiền thưởng nhận từ CMS',
      dataIndex: 'cms',
      render: (cms: number) => {
        return <span>{formatNumber(cms)}</span>;
      },
    },
    {
      title: 'Đã rút',
      dataIndex: 'payment',
      render: (payment: number) => {
        return <span>{formatNumber(payment)}</span>;
      },
    },
    {
      title: 'Tổng hoa hồng nhận',
      dataIndex: 'totalCommissionReceived',
      render: (totalCommissionReceived: number) => {
        return <span>{formatNumber(totalCommissionReceived)}</span>;
      },
    },
    {
      title: 'Số dư',
      dataIndex: 'commissionAvailable',
      render: (commissionAvailable: number) => {
        return <span>{formatNumber(commissionAvailable)}</span>;
      },
    },
    {
      title: 'Tùy chọn',
      dataIndex: 'userId',
      fixed: right,
      render: (id: string) => {
        return (
          getCheckPermissionUser(PERMISSION_NAME.COMMISSION_HISTORY).edit && (
            <div onClick={() => detailHistory(id)}>
              <Text underline style={{ color: '#12a0c0', cursor: 'pointer' }}>
                Chi tiết
              </Text>
            </div>
          )
        );
      },
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm
  const { run } = useDebounceFn(
    (data: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search: data });
    },
    {
      wait: 500,
    },
  );

  const onDateRanger = (dateRanger: [string, string]) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, minDate: dateRanger[0] || undefined, maxDate: dateRanger[1] || undefined });
  };

  return (
    <>
      <FilterHistoryComponent onSearch={(data: string) => run(data)} onDateRanger={onDateRanger} />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataHistory || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalHistory}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default HistoryScreen;
