/* eslint-disable react-native/no-inline-styles */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import { ToastError } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useAddProperty from 'hooks/PropertyType/useAddProperty';
import useDetailProperty from 'hooks/PropertyType/useDetailProperty';
import useListParents from 'hooks/PropertyType/useListParents';
import useUpdateProperty from 'hooks/PropertyType/useUpdateProperty';
import useUpload from 'hooks/Upload/useUpload';
import images from 'images';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';

const { Option } = Select;

function FormPropertyPage(): JSX.Element {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_PROPERTY);
  const [ImageFile, setImageFile] = useState<UploadFile[] | any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [showImage, setShowImage] = useState('');

  // Lấy danh sách cấp cha
  const { onListParent, listParent } = useListParents();
  // Thêm mới dữ liệu
  const { onAddProperty, loading } = useAddProperty();
  // Lấy chi tiết loại bất động sản
  const [useGetDetailProperty, detailProperty] = useDetailProperty();
  // Cập nhật dữ liệu
  const [onUpdateProperty] = useUpdateProperty();
  // Upload hình ảnh
  const [onUpload, resUpload, messageError] = useUpload();
  const flag = useRef('');

  useEffect(() => {
    onListParent();
  }, []);
  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      useGetDetailProperty(params.id);
    } else {
      form.setFieldsValue({
        level_parent: cacheForm.level_parent,
        status: cacheForm.status,
        type_level: cacheForm.type_level,
      });
    }
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailProperty) {
      form.setFieldsValue({
        level_parent: detailProperty?.parentId._id,
        status: detailProperty?.status,
        type_level: detailProperty?.name,
      });
    }

    // Hình ảnh
    if (detailProperty?.mediaId) {
      setImageFile([
        {
          uid: detailProperty?._id,
          name: 'image.png',
          status: 'done',
          url: getUrlImage(detailProperty?.mediaId),
        },
      ]);
      form.setFieldsValue({ logo: detailProperty?.mediaId?._id });
    }
  }, [detailProperty]);

  // Upload hình ảnh ngân hàng
  useEffect(() => {
    if (flag.current === 'uploadLogo') {
      if (ImageFile && ImageFile.length > 0) {
        const data = new FormData();
        data.append('file', ImageFile[0].originFileObj);
        flag.current = 'logo';
        onUpload(data);
      } else {
        form.setFieldsValue({ logo: null });
      }
    }
  }, [ImageFile]);

  useEffect(() => {
    if (flag.current === 'logo') {
      let imageLogo: string = '';
      if (resUpload) {
        imageLogo = resUpload?._id;
      }
      if (imageLogo !== '') {
        form.setFieldsValue({ logo: imageLogo });
      }
    }
  }, [resUpload]);

  const handleSubmit = () => {
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: StatusType.ACTIVE });
    if (!Array.isArray(ImageFile) || !ImageFile.length) {
      form.setFieldsValue({ logo: null });
    }
    form.validateFields().then(values => {
      const data = {
        name: values.type_level,
        mediaId: values.logo,
        status: values.status,
        parentId: values.level_parent,
      };
      if (isEdit) {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_PROPERTY);
        onUpdateProperty(params.id, data);
        return;
      } else {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_PROPERTY);
        onAddProperty(data);
        return;
      }
    });
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_PROPERTY, form.getFieldsValue());
  };

  // Validate dữ liệu
  const validateForm = {
    level_parent: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('property.level_parent'),
        }),
      },
    ],
    type_level: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('property.type_level'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('property.type_level_validate'),
      },
    ],
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_ADMIN);
    history.back();
  };

  const handlePreview = async (file: any) => {
    const src = file.thumbUrl ? file.thumbUrl : file.url;
    setShowImage(src);
    setVisible(true);
  };

  const handleCancelImage = () => {
    setVisible(false);
  };

  const onRemoveImage = () => {
    setImageFile([]);
  };

  const handleChange = ({ fileList }: any) => {
    if (fileList.length > 0) {
      const isJpgOrPng =
        fileList[0].type === 'image/jpeg' || fileList[0].type === 'image/png' || fileList[0].type === 'image/jpg';
      if (!isJpgOrPng) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('upload_file_validate'),
        });
        return;
      }
      const isLt2M = fileList[0].size / 1024 / 1024 < 10;
      if (!isLt2M) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('upload_max_file_10MB'),
        });
        return;
      }
      fileList[0].status = 'done';
      flag.current = 'uploadLogo';
      setImageFile(fileList);
    }
  };

  const uploadButton = () => {
    return (
      <div>
        <img width="100%" height="100%" src={images.ic_image} alt="" />{' '}
      </div>
    );
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).create ||
      getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).edit ? (
        <>
          <Modal title={translate('detail_image')} visible={visible} onCancel={handleCancelImage} footer={null}>
            <div style={{ textAlign: 'center' }}>
              <img alt="" src={showImage} width="250px" />
            </div>
          </Modal>
          <Form onFieldsChange={updateCacheForm} form={form}>
            <Row>
              <Col span={20} offset={3}>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="status"
                      label={translate('status.name')}
                      labelCol={{ sm: 7 }}
                      labelAlign="left"
                      initialValue={StatusType.ACTIVE}
                    >
                      <Select
                        placeholder={translate('admin.status_active')}
                        // defaultValue={StatusType.ACTIVE}
                      >
                        <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                        <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="level_parent"
                      label={translate('property.level_parent')}
                      labelCol={{ sm: 7 }}
                      labelAlign="left"
                      rules={validateForm.level_parent}
                    >
                      <Select placeholder={translate('property.level_parent')}>
                        {listParent
                          ? listParent.map((data: any, index: number) => {
                              return (
                                <Option value={data._id} key={data._id}>
                                  {data.name}
                                </Option>
                              );
                            })
                          : ''}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="type_level"
                        label={translate('property.type_level')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={validateForm.type_level}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="logo"
                        label={translate('property.logo')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('property.logo'),
                            }),
                          },
                        ]}
                      >
                        <Upload
                          listType="picture-card"
                          fileList={ImageFile}
                          accept="image/*"
                          onPreview={handlePreview}
                          onRemove={onRemoveImage}
                          onChange={handleChange}
                        >
                          {ImageFile.length >= 1 ? null : uploadButton()}
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={closeButton}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Button type="primary" onClick={handleSubmit} htmlType="submit">
                            {isEdit ? translate('action.update') : translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormPropertyPage;
