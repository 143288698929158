/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError } from 'components/Toast';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import socketIOClient from 'network/SocketIo';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetLogin } from 'redux/actions';
import events from './Event';

function useSocketIO() {
  const dispatch = useDispatch();

  useEffect(() => {
    socketIOClient.on('connect', () => {
      console.log('Socket is connected');
    });

    socketIOClient.on(events.PERMISSION_CHANGED, (data: any) => {
      console.log('🚀 ~ file: useSocketIO.ts:20 ~ socketIOClient.on ~ data:', data);
      if (data === 'PERMISSION_UPDATED') {
        dispatch(resetLogin());
        LocalStorageHelper.remove(StorageKey.TOKEN);
        LocalStorageHelper.remove(StorageKey.USER_SESSION);
        ToastError({
          message: 'Thông Báo',
          description: 'Bạn có quyền mới! Vui lòng đăng nhập lại',
        });
      }
    });

    return () => {
      console.log('disconnect');
      socketIOClient.disconnect();
    };
  }, []);
}

export default useSocketIO;
