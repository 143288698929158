/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Image, Input, message, Row, Select, Switch } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import InputAddLinkVideo from 'pages/ProjectPage/InputAddLinkVideo';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import useNewService from './news.service';

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

function NewsDetailScreen(): JSX.Element {
  const history = useHistory();
  const [form] = Form.useForm();
  const [onUpload, resUpload] = useUploadMulti();
  const [fileListVideoIds, setfileListVideoIds] = useState<UploadFile<any>[] | any>([]);
  // Chỉnh sửa
  const params: { id: string } = useParams();
  const isEdit = params && params.id;

  // Hiển thị hình ảnh + url hình ảnh
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const flag = useRef('');

  const { onCreateNew, onDetailNew, detailNew, onUpdateNew } = useNewService();
  // Hình ảnh đại diện
  const [imgContent, setImgContent] = useState<Media[]>([]);

  // Khi chỉnh sửa
  useEffect(() => {
    if (isEdit) {
      onDetailNew(params.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && detailNew) {
      form.setFieldsValue({
        title: detailNew.title,
        status: detailNew.status,
        content: detailNew.content,
      });

      if (detailNew.content) {
        setContent(detailNew.content);
      }

      if (detailNew.youtubeLinks) {
        setfileListVideoIds(detailNew.youtubeLinks);
      }

      if (detailNew.thumbnail) {
        setImgContent([detailNew.thumbnail]);
      }
    }
  }, [detailNew]);

  // Hình ảnh
  const onChangeFiles = (fileList: any, type: string) => {
    let image: Media[] = [];

    if (type === 'img_content') {
      image = imgContent;
    }

    const newData: any = [...image, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }

    if (type === 'img_content') {
      setImgContent(newData);
      flag.current = 'img_content';
    }
    onUpload(data);
  };

  // Xóa hình ảnh
  const onRemoveFiles = (item: Media | any, type: string) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => {
        if (type === 'img_content') {
          const newArrImg = imgContent.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImgContent(newArrImg);
        }
        message.success('Xoá ảnh thành công');
      },
    });
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      if (flag.current === 'img_content') {
        const newfileList = [...imgContent].filter(e => e._id);
        setImgContent([...newfileList, ...resUpload]);
      }
    }
  }, [resUpload]);

  // Xem chi tiết hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal hiển thị
  const onCancelImage = () => {
    setModalVisible(false);
  };

  const onRemoveItem = (item: any) => {
    confirm({
      title: translate('delete_video'),
      onOk: () => {
        const newFileVideo = [...fileListVideoIds].filter(e => e !== item);
        setfileListVideoIds(newFileVideo);
      },
      onCancel: () => {},
    });
  };

  const onChangeInputFileVideo = (value: any) => {
    const newData = [value, ...fileListVideoIds];
    setfileListVideoIds(newData);
  };

  const [content, setContent] = useState<string>('');

  const handleChangeContent = (event: any, editor: any) => {
    form.setFieldsValue({
      content: editor.getData(),
    });
  };

  // Button Lưu
  const onFinish = (value: any) => {
    const data = {
      title: value.title,
      content: value.content || '',
      thumbnailId: imgContent && imgContent.length > 0 ? imgContent[0]._id : undefined,
      status: value.status,
      youtubeLinks: fileListVideoIds,
    };
    if (isEdit && detailNew) {
      const dataUpdate = { ...data, _id: params.id };
      return onUpdateNew(dataUpdate);
    }
    onCreateNew(data);
  };

  const onFinishFailed = (value: any) => {
    console.log(121212, value);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NEWS).create ||
      getCheckPermissionUser(PERMISSION_NAME.CONTENT_NEWS).edit ? (
        <>
          <Modal title="" visible={isModalVisible} onCancel={onCancelImage} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
          <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Row gutter={24}>
              <Col span={20} offset={3}>
                <Row>
                  {/* Ảnh chia sẻ */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>Ảnh</Col>
                      <Col span={17}>
                        <UploadButtonImages
                          accept={'.gif, .jpg, .png, .webp'}
                          files={imgContent}
                          onPreview={handlePreview}
                          onChange={(fileList: any) => onChangeFiles(fileList, 'img_content')}
                          onRemove={(item: any) => onRemoveFiles(item, 'img_content')}
                          imageLength={1}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={20}>
                    <Form.Item
                      name="title"
                      label="Tiêu đề"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: 'Tiêu đề không được để trống',
                        },
                        {
                          whitespace: true,
                          min: 2,
                          max: 200,
                          message: 'Tiêu đề bao gồm 2- 200 ký tự',
                        },
                      ]}
                    >
                      <Input.TextArea rows={5} />
                    </Form.Item>
                  </Col>

                  {/* Link youtube */}
                  <Col span={20}>
                    <Form.Item name="youtubeLinks" label="Link youtube" labelCol={{ span: 7 }} labelAlign="left">
                      <InputAddLinkVideo
                        files={fileListVideoIds}
                        onRemove={onRemoveItem}
                        onChange={onChangeInputFileVideo}
                        lengthVideo={3}
                      />
                    </Form.Item>
                  </Col>

                  {/* Nội dung */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>
                        <div>
                          <span style={{ color: 'red' }}>*</span> Nội dung
                        </div>
                      </Col>
                      <Col span={17}>
                        <Form.Item
                          name="content"
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                              message: 'Nội dung không được để trống',
                            },
                          ]}
                        >
                          <CKEEditor onChange={handleChangeContent} data={content} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  {/* Trạng thái */}
                  <Col span={20}>
                    <Form.Item
                      name="status"
                      label="Trạng thái"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: 'Trạng thái không được để trống',
                        },
                      ]}
                    >
                      <Select placeholder="Trạng thái">
                        <Select.Option value={StatusType.ACTIVE}>Hoạt động</Select.Option>
                        <Select.Option value={StatusType.INACTIVE}>Không hoạt động</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={() => history.goBack()}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            {translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default NewsDetailScreen;
