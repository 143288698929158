import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

export interface ReqPoint {
    "USER_FIRST_UPDATED_POINT"?: number,
    "VIEW_SELLER_INFO_POINT"?: number,
    "NEW_CHILD_POINT"?: number
}

function useUpdatePoint() {
    const [loadingUpdate, setLoading] = useState(false)
    const history = useHistory();

    const onUpdatePoint = async (request: ReqPoint) => {
        try {
            setLoading(true)
            const res = await ApiHelper.put<ReqPoint, any>(
                BASE_URL.DETAIL_POINT,
                request
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false)
                ToastSuccess({
                    message: translate("message_type.success"),
                    description: translate("messages.update_success"),
                });
                history.push(ROUTER_PATH.INTERACTION)
            } else {
                setLoading(false)
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("number_validate"),
                });
                history.push(ROUTER_PATH.INTERACTION)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    return { onUpdatePoint, loadingUpdate }
}

export default useUpdatePoint;