/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { getUrlImageChat } from 'helpers/getImageUrl';
import React, { useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import styles from './item.module.scss';

const { Text, Link } = Typography;

function CustomerItem(props: any) {
  const { item, detailTransaction } = props;
  const [view, setView] = useState(detailTransaction.areView ? !detailTransaction.areView.includes(item._id) : null);
  const onClickView = () => {
    setView(detailTransaction.areView.includes(item._id));
    props.onUpdateViewMessage(item._id);
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputChat}>
        <img
          className={styles.imageAvatar}
          src={
            item.avatarId
              ? getUrlImageChat(item.avatarId)
              : `${process.env.REACT_APP_API_DOMAIN}/statics/images/default-avatar.jpg`
          }
        />
      </div>
      <div>
        <Link href={`${ROUTER_PATH.EDIT_CUSTOMER}${item._id}`} target="_blank" className="fontSize18">
          <Text underline style={{ color: '#12a0c0' }}>
            {item.name}
          </Text>
        </Link>
        <div style={{ color: `${item.color}` }}>{item.role.toString()}</div>
      </div>
      <div style={{ marginLeft: 10 }} className="view">
        {view ? (
          <span className="view" onClick={onClickView}>
            <EyeOutlined />
          </span>
        ) : (
          <span className="view" onClick={onClickView}>
            <EyeInvisibleOutlined />
          </span>
        )}
      </div>
    </div>
  );
}

export default CustomerItem;
