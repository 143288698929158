import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

function useIdentifyCheck() {
    const [loadingIdentify, setLoading] = useState(false);
    const [listIdentify, setListIdentify] = useState<[] | undefined>();

    const onGetIdentifyCode = async () => {
        try {
            setLoading(true);
            const res = await ApiHelper.fetch(BASE_URL.IDENTIFY_CODES);
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false);
                setListIdentify(res?.data)
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return { onGetIdentifyCode, listIdentify, loadingIdentify }
}

export default useIdentifyCheck;