/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { translate } from 'translate/language';

function useUploadMulti(): [(request: FormData, url?: string) => Promise<void>, any, string] {
  const [resUpload, setResUpload] = useState<any>();
  const [messageError, setMessageError] = useState<string>('');

  const onUpload = async (request: FormData, url?: string) => {
    try {
      const res = await ApiHelper.postFormData<any>(url || BASE_URL.UPLOAD_MULTI_FILE_10MB, request, {
        'Content-Type': 'multipart/form-data',
      });
      if (res.status === StatusResponse.SUCCESS) {
        setResUpload(res?.data);
      } else {
        setMessageError(`${translate('upload_fail')}`);
        setResUpload(null);
      }
    } catch (error) {
      setMessageError(`${translate('upload_fail')}`);
      setResUpload(null);
    }
  };
  return [onUpload, resUpload, messageError];
}

export default useUploadMulti;
