/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';

export interface ActiveRes {
  _id: string;
  content: string;
  createdAt: string;
}

export interface Params {
  page: number | string;
  limit: number | string;
}

const URL_ACTIVE = '/activities';

function useActiveService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataActive, setDataActive] = useState<ActiveRes[]>([]);
  const [totalActive, setTotalActive] = useState<number>();

  // Danh sách người hoạt động
  const onGetActive = async (param: Params) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL_ACTIVE, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataActive(res.data);
        setTotalActive(res.total);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setDataActive([]);
      setLoading(false);
    }
  };

  return { onGetActive, dataActive, totalActive, loading };
}

export default useActiveService;
