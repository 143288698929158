/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

interface Props {
  onSearch: (search: string) => void;
}
function FilterHeaderPermission(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch } = props;
  const history = useHistory();
  // Tìm kiếm theo nhóm phân quyền

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  // Action Thêm mới
  const addNewPermission = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PERMISSION);
    history.push(ROUTER_PATH.ADD_PERMISSION);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={20}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder={translate('permission.name_permission')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={17}>
            <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Col span={4}>
                {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_PERMISSIONS).create ? (
                  <div>
                    <Button style={{ width: '100%' }} type="primary" onClick={addNewPermission}>
                      {translate('action.add_new')}
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterHeaderPermission;
