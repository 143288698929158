import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onLOGIN } from 'redux/actions';
import { translate } from 'translate/language';
import { LoginUserRequest } from 'types/requests/UserRequest';
import { User } from 'types/response/UserResponse';

function useLogin(): [(requests: LoginUserRequest) => Promise<void>, boolean, User | {}] {
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User | {}>({});
  const dispatch = useDispatch();
  const history = useHistory();

  const loginCMS = async (values: LoginUserRequest) => {
    try {
      setLoading(true);
      const body: LoginUserRequest = {
        phone: values.phone,
        password: values.password,
      };
      const res = await ApiHelper.post<LoginUserRequest, any>(BASE_URL.LOGIN, body, null);
      if (res.status == StatusResponse.USER_DO_NOT_EXISTS) {
        ToastError({
          message: translate('login.error'),
          description: translate('login.USER_DO_NOT_EXISTS'),
        });
        setLoading(false);
        return;
      }
      if (res.status == StatusResponse.USER_STATUS_DO_NOT_EXISTS) {
        ToastError({
          message: translate('login.error'),
          description: translate('login.error_login_status'),
        });
        setLoading(false);
        return;
      }
      if (res.status == StatusResponse.PASSWORD_INCORRECT) {
        ToastError({
          message: translate('login.error'),
          description: translate('login.PASSWORD_INCORRECT'),
        });
        setLoading(false);
        return;
      }
      if (res.status == StatusResponse.SUCCESS) {
        if (res.user) {
          setLoading(false);
          setUser(res.user);
          // Lưu lại Token
          LocalStorageHelper.save(StorageKey.TOKEN, res.token);
          LocalStorageHelper.save(StorageKey.USER_SESSION, JSON.stringify(res));
          LocalStorageHelper.save(StorageKey.REFRESH_TOKEN, res.refreshToken);
          dispatch(onLOGIN(res.user));
          history.push('/');
          ToastSuccess({
            message: translate('login.success'),
            description: translate('login.sucess_login'),
          });
        }
      }
    } catch (error) {
      setLoading(false);
      console.log('🚀 ~ file: useLogin.tsx ~ line 70 ~ loginCMS ~ error', error);
      ToastError({
        message: translate('login.error'),
        description: translate('login.error_message'),
      });
    }
  };
  return [loginCMS, loading, user];
}

export default useLogin;
