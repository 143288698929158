/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Image, Input, message, Row } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import { IS_PHONE_NUMBER, LENGTH_BEGIN } from 'constants/SubstringPhone';
import { getUrlImage } from 'helpers/getImageUrl';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import usePhoneCheck from 'hooks/ValidateMatch/usePhoneCheck';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useSupportService from './support.service';
import { SupportBody } from './support.type';

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

function DetailSupportScreen(): JSX.Element {
  const history = useHistory();
  const [form] = Form.useForm();
  const [onUpload, resUpload] = useUploadMulti();
  // Chỉnh sửa
  const params: { id: string } = useParams();
  const isEdit = params && params.id;

  // Hiển thị hình ảnh + url hình ảnh
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const flag = useRef('');

  // Thêm mới hỗ trợ
  const { onCreateSupport, onDetailSupport, detailSupport, onUpdateSupport } = useSupportService();

  // Hình ảnh đại diện
  const [imgContent, setImgContent] = useState<Media[]>([]);

  // Get danh sách số điện thoại
  const { onGetPhoneCode, listPhone, loadingPhone } = usePhoneCheck();

  useEffect(() => {
    onGetPhoneCode();
  }, []);
  // Khi chỉnh sửa
  useEffect(() => {
    if (isEdit) {
      onDetailSupport(params.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && detailSupport) {
      form.setFieldsValue({
        name: detailSupport.name,
        phone: detailSupport.phone,
        email: detailSupport.email,
        imageId: detailSupport.imageId,
      });
      if (detailSupport.mediaInfo) {
        setImgContent([detailSupport.mediaInfo]);
      }
    }
  }, [detailSupport]);

  // Hình ảnh
  const onChangeFiles = (fileList: any, type: string) => {
    let image: Media[] = [];

    if (type === 'img_content') {
      image = imgContent;
    }

    const newData: any = [...image, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }

    if (type === 'img_content') {
      setImgContent(newData);
      flag.current = 'img_content';
    }
    onUpload(data);
  };

  // Xóa hình ảnh
  const onRemoveFiles = (item: Media | any, type: string) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => {
        if (type === 'img_content') {
          const newArrImg = imgContent.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImgContent(newArrImg);
        }
        message.success('Xoá ảnh thành công');
      },
    });
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      if (flag.current === 'img_content') {
        const newfileList = [...imgContent].filter(e => e._id);
        setImgContent([...newfileList, ...resUpload]);
      }
    }
  }, [resUpload]);

  // Xem chi tiết hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal hiển thị
  const onCancelImage = () => {
    setModalVisible(false);
  };

  // Button Lưu
  const onFinish = (value: any) => {
    const data: SupportBody = {
      name: value.name,
      phone: value.phone,
      email: value.email,
      imageId: imgContent && imgContent.length > 0 ? imgContent[0]._id : undefined,
    };
    if (isEdit) {
      return onUpdateSupport(params.id, data);
    }
    onCreateSupport(data);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).create ||
      getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).edit ? (
        <>
          <Modal title="" visible={isModalVisible} onCancel={onCancelImage} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={20} offset={3}>
                <Row>
                  {/* Ảnh chia sẻ */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>Ảnh</Col>
                      <Col span={17}>
                        <UploadButtonImages
                          accept={'.gif, .jpg, .png, .webp'}
                          files={imgContent}
                          onPreview={handlePreview}
                          onChange={(fileList: any) => onChangeFiles(fileList, 'img_content')}
                          onRemove={(item: any) => onRemoveFiles(item, 'img_content')}
                          imageLength={1}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={20} style={{ marginTop: '15px' }}>
                    <Form.Item
                      name="name"
                      label="Tên liên hệ"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: 'Tên liên hệ không được để trống',
                        },
                        {
                          whitespace: true,
                          min: 2,
                          max: 100,
                          message: 'Tên liên hệ bao gồm 2- 100 ký tự',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={20}>
                    <Form.Item
                      name="phone"
                      label={translate('customer.phone')}
                      labelAlign="left"
                      labelCol={{ span: 7 }}
                      rules={[
                        {
                          required: true,
                          message: translate('require_field', {
                            label: translate('customer.phone'),
                          }),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (typeof value !== 'undefined' && value != null) {
                              // Kiểm tra xem giá trị truyền vào có phải là số không
                              if (!value.match(/^-?\d+$/)) {
                                if (value.length > LENGTH_BEGIN.ZERO) {
                                  return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                }
                              }
                              // Nếu có giá trị kiểm tra có phải là số điện thoại không
                              if (value.length > LENGTH_BEGIN.ZERO) {
                                // Kiểm tra đầu có có đúng định dạng với dữ liệu có sẵn không
                                if (listPhone && listPhone.some(data => value.startsWith(data['code']))) {
                                  // Trường hợp đúng với dữ liệu => Check số điện thoại có đúng định dạng không
                                  if (value.length === IS_PHONE_NUMBER) {
                                    return Promise.resolve();
                                  }
                                  // Số điện thoại không đúng định dạng => Lỗi
                                  return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                } else {
                                  // Đầu số không đúng dữ liệu => Lỗi
                                  return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                }
                              }
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={20}>
                    <Form.Item
                      name="email"
                      label={translate('customer.email')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          type: 'email',
                          message: translate('customer.email_invalid'),
                        },
                        {
                          whitespace: true,
                          required: true,
                          message: translate('require_field', {
                            label: translate('customer.email'),
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={() => history.goBack()}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            {translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default DetailSupportScreen;
