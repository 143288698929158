/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Col, Form, Input, Row, Select, Upload } from 'antd';
import { StatusType } from 'constants/Status';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import useAddBank from 'hooks/Bank/useAddBank';
import useDetailBank from 'hooks/Bank/useDetailBank';
import useUpdateBank from 'hooks/Bank/useUpdateBank';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { UploadFile } from 'antd/lib/upload/interface';
import { ToastError } from 'components/Toast';
import images from 'images';
import Modal from 'antd/lib/modal/Modal';
import useUpload from 'hooks/Upload/useUpload';
import { getUrlImage } from 'helpers/getImageUrl';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';

const { Option } = Select;

interface Props {}
function FormBankPage(props: Props) {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_BANK);
  const [ImageFile, setImageFile] = useState<UploadFile[] | any[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [showImage, setShowImage] = useState('');

  // Thêm mới dữ liệu
  const [onAddBank, loading] = useAddBank();
  // Lấy chi tiết
  const [useGetDetailBank, detailBank] = useDetailBank();
  // Cập nhật dữ liệu
  const [onUpdateBank] = useUpdateBank();
  // Upload hình ảnh
  const [onUpload, resUpload, messageError] = useUpload();

  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      useGetDetailBank(params.id);
    } else {
      form.setFieldsValue({
        code: cacheForm.code,
        name: cacheForm.name,
        status: cacheForm.status,
      });
    }
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailBank) {
      form.setFieldsValue({
        code: detailBank.code,
        name: detailBank.name,
        status: detailBank.status,
      });
      // Hình ảnh
      if (detailBank?.mediaId) {
        setImageFile([
          {
            uid: detailBank?._id,
            name: 'image.png',
            status: 'done',
            url: getUrlImage(detailBank?.mediaId),
          },
        ]);
        form.setFieldsValue({ logo: detailBank?.mediaId?._id });
      }
    }
  }, [detailBank]);

  // Upload hình ảnh ngân hàng
  useEffect(() => {
    if (ImageFile && ImageFile.length > 0) {
      const data = new FormData();
      data.append('file', ImageFile[0].originFileObj);
      onUpload(data);
    } else {
      form.setFieldsValue({ logo: null });
    }
  }, [ImageFile]);

  useEffect(() => {
    let imageLogo = '';
    if (resUpload) {
      imageLogo = resUpload?._id;
    }
    if (imageLogo !== '') {
      form.setFieldsValue({ logo: imageLogo });
    }
  }, [resUpload]);

  const handleSubmit = () => {
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: StatusType.ACTIVE });
    if (!form.getFieldValue('logo')) form.setFieldsValue({ logo: null });
    form.validateFields().then(values => {
      const data = {
        name: values.name,
        code: values.code,
        status: values.status,
        mediaId: values?.logo,
      };
      if (isEdit) {
        const dataUpdate = { ...data, _id: params.id };
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_BANK);
        onUpdateBank(params.id, dataUpdate);
      } else {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_BANK);
        onAddBank(data);
      }
    });
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_BANK, form.getFieldsValue());
  };

  // Validate dữ liệu
  const validateForm = {
    name: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('bank.name_bank'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('bank.name_bank_validate'),
      },
    ],
    code: [
      {
        transform: (value: string) => {
          return value?.trim();
        },
        max: 500,
        message: translate('bank.code_validate'),
      },
    ],
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_ADMIN);
    history.back();
  };

  const handlePreview = async (file: any) => {
    const src = file.thumbUrl ? file.thumbUrl : file.url;
    setShowImage(src);
    setVisible(true);
  };

  const handleCancelImage = () => {
    setVisible(false);
  };

  const onRemoveImage = () => {
    setImageFile([]);
  };

  const handleChange = ({ fileList }: any) => {
    if (fileList.length > 0) {
      const isJpgOrPng =
        fileList[0].type === 'image/jpeg' || fileList[0].type === 'image/png' || fileList[0].type === 'image/jpg';
      if (!isJpgOrPng) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('upload_file_validate'),
        });
        return;
      }
      const isLt2M = fileList[0].size / 1024 / 1024 < 10;
      if (!isLt2M) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('upload_max_file_10MB'),
        });
        return;
      }
      fileList[0].status = 'done';
      setImageFile(fileList);
    }
  };

  const uploadButton = () => {
    return (
      <div>
        <img width="100%" height="100%" src={images.ic_image} alt="" />{' '}
      </div>
    );
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_BANKS).create ||
      getCheckPermissionUser(PERMISSION_NAME.CATEGORY_BANKS).edit ? (
        <>
          <Modal title={translate('detail_image')} visible={visible} onCancel={handleCancelImage} footer={null}>
            <div style={{ textAlign: 'center' }}>
              <img alt="" src={showImage} width="250px" />
            </div>
          </Modal>
          <Form onFieldsChange={updateCacheForm} form={form}>
            <Row>
              <Col span={20} offset={3}>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="status"
                      label={translate('status.name')}
                      labelCol={{ sm: 7 }}
                      labelAlign="left"
                      initialValue={StatusType.ACTIVE}
                    >
                      <Select
                        placeholder={translate('admin.status_active')}
                        // defaultValue={StatusType.ACTIVE}
                      >
                        <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                        <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="name"
                        label={translate('bank.name_bank')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={validateForm.name}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="code"
                        label={translate('bank.code')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={validateForm.code}
                      >
                        <Input autoComplete="off" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item name="logo" label={translate('bank.logo')} labelCol={{ span: 7 }} labelAlign="left">
                        <Upload
                          listType="picture-card"
                          fileList={ImageFile}
                          accept="image/*"
                          onPreview={handlePreview}
                          onRemove={onRemoveImage}
                          onChange={handleChange}
                        >
                          {ImageFile.length >= 1 ? null : uploadButton()}
                        </Upload>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={closeButton}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Button type="primary" onClick={handleSubmit} htmlType="submit">
                            {isEdit ? translate('action.update') : translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormBankPage;
