import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { InforAdminResponse } from 'types/response/AdminResponseRes';
import { User } from 'types/response/UserResponse';

function useDetailAdmin() : [(request:string) => Promise<void>,User | undefined] {
   const [detailAdmin,getDetailAdmin] = useState<User | undefined>();

   const useGetDetailAdmin = async (request : string) => {
        try {
            const res = await ApiHelper.fetch<string,InforAdminResponse>(
                BASE_URL.DETAIL_ADMIN + `${request}`
            )
            if(res && res.status == StatusResponse.SUCCESS){
                getDetailAdmin(res.data)
            }
        } catch (error) {
            getDetailAdmin(undefined)
        }
   }
   return [useGetDetailAdmin,detailAdmin];
}

export default useDetailAdmin;