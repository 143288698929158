import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { translate } from 'translate/language';

function useUploadVideo() {
    const [resVideo, setResVideo] = useState<{ url: string, _id: string ,}>();
    const [messageError, setMessageError] = useState<string>("");

    const onUploadVideo = async (request: FormData) => {
        try {
            const res = await ApiHelper.postFormData(
                BASE_URL.UPLOAD_VIDEO,
                request,
                {
                    "Content-Type": "multipart/form-data",
                }
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                setResVideo(res?.data)
            } else {
                setMessageError(`${translate("upload_fail")}`);
            }
        } catch (error) {
            setMessageError(`${translate("upload_fail")}`);
        }
    }

    return { onUploadVideo, resVideo, messageError }
}

export default useUploadVideo;