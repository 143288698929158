/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { translate } from 'translate/language';

const DELETE_LIST_EMPLOYEES = 'user/deleteManyEmployees';
export interface DeleteAdminReq {
  id: string;
}

function useDeleteAdmin(): [(id: DeleteAdminReq) => Promise<void>, (id: string[]) => Promise<void>, boolean] {
  const [loadingDelete, setLoading] = useState<boolean>(false);

  const onDeleteAdmin = async (id: DeleteAdminReq) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete<DeleteAdminReq, any>(BASE_URL.DELETE_ADMIN + `/${id.id}`, id);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoading(false);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  // Xóa danh dách phân quyền
  const onDeleteListEmployees = async (body: string[]) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete(DELETE_LIST_EMPLOYEES, { ids: body });
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      } else {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return [onDeleteAdmin, onDeleteListEmployees, loadingDelete];
}

export default useDeleteAdmin;
