import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useEffect, useState } from 'react';

function useDetailCustomer(): [(id: string) => Promise<void>, any, boolean, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [detailCustomer, getDetailCustomer] = useState({});
  const [messageCheck, setMessageCheck] = useState(false);

  const onGetDetailCustomer = async (id: string) => {
    try {
      setLoading(true);
      setMessageCheck(false);
      const res = await ApiHelper.fetch<string, any>(BASE_URL.DETAIL_CUSTOMER + `${id}`);
      if (res && res.status == StatusResponse.SUCCESS) {
        setMessageCheck(true);
        setLoading(false);
        getDetailCustomer(res?.data);
      } else {
        setMessageCheck(false);
        setLoading(false);
      }
    } catch (error) {
      setMessageCheck(false);
      setLoading(false);
    }
  };
  return [onGetDetailCustomer, detailCustomer, loading, messageCheck];
}

export default useDetailCustomer;
