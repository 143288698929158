import { EditOutlined } from '@ant-design/icons';
import { Col, Collapse, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import { StatusType } from 'constants/Status';
import useListProperty from 'hooks/PropertyType/useListProperty';
import useDeleteProperty from 'hooks/PropertyType/useDeleteProperty';
import images from 'images';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHeaderPropertyPage from './components/FilterHeaderPropertyPage';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';

function index() {
  const history = useHistory();
  const Panel = Collapse.Panel;

  // Lấy danh sách loại bất động sản
  const [onFetchListProperty, listProperty, loading] = useListProperty();
  // Xóa loại bất động sản
  const [onDeleteProperty, loadingDelete] = useDeleteProperty();

  useEffect(() => {
    onFetchListProperty({});
  }, [loadingDelete]);
  const genExtra = (id: string) => (
    <EditOutlined
      onClick={event => {
        event.stopPropagation();
        history.push(ROUTER_PATH.EDIT_PARENT + id);
      }}
    />
  );

  const customPanelStyle = {
    background: '#f7f7f7',
    marginBottom: 25,
    border: '2px solid #2c2c2c',
    overflow: 'hidden',
  };

  // Action xóa
  const onDelete = (_id: string) => () => {
    onDeleteProperty(_id);
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.EDIT_PROPERTY + _id);
            }}
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderColumns = [
    {
      title: translate('property.type'),
      key: '_id',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: translate('status.name'),
      key: '_id',
      dataIndex: 'status',
      render: (status: string) => {
        return <span>{status === StatusType.ACTIVE ? translate('status.show') : translate('status.hidden')}</span>;
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      render: renderAction,
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <FilterHeaderPropertyPage />
        </Col>
        {loading || loadingDelete ? (
          <PageLoading />
        ) : (
          <Col span={18} offset={3}>
            <Collapse bordered={false} expandIconPosition="left" accordion>
              {listProperty
                ? listProperty.data.map((data: any, index: number) => {
                    return (
                      <Panel
                        header={data.parent.name}
                        key={index}
                        style={customPanelStyle}
                        extra={
                          getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).edit
                            ? genExtra(data.parent._id)
                            : ''
                        }
                      >
                        <Col span={24}>
                          <Table
                            key={index}
                            dataSource={data?.children || []}
                            columns={renderColumns}
                            pagination={false}
                            scroll={{ x: 'auto' }}
                            rowKey="_id"
                          ></Table>
                        </Col>
                      </Panel>
                    );
                  })
                : ''}
            </Collapse>
          </Col>
        )}
      </Row>
    </>
  );
}

export default index;
