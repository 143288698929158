import styled from 'styled-components';
const Styled = {
  Container: styled.div`
    .content {
      background: #fff;
      padding: 15px;
      border-radius: 5px;
      box-sizing: border-box;
    }
  `,
};
export default Styled;
