import { translate } from 'translate/language';
import { ToastSuccess, ToastError } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { BaseResponse } from 'types/response/BaseResponse';
import { Message } from './MessageType';
import {
  IDataListMember,
  IRoleChat,
  Memmber,
  MessageViewInput,
  MessageUpdateInput,
  ROLE_INDIVIDUAL,
  ROLE_PAG,
} from './ViewMember.entity';

const LIST_TRANSACTIONS = '/project/transactions';
const DETAIL_TRANSACTION = '/project/detail-transaction';
const UPDATE_STATUS = '/project/transaction';
const UPDATE_HIDE_SHOW_MESSAGE = '/message/show-hide-message';

function useCustomerProjectChatService() {
  const [history, setMessagehistory] = useState<Message[]>([]);
  const [ListMember, setMemmber] = useState<Memmber[]>([]);
  const [stopLoadmore, setStopLoadmore] = useState<boolean>(false);
  const onGetListHistoryMess = async (params: { transactionId: string; limit: number; after?: string }) => {
    const res = await ApiHelper.fetch<{ transactionId: string; limit: number }, BaseResponse>(BASE_URL.MESSAGE, params);
    if (res && res.status === StatusResponse.SUCCESS) {
      const newData = params.after ? [...history, ...res.data] : res.data;
      if (newData.length < 20 * newData.length) {
        setStopLoadmore(true);
      }
      setMessagehistory(newData);
    } else {
      setStopLoadmore(false);
    }
  };

  const preHandleData = (data: IDataListMember, role: IRoleChat) => {
    let arrMember: Memmber[] = [];
    arrMember.push({ ...data.client, role: [role['client']], color: '#d3ce27' });
    if (data.owner) {
      const ind = arrMember.findIndex(item => item._id === data.owner._id);
      if (ind < 0) {
        arrMember.push({ ...data.owner, role: [role['owner']], color: '#ffaaa2' });
      } else {
        arrMember[ind].role?.push(role['owner']);
      }
    }
    if (data.supporter) {
      const ind = arrMember.findIndex(item => item._id === data.supporter._id);
      if (ind < 0) {
        arrMember.push({
          ...data.supporter,
          role: [role['supporter']],
          color: '#393c9e',
        });
      } else {
        arrMember[ind].role?.push(role['supporter']);
      }
    }
    if (data.introducer) {
      const ind = arrMember.findIndex(item => item._id === data.introducer._id);
      if (ind < 0) {
        arrMember.push({
          ...data.introducer,
          role: [role['introducer']],
          color: '#c7f0c0',
        });
      } else {
        arrMember[ind].role?.push(role['introducer']);
      }
    }

    setMemmber(arrMember);
  };

  const getListMemberIndividual = async (transactionId: string) => {
    try {
      const res = await ApiHelper.fetch<any, any>('/project-news/transaction/member', {
        transactionId,
      });
      if (res.status === StatusResponse.SUCCESS) {
        preHandleData(res.data, ROLE_INDIVIDUAL);
      }
    } catch (error) {}
  };

  const getListMemberPag = async (transactionId: string) => {
    try {
      const res = await ApiHelper.fetch<any, any>('/project/transactions/member', {
        transactionId,
      });
      if (res.status === StatusResponse.SUCCESS) {
        preHandleData(res.data, ROLE_PAG);
      }
    } catch (error) {}
  };

  const updateMessage = async (body: MessageUpdateInput) => {
    try {
      const res = await ApiHelper.put<any, BaseResponse>(UPDATE_HIDE_SHOW_MESSAGE, body);
      if (res.status === StatusResponse.SUCCESS) {
        const newData: Message[] = history.map((e: Message) => {
          if (e._id === body.idMessage) {
            e.isHide = !e.isHide;
          }
          return e;
        });
        setMessagehistory(newData);
        // preHandleData(res.data, ROLE_PAG);
      }
    } catch (error) {}
  };

  return {
    onGetListHistoryMess,
    history,
    stopLoadmore,
    getListMemberIndividual,
    ListMember,
    getListMemberPag,
    updateMessage,
  };
}

export default useCustomerProjectChatService;
