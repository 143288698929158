/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
import { Col, Collapse, Row } from 'antd';
import PageLoading from 'components/PageLoading';
import React, { useEffect } from 'react';
import SystemChild from './system-child';
import useSystemTreeService from './system-tree.service';

const { Panel } = Collapse;

function SystemTreeScreen(): JSX.Element {
  const { onGetSystemTree, dataSystem, loading } = useSystemTreeService();

  useEffect(() => {
    onGetSystemTree();
  }, []);

  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <Row>
          {dataSystem && (
            <>
              <Col span={24} style={{ fontSize: '23px', fontWeight: 'bold' }}>
                Cây hệ thống
              </Col>
              <Col span={20} offset={2}>
                <Collapse ghost>
                  <Panel
                    header={
                      <>
                        <div>
                          <b>{dataSystem.customerType === 'COMPANY' ? dataSystem.businessName : dataSystem.name}</b>
                          <span
                            style={{
                              background: '#0485fa',
                              color: 'white',
                              padding: '0px 10px',
                              marginLeft: '5px',
                              borderRadius: '10px',
                              position: 'relative',
                              top: '2px',
                            }}
                          >
                            {dataSystem.childrenCount}
                          </span>
                        </div>
                      </>
                    }
                    key={dataSystem._id}
                  >
                    <SystemChild phone={dataSystem.phone} />
                  </Panel>
                </Collapse>
              </Col>
            </>
          )}
        </Row>
      )}
    </>
  );
}

export default SystemTreeScreen;
