/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd';
import { StatusTransactions, StatusType } from 'constants/Status';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import React, { useEffect } from 'react';
import { translate } from 'translate/language';

const { Option } = Select;

export interface Props {
  onSearch?: (data: string) => void;
  onProjectId: (data: string | number) => void;
  onStatus?: (data: string) => void;
  isActiveStatus?: boolean;
}

function FilterProjectCustomerComponent(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, onProjectId, onStatus } = props;
  // Danh sách dự án
  const { listProject, onListProject } = useLitsHouseTemplate();
  useEffect(() => {
    onListProject({
      status: props.isActiveStatus ? StatusType.ACTIVE : undefined,
    });
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch && onSearch(event.target.value);
  };
  const handChangeProject = (value: any) => {
    onProjectId(value);
  };
  const handChangeStatus = (value: string) => {
    onStatus && onStatus(value);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={20}>
          {onSearch && (
            <Col className="gutter-row" span={7}>
              <div>
                <Form.Item name="search">
                  <Input
                    size="small"
                    placeholder={translate('customer_project.search')}
                    onChange={handleSearch}
                    suffix={<SearchOutlined />}
                  />
                </Form.Item>
              </div>
            </Col>
          )}
          <Col className="gutter-row" span={4}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('customer_project.project')} onChange={handChangeProject} allowClear>
                  {listProject &&
                    listProject.length > 0 &&
                    listProject.map(data => {
                      return (
                        <Option value={data._id}>
                          {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          {onStatus && (
            <Col className="gutter-row" span={4}>
              <div>
                <Form.Item name="status">
                  <Select placeholder={translate('customer_project.status')} onChange={handChangeStatus} allowClear>
                    <Option value={StatusTransactions.INTRODUCED}>{translate('transaction.INTRODUCED')}</Option>
                    <Option value={StatusTransactions.FOUND_SUPPORTER}>
                      {translate('transaction.FOUND_SUPPORTER')}
                    </Option>
                    <Option value={StatusTransactions.CONFIRMED_INFO}>{translate('transaction.CONFIRMED_INFO')}</Option>
                    <Option value={StatusTransactions.SUPPORTED}>{translate('transaction.SUPPORTED')}</Option>
                    <Option value={StatusTransactions.BOOKING}>{translate('transaction.BOOKING')}</Option>
                    <Option value={StatusTransactions.BOOKED}>{translate('transaction.BOOKED')}</Option>
                    <Option value={StatusTransactions.DEPOSIT_PENDING}>
                      {translate('transaction.DEPOSIT_PENDING')}
                    </Option>
                    <Option value={StatusTransactions.DEPOSIT}>{translate('transaction.DEPOSIT')}</Option>
                    <Option value={StatusTransactions.SUCCESS}>{translate('transaction.SUCCESS')}</Option>
                    <Option value={StatusTransactions.CANCEL_BOOKING}>{translate('transaction.CANCEL_BOOKING')}</Option>
                    <Option value={StatusTransactions.CANCEL_DEPOSIT}>{translate('transaction.CANCEL_DEPOSIT')}</Option>
                    <Option value={StatusTransactions.CUSTOMER_CANCEL}>
                      {translate('transaction.CUSTOMER_CANCEL')}
                    </Option>
                    {/* <Option value="REQUEST_DEPOSIT_PENDING">Đang Gửi yêu cầu đặt cọc</Option> */}
                    {/* <Option value="REQUEST_DEPOSIT">Đã Xác Nhận chuyển cọc</Option> */}
                  </Select>
                </Form.Item>
              </div>
            </Col>
          )}
        </Row>
      </Form>
    </>
  );
}

export default FilterProjectCustomerComponent;
