import { useState } from 'react';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { FilterTransactions, ProjectNewsTransactionsRes } from './transactions.type';

const LIST_TRANSACTIONS = '/project-news/transaction/all';

function useCustomerProjectService() {
  const [loading, setLoading] = useState(false);
  const [dataHouseTransactions, setDataHouseTransactions] = useState<ProjectNewsTransactionsRes[]>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>();

  const onGetListTransactions = async (request: FilterTransactions) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch<FilterTransactions, any>(LIST_TRANSACTIONS, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataHouseTransactions(res.data);
        setTotalTransactions(res.total);
        setLoading(false);
      }
    } catch (error) {
      setDataHouseTransactions([]);
      setLoading(false);
    }
  };

  return {
    onGetListTransactions,
    dataHouseTransactions,
    totalTransactions,
    loading,
  };
}

export default useCustomerProjectService;
