import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

function useListParentChildRealeState() {
    const [ListParentChildRealeState, getListParentChildRealeState] = useState([]);

    const onFetchListParentChildRealeState = async () => {
        try {
            const res = await ApiHelper.fetch(BASE_URL.LIST_PARENT_CHILD)
            if (res && res.status == StatusResponse.SUCCESS) {
                getListParentChildRealeState(res.data)
            }
        } catch (error) {
            getListParentChildRealeState([])
        }
    }

    return { onFetchListParentChildRealeState, ListParentChildRealeState }
}

export default useListParentChildRealeState;