/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable no-return-assign */
import LocalStorageHelper from './LocalStorageHelper';

let token: any = null;

export const setToken = (_token: string) => {
  token = _token;
};

export const getToken = (): string => {
  if (localStorage.getItem('_refreshToken')) {
    return token;
  }
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
  return '';
};

export const getCheckPermissionUser = (name: string) => {
  const permission = LocalStorageHelper.getPermission();
  let data: any;
  if (permission && permission.length > 0) {
    permission.map((permission: any) => {
      if (name === permission?.type) return (data = permission);
    });
    return data;
  }
  return null;
};
