/* eslint-disable import/no-useless-path-segments */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StatusResponse } from '../../constants/Status';
import { ToastError, ToastSuccess } from './../../components/Toast/index';
import { translate } from './../../translate/language';
import { FilterPoint, PointRes, ValueReq } from './point-types';

const POINT = '/point-packages';
const DETAIL_POINT = '/point-packages/detail';

function usePointService() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataPoint, setDataPoint] = useState<PointRes[]>([]);
  const [totalPoint, setTotal] = useState<number>();
  const [detailPoint, setDetailPoint] = useState<PointRes | undefined>(undefined);
  const [loadingDel, setLoadingDel] = useState(false);

  // Danh sách gói điểm
  const onGetPoint = async (param: FilterPoint) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(POINT, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataPoint(res.data);
        setTotal(res.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Thêm mới gói điểm
  const onCreatePoint = async (body: ValueReq) => {
    try {
      const res: any = await ApiHelper.post(POINT, body);
      if (res && res.status === StatusResponse.NAME_ALREADY_EXIST) {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tên gói điểm đã tồn tại',
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
    }
  };

  // Chi tiết gói điểm
  const onDetailPoint = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(`${DETAIL_POINT}?pointPackageId=${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailPoint(res.data);
      } else {
        setDetailPoint(undefined);
      }
    } catch (error) {
      setDetailPoint(undefined);
    }
  };

  // Cập nhật gói điểm
  const onUpdatePoint = async (body: ValueReq) => {
    try {
      const res: any = await ApiHelper.put(POINT, body);
      if (res && res.status === StatusResponse.NAME_ALREADY_EXIST) {
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tên gói điểm đã tồn tại',
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  // Xóa điểm
  const onDeletePoint = async (id: string) => {
    try {
      setLoadingDel(true);
      const res: any = await ApiHelper.delete(POINT, { pointPackageId: id });
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoadingDel(false);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
        setLoadingDel(false);
      }
    } catch (error) {
      setLoadingDel(false);
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return {
    onGetPoint,
    dataPoint,
    totalPoint,
    loading,
    onCreatePoint,
    detailPoint,
    onDetailPoint,
    onUpdatePoint,
    onDeletePoint,
    loadingDel,
  };
}

export default usePointService;
