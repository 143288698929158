import { ToastError } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { InforAdminResponse } from 'types/response/AdminResponseRes';
import { User } from 'types/response/UserResponse';

function useDetailArea(): [(request: string) => Promise<void>, any | undefined] {
  const [detailArea, getArea] = useState<any | undefined>();
  const history = useHistory();
  const useGetDetailArea = async (request: string) => {
    try {
      const res = await ApiHelper.fetch<string, InforAdminResponse>(BASE_URL.DETAIL_AREA + `${request}`);
      if (res && res.status === StatusResponse.BLOCK_DO_NOT_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.data_do_not_exists'),
        });
        history.push(ROUTER_PATH.BLOCKS_AREA);
        return;
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        getArea(res.data);
      }
    } catch (error) {
      getArea(undefined);
    }
  };
  return [useGetDetailArea, detailArea];
}

export default useDetailArea;
