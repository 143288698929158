/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Modal, Popconfirm, Row } from 'antd';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import images from 'images';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import _ from 'lodash';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useContentGuideService from './content-guide.service';

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

function ContentGuideScreen(): JSX.Element {
  const history = useHistory();
  const [form] = Form.useForm();
  const [formVideo] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const [content, setContent] = useState<string>('');

  const handleChangeContent = (event: any, editor: any) => {
    form.setFieldsValue({
      content: editor.getData(),
    });
    setContent(editor.getData());
  };

  const { onData, data, onDataUpdate } = useContentGuideService();
  const [dataVideo, setDataVideo] = useState<any[]>([]);

  useEffect(() => {
    onData();
  }, []);

  useEffect(() => {
    if (data) {
      setContent(data?.content);
      if (data?.listVideos && data?.listVideos.length > 0) {
        setDataVideo(data?.listVideos);
      }
      form.setFieldsValue({
        content: data.content,
      });
    }
  }, [data]);

  // Button Lưu
  const onFinish = (value: any) => {
    value.listVideos = dataVideo;
    value.content = content || '';

    onDataUpdate(value);
  };

  const onCancel = () => {
    setVisible(false);
    formVideo.resetFields();
  };

  const onCreate = (values: any) => {
    if (values._id) {
      const data = dataVideo.filter(item => item._id === values._id);
      data[0].title = values.title;
      data[0].url = values.url;

      const dataNew = _.uniqWith([...dataVideo, data[0]], _.isEqual);
      setDataVideo(dataNew);
    } else {
      setDataVideo([...dataVideo, values]);
    }

    setVisible(false);
    formVideo.resetFields();
  };

  const removeVideo = (id?: string) => {
    const newVideo = dataVideo.filter((data: any) => data._id !== id);
    setDataVideo(newVideo);
  };

  const editVideo = async (data: any) => {
    setVisible(true);
    if (data) {
      await formVideo.setFieldsValue({
        _id: data._id,
        title: data.title,
        url: data.url,
      });
    }
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CONTENT_INTRO_TERMS).create ||
      getCheckPermissionUser(PERMISSION_NAME.CONTENT_INTRO_TERMS).edit ? (
        <>
          <Modal
            visible={visible}
            title="Tải lên Video"
            okText="Đồng ý"
            cancelText="Hủy bỏ"
            onCancel={onCancel}
            onOk={() => {
              formVideo.validateFields().then(values => {
                form.resetFields();
                onCreate(values);
              });
            }}
          >
            <Form form={formVideo} layout="vertical" initialValues={{ modifier: 'public' }}>
              <Form.Item name="_id" className="hidden">
                <Input />
              </Form.Item>

              <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[{ required: true, message: 'Tiêu đề video không được để trống' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="url"
                label="Nhập Link Youtube"
                rules={[
                  { required: true, message: 'Tiêu đề video không được để trống' },
                  {
                    pattern: /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/,
                    message: 'Link youtube không đúng định dạng',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={20} offset={3}>
                <Row>
                  {/* Câu hỏi thường gặp */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>
                        <div>Câu hỏi thường gặp</div>
                      </Col>
                      <Col span={17}>
                        <Form.Item name="content" labelAlign="left">
                          <CKEEditor onChange={handleChangeContent} data={content} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  {/* Link youtube */}
                  <Col span={20}>
                    <Row>
                      <Col span={7}>
                        <div>Video</div>
                      </Col>
                      <Col span={17}>
                        <PlusCircleOutlined
                          onClick={() => {
                            setVisible(true);
                          }}
                          style={{ fontSize: '40px', cursor: 'pointer' }}
                        />
                      </Col>
                    </Row>
                  </Col>

                  <Col span={20} style={{ marginTop: '30px' }}>
                    <Row>
                      <Col span={7}></Col>
                      <Col span={17}>
                        {dataVideo &&
                          dataVideo.length > 0 &&
                          dataVideo.map((data: any) => {
                            return (
                              <Row>
                                <span style={{ fontSize: '20px', color: 'red', fontWeight: 'bold' }}>{data.title}</span>
                                <span
                                  style={{ position: 'relative', top: '4px', margin: '0px 10px' }}
                                  onClick={() => editVideo(data)}
                                >
                                  <img className="img-style" src={images.ic_edit} alt="edit" />
                                </span>
                                <span style={{ position: 'relative', top: '4px' }}>
                                  <Popconfirm
                                    placement="top"
                                    title="Bạn có chắc muốn xóa?"
                                    onConfirm={() => removeVideo(data?._id)}
                                    okText={translate('action.yes')}
                                    cancelText={translate('action.no')}
                                  >
                                    <img className="img-style" src={images.ic_delete} alt="delete" />
                                  </Popconfirm>
                                </span>
                              </Row>
                            );
                          })}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={() => history.goBack()}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit">
                            {translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default ContentGuideScreen;
