/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, List, Row, Select, Table, Typography } from 'antd';
import { StatusTransactions } from 'constants/Status';
import { formatNumberPrice } from 'helpers/FormatPrice';
import moment from 'moment';
import CustomerItem from 'pages/CustomerProject/components/customer-chat-item';
import InputChatItem from 'pages/CustomerProject/components/customer-project-item';
import useCustomerProjectChatService from 'pages/CustomerProject/customer-project-transaction-chat.services';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useRelatedHook from '../useSaleAndRentalhooks/useRelatedHook';

const { Text, Link } = Typography;
const { Option } = Select;

function DetailRelatedScreen(): JSX.Element {
  const param: { id: string } = useParams();
  const isEdit = param && param.id;
  const [form] = Form.useForm();
  const historyBack = useHistory();
  const backHistory = () => {
    historyBack.goBack();
  };

  const {
    onDetailHistory,
    dataHistory,
    onUpdateRelated,
    onHistoryTransaction,
    historyTransaction,
    updateViewMessageNews,
  } = useRelatedHook();

  useEffect(() => {
    if (isEdit) {
      onDetailHistory(param.id);
      onHistoryTransaction(param.id);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      status: dataHistory.status,
      note: dataHistory.note,
    });
  }, [dataHistory]);

  const onFinish = (value: { status: string; note: string }) => {
    if (isEdit) {
      value.note = value.note ? value.note : '';
      onUpdateRelated(value, param.id);
    }
  };

  const columns = [
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'INTRODUCED':
            return translate('transaction.INTRODUCED');
          case 'FOUND_SUPPORTER':
            return translate('transaction.FOUND_SUPPORTER');
          case 'CONFIRMED_INFO':
            return translate('transaction.CONFIRMED_INFO');
          case 'SUPPORTED':
            return translate('transaction.SUPPORTED');
          case 'BOOKING':
            return translate('transaction.BOOKING');
          case 'BOOKED':
            return 'Đang tiến hành đặt cọc';
          case 'DEPOSIT_PENDING':
            return translate('transaction.DEPOSIT_PENDING');
          case 'DEPOSIT':
            return translate('transaction.DEPOSIT');
          case 'SUCCESS':
            return translate('transaction.SUCCESS');
          case 'CANCEL_BOOKING':
            return translate('transaction.CANCEL_BOOKING');
          case 'CANCEL_DEPOSIT':
            return translate('transaction.CANCEL_DEPOSIT');
          case 'CUSTOMER_CANCEL':
            return translate('transaction.CUSTOMER_CANCEL');
          default:
            return '';
        }
      },
    },
    {
      title: 'Ngày được set',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <>{moment(createdAt).format('HH:mm DD-MM-YYYY')}</>;
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
  ];

  const historyCommissionColumns = [
    {
      title: 'Số tiền',
      dataIndex: 'money',
      render: (money: number) => {
        return <>{formatNumberPrice(money)}</>;
      },
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case 'COMMISSION_RETAIL_REAL_ESTATE_INTRODUCER':
            return 'HH cho CTV giới thiệu';
          case 'COMMISSION_RETAIL_REAL_ESTATE_SELLER':
            return 'HH cho người bán';
          case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_INTRODUCE':
            return 'HH cho hệ thống CTV giới thiệu';
          case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_CUSTOMER':
            return 'HH cho hệ thống khách hàng';
          case 'COMMISSION_RETAIL_REAL_ESTATE_PAG':
            return 'HH cho Phúc An Gia';
          case 'COMMISSION_RETAIL_REAL_ESTATE_CUSTOMER':
            return 'HH cho người mua';
          default:
            return '';
        }
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'PENDING_APPROVED':
            return 'Chờ duyệt';
          case 'APPROVED':
            return 'Đã duyệt';
          case 'CANCEL':
            return 'Đã huỷ';
          case 'PENDING_PAYMENT':
            return 'Chờ chi trả';
          default:
            return '';
        }
      },
    },
    {
      title: 'Người nhận',
      dataIndex: 'userInfo',
      render: (userInfo: any) => {
        return <>{userInfo ? userInfo.name : 'PAG'}</>;
      },
    },
    {
      title: 'Ngày nhận',
      dataIndex: 'receivedDate',
      render: (receivedDate: moment.Moment) => {
        return <>{moment(receivedDate).format('HH:mm DD-MM-YYYY')}</>;
      },
    },
  ];

  const {
    history,
    onGetListHistoryMess,
    stopLoadmore,
    getListMemberIndividual,
    ListMember,
    updateMessage,
  } = useCustomerProjectChatService();

  const [filterMessage, setFilterMessage] = React.useState<any>({
    transactionId: param.id,
    limit: 20,
    type: 'CMS',
    projectType: 'INDIVIDUAL',
  });

  useEffect(() => {
    if (isEdit) {
      getListMemberIndividual(param.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && filterMessage) {
      onGetListHistoryMess(filterMessage);
    }
  }, [filterMessage]);

  const onLoadMore = (e: any) => {
    if (e && !stopLoadmore) {
      setFilterMessage({
        ...filterMessage,
        after: history[history.length - 1]._id,
      });
    }
  };

  const onUpdateMessage = (idMessage: string, isHide: boolean) => {
    const params = {
      transactionId: param.id,
      idMessage: idMessage,
      isHide: isHide,
    };
    updateMessage(params);
  };

  const onUpdateViewMessage = (userId: string) => {
    const newParams = {
      transactionId: param.id,
      userId: userId,
    };
    updateViewMessageNews(newParams);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).create ||
      getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).edit ? (
        <>
          <Row>
            <Col span={24}>
              <Text underline>
                <div style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>Chi tiết giao dịch</div>
              </Text>
            </Col>
          </Row>
          {dataHistory && (
            <Form form={form} onFinish={onFinish}>
              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <Row>
                    <Col span={24} offset={0}>
                      <Row>
                        <Col span={11}>Tên khách hàng</Col>
                        <Col span={13} className="fontSize20">
                          <b>
                            <Link href={ROUTER_PATH.EDIT_CUSTOMER + dataHistory.userInfo?._id} target="_blank">
                              <Text underline style={{ color: '#12a0c0' }} className="fontSize20">
                                {dataHistory.userInfo && dataHistory.userInfo.name}
                              </Text>
                            </Link>
                          </b>
                        </Col>
                      </Row>

                      {/* Số điện thoại */}
                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.phone')}</Col>
                        <Col span={13} className="fontSize20">
                          <b>{dataHistory.userInfo && dataHistory.userInfo.phone}</b>
                        </Col>
                      </Row>

                      {/* Số CMND */}
                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.CMND')}</Col>
                        <Col span={13} className="fontSize20">
                          <b>{dataHistory.userInfo && dataHistory.userInfo.identify}</b>
                        </Col>
                      </Row>

                      {/* CTV giới thiệu */}
                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.ctv_introducer')}</Col>
                        <Col span={13} className="fontSize20">
                          <Link href={ROUTER_PATH.EDIT_CUSTOMER + dataHistory.introducerInfo?._id} target="_blank">
                            <Text underline style={{ color: '#12a0c0' }}>
                              {dataHistory.introducerInfo && dataHistory.introducerInfo?.name}
                            </Text>
                          </Link>
                        </Col>
                      </Row>

                      {/* Số điện thoại CTV giới thiệu */}
                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.phone_ctv_introducer')}</Col>
                        <Col span={13} className="fontSize20">
                          <b>{dataHistory.introducerInfo && dataHistory.introducerInfo?.phone}</b>
                        </Col>
                      </Row>

                      {/* CTV hỗ trợ */}
                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.ctv_supporter')}</Col>
                        <Col span={13} className="fontSize20">
                          <Link href={ROUTER_PATH.EDIT_CUSTOMER + dataHistory.supporterInfo?._id} target="_blank">
                            <Text underline style={{ color: '#12a0c0' }}>
                              {dataHistory.supporterInfo && dataHistory.supporterInfo?.name}
                            </Text>
                          </Link>
                        </Col>
                      </Row>

                      {/* Số điện thoại CTV hỗ trợ */}
                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.phone_ctv_supporter')}</Col>
                        <Col span={13} className="fontSize20">
                          <b>{dataHistory.supporterInfo && dataHistory.supporterInfo?.phone}</b>
                        </Col>
                      </Row>

                      {/* Mã căn hộ */}
                      <Row className="marginTop">
                        <Col span={11}>Giá bán/cho thuê</Col>
                        <Col span={13} className="fontSize20">
                          <b>{formatNumberPrice(dataHistory.projectNewsInfo?.price || 0)}</b>
                        </Col>
                      </Row>

                      {/* Giá bán */}
                      <Row className="marginTop">
                        <Col span={11}>Tổng hoa hồng (VNĐ)</Col>
                        <Col span={13} className="fontSize20">
                          <b>
                            {dataHistory.projectNewsInfo &&
                              formatNumberPrice(dataHistory.projectNewsInfo.commissionFee || 0)}
                          </b>
                        </Col>
                      </Row>

                      {/* Trạng thái giao dịch */}

                      <Row className="marginTop">
                        <Col span={11}>{translate('customer_project.status_detail')}</Col>
                        <Col span={5}>
                          <Form.Item name="status">
                            <Select style={{ width: '100%' }}>
                              <Option
                                value={StatusTransactions.INTRODUCED}
                                disabled={
                                  dataHistory.status === StatusTransactions.CONFIRMED_INFO ||
                                  dataHistory.status === StatusTransactions.SUPPORTED ||
                                  dataHistory.status === StatusTransactions.BOOKING ||
                                  dataHistory.status === StatusTransactions.BOOKED ||
                                  dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                {translate('transaction.INTRODUCED')}
                              </Option>
                              {/* Giới thiệu khách hàng */}
                              <Option
                                value={StatusTransactions.FOUND_SUPPORTER}
                                disabled={
                                  dataHistory.status === StatusTransactions.INTRODUCED ||
                                  dataHistory.status === StatusTransactions.FOUND_SUPPORTER ||
                                  dataHistory.status === StatusTransactions.CONFIRMED_INFO ||
                                  dataHistory.status === StatusTransactions.SUPPORTED ||
                                  dataHistory.status === StatusTransactions.BOOKING ||
                                  dataHistory.status === StatusTransactions.BOOKED ||
                                  dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                {translate('transaction.FOUND_SUPPORTER')}
                              </Option>
                              {/* Yêu cầu hỗ trợ */}
                              {/* <Option
                          value={StatusTransactions.CONFIRMED_INFO}
                          disabled={
                            dataHistory.status === StatusTransactions.CONFIRMED_INFO ||
                            dataHistory.status === StatusTransactions.SUPPORTED ||
                            dataHistory.status === StatusTransactions.BOOKING ||
                            dataHistory.status === StatusTransactions.BOOKED ||
                            dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                            dataHistory.status === StatusTransactions.DEPOSIT ||
                            dataHistory.status === StatusTransactions.SUCCESS ||
                            dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                            dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                            dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                          }
                        >
                          {translate('transaction.CONFIRMED_INFO')}
                        </Option> */}
                              {/* CTV hỗ trợ xác minh */}
                              <Option
                                value={StatusTransactions.SUPPORTED}
                                disabled={
                                  dataHistory.status === StatusTransactions.SUPPORTED ||
                                  dataHistory.status === StatusTransactions.BOOKING ||
                                  dataHistory.status === StatusTransactions.BOOKED ||
                                  dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                {translate('transaction.SUPPORTED')}
                              </Option>
                              {/* Hỗ trợ và tư vấn bán hàng */}
                              <Option
                                value={StatusTransactions.BOOKING}
                                disabled={
                                  dataHistory.status === StatusTransactions.INTRODUCED ||
                                  dataHistory.status === StatusTransactions.FOUND_SUPPORTER ||
                                  dataHistory.status === StatusTransactions.CONFIRMED_INFO ||
                                  // dataHistory.status === StatusTransactions.SUPPORTED ||
                                  dataHistory.status === StatusTransactions.BOOKING ||
                                  dataHistory.status === StatusTransactions.BOOKED ||
                                  dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                {translate('transaction.BOOKING')}
                              </Option>
                              {/* Yêu cầu giữ chỗ */}
                              <Option
                                value={StatusTransactions.BOOKED}
                                disabled={
                                  dataHistory.status === StatusTransactions.BOOKED ||
                                  dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                Đang tiến hành đặt cọc
                              </Option>
                              {/* Đã giữ chỗ */}
                              <Option
                                value={StatusTransactions.DEPOSIT_PENDING}
                                disabled={
                                  dataHistory.status === StatusTransactions.INTRODUCED ||
                                  dataHistory.status === StatusTransactions.FOUND_SUPPORTER ||
                                  dataHistory.status === StatusTransactions.CONFIRMED_INFO ||
                                  dataHistory.status === StatusTransactions.SUPPORTED ||
                                  dataHistory.status === StatusTransactions.BOOKING ||
                                  dataHistory.status === StatusTransactions.BOOKED ||
                                  dataHistory.status === StatusTransactions.DEPOSIT_PENDING ||
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                {translate('transaction.DEPOSIT_PENDING')}
                              </Option>
                              {/* Yêu cầu đặt cọc */}
                              <Option
                                value={StatusTransactions.DEPOSIT}
                                disabled={
                                  dataHistory.status === StatusTransactions.DEPOSIT ||
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.CANCEL_BOOKING ||
                                  dataHistory.status === StatusTransactions.CANCEL_DEPOSIT ||
                                  dataHistory.status === StatusTransactions.CUSTOMER_CANCEL
                                }
                              >
                                {translate('transaction.DEPOSIT')}
                              </Option>
                              {/* Đã đặt cọc */}
                              <Option value={StatusTransactions.SUCCESS}>{translate('transaction.SUCCESS')}</Option>
                              {/* Giao dịch thành công */}
                              <Option
                                value={StatusTransactions.CANCEL_BOOKING}
                                disabled={
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.BOOKED
                                }
                              >
                                {translate('transaction.CANCEL_BOOKING')}
                              </Option>
                              {/* Hủy giữ chỗ */}
                              <Option
                                value={StatusTransactions.CANCEL_DEPOSIT}
                                disabled={
                                  dataHistory.status === StatusTransactions.SUCCESS ||
                                  dataHistory.status === StatusTransactions.BOOKING
                                }
                              >
                                {translate('transaction.CANCEL_DEPOSIT')}
                              </Option>
                              {/* Hủy đặt cọc */}
                              <Option
                                value={StatusTransactions.CUSTOMER_CANCEL}
                                disabled={dataHistory.status === StatusTransactions.SUCCESS}
                              >
                                {translate('transaction.CUSTOMER_CANCEL')}
                              </Option>
                              {/* Giao dịch không thành công */}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      {/* Ghi chú */}
                      <Row className="marginTop">
                        <Col span={7}>Ghi chú</Col>
                        <Col span={10} className="fontSize20">
                          <Form.Item name="note">
                            <Input.TextArea rows={5} />
                          </Form.Item>
                        </Col>
                      </Row>

                      {/* Lịch sử giao dịch */}
                      <Row className="marginTop">
                        <Col span={12}>
                          <h4>Lịch sử giao dịch:</h4>
                          <Table dataSource={historyTransaction || []} columns={columns} pagination={false} />
                        </Col>

                        <Col span={1}></Col>
                        {/* Lịch sử giao dịch */}
                        <Col span={11}>
                          <h4>Lịch sử nhận hoa hồng:</h4>
                          <Table
                            dataSource={
                              dataHistory.commissionHistoryInfo?.length > 0 ? dataHistory.commissionHistoryInfo : []
                            }
                            columns={historyCommissionColumns}
                            pagination={false}
                          />
                        </Col>
                      </Row>

                      {/* Thảo luận liên quan */}
                      <Row className="marginTop">
                        <hr style={{ borderTop: '2px solid black', width: '100%' }} />
                      </Row>

                      <Row>
                        <Col span={24}>
                          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '25px' }}>
                            Thảo luận liên quan
                          </div>
                        </Col>
                      </Row>
                      {/* Danh sách thảo luận   */}
                      <Row className="marginTop">
                        <Col span={15}>
                          <div className="demo-infinite-container">
                            <InfiniteScroll
                              initialLoad={false}
                              pageStart={0}
                              loadMore={onLoadMore}
                              hasMore={true}
                              useWindow={false}
                            >
                              <List
                                className="comment-list"
                                itemLayout="horizontal"
                                dataSource={history}
                                renderItem={(item: any) => (
                                  <InputChatItem onUpdateMessage={onUpdateMessage} item={item} />
                                )}
                              />
                            </InfiniteScroll>
                          </div>
                        </Col>

                        <Col span={4} offset={1}>
                          <List
                            className="comment-list"
                            itemLayout="horizontal"
                            dataSource={ListMember}
                            renderItem={(item: any) => (
                              <CustomerItem
                                detailTransaction={dataHistory}
                                onUpdateViewMessage={onUpdateViewMessage}
                                item={item}
                              />
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col span={20}>
                          <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Col>
                              <Form.Item>
                                <Button type="default" onClick={backHistory}>
                                  {translate('action.close')}
                                </Button>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  style={{ backgroundColor: 'rgb(58, 200, 110)' }}
                                  htmlType="submit"
                                >
                                  {translate('action.save')}
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default DetailRelatedScreen;
