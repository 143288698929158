/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/self-closing-comp */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table, Tooltip } from 'antd';

import { truncateString } from 'helpers/String';
import { BarsOutlined } from '@ant-design/icons';
import { CustomerType } from 'constants/CustomerType';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useDeleteCustomer from 'hooks/Customer/useDeleteCustomer';
import useListCustomer from 'hooks/Customer/useListCustomer';
import images from 'images';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHeaderCustomer from './components/FilterHeaderCustomer';
import './css/index.scss';

export interface Filter {
  status?: string;
  page?: number;
  limit?: string | number;
  search?: string;
  customerType?: string;
}

const CustomerPage: React.FC = () => {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<Filter>({
    status: '',
    page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: '',
    customerType: '',
  });
  // Danh sách nhân viên
  const [onFetchListCustomer, listCustomer, loading] = useListCustomer();
  // Xoá dữ liệu khách hàng
  const [onDeleteCustomer, onDeleteListCustomer, loadingDelete] = useDeleteCustomer();
  // danh sách khách hàng chọn để xóa
  const [customerIdSelect, setCustomerIdSelect] = useState<string[]>([]);

  useEffect(() => {
    onFetchListCustomer(filter);
  }, [filter, loadingDelete]);

  const searchInput = useRef<any>(null);

  // Tìm kiếm Tên,SĐT
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search });
    },
    {
      wait: 500,
    },
  );

  // Select loại tài khoản
  const onChangeType = (type: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, customerType: type });
  };
  // Select trạng thái
  const onChangeStatus = (status: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status });
  };

  // Action xóa
  const onDelete = (_id: string) => () => {
    onDeleteCustomer(_id);
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.EDIT_CUSTOMER + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  // Action phân trang
  // eslint-disable-next-line no-shadow
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page });
  };

  // Click box mail
  const openBoxMail = (email: string) => {
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=&body=`, '_blank');
  };

  // Xóa nhiều khách hàng
  const onDeleteAll = () => {
    onDeleteListCustomer(customerIdSelect);
    setCustomerIdSelect([]);
  };

  const rowSelection = {
    selectedRowKeys: customerIdSelect,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      const arrEmployeeIdNotInPage = customerIdSelect.filter(
        phone => !listCustomer?.data.find((item: { phone: string }) => phone === item.phone),
      );
      setCustomerIdSelect(_.uniq([...arrEmployeeIdNotInPage, ...selectedRowKeys]));
    },
  };

  // Render Column Table
  const renderColumns = [
    {
      title: '#',
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
      width: '1%',
    },
    {
      title: 'Mã số',
      key: '_id',
      dataIndex: 'agentId',
      render: (agentId: string) => {
        return <span>{agentId}</span>;
      },
      width: '5%',
    },
    {
      title: translate('customer.name'),
      key: '_id',
      render: (data: any) => {
        return data?.customerType && data?.customerType === 'PRIVATE' ? (
          <Tooltip placement="topLeft" title={data?.name}>
            <span style={{ cursor: 'pointer' }}>{data?.name}</span>
          </Tooltip>
        ) : (
          <Tooltip placement="topLeft" title={data?.businessName}>
            <span style={{ cursor: 'pointer' }}>{data?.businessName}</span>
          </Tooltip>
        );
      },
      width: '12%',
    },
    {
      title: 'SĐT',
      key: '_id',
      dataIndex: 'phone',
      render: (phone: number) => {
        return <span>{phone}</span>;
      },
    },
    {
      title: translate('customer.email'),
      key: '_id',
      dataIndex: 'email',
      render: (email: string) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <span
            onClick={() => openBoxMail(email)}
            style={{ cursor: 'pointer', textDecorationLine: 'underline', color: '#7e7ef7' }}
          >
            {truncateString(email, 15)}
          </span>
        );
      },
    },
    {
      title: 'CCCD/MST',
      key: '_id',
      render: (text = '', record: any) => {
        return <span>{record.customerType === CustomerType.PRIVATE ? record.identify : record.taxCode}</span>;
      },
    },
    {
      title: translate('customer.people'),
      key: '_id',
      dataIndex: 'userIntroduceInfo',
      render: (userIntroduceInfo: any) => {
        return (
          <span>
            {userIntroduceInfo && userIntroduceInfo.name
              ? `${userIntroduceInfo.name} - ${userIntroduceInfo.phone}`
              : ''}
          </span>
        );
      },
    },
    {
      title: 'Loại',
      key: '_id',
      dataIndex: 'customerType',
      render: (customerType: string) => {
        return (
          <span>
            {customerType === 'PRIVATE' ? translate('customerType.PRIVATE') : translate('customerType.COMPANY')}
          </span>
        );
      },
    },
    {
      title: 'Online',
      key: '_id',
      dataIndex: 'lastOnlineTime',
      render: (lastOnlineTime: any) => {
        return <span>{moment(lastOnlineTime).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: translate('status.name'),
      key: '_id',
      dataIndex: 'status',
      render: (status: string) => {
        if (status === StatusType.ACTIVE) {
          return translate('status.show');
          // eslint-disable-next-line no-else-return
        } else if (status === StatusType.ACTIVE_NOT_VERIFIED) {
          return translate('status.ACTIVE_NOT_VERIFIED');
        } else {
          return translate('status.hidden');
        }
      },
    },
    {
      title: <BarsOutlined />,
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
    },
  ];

  return (
    <>
      <FilterHeaderCustomer
        onSearch={(value: string) => run(value)}
        onChangeType={onChangeType}
        onChangeStatus={onChangeStatus}
      />
      <Row>
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).delete ? (
          <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
            {customerIdSelect && customerIdSelect.length > 0 && (
              <Popconfirm
                placement="top"
                title="Bạn có chắc muốn xóa"
                onConfirm={() => onDeleteAll()}
                okText={translate('action.yes')}
                cancelText={translate('action.no')}
              >
                <img className="img-style" src={images.ic_delete} alt="" />
                <span style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}>
                  Xóa nhiều
                </span>
              </Popconfirm>
            )}
            <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
              Đã chọn {customerIdSelect.length || 0} khách hàng
            </span>
          </div>
        ) : (
          ''
        )}
        <Col span={24}>
          <Table
            key={1}
            dataSource={listCustomer?.data || []}
            columns={renderColumns}
            pagination={false}
            scroll={{ x: 'auto' }}
            rowKey="phone"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            loading={loading}
          ></Table>
        </Col>
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={listCustomer?.total}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomerPage;
