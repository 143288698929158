/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, Row } from 'antd';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import {
  MentionEmail1,
  MentionEmail10,
  MentionEmail11,
  MentionEmail2,
  MentionEmail3,
  MentionEmail4,
  MentionEmail5,
  MentionEmail6,
  MentionEmail7,
  MentionEmail8,
  MentionEmail9,
} from 'constants/MentionEmail';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// @ts-ignore
import ReactSrcDocIframe from 'react-srcdoc-iframe';
import { translate } from 'translate/language';
import './email.scss';
import useEmailService from './email.service';

function EmailDetailScreen(): JSX.Element {
  const [form] = Form.useForm();
  const history = useHistory();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const [content, setContent] = useState<string>('');
  const [contentContract, setContentContract] = useState<string>('');
  const [checkContract, setCheckContract] = useState<boolean>(true);
  const { onUpdateEmail, onDetailEmail, detailEmail } = useEmailService();

  useEffect(() => {
    onDetailEmail(params.id);
  }, [isEdit]);

  const [typeCode, setTypeCode] = useState<string>();

  useEffect(() => {
    if (isEdit && detailEmail) {
      form.setFieldsValue({
        name: detailEmail.name,
        code: detailEmail.code,
        contentEmail: detailEmail.contentEmail,
        contentContract: detailEmail.contentContract,
        title: detailEmail.title,
      });
      setContent(detailEmail.contentEmail || '');
      setContentContract(detailEmail.contentContract);

      if (detailEmail.code === '01') {
        setCheckContract(false);
      }
      setTypeCode(detailEmail.code);
    }
  }, [detailEmail]);

  // Submit form
  const onFinish = (value: any) => {
    value.emailTemplateId = params.id;
    onUpdateEmail(value);
  };

  // Go back
  const onGoBack = () => {
    history.goBack();
  };

  // Nội dung Email
  const handleChangeContentEmail = (event: any, editor: any) => {
    form.setFieldsValue({
      contentEmail: editor.getData(),
    });
  };

  // Nội dung hợp đồng
  const handleChangeContentContract = (event: any, editor: any) => {
    form.setFieldsValue({
      contentContract: editor.getData(),
    });
    setContentContract(editor.getData());
  };

  const printIframe = (id: string) => {
    const doc: any = document;

    const iframe = doc.frames ? doc.frames[1] : doc.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const MentionEmail = (code: any) => {
    switch (code) {
      case '01':
        return MentionEmail1;
      case '02':
        return MentionEmail2;
      case '03':
        return MentionEmail3;
      case '04':
        return MentionEmail4;
      case '05':
        return MentionEmail5;
      case '06':
        return MentionEmail6;
      case '07':
        return MentionEmail7;
      case '08':
        return MentionEmail8;
      case '09':
        return MentionEmail9;
      case '10':
        return MentionEmail10;
      case '11':
        return MentionEmail11;
      default:
        return [];
    }
  };

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          {/* Mã Email */}
          <Col span={20}>
            <Form.Item name="code" label="Mã Email" labelCol={{ span: 6 }} labelAlign="left" initialValue="01">
              <Input readOnly />
            </Form.Item>
          </Col>
          {/* Tên Email */}
          <Col span={20}>
            <Form.Item
              name="name"
              label={translate('email_template.name')}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: translate('email_template.name_required'),
                },
                {
                  whitespace: true,
                  min: 2,
                  max: 200,
                  message: translate('email_template.name_length'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* Tiêu đề gửi Email */}
          <Col span={20}>
            <Form.Item
              name="title"
              label={translate('email_template.title')}
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: translate('email_template.title_required'),
                },
                {
                  whitespace: true,
                  min: 2,
                  max: 200,
                  message: translate('email_template.title_length'),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          {/* Nội dung gửi Email */}
          {typeCode && (
            <Col span={20}>
              <Form.Item
                name="contentEmail"
                label={translate('email_template.content_email')}
                labelCol={{ span: 6 }}
                labelAlign="left"
              >
                <CKEEditor onChange={handleChangeContentEmail} data={content} MentionEmail={MentionEmail(typeCode)} />
              </Form.Item>
            </Col>
          )}

          {/* Nội dung gửi hợp đồng */}
          {checkContract && typeCode && (
            <Col span={20}>
              <Form.Item
                name="contentContract"
                label={translate('email_template.content_contract')}
                labelCol={{ span: 6 }}
                labelAlign="left"
              >
                <CKEEditor
                  onChange={handleChangeContentContract}
                  data={contentContract}
                  MentionEmail={MentionEmail(typeCode)}
                />
                <Button type="link" onClick={() => printIframe('contract-content-iframe')}>
                  {translate('action.preview')}
                </Button>
              </Form.Item>
              <div className={'content-iframe'}>
                <ReactSrcDocIframe
                  className={'content-iframe'}
                  id={'contract-content-iframe'}
                  srcDoc={`
                  <html>
                    <head><link rel="stylesheet" href="https://ckeditor.com/docs/ckeditor5/latest/snippets/features/page-break/snippet.css" type="text/css"></head>
                    <body class="ck-content">${contentContract}</body>
                  </html>`}
                  width="1"
                  height="1"
                />
                ;
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col span={20}>
            <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Col>
                <Form.Item>
                  <Button type="default" onClick={onGoBack}>
                    {translate('action.close')}
                  </Button>
                </Form.Item>
              </Col>
              {getCheckPermissionUser(PERMISSION_NAME.CONFIG_EMAILS_CONTRACTS).edit ? (
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {translate('action.update')}
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EmailDetailScreen;
