/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable dot-notation */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable consistent-return */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-shadow */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Col, DatePicker, Form, Image, Input, message, Row, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import Axios from 'axios';
import { StatusType } from 'constants/Status';
import { IS_PHONE_NUMBER, LENGTH_BEGIN } from 'constants/SubstringPhone';
import { getUrlImage } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import useListBank from 'hooks/Bank/useListBank';
import useCreateCustomer from 'hooks/Customer/useCreateCustomer';
import useDetailCustomer from 'hooks/Customer/useDetailCustomer';
import useReferrer from 'hooks/Customer/useReferrer';
import useUpdateCustomer from 'hooks/Customer/useUpdateCustomer';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import useIdentifyCheck from 'hooks/ValidateMatch/useIdentifyCheck';
import usePhoneCheck from 'hooks/ValidateMatch/usePhoneCheck';
import moment from 'moment';
import ApiHelper from 'network/ApiClient';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import ReferrerPage from './components/ReferrerPage';
import './css/index.scss';

const { Option } = Select;

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

export interface LocationMap {
  name?: string;
  address: string;
  lat: number;
  lng: number;
}

function FormCustommerPage(): JSX.Element {
  const [form] = Form.useForm();

  const [onGetDetailCustomer, detailCustomer, loadingDetail] = useDetailCustomer();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const [checkTypeAccount, getCheckTypeAccount] = useState<boolean>(true);

  // Lấy danh sách ngân hàng
  const [onFetchListBank, listBank, loadingBank] = useListBank();

  // Thêm mơi tài khoản khách hàng
  const [onCreateCustomer, loading] = useCreateCustomer();
  // Update tài khoản khách hàng
  const [onUpdateCustomer, loadingCustomer] = useUpdateCustomer();

  // Get danh sách số điện thoại
  const { onGetPhoneCode, listPhone, loadingPhone } = usePhoneCheck();
  // Get danh sách chứng minh nhân dân
  const { onGetIdentifyCode, listIdentify, loadingIdentify } = useIdentifyCheck();

  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_CUSTOMER);
  const [onUpload, resUpload, messageError] = useUploadMulti();

  const flag = useRef('');
  // Hiển thị hình ảnh + url hình ảnh
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);

  // Hình ảnh đại diện
  const [ImageAvatar, setImageAvatar] = useState<Media[]>([]);
  // Hình ảnh CMT mặt trước
  const [ImageIdentifyLicenseFront, setImageIdentifyLicenseFront] = useState<Media[]>([]);
  // Hình ảnh CMT mặt sau
  const [ImageIdentifyLicenseBack, setImageIdentifyLicenseBack] = useState<Media[]>([]);
  // Hình ảnh chứng nhận tư pháp
  const [ImagePolicyLicenseFront, setImagePolicyLicenseFront] = useState<Media[]>([]);
  const [ImagePolicyLicenseBack, setImagePolicyLicenseBack] = useState<Media[]>([]);
  // Hình ảnh giấy phép kinh doanh
  const [ImageBusinessLicenseFront, setImageBusinessLicenseFront] = useState<Media[]>([]);
  const [ImageBusinessLicenseBack, setImageBusinessLicenseBack] = useState<Media[]>([]);

  const onChangeFiles = (fileList: any, type: string) => {
    let image: Media[] = [];
    if (type === 'avatar') {
      image = ImageAvatar;
    } else if (type === 'identifyLicenseFront') {
      image = ImageIdentifyLicenseFront;
    } else if (type === 'identifyLicenseBack') {
      image = ImageIdentifyLicenseBack;
    } else if (type === 'policyLicenseFront') {
      image = ImagePolicyLicenseFront;
    } else if (type === 'policyLicenseBack') {
      image = ImagePolicyLicenseBack;
    } else if (type === 'businessLicenseFront') {
      image = ImageBusinessLicenseFront;
    } else if (type === 'businessLicenseBack') {
      image = ImageBusinessLicenseBack;
    }

    const newData: any = [...image, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh vượt quá dung lượng <= 5MB`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    if (type === 'avatar') {
      setImageAvatar(newData);
      flag.current = 'avatar';
    } else if (type === 'identifyLicenseFront') {
      setImageIdentifyLicenseFront(newData);
      flag.current = 'identifyLicenseFront';
    } else if (type === 'identifyLicenseBack') {
      setImageIdentifyLicenseBack(newData);
      flag.current = 'identifyLicenseBack';
    } else if (type === 'policyLicenseFront') {
      // Tư pháp
      setImagePolicyLicenseFront(newData);
      flag.current = 'policyLicenseFront';
    } else if (type === 'policyLicenseBack') {
      setImagePolicyLicenseBack(newData);
      flag.current = 'policyLicenseBack';
    } else if (type === 'businessLicenseFront') {
      setImageBusinessLicenseFront(newData);
      flag.current = 'businessLicenseFront';
    } else if (type === 'businessLicenseBack') {
      setImageBusinessLicenseBack(newData);
      flag.current = 'businessLicenseBack';
    }
    onUpload(data);
  };

  // Xóa ảnh Avatar
  const onRemoveFiles = (item: Media | any, type: string) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => {
        if (type === 'avatar') {
          const newArrImg = ImageAvatar.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImageAvatar(newArrImg);
        } else if (type === 'identifyLicenseFront') {
          const newArrImg = ImageIdentifyLicenseFront.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImageIdentifyLicenseFront(newArrImg);
        } else if (type === 'identifyLicenseBack') {
          const newArrImg = ImageIdentifyLicenseBack.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImageIdentifyLicenseBack(newArrImg);
        } else if (type === 'policyLicenseFront') {
          const newArrImg = ImagePolicyLicenseFront.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImagePolicyLicenseFront(newArrImg);
        } else if (type === 'policyLicenseBack') {
          const newArrImg = ImagePolicyLicenseBack.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImagePolicyLicenseBack(newArrImg);
        } else if (type === 'businessLicenseFront') {
          const newArrImg = ImageBusinessLicenseFront.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImageBusinessLicenseFront(newArrImg);
        } else if (type === 'businessLicenseBack') {
          const newArrImg = ImageBusinessLicenseBack.filter(data => {
            return (data as Media)._id !== (item as Media)._id;
          });
          setImageBusinessLicenseBack(newArrImg);
        }

        message.success('Xoá ảnh thành công');
      },
    });
  };

  // Xem chi tiết hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal hiển thị
  const onCancelImage = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      if (flag.current === 'avatar') {
        // Ảnh avatar
        const newfileList = [...ImageAvatar].filter(e => e._id);
        setImageAvatar([...newfileList, ...resUpload]);
      } else if (flag.current === 'identifyLicenseFront') {
        // Ảnh CMT
        const newfileList = [...ImageIdentifyLicenseFront].filter(e => e._id);
        setImageIdentifyLicenseFront([...newfileList, ...resUpload]);
      } else if (flag.current === 'identifyLicenseBack') {
        // Ảnh CMT
        const newfileList = [...ImageIdentifyLicenseBack].filter(e => e._id);
        setImageIdentifyLicenseBack([...newfileList, ...resUpload]);
      } else if (flag.current === 'policyLicenseFront') {
        // Giấy chứng nhận tư pháp
        const newfileList = [...ImagePolicyLicenseFront].filter(e => e._id);
        setImagePolicyLicenseFront([...newfileList, ...resUpload]);
      } else if (flag.current === 'policyLicenseBack') {
        // Giấy chứng nhận tư pháp
        const newfileList = [...ImagePolicyLicenseBack].filter(e => e._id);
        setImagePolicyLicenseBack([...newfileList, ...resUpload]);
      } else if (flag.current === 'businessLicenseFront') {
        // Giấy phép kinh doanh
        const newfileList = [...ImageBusinessLicenseFront].filter(e => e._id);
        setImageBusinessLicenseFront([...newfileList, ...resUpload]);
      } else if (flag.current === 'businessLicenseBack') {
        // Giấy phép kinh doanh
        const newfileList = [...ImageBusinessLicenseBack].filter(e => e._id);
        setImageBusinessLicenseBack([...newfileList, ...resUpload]);
      }
    }
  }, [resUpload]);

  useEffect(() => {
    onFetchListBank({
      status: StatusType.ACTIVE,
    });
    onGetPhoneCode();
    onGetIdentifyCode();
  }, []);

  const [valueMap, setValueMap] = useState<string | undefined>(undefined);
  const [cityName, setCityName] = useState<string | undefined>(undefined);
  const [data, setData] = useState<any[]>([]);

  let timeout: any;
  let currentValue: any;

  const handSubmit = (e: any) => {
    e.preventDefault();
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: StatusType.ACTIVE_NOT_VERIFIED });
    if (!form.getFieldValue('gender')) form.setFieldsValue({ gender: 'FEMALE' });
    if (!form.getFieldValue('customer_type')) form.setFieldsValue({ customer_type: 'PRIVATE' });

    form.validateFields().then(values => {
      let re = /^([^<>()\[\]\\.,;:\s@"]+((?:\.[a-zA-Z0-9_]+)*))@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,3}$/;
      let check = re.test(values.email.toString());
      if (!check) {
        return message.error('Địa chỉ Email không đúng định dạng');
      }

      let data = {
        name: values?.full_name,
        email: values.email,
        phone: values.phone,
        agentId: values.agentId ? values.agentId : '',
        bankId: values?.bank_name,
        taxCode: values?.tax_code,
        address: valueMap || '',
        cityName: cityName || '',
        avatarId: ImageAvatar && ImageAvatar.length > 0 ? ImageAvatar[0]._id : null,
        identify: values.number_cmnd,
        password: values.password
          ? values.password
          : isEdit && detailCustomer
          ? detailCustomer.password
          : values.password,
        position: values?.position,
        dateOfBirth: moment(values?.birthday).format('YYYY/MM/DD'),
        identifyLicenseDate: moment(values?.date_create).format('YYYY/MM/DD'),
        gender: values.gender,
        bankBranchName: values?.branch_name,
        status: values.status,
        bankAccountNum: values?.number_bank,
        businessName: values?.businessName,
        dateOfIncorporation: moment(values?.dateOfIncorporation).format('YYYY/MM/DD'), // Ngày thành lập công ty
        identifyLicensePlace: values?.address_by,
        customerType: values?.customer_type,
        // Ảnh chứng nhận tư pháp
        policyLicenseFront:
          ImagePolicyLicenseFront && ImagePolicyLicenseFront.length > 0 ? ImagePolicyLicenseFront[0]._id : null,
        policyLicenseBack:
          ImagePolicyLicenseBack && ImagePolicyLicenseBack.length > 0 ? ImagePolicyLicenseBack[0]._id : null,
        // Ảnh giấy phép kinh doanh
        businessLicenseFront:
          ImageBusinessLicenseFront && ImageBusinessLicenseFront.length > 0 ? ImageBusinessLicenseFront[0]._id : null,
        businessLicenseBack:
          ImageBusinessLicenseBack && ImageBusinessLicenseBack.length > 0 ? ImageBusinessLicenseBack[0]._id : null,
        // Ảnh chứng minh thư
        identifyLicenseFront:
          ImageIdentifyLicenseFront && ImageIdentifyLicenseFront.length > 0 ? ImageIdentifyLicenseFront[0]._id : null,
        identifyLicenseBack:
          ImageIdentifyLicenseBack && ImageIdentifyLicenseBack.length > 0 ? ImageIdentifyLicenseBack[0]._id : null,
        referralCode: values.code_introduce ? values.code_introduce : '',
      };
      if (isEdit) {
        LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_CUSTOMER);
        return onUpdateCustomer(params.id, data);
      } else {
        LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_CUSTOMER);
        onCreateCustomer(data);
      }
    });
  };

  const dateFormatBirthday = ['DD/MM/YYYY'];

  // Validate dữ liệu
  const validateForm = {
    full_name: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('customer.full_name'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('customer.full_name_invalid'),
      },
    ],
    tax_code: [
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 5,
        message: translate('customer.tax_code_by_invalid'),
      },
      {
        pattern: /^\d+$/,
        message: translate('messages.integer_field', {
          label: translate('customer.tax_code'),
        }),
      },
    ],
    number_bank: [
      {
        pattern: /^\d+$/,
        message: translate('messages.integer_field', {
          label: translate('customer.number_bank'),
        }),
      },
    ],
    code_introduce: [
      {
        pattern: /^\d+$/,
        message: translate('messages.integer_field', {
          label: translate('customer.code_introduce'),
        }),
      },
    ],
    businessName: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('customer.businessName'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('customer.businessName_invalid'),
      },
    ],
    address_by: [
      {
        transform: (value: string) => {
          return value?.trim();
        },
        max: 200,
        message: translate('customer.address_by_invalid'),
      },
    ],
    position: [
      {
        transform: (value: string) => {
          return value?.trim();
        },
        max: 200,
        message: translate('customer.position_by_invalid'),
      },
    ],
    branch_name: [
      {
        transform: (value: string) => {
          return value?.trim();
        },
        max: 500,
        message: translate('customer.branch_name_invalid'),
      },
    ],
    password: isEdit
      ? [
          {
            transform: (value: string) => {
              return value?.trim();
            },
            min: 6,
            message: translate('customer.password_invalid'),
          },
        ]
      : [
          {
            whitespace: true,
            required: true,
            message: translate('require_field', {
              label: translate('customer.password'),
            }),
          },
          {
            transform: (value: string) => {
              return value?.trim();
            },
            min: 6,
            message: translate('customer.password_invalid'),
          },
        ],
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_CUSTOMER);
    history.back();
  };

  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      onGetDetailCustomer(params.id);
    } else {
      form.setFieldsValue({
        full_name: cacheForm.full_name,
        email: cacheForm.email,
        phone: cacheForm.phone,
        agentId: cacheForm.agentId,
        bank_name: cacheForm.bank_name,
        tax_code: cacheForm.tax_code,
        number_cmnd: cacheForm.number_cmnd,
        position: cacheForm.position,
        // birthday: moment(cacheForm.birthday),
        date_create: moment(cacheForm.date_create),
        gender: cacheForm.gender,
        branch_name: cacheForm.branch_name,
        status: cacheForm.status,
        number_bank: cacheForm.number_bank,
        businessName: cacheForm.businessName,
        // dateOfIncorporation: moment(cacheForm.dateOfIncorporation),
        address_by: cacheForm.address_by,
        // customer_type: cacheForm.customer_type,
        code_introduce: cacheForm.code_introduce,
      });
    }
  }, []);

  const [phoneRef, setPhoneRef] = useState();
  const [addressM, setAddressM] = useState<string>('');

  // Set gía trị vào Form
  useEffect(() => {
    if (isEdit && detailCustomer) {
      // Todo Check type
      form.setFieldsValue({
        full_name: detailCustomer.name,
        email: detailCustomer.email,
        phone: detailCustomer.phone,
        agentId: detailCustomer.agentId,
        bank_name: detailCustomer?.bankId?._id,
        tax_code: detailCustomer.taxCode,
        address: detailCustomer.address,
        cityName: detailCustomer.cityName,
        number_cmnd: detailCustomer.identify,
        position: detailCustomer.position,
        birthday: moment(detailCustomer.dateOfBirth),
        date_create: moment(detailCustomer.identifyLicenseDate),
        gender: detailCustomer.gender,
        branch_name: detailCustomer.bankBranchName,
        status: detailCustomer.status,
        number_bank: detailCustomer.bankAccountNum,
        businessName: detailCustomer.businessName,
        dateOfIncorporation: detailCustomer.dateOfIncorporation
          ? moment(detailCustomer.dateOfIncorporation)
          : undefined,
        address_by: detailCustomer.identifyLicensePlace,
        customer_type: detailCustomer.customerType,
        code_introduce: detailCustomer.referralCode,
      });
      setAddressM(detailCustomer.address);

      // set địa chỉ
      setValueMap(detailCustomer.address);

      setCityName(detailCustomer.cityName);

      if (detailCustomer.customerType === 'PRIVATE') {
        getCheckTypeAccount(true);
      } else {
        getCheckTypeAccount(false);
      }
      // Set dữ liệu phone
      setPhoneRef(detailCustomer.referralCode);

      // Hình ảnh đại diện
      if (detailCustomer.avatarId) {
        setImageAvatar([detailCustomer.avatar]);
      }
      // Hình ảnh CMT mặt trước
      if (detailCustomer.identifyLicenseFront) {
        setImageIdentifyLicenseFront([detailCustomer.identifyLicenseFront]);
      }
      // Hình ảnh CMT mặt sau
      if (detailCustomer.identifyLicenseBack) {
        setImageIdentifyLicenseBack([detailCustomer.identifyLicenseBack]);
      }
      // Hình ảnh chứng nhận tư pháp
      if (detailCustomer.policyLicenseFront) {
        setImagePolicyLicenseFront([detailCustomer.policyLicenseFront]);
      }
      if (detailCustomer.policyLicenseBack) {
        setImagePolicyLicenseBack([detailCustomer.policyLicenseBack]);
      }
      // Hình ảnh giấy phép kinh doanh
      if (detailCustomer.businessLicenseFront) {
        setImageBusinessLicenseFront([detailCustomer.businessLicenseFront]);
      }
      // Hình ảnh giấy phép kinh doanh
      if (detailCustomer.businessLicenseBack) {
        setImageBusinessLicenseBack([detailCustomer.businessLicenseBack]);
      }
    }
  }, [detailCustomer]);

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_CUSTOMER, form.getFieldsValue());
  };

  const handleChangeTypeAccount = (value: any) => {
    if (value === 'COMPANY') {
      getCheckTypeAccount(false);
    } else {
      getCheckTypeAccount(true);
    }
  };
  const [useGetReferrer, resReferrer, loadingRef, messageReferrer] = useReferrer();
  useEffect(() => {
    if (isEdit && detailCustomer) {
      useGetReferrer({
        referralCode: detailCustomer?.referralCode,
      });
    }
  }, [detailCustomer]);

  const fetchData = (value: any, callback: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    function fetchPlaces() {
      ApiHelper.fetch('project-news/googlePlace', {
        search: value,
      })
        .then((response: any) => {
          return response.data?.googleData;
        })
        .then(predictions => {
          if (predictions && currentValue === value) {
            if (predictions.length > 0) {
              const dataSelect = predictions.map((data: any) => {
                return {
                  value: data.address,
                  text: data.address,
                  cityName: data.city,
                };
              });
              callback(dataSelect);
            } else {
              callback([]);
            }
          }
        });
    }

    timeout = setTimeout(fetchPlaces, 500);
  };

  const handleSearchMap = (value: any) => {
    if (value) {
      fetchData(value, (data: any) => {
        setData(data);
      });
    } else {
      setData([]);
    }
  };

  const handleChangeMap = (value: any, option: any) => {
    setCityName(option.cityName || undefined);
    setValueMap(value);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).read ? (
        <>
          <Modal title="" visible={isModalVisible} onCancel={onCancelImage} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
          <Form form={form} onFieldsChange={updateCacheForm}>
            <Row>
              <Col span={20} offset={3}>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="customer_type"
                      label={translate('admin.account_role')}
                      labelCol={{ sm: 7 }}
                      labelAlign="left"
                    >
                      <Select
                        placeholder={translate('customer.type_account')}
                        defaultValue="PRIVATE"
                        onChange={handleChangeTypeAccount}
                      >
                        <Option value="PRIVATE">{translate('customer.account_user')}</Option>
                        <Option value="COMPANY">{translate('customer.account_company')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20} style={{ display: 'flex', alignItems: 'center' }}>
                  <Col span={4} offset={2}>
                    <Row>
                      <Col span={12}>
                        <UploadButtonImages
                          accept={'.gif, .jpg, .png, .webp'}
                          files={ImageAvatar}
                          onPreview={handlePreview}
                          onChange={(fileList: any) => onChangeFiles(fileList, 'avatar')}
                          onRemove={(item: any) => onRemoveFiles(item, 'avatar')}
                          imageLength={1}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={7} style={{ paddingLeft: '0px' }}>
                    <div style={{ textAlign: 'center' }}>Agent ID</div>
                    <Form.Item
                      name="agentId"
                      rules={[
                        {
                          transform: (value: string) => {
                            return value?.trim();
                          },
                          min: 10,
                          max: 10,
                          message: 'Agent ID 10 chữ số',
                        },
                        {
                          pattern: /^\d+$/,
                          message: 'Agent ID không đúng định dạng',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <div style={{ textAlign: 'center' }}>{translate('status.name')}</div>
                    <div>
                      <Form.Item name="status">
                        <Select placeholder={translate('status.name')} defaultValue={StatusType.ACTIVE_NOT_VERIFIED}>
                          <Option value={StatusType.ACTIVE_NOT_VERIFIED}>
                            {translate('status.ACTIVE_NOT_VERIFIED')}
                          </Option>
                          <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                          <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                {!checkTypeAccount && (
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="businessName"
                        label={translate('customer.businessName')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={validateForm.businessName}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                {!checkTypeAccount && (
                  <Row style={{ marginBottom: '25px' }}>
                    <Col span={20}>
                      <Row>
                        <Col span={7}>{translate('customer.address')}</Col>
                        <Col span={17}>
                          <Select
                            showSearch
                            value={valueMap}
                            placeholder="Tìm kiếm chọn địa chỉ"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearchMap}
                            onChange={handleChangeMap}
                            notFoundContent={null}
                            style={{ width: '100%' }}
                          >
                            {data &&
                              data.length > 0 &&
                              data.map((d: any, index: number) => {
                                return (
                                  <Option key={index + 1} value={d.value} cityName={d.cityName}>
                                    {d.text}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                {!checkTypeAccount && (
                  <Row>
                    <Col span={20}>
                      <div>
                        <Form.Item
                          name="tax_code"
                          label={translate('customer.tax_code')}
                          labelAlign="left"
                          labelCol={{ span: 7 }}
                          rules={validateForm.tax_code}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                )}
                {!checkTypeAccount && (
                  <Row>
                    <Col span={20}>
                      <div>
                        <Form.Item
                          name="dateOfIncorporation"
                          label={translate('customer.dateOfIncorporation')}
                          labelAlign="left"
                          labelCol={{ span: 7 }}
                          rules={[
                            {
                              required: true,
                              message: translate('require_field', {
                                label: translate('customer.dateOfIncorporation'),
                              }),
                            },
                          ]}
                        >
                          <DatePicker
                            placeholder={translate('customer.dateOfIncorporation')}
                            style={{ width: '100%' }}
                            format={dateFormatBirthday}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="full_name"
                      label={translate('customer.full_name')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={validateForm.full_name}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                {!checkTypeAccount && (
                  <Row>
                    <Col span={20}>
                      <Form.Item
                        name="position"
                        label={translate('customer.position')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={validateForm.position}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="email"
                        label={translate('customer.email')}
                        labelCol={{ span: 7 }}
                        labelAlign="left"
                        rules={[
                          {
                            type: 'email',
                            message: translate('customer.email_invalid'),
                          },
                          {
                            whitespace: true,
                            required: true,
                            message: translate('require_field', {
                              label: translate('customer.email'),
                            }),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="phone"
                        label={translate('customer.phone')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('customer.phone'),
                            }),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (typeof value !== 'undefined' && value != null) {
                                // Kiểm tra xem giá trị truyền vào có phải là số không
                                if (!value.match(/^-?\d+$/)) {
                                  if (value.length > LENGTH_BEGIN.ZERO) {
                                    return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                  }
                                }
                                // Nếu có giá trị kiểm tra có phải là số điện thoại không
                                if (value.length > LENGTH_BEGIN.ZERO) {
                                  // Kiểm tra đầu có có đúng định dạng với dữ liệu có sẵn không
                                  if (listPhone && listPhone.some(data => value.startsWith(data['code']))) {
                                    // Trường hợp đúng với dữ liệu => Check số điện thoại có đúng định dạng không
                                    if (value.length === IS_PHONE_NUMBER) {
                                      return Promise.resolve();
                                    }
                                    // Số điện thoại không đúng định dạng => Lỗi
                                    return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                  } else {
                                    // Đầu số không đúng dữ liệu => Lỗi
                                    return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                  }
                                }
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="birthday"
                        label={translate('customer.birthday')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('customer.birthday'),
                            }),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (typeof value !== 'undefined' && value != null) {
                                const getYearBirthday: number = moment(getFieldValue('birthday')).year();
                                const getYearNow: number = moment().year();
                                const age = 20;
                                if (getYearNow - getYearBirthday >= age) {
                                  return Promise.resolve();
                                } else {
                                  return Promise.reject(`${translate('admin.birthday_invalid')}`);
                                }
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <DatePicker placeholder={'Ngày sinh'} style={{ width: '100%' }} format={dateFormatBirthday} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="gender"
                      label={translate('customer.gender')}
                      labelAlign="left"
                      labelCol={{ span: 7 }}
                    >
                      <Select placeholder={translate('customer.gender')} defaultValue="FEMALE">
                        <Option value="MALE">{translate('customer.male')}</Option>
                        <Option value="FEMALE">{translate('customer.female')}</Option>
                        <Option value="OTHER">{translate('customer.other')}</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {checkTypeAccount && (
                  <Row style={{ marginBottom: '25px' }}>
                    <Col span={20}>
                      <Row>
                        <Col span={7}>{translate('customer.address')}</Col>
                        <Col span={17}>
                          <Select
                            showSearch
                            value={valueMap}
                            placeholder="Tìm kiếm chọn địa chỉ"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearchMap}
                            onChange={handleChangeMap}
                            notFoundContent={null}
                            style={{ width: '100%' }}
                          >
                            {data &&
                              data.length > 0 &&
                              data.map((d: any, index: number) => {
                                return (
                                  <Option key={index + 1} value={d.value} cityName={d.cityName}>
                                    {d.text}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="number_cmnd"
                        label={translate('customer.number_cmnd')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            required: true,
                            message: translate('require_field', {
                              label: translate('customer.number_cmnd'),
                            }),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (typeof value !== 'undefined' && value != null) {
                                // Kiểm tra xem giá trị truyền vào có phải là số không
                                if (!('' + value).match(/^-?\d+$/)) {
                                  if (value.length > LENGTH_BEGIN.ZERO) {
                                    return Promise.reject(`${translate('customer.number_cmnd_require_validate')}`);
                                  }
                                }
                                // Nếu có giá trị kiểm tra có phải là số chứng minh thư không
                                if (('' + value).length > LENGTH_BEGIN.ZERO) {
                                  // Kiểm tra đầu có có đúng định dạng với dữ liệu có sẵn không
                                  if (
                                    (listIdentify &&
                                      listIdentify.some(data => ('' + value).startsWith(data['cccdCode']))) ||
                                    (listIdentify &&
                                      listIdentify.some(data => ('' + value).startsWith(data['cmndCode'])))
                                  ) {
                                    if (
                                      ('' + value).length === LENGTH_BEGIN.CCCD_CODE ||
                                      ('' + value).length === LENGTH_BEGIN.CMND_CODE
                                    ) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(`${translate('customer.number_cmnd_require_validate')}`);
                                  } else {
                                    return Promise.reject(`${translate('customer.number_cmnd_require_validate')}`);
                                  }
                                }
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input type="text" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="date_create"
                        label={translate('customer.date_create')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                      >
                        <DatePicker placeholder={'Ngày cấp'} style={{ width: '100%' }} format={dateFormatBirthday} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="address_by"
                        label={translate('customer.address_by')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={validateForm.address_by}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                {/* Ảnh CMND  */}
                <Row>
                  <Col span={6}>Ảnh CMND</Col>
                  <Col span={2}>
                    <UploadButtonImages
                      accept={'.gif, .jpg, .png, .webp'}
                      files={ImageIdentifyLicenseFront}
                      onPreview={handlePreview}
                      onChange={(fileList: any) => onChangeFiles(fileList, 'identifyLicenseFront')}
                      onRemove={(item: any) => onRemoveFiles(item, 'identifyLicenseFront')}
                      imageLength={1}
                    />
                  </Col>
                  <Col span={2}>
                    <UploadButtonImages
                      accept={'.gif, .jpg, .png, .webp'}
                      files={ImageIdentifyLicenseBack}
                      onPreview={handlePreview}
                      onChange={(fileList: any) => onChangeFiles(fileList, 'identifyLicenseBack')}
                      onRemove={(item: any) => onRemoveFiles(item, 'identifyLicenseBack')}
                      imageLength={1}
                    />
                  </Col>
                </Row>

                {/* Giấy chứng nhận tư pháp */}
                {checkTypeAccount && (
                  <Row>
                    <Col span={6}>Giấy chứng nhận tư pháp</Col>
                    <Col span={2}>
                      <UploadButtonImages
                        accept={'.gif, .jpg, .png, .webp'}
                        files={ImagePolicyLicenseFront}
                        onPreview={handlePreview}
                        onChange={(fileList: any) => onChangeFiles(fileList, 'policyLicenseFront')}
                        onRemove={(item: any) => onRemoveFiles(item, 'policyLicenseFront')}
                        imageLength={1}
                      />
                    </Col>
                    <Col span={2}>
                      <UploadButtonImages
                        accept={'.gif, .jpg, .png, .webp'}
                        files={ImagePolicyLicenseBack}
                        onPreview={handlePreview}
                        onChange={(fileList: any) => onChangeFiles(fileList, 'policyLicenseBack')}
                        onRemove={(item: any) => onRemoveFiles(item, 'policyLicenseBack')}
                        imageLength={1}
                      />
                    </Col>
                  </Row>
                )}

                {/* Giấy phép kinh doanh */}
                {!checkTypeAccount && (
                  <Row>
                    <Col span={6}>Giấy phép kinh doanh</Col>
                    <Col span={2}>
                      <UploadButtonImages
                        accept={'.gif, .jpg, .png, .webp'}
                        files={ImageBusinessLicenseFront}
                        onPreview={handlePreview}
                        onChange={(fileList: any) => onChangeFiles(fileList, 'businessLicenseFront')}
                        onRemove={(item: any) => onRemoveFiles(item, 'businessLicenseFront')}
                        imageLength={1}
                      />
                    </Col>
                    <Col span={2}>
                      <UploadButtonImages
                        accept={'.gif, .jpg, .png, .webp'}
                        files={ImageBusinessLicenseBack}
                        onPreview={handlePreview}
                        onChange={(fileList: any) => onChangeFiles(fileList, 'businessLicenseBack')}
                        onRemove={(item: any) => onRemoveFiles(item, 'businessLicenseBack')}
                        imageLength={1}
                      />
                    </Col>
                  </Row>
                )}
                {/* Tên ngân hàng */}
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="bank_name"
                        label={translate('customer.bank_name')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={[
                          {
                            whitespace: true,
                            required: false,
                            message: translate('require_field', {
                              label: translate('customer.bank_name'),
                            }),
                          },
                        ]}
                      >
                        <Select
                          placeholder={translate('customer.bank_name')}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input: string, option: any | undefined) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {listBank && listBank.data
                            ? listBank.data.map((data: any, key: any) => (
                                <Option value={data._id} key={data._id}>
                                  {data.name}
                                </Option>
                              ))
                            : ''}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="branch_name"
                        label={translate('customer.branch_name')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={validateForm.branch_name}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="number_bank"
                        label={translate('customer.number_bank')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={validateForm.number_bank}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                {checkTypeAccount && (
                  <Row>
                    <Col span={20}>
                      <div>
                        <Form.Item
                          name="tax_code"
                          label={translate('customer.tax_code')}
                          labelAlign="left"
                          labelCol={{ span: 7 }}
                          rules={validateForm.tax_code}
                        >
                          <Input />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={20}>
                    <div>
                      <Form.Item
                        name="password"
                        label={translate('customer.password')}
                        labelAlign="left"
                        labelCol={{ span: 7 }}
                        rules={validateForm.password}
                      >
                        <Input.Password style={{ padding: '0px 12px' }} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div className="borderFooter"></div>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <div className="people">{translate('customer.people')}</div>
                  </Col>
                </Row>
                <ReferrerPage checkReferrer={isEdit} phoneReferrer={phoneRef} />

                <Row gutter={10}>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={closeButton}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).create ||
                      getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).edit ? (
                        <Col>
                          <Form.Item>
                            <Button type="primary" htmlType="submit" onClick={handSubmit}>
                              {translate('action.save')}
                            </Button>
                          </Form.Item>
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormCustommerPage;
