/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Media } from 'types/response/user-type';

/* eslint-disable prefer-template */
export interface MediaType {
  createdAt: string;
  deletedAt?: string;
  thumbnail: string;
  thumbnail2x?: string;
  type: string;
  url: string;
  _id: string;
  origin?: string;
}

export const getUrlImage = (image: any) => {
  return process.env.REACT_APP_API_DOMAIN + '/' + image?.thumbnail;
};
export const getUrlImageChat = (image: any) => {
  return process.env.REACT_APP_API_DOMAIN + '/' + image?.url;
};
export const getUrlExport = (path: string) => {
  return process.env.REACT_APP_API_DOMAIN + '/' + path;
};
export const getUrlImageCk = (image: MediaType) => {
  return process.env.REACT_APP_API_DOMAIN + '/' + image?.origin;
};
