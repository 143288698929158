/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { ValueReq } from './point-types';
import usePointService from './point.service';

function PointDetailScreen(): JSX.Element {
  const params: { id: string } = useParams();
  const isEdit = params && params.id;

  const [form] = Form.useForm();
  const history = useHistory();
  const { onCreatePoint, onDetailPoint, detailPoint, onUpdatePoint } = usePointService();

  const closeButton = () => {
    history.goBack();
  };

  useEffect(() => {
    if (isEdit) {
      onDetailPoint(params.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (detailPoint) {
      console.log(detailPoint, '12');
      form.setFieldsValue({
        name: detailPoint.name,
        description: detailPoint.description,
        pointActive: detailPoint.pointActive,
        price: detailPoint.price,
        status: detailPoint.status,
      });
    }
  }, [detailPoint]);

  // Submit form
  const onFinish = (values: ValueReq) => {
    if (isEdit) {
      values.pointPackageId = params.id;
      return onUpdatePoint(values);
    }
    onCreatePoint(values);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).create ||
      getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).edit ? (
        <Form form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={20} offset={3}>
              <Row>
                {/* Tên gói điểm */}
                <Col span={20}>
                  <Form.Item
                    name="name"
                    label="Tên gói điểm"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      { required: true, message: 'Tên gói điểm không được để trống' },
                      {
                        whitespace: true,
                        min: 2,
                        max: 200,
                        message: 'Tên gói điểm bao gồm 2- 200 ký tự',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                {/* Mô tả */}
                <Col span={20}>
                  <Form.Item
                    name="description"
                    label="Mô tả"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      { required: true, message: 'Mô tả không được để trống' },
                      {
                        whitespace: true,
                        min: 2,
                        max: 200,
                        message: 'Mô tả bao gồm 2- 200 ký tự',
                      },
                    ]}
                  >
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>
                {/* Giá tiền */}
                <Col span={20}>
                  <Form.Item
                    name="price"
                    label="Giá tiền"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Giá tiền không được trống',
                      },
                    ]}
                    initialValue={1}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      parser={(value: any) => value.replace(/(\.*)/g, '')}
                      step={1000}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                {/* Số điểm nhận */}
                <Col span={20}>
                  <Form.Item
                    name="pointActive"
                    label="Số điểm nhận"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Số điểm nhận không được trống',
                      },
                    ]}
                    initialValue={0}
                  >
                    <InputNumber style={{ width: '100%' }} step={1} min={0} />
                  </Form.Item>
                </Col>
                {/* Trạng thái */}
                <Col span={20}>
                  <Form.Item
                    name="status"
                    label="Trạng thái"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    initialValue={STATUS_TYPE.ACTIVE}
                  >
                    <Select>
                      <Select.Option value={STATUS_TYPE.ACTIVE}>{translate('BaseStatusProject.ACTIVE')}</Select.Option>
                      <Select.Option value={STATUS_TYPE.INACTIVE}>
                        {translate('BaseStatusProject.INACTIVE')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {isEdit ? translate('action.update') : translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default PointDetailScreen;
