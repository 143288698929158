/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* 
  Created by no name at 01-06-2021 11:33:40
  Màn hình quản lí bán cho thuê
*/

import { useDebounceFn } from 'ahooks';
import _ from 'lodash';
import { Col, Pagination, Popconfirm, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import images from 'images';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHeaderSaleAndRental from './components/FilterHeaderSaleAndRental';
import { nameProjectNewStatus, ReqListsalesandRent, SaleAndRent } from './modelSaleAndRental/SaleAndRental.type';
import useDeleteSaleAndRental from './useSaleAndRentalhooks/useDeleteSaleAndRental';
import useLitsSaleAndRental from './useSaleAndRentalhooks/useSaleAndRentalhooks';

const { Text, Link } = Typography;

function SaleAndRentalpage() {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [filter, setFilter] = useState<ReqListsalesandRent>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
    newsConfigType: undefined,
  });
  const { onSaleAndRental, listSaleAndRental, total, loading } = useLitsSaleAndRental();
  const { onDeleteProjectNew, loadingDel, deleteArray } = useDeleteSaleAndRental();

  useEffect(() => {
    onSaleAndRental(filter);
  }, [filter]);

  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search });
    },
    {
      wait: 500,
    },
  );

  const onDelete = (_id: string) => () => {
    deleteArray([_id]);
    setFilter({ ...filter, page: 1 });
    // onDeleteProjectNew(_id);
  };

  const onChangeStatus = (status: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status });
  };
  const onChangeStatusTypeBDS = (status: string | '', key: any) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, type: status });
  };
  const onChangeTypeEstate = (status: string | '', key: any) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, realEstate: status });
  };

  // Action phân trang
  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  const onChangeCreate = (data: string) => {
    setFilter({ ...filter, createdType: data, page: 1 });
  };

  const onChangeConfigType = (data: string) => {
    setFilter({ ...filter, newsConfigType: data, page: 1 });
  };

  const onDateRanger = (dateRanger: [string, string]) => {
    // const dateRange: any = {};
    // dateRange.startDate = moment(dateRanger[0]).toISOString();
    // dateRange.endDate = moment(dateRanger[1]).toISOString();
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({
      ...filter,
      startDate: moment(dateRanger[0]).toISOString(),
      endDate: moment(dateRanger[1]).toISOString(),
    });
  };

  const renderAction = (_id: string, item: any) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.SALES_AND_RENTAL_EDIT + _id);
            }}
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).delete && item.transactions.length === 0 ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const onCheckImport = (data: string) => {
    if (data === 'success') {
      setFilter({ ...filter });
    }
  };

  // Render Column Table
  const renderColumns = [
    {
      title: translate('real_estate.stt'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
      fixed: 'left' as any,
      width: '2%',
    },
    {
      title: translate('real_estate.code'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{item.code}</span>;
      },
      fixed: 'left' as any,
    },
    {
      title: translate('real_estate.name_real_estate'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{item.landName}</span>;
      },
    },
    {
      title: 'Loại tin',
      render: (record: any, item: SaleAndRent) => {
        switch (item.newsConfigType) {
          case 'PAID_METHOD_1':
            return <Text style={{ color: '#12a0c0' }}>Tin Diamond</Text>;
          case 'PAID_METHOD_2':
            return <Text style={{ color: '#EADA21' }}>Tin Gold</Text>;
          case 'FREE_METHOD':
            return <Text style={{ color: '#21EA30' }}>Tin miễn phí</Text>;
          case 'PAG':
            return <Text style={{ color: '#F04550' }}>Phúc An Gia</Text>;
          default:
            return '__';
        }
      },
    },
    {
      title: translate('real_estate.creator'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return (
          <>
            {item.createdBy && item.createdBy.type !== 'CMS' ? (
              <>
                <div>
                  <Link href={ROUTER_PATH.EDIT_CUSTOMER + item.createdBy._id} target="_blank">
                    <Text underline style={{ color: '#12a0c0' }}>
                      {item.createdBy.name}
                    </Text>
                  </Link>
                </div>
                <div>
                  <Text>{item.createdBy.phone}</Text>
                </div>
              </>
            ) : (
              <Text>{(item.createdBy && item.createdBy.name) || ''}</Text>
            )}
          </>
        );
      },
    },
    {
      title: translate('real_estate.date_create'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{moment(item.createdAt).format('DD/MM/YYYY HH:mm')}</span>;
      },
    },
    {
      title: translate('real_estate.type_real_estate'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return (
          <span>{(item.childRealEstate && item.childRealEstate.length > 0 && item.childRealEstate[0].name) || ''}</span>
        );
      },
    },
    {
      title: translate('real_estate.area'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{(item.props && item.props.area) || 0}</span>;
      },
    },
    {
      title: translate('real_estate.price'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{formatNumber(item.price)}</span>;
      },
    },
    {
      title: translate('real_estate.status'),
      key: '_id',
      render: (record: any, item: SaleAndRent, index: any) => {
        return <span>{translate(nameProjectNewStatus[item.status])}</span>;
      },
    },
    {
      title: translate('real_estate.optional'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
      fixed: 'right' as any,
    },
  ];

  // Action Xóa nhiều
  const [dataDelete, setDataDelete] = useState<string[]>([]);

  const onDeleteAll = () => {
    deleteArray(dataDelete);
    setDataDelete([]);
    setFilter({ ...filter, page: 1 });
  };

  const rowSelection = {
    selectedRowKeys: dataDelete,
    onChange: (selectedRowKeys: string[]) => {
      console.log('selectedRowKeys: ', selectedRowKeys);
      const arrDelete = dataDelete.filter(_id => !listSaleAndRental.find(item => _id === item._id));
      setDataDelete(_.uniq([...arrDelete, ...selectedRowKeys]));
    },
    getCheckboxProps: (record: any) => {
      const rowindex = record.transactions.length > 0;
      return {
        disabled: rowindex,
      };
    },
  };

  return (
    <>
      <FilterHeaderSaleAndRental
        onChangeStatusTypeBDS={onChangeStatusTypeBDS}
        onChangeTypeEstate={onChangeTypeEstate}
        onSearch={(value: string) => run(value)}
        onChangeStatus={onChangeStatus}
        onCheckImport={onCheckImport}
        onChangeCreate={onChangeCreate}
        onChangeConfigType={onChangeConfigType}
        onDateRanger={onDateRanger}
      />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
              {dataDelete && dataDelete.length > 0 && (
                <Popconfirm
                  placement="top"
                  title="Bạn có chắc muốn xóa"
                  onConfirm={() => onDeleteAll()}
                  okText={translate('action.yes')}
                  cancelText={translate('action.no')}
                >
                  <img className="img-style" src={images.ic_delete} alt="" />
                  <span
                    style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                  >
                    Xóa nhiều
                  </span>
                </Popconfirm>
              )}
              <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                Đã chọn {dataDelete.length || 0}
              </span>
            </div>

            <Col span={24}>
              {listSaleAndRental && (
                <Table
                  rowKey="_id"
                  dataSource={listSaleAndRental || []}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  columns={renderColumns}
                  pagination={false}
                  scroll={{ x: 'auto' }}
                />
              )}
            </Col>
          </>
        )}

        {listSaleAndRental && (
          <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
            <div className="d-flex justify-content-end pagination">
              <Pagination
                showSizeChanger={false}
                total={total}
                current={page}
                defaultCurrent={page}
                defaultPageSize={PaginateList.LIMIT_DEFAULT}
                onChange={onPageChange}
              ></Pagination>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
export default SaleAndRentalpage;
