import { translate } from './../../translate/language';
import { ToastError, ToastSuccess } from './../../components/Toast/index';
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { StatusResponse } from './../../constants/Status';
import { TermRes } from './terms-of-user.type';

const URL_TERM = 'term';

function useTermsOfUseService() {
  const [dataTerm, setDataTerm] = useState<TermRes | undefined>();

  const onGetTerm = async () => {
    try {
      const res: any = await ApiHelper.fetch(URL_TERM);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataTerm(res.data);
      }
    } catch (error) {
      setDataTerm(undefined);
    }
  };

  const onUpdateTerm = async (data: { content: string }) => {
    try {
      const res: any = await ApiHelper.put(URL_TERM, data);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return { onGetTerm, dataTerm, onUpdateTerm };
}

export default useTermsOfUseService;
