import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

function useListParents() {
    const [listParent, setListParent] = useState<any>();

    const onListParent = async () => {
        try {
            const res = await ApiHelper.fetch(
                BASE_URL.LIST_PARENT
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                setListParent(res?.data)
            } else {
                setListParent(undefined)
            }
        } catch (error) {
            setListParent(undefined);
        }
    }

    return { onListParent, listParent }
}

export default useListParents;