import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import { translate } from 'translate/language';
import styles from '../css/house-template-create.module.scss';
import React from 'react';
const { RangePicker } = DatePicker;

interface InputProps {
  label: string;
  onChangevalue?: (e: React.ChangeEvent<HTMLInputElement>) => void | any;
  onChangevalueNumber?: (e: string | number | any) => void | any;
  onBlur?: (e: any) => void | any;
  value?: string;
  suffix?: any;
  rules?: any[];
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  styles?: React.CSSProperties;
  styleCol?: React.CSSProperties;
  maxLength?: number;
  initialValueNumber?: string;
  typeInput?: string;
}

interface SelectProps {
  label: string;
  data: any[];
  name?: string;
  fistSelect?: string;
  styles?: React.CSSProperties;
  rules?: any[];
  multiple?: boolean;
  onPopupScroll?: any;
  styleCol?: React.CSSProperties;
  onChangeCascader?: any;
  initialValueSelect?: string;
}

interface DateProps {
  label: string;
  name?: string;
  rules?: any;
  onChange?: (value: any) => void;
}

export function SelectInput(props: SelectProps) {
  const { label, data, multiple, name, fistSelect, styleCol = {}, rules, onChangeCascader, initialValueSelect } = props;

  return (
    <Row>
      <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
        <div className={rules ? styles.nameLabel : styles.nameLabelNotValid}>{translate(label)}</div>
      </Col>
      <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
        <Form.Item
          name={name}
          style={{ width: '100%' }}
          rules={
            rules && [
              {
                required: true,
                message: translate(label) + ' ' + translate('require'),
              },
            ]
          }
          initialValue={initialValueSelect}
        >
          <Select size="middle" {...props} mode={multiple ? 'multiple' : undefined}>
            {fistSelect && <Option key={''}>{fistSelect}</Option>}
            {data.map((e: any) => {
              return <Option key={e._id}>{e.name || e.child}</Option>;
            })}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
}


export function InputComponent(props: InputProps) {
  const { label, name, rules, initialValueNumber, typeInput } = props;
  return (
    <Row>
      <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
        <div className={rules ? styles.nameLabel : styles.nameLabelNotValid}>{translate(label)}</div>
      </Col>
      <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
        <Form.Item
          name={name}
          initialValue={initialValueNumber}
          rules={
            rules
              ? [
                  ...rules,
                  {
                    whitespace: true,
                    required: true,
                    message: translate(label) + ' ' + translate('require'),
                  },
                ]
              : undefined
          }
        >
          <Input size="small" type={typeInput} />
        </Form.Item>
      </Col>
    </Row>
  );
}
export function InputAreaComponent(props: InputProps) {
  const { label, name, rules = [] } = props;
  return (
    <Row>
      <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
        <div className={rules ? styles.nameLabel : styles.nameLabelNotValid}>{translate(label)}</div>
      </Col>
      <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
        <Form.Item
          name={name}
          rules={
            rules && [
              ...rules,
              {
                transform: (value: string) => {
                  return value?.trim();
                },
                whitespace: true,
                required: true,
                message: translate(label) + ' ' + translate('require'),
              },
            ]
          }
        >
          <Input.TextArea style={{ resize: 'none' }} rows={6} />
        </Form.Item>
      </Col>
    </Row>
  );
}
