/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable object-shorthand */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import images from 'images';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterPointComponent from './components/filter-point.component';
import { FilterPoint } from './point-types';
import usePointService from './point.service';

function PointScreen(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterPoint>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
  });
  const { onGetPoint, dataPoint, totalPoint, loading, onDeletePoint, loadingDel } = usePointService();

  useEffect(() => {
    onGetPoint(filter);
  }, [filter, loadingDel]);

  // Tìm kiếm theo tên gói
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search: search });
    },
    {
      wait: 500,
    },
  );

  // Thay đổi trạng thái
  const onStatus = (data: string) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status: data });
  };

  const onDelete = (_id: string) => {
    onDeletePoint(_id);
  };

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.POINT_DETAIL + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={() => onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tên gói',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: 'Giá tiền',
      dataIndex: 'price',
      render: (price: number) => {
        return <span>{formatNumber(price)}</span>;
      },
    },
    {
      title: 'Điểm nhận',
      dataIndex: 'pointActive',
      render: (pointActive: number) => {
        return <span>{formatNumber(pointActive)}</span>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        return <span>{status === StatusType.ACTIVE ? 'Hoạt động' : 'Không hoạt động'}</span>;
      },
    },
    {
      title: 'Tùy chọn',
      dataIndex: '_id',
      render: renderAction,
    },
  ];

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  return (
    <>
      <FilterPointComponent onSearch={(data: string) => run(data)} onStatus={onStatus} />
      <Row>
        {loading || loadingDel ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataPoint || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalPoint}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default PointScreen;
