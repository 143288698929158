/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/alt-text */
import { Table } from 'antd';
import PageLoading from 'components/PageLoading';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import images from 'images';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import useEmailService from './email.service';
import { EmailTemplateRes } from './email.types';

function EmailScreen(): JSX.Element {
  const history = useHistory();
  const { onGetEmail, dataEmail, loading } = useEmailService();

  useEffect(() => {
    onGetEmail();
  }, []);

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.CONFIG_EMAILS_CONTRACTS).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.DETAIL_EMAIL_MANAGER + _id);
            }}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  const renderColumns = [
    {
      title: translate('customer.stt'),
      render: (_: any, data: EmailTemplateRes, number: number) => {
        return <span>{number + 1}</span>;
      },
      width: '5%',
    },

    {
      title: translate('email_template.code'),
      key: '_id',
      dataIndex: 'code',
      render: (code: string) => {
        return <span>{code}</span>;
      },
      width: '10%',
    },
    {
      title: translate('email_template.name'),
      key: '_id',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      render: renderAction,
      width: '10%',
      align: 'center' as 'center',
    },
  ];
  return (
    <>{loading ? <PageLoading /> : <Table dataSource={dataEmail || []} columns={renderColumns} pagination={false} />}</>
  );
}

export default EmailScreen;
