import {
    Button, Col,
    Form, Row
} from "antd";
import { PERMISSION_NAME } from "constants/PermissionName";
import LocalStorageHelper, { StorageKey } from "helpers/LocalStorageHelper";
import { getCheckPermissionUser } from "helpers/tokenHelpers";
import React from 'react';
import { useHistory } from "react-router-dom";
import { ROUTER_PATH } from "routes/Path";
import { translate } from "translate/language";

function FilterHeaderPropertyPage() {
    const [form] = Form.useForm();
    const history = useHistory();

    // Thêm mới người dùng
    const addNew = () => {
        LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PROPERTY)
        history.push(ROUTER_PATH.ADD_PROPERTY)
    }
    return (
        <div style={{ marginBottom: "10px" }}>
            <Form form={form}>
                <Row gutter={20} >
                    <Col className="gutter-row" span={7}>
                    </Col>
                    <Col className="gutter-row" span={14}></Col>
                    <Col className="gutter-row" span={3}>
                        {
                            getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).create ?
                                <div>
                                    <Button style={{ width: "100%", display: "inline-flex", justifyContent: "center" }} type="primary" htmlType="submit"
                                        onClick={addNew}
                                    >
                                        {translate("action.add_new")}
                                    </Button>
                                </div> : ''
                        }

                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export default FilterHeaderPropertyPage;