/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import React, { useEffect } from 'react';

const { Option } = Select;
export interface Props {
  onSearch: (data: string) => void;
  onType: (data: string) => void;
  onRealEstates: (data: string) => void;
}

function FilterFeedbackComponent(props: Props): JSX.Element {
  const { onSearch, onType, onRealEstates } = props;

  const [form] = Form.useForm();
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();

  useEffect(() => {
    // Loại bất động sản
    onFetchListParentChildRealeState();
  }, []);

  // Tìm kiếm theo người đăng tin
  const handleSearch = (data?: any) => {
    onSearch(data.target.value);
  };

  // Select loại kí gửi
  const onChangeType = (typeChange: string) => {
    onType(typeChange);
  };

  // Select loại BĐS
  const onChangeRealEstates = (type: string) => {
    onRealEstates(type);
  };

  return (
    <Form form={form}>
      {/* Tìm kiếm người đăng tin   */}
      <Row gutter={16}>
        <Col className="gutter-row" span={6}>
          <Form.Item name="search" style={{ width: '100%' }}>
            <Input size="small" placeholder="Mã tin, tên BĐS" onChange={handleSearch} suffix={<SearchOutlined />} />
          </Form.Item>
        </Col>
        {/* Loại kí gửi */}
        <Col className="gutter-row" span={4}>
          <Form.Item>
            <Select placeholder="Loại kí gửi" onChange={onChangeType} allowClear>
              <Option value="SELL">Bán</Option>
              <Option value="RENT">Cho thuê</Option>
            </Select>
          </Form.Item>
        </Col>
        {/* Loại BĐS */}
        <Col className="gutter-row" span={4}>
          <Form.Item>
            <Select placeholder="Loại BĐS" onChange={onChangeRealEstates} allowClear>
              {ListParentChildRealeState
                ? ListParentChildRealeState.map((data: any) => {
                    return (
                      <Option value={data._id} key={data._id} title={data.child}>
                        {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                      </Option>
                    );
                  })
                : ''}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default FilterFeedbackComponent;
