/* eslint-disable react/jsx-boolean-value */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusTransactions } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import images from 'images';
import { UserInfo } from 'pages/RealEstateNewPage/real-estate-new.types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterProjectCustomerComponent from './components/filter-project-customer.component';
import useCustomerProjectService from './customer-project.service';
import { FilterCustomerProject, HouseInfo, ProjectInfo } from './customer-project.type';

const { Text, Link } = Typography;

function CustomerProjectScreen(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterCustomerProject>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    projectId: undefined,
    status: undefined,
  });
  const { onGetListTransactions, dataHouseTransactions, totalTransactions, loading } = useCustomerProjectService();

  useEffect(() => {
    onGetListTransactions(filter);
  }, [filter]);

  const detailCustomer = (_id: number | string) => {
    history.push(`${ROUTER_PATH.DETAIL_PROJECT_CUSTOMER}${_id}?type=PAG`);
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_BOOKING).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            alt=""
            onClick={() => detailCustomer(_id)}
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer_project.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
      fixed: 'left' as any,
    },
    {
      title: translate('customer_project.name_customer'),
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return (
          userInfo && (
            <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo._id} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {userInfo.name}
              </Text>
            </Link>
          )
        );
      },
      fixed: 'left' as any,
    },
    {
      title: 'Tên dự án',
      dataIndex: 'projectInfo',
      render: (projectInfo: ProjectInfo) => {
        return (
          projectInfo && (
            <Link href={ROUTER_PATH.EDIT_PROJECT + projectInfo._id} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {projectInfo.name}
              </Text>
            </Link>
          )
        );
      },
    },
    {
      title: translate('customer_project.phone'),
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return userInfo && <Text>{userInfo.phone}</Text>;
      },
    },
    {
      title: translate('customer_project.CMND'),
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return userInfo && <Text>{userInfo.identify}</Text>;
      },
    },
    {
      title: translate('customer_project.ctv_introducer'),
      dataIndex: 'introducerInfo',
      render: (introducerInfo: any) => {
        return (
          introducerInfo && (
            <>
              <div>
                <Link href={ROUTER_PATH.EDIT_CUSTOMER + introducerInfo._id} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {introducerInfo.name}
                  </Text>
                </Link>
              </div>
              <div>
                <Text>{introducerInfo.phone}</Text>
              </div>
            </>
          )
        );
      },
    },
    {
      title: translate('customer_project.ctv_supporter'),
      dataIndex: 'supporterInfo',
      render: (supporterInfo: any) => {
        return (
          supporterInfo && (
            <>
              <div>
                <Link href={ROUTER_PATH.EDIT_CUSTOMER + supporterInfo._id} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {supporterInfo.name}
                  </Text>
                </Link>
              </div>
              <div>
                <Text>{supporterInfo.phone}</Text>
              </div>
            </>
          )
        );
      },
    },
    {
      title: translate('customer_project.code'),
      dataIndex: 'houseInfo',
      render: (houseInfo: HouseInfo) => {
        return (
          houseInfo && (
            <Link href={ROUTER_PATH.EDIT_HOUSE_LOTS + houseInfo._id} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {houseInfo.code}
              </Text>
            </Link>
          )
        );
      },
    },
    {
      title: translate('customer_project.status'),
      dataIndex: 'status',
      render: (status: string) => {
        if (status === StatusTransactions.INTRODUCED) {
          return <Text>{translate('transaction.INTRODUCED')}</Text>;
        }
        if (status === StatusTransactions.FOUND_SUPPORTER) {
          return <Text>{translate('transaction.FOUND_SUPPORTER')}</Text>;
        }
        if (status === StatusTransactions.CONFIRMED_INFO) {
          return <Text>{translate('transaction.CONFIRMED_INFO')}</Text>;
        }
        if (status === StatusTransactions.SUPPORTED) {
          return <Text>{translate('transaction.SUPPORTED')}</Text>;
        }
        if (status === StatusTransactions.BOOKING) {
          return <Text>{translate('transaction.BOOKING')}</Text>;
        }
        if (status === StatusTransactions.BOOKED) {
          return <Text>{translate('transaction.BOOKED')}</Text>;
        }
        if (status === StatusTransactions.DEPOSIT_PENDING) {
          return <Text>{translate('transaction.DEPOSIT_PENDING')}</Text>;
        }
        if (status === StatusTransactions.DEPOSIT) {
          return <Text>{translate('transaction.DEPOSIT')}</Text>;
        }
        if (status === StatusTransactions.SUCCESS) {
          return <Text>{translate('transaction.SUCCESS')}</Text>;
        }
        if (status === StatusTransactions.CANCEL_BOOKING) {
          return <Text>{translate('transaction.CANCEL_BOOKING')}</Text>;
        }
        if (status === StatusTransactions.CANCEL_DEPOSIT) {
          return <Text>{translate('transaction.CANCEL_DEPOSIT')}</Text>;
        }
        if (status === StatusTransactions.CUSTOMER_CANCEL) {
          return <Text>{translate('transaction.CUSTOMER_CANCEL')}</Text>;
        }
        if (status === 'REQUEST_DEPOSIT_PENDING') {
          return <Text>Đang xác nhận chuyển cọc</Text>;
        }
        if (status === 'REQUEST_DEPOSIT') {
          return <Text>Đã Xác Nhận chuyển cọc</Text>;
        }
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
      fixed: 'right' as any,
    },
  ];

  const { run } = useDebounceFn(
    (data: string) => {
      if (data === '') {
        setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: undefined });
        return;
      } else {
        setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: data });
      }
    },
    {
      wait: 500,
    },
  );

  const onProjectId = (data: any) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, projectId: data });
  };

  const onStatus = (data?: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, status: data });
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  return (
    <>
      <FilterProjectCustomerComponent
        onSearch={(data: string) => run(data)}
        onProjectId={onProjectId}
        onStatus={onStatus}
        isActiveStatus={true}
      />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <Col span={24}>
              <Table
                dataSource={dataHouseTransactions || []}
                columns={columns}
                pagination={false}
                scroll={{ x: 'auto' }}
              />
            </Col>
            <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div className="d-flex justify-content-end pagination">
                <Pagination
                  showSizeChanger={false}
                  total={totalTransactions}
                  current={page}
                  defaultCurrent={page}
                  defaultPageSize={PaginateList.LIMIT_DEFAULT}
                  onChange={onPageChange}
                ></Pagination>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default CustomerProjectScreen;
