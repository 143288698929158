/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable react-native/no-inline-styles */
import { useDebounceFn } from 'ahooks';
import { Button, Col, InputNumber, Modal, Pagination, Row, Table, Typography } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterPointPlusComponent from './components/filter-point-plus.component';
import usePointPlusService from './point-plus.service';
import { PointPlusReq, UserInfoPlus } from './point-plus.type';

const { Link, Text } = Typography;

const PointPlusScreen = (): JSX.Element => {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<PointPlusReq>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    minDate: undefined,
    maxDate: undefined,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [point, setPoint] = useState<number>(0);
  const history = useHistory();

  const { onGetPackagePoint, dataPoint, totalPrice, totalPoint, loading, onUpdatePointPlus } = usePointPlusService();

  useEffect(() => {
    onGetPackagePoint(filter);
  }, [filter]);

  const handleShowModal = (item: any) => {
    setIsModalOpen(true);
    setSelectedData(item);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedData(undefined);
  };

  const onChangePoint = (value: number) => {
    setPoint(value);
  };

  const handleChangePlusPoint = () => {
    if (!point || !selectedData) return;
    onUpdatePointPlus(selectedData._id, point);
    setFilter({ ...filter });
    setSelectedData(undefined);
    setPoint(0);
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Người nhận điểm',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoPlus) => {
        return (
          <span>
            {userInfo !== null ? (
              <>
                <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo._id} target="_blank">
                  <Text underline style={{ color: '#12a0c0' }}>
                    {userInfo.name}
                  </Text>
                </Link>
              </>
            ) : (
              'Tất cả người dùng'
            )}
          </span>
        );
      },
    },
    {
      title: 'Ngày cộng điểm',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoPlus) => {
        return <span>{userInfo !== null ? userInfo.phone : ''}</span>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoPlus) => {
        return <span>{userInfo !== null ? userInfo.email : ''}</span>;
      },
    },
    {
      title: 'Số CMND',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfoPlus) => {
        return <span>{userInfo !== null ? userInfo.identify : ''}</span>;
      },
    },
    {
      title: 'Số lượng',
      dataIndex: 'numberUser',
      render: (numberUser: number) => {
        return <span>{numberUser}</span>;
      },
    },
    {
      title: 'Số điểm nhận',
      dataIndex: 'point',
      render: (point: number) => {
        return <span>{formatNumber(point)}</span>;
      },
    },
    {
      title: 'Sửa lại',
      render: (item: any) => {
        return (
          <>
            {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGE_ADD_POINT).edit && (
              <button onClick={() => handleShowModal(item)} type="button" style={{ cursor: 'pointer' }}>
                <MinusCircleOutlined />
              </button>
            )}
          </>
        );
      },
    },
  ];

  // Thay đổi Page
  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  const addNewPoint = () => {
    history.push(ROUTER_PATH.ADD_POINT_PLUS);
  };

  // Tìm kiếm
  const { run } = useDebounceFn(
    (data: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search: data });
    },
    {
      wait: 500,
    },
  );

  const onRangePicker = (data: [string, string]) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, minDate: data[0] || undefined, maxDate: data[1] || undefined });
  };

  return (
    <>
      <FilterPointPlusComponent onSearch={(data: string) => run(data)} onRangePicker={onRangePicker} />
      <Row>
        <Col span={12}>
          Tổng giá trị : <b style={{ fontSize: '24px' }}>{formatNumber(totalPrice)} </b>
        </Col>
        {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGE_ADD_POINT).create ? (
          <Col span={12} style={{ display: 'grid', justifyContent: 'flex-end', alignItems: 'baseline' }}>
            <Button style={{ width: '100%' }} type="primary" onClick={addNewPoint}>
              {translate('action.add_new')}
            </Button>
          </Col>
        ) : (
          ''
        )}
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Row>
            {loading ? (
              <PageLoading />
            ) : (
              <Col span={24}>
                <Table
                  key={1}
                  dataSource={dataPoint || []}
                  columns={columns}
                  pagination={false}
                  scroll={{ x: 'auto' }}
                  rowKey="_id"
                />
              </Col>
            )}
            <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div className="d-flex justify-content-end pagination">
                <Pagination
                  showSizeChanger={false}
                  total={totalPoint}
                  current={page}
                  defaultCurrent={page}
                  defaultPageSize={PaginateList.LIMIT_DEFAULT}
                  onChange={onPageChange}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {selectedData && (
        <Modal
          footer={false}
          title={`Sửa lịch sử cộng điểm ${selectedData.point}`}
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <h3>Điểm cũ: {selectedData.point}</h3>
          <h3>
            Điểm mới: <InputNumber size="middle" min={1} defaultValue={selectedData.point} onChange={onChangePoint} />{' '}
            (phải nhỏ hơn {selectedData.point})
          </h3>
          <Button onClick={handleChangePlusPoint} type="primary">
            Đồng ý
          </Button>
        </Modal>
      )}
    </>
  );
};

export default PointPlusScreen;
