import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { translate } from 'translate/language';

const DELETE_LIST_CUSTOMER = 'user/deleteManyCustomers';
function useDeleteCustomer(): [(id: string) => Promise<void>, (phone: string[]) => Promise<void>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);

  const onDeleteCustomer = async (id: string) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete<string, any>(`${BASE_URL.DELETE_CUSTOMER}/${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoading(false);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: res?.message || translate('messages.delete_failed'),
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Xóa danh dách khách hàng
  const onDeleteListCustomer = async (body: string[]) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete(DELETE_LIST_CUSTOMER, { phones: body });
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      } else {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.failed'),
          description: res?.message || translate('messages.delete_failed'),
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return [onDeleteCustomer, onDeleteListCustomer, loading];
}

export default useDeleteCustomer;
