/* eslint-disable consistent-return */
import { translate } from 'translate/language';
import { ToastSuccess, ToastError } from 'components/Toast';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { COMMISSIONRES } from './commissions-type';

const LIST_PROJECT_COMMISSION = '/project-commissions';

function useCommissionService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCommission, setDataCommission] = useState<COMMISSIONRES[] | any>([]);
  // Cá nhân
  const [privateCommissionF1, setPrivateCommissionF1] = useState<any>();
  const [privateCommissionNonF1, setPrivateCommissionNonF1] = useState<any>();
  // Doanh nghiệp
  const [companyCommissionF1, setCompanyCommissionF1] = useState<any>();
  const [companyCommissionNonF1, setCompanyCommissionNonF1] = useState<any>();

  const onFetchProjectComission = async () => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(LIST_PROJECT_COMMISSION);
      if (res.data && res.status === StatusResponse.SUCCESS) {
        setDataCommission(res.data);
        // Cá nhân
        setPrivateCommissionF1(res.data.privateCommission.f1Commission);
        setPrivateCommissionNonF1(res.data.privateCommission.nonF1Commission);
        // Doanh nghiệp
        setCompanyCommissionF1(res.data.companyCommission.f1Commission);
        setCompanyCommissionNonF1(res.data.companyCommission.nonF1Commission);
        setLoading(false);
      }
    } catch (error) {
      setDataCommission([]);
      setLoading(false);
    }
  };

  const onUpdateProjectCommission = async (data: any) => {
    const res: any = await ApiHelper.put(LIST_PROJECT_COMMISSION, data);
    if (res && res.status === StatusResponse.SUM_OF_ELEMENTS_IN_ROW_MUST_BE_100) {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('project_commission.SUM_OF_ELEMENTS_IN_ROW_MUST_BE_100'),
      });
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.update_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return {
    onFetchProjectComission,
    dataCommission,
    loading,
    privateCommissionF1,
    privateCommissionNonF1,
    companyCommissionF1,
    companyCommissionNonF1,
    onUpdateProjectCommission,
  };
}

export default useCommissionService;
