/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';

const { Option } = Select;

export interface Props {
  onSearch: (data: string) => void;
  onStatus: (data: string) => void;
}

function FilterWithDrawComponent(props: Props): JSX.Element {
  const { onSearch, onStatus } = props;
  const [form] = Form.useForm();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  // Thay đổi trạng thái
  const handChangeStatus = (value: any) => {
    onStatus(value || undefined);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item name="search">
              <Input size="small" placeholder="Tên, SĐT" onChange={handleSearch} suffix={<SearchOutlined />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="customer_type">
              <Select placeholder="Trạng thái" onChange={handChangeStatus}>
                <Option value="">Tất cả</Option>
                <Option value="PENDING_PAYMENT">Chờ thanh toán</Option>
                <Option value="PENDING_APPROVED">Chờ duyệt</Option>
                <Option value="APPROVED">Đã thanh toán</Option>
                <Option value="CANCEL">Từ chối thanh toán</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterWithDrawComponent;
