/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'ahooks';
import { Button, Checkbox, Col, ConfigProvider, DatePicker, Form, Input, InputNumber, Row, Select } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import useListCustomer from 'hooks/Customer/useListCustomer';
import moment from 'moment';
import 'moment/locale/vi';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import usePointPlusService from './point-plus.service';
import { City } from './point-plus.type';

const { Option } = Select;

function PointPlusDetailScreen(): JSX.Element {
  const [form] = Form.useForm();
  const [checkType, setCheckType] = useState<boolean>(true);
  const [disabledBirthDay, setDisabledBirthDay] = useState<boolean>(true);
  const [disabledPlace, setDisabledPlace] = useState<boolean>(true);
  const history = useHistory();
  const { onGetCity, dataCity, onCreatePointPlus, disabled } = usePointPlusService();
  const [dataName, setDataName] = useState<any[]>([]);

  // Type check validate ngày sinh
  const [checkBirthday, setCheckBirthday] = useState(false);
  // Type check địa điểm
  const [checkAddress, setCheckAddress] = useState(false);

  useEffect(() => {
    onGetCity();
  }, []);

  useEffect(() => {
    form.validateFields(['birthday', 'address']);
  }, [checkBirthday, checkAddress]);

  const onCheckBirthday = (e: { target: { checked: boolean } }) => {
    const checked = e.target.checked;
    if (checked) {
      setDisabledBirthDay(false);
    } else {
      setDisabledBirthDay(true);
    }
    setCheckBirthday(checked);
  };

  const onCheckAddress = (e: { target: { checked: boolean } }) => {
    const checked = e.target.checked;
    if (checked) {
      setDisabledPlace(false);
    } else {
      setDisabledPlace(true);
    }
    setCheckAddress(checked);
  };

  const onFinish = (value: any) => {
    value.birthDay =
      value.birthDay && value.checked_birthday ? moment(value?.birthDay).format('DD/MM/YYYY') : undefined;
    value.cityId = value.cityId && value.checked_address ? value.cityId : undefined;
    onCreatePointPlus(value);
  };

  const closeButton = () => {
    history.goBack();
  };

  const onSelectType = (value: string) => {
    setCheckType(value === 'INDIVIDUAL' ? true : false);
  };

  const [valueName, setValueName] = useState<string>();
  const debouncedValue = useDebounce(valueName, { wait: 500 });

  const [onFetchListCustomer, listCustomer] = useListCustomer();

  useEffect(() => {
    if (debouncedValue) {
      onFetchListCustomer({ search: debouncedValue });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (listCustomer) {
      setDataName(listCustomer.data);
    }
  }, [listCustomer]);

  // Cộng điểm cá nhân
  const handleSearchName = (value: string) => {
    setValueName(value);
  };

  const [dataDetailCustomer, setDataDetailCustomer] = useState<any>();

  const onChangeName = (value: any, option: any) => {
    setDataDetailCustomer(listCustomer?.data[option.key]);
  };

  return (
    <>
      {' '}
      {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGE_ADD_POINT).create ? (
        <ConfigProvider locale={locale}>
          <Form form={form} onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={20} offset={3}>
                <Row>
                  {/* Loại cộng điểm */}
                  <Col span={20}>
                    <Form.Item
                      name="typePoint"
                      label="Loại cộng điểm"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      initialValue="INDIVIDUAL"
                      rules={[
                        {
                          required: true,
                          message: 'Loại cộng điểm không được để trống',
                        },
                      ]}
                    >
                      <Select onSelect={onSelectType}>
                        <Select.Option value="INDIVIDUAL">Cá nhân</Select.Option>
                        <Select.Option value="ALL_USER">Tất cả người dùng</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  {/* Type cộng điểm */}
                  {!checkType ? (
                    <>
                      {/* Giới tính */}
                      <Col span="20">
                        <Row>
                          <Col span={7}>Giới tính</Col>
                          <Col span={17}>
                            <Form.Item name="gender">
                              <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                  <Col span={8}>
                                    <Checkbox value="MALE">Nam</Checkbox>
                                  </Col>
                                  <Col span={8}>
                                    <Checkbox value="FEMALE">Nữ</Checkbox>
                                  </Col>
                                  <Col span={8}>
                                    <Checkbox value="OTHER">Khác</Checkbox>
                                  </Col>
                                </Row>
                              </Checkbox.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      {/* Ngày sinh */}
                      <Col span="20">
                        <Row>
                          <Col span={7}>
                            <Form.Item name="checked_birthday" valuePropName="checked">
                              <Checkbox checked={checkBirthday} onChange={onCheckBirthday}>
                                Ngày sinh
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              name="birthDay"
                              rules={[
                                {
                                  required: checkBirthday,
                                  message: 'Ngày sinh không được để trống',
                                },
                              ]}
                            >
                              <DatePicker
                                disabled={disabledBirthDay}
                                format="DD/MM/YYYY"
                                placeholder="Ngày sinh"
                                style={{ width: '100%' }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                      {/* Địa điểm */}
                      <Col span="20">
                        <Row>
                          <Col span={7}>
                            <Form.Item name="checked_address" valuePropName="checked">
                              <Checkbox checked={checkAddress} onChange={onCheckAddress}>
                                Địa điểm
                              </Checkbox>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              name="cityId"
                              rules={[
                                {
                                  required: checkAddress,
                                  message: 'Địa điểm không được để trống',
                                },
                              ]}
                            >
                              <Select placeholder="Chọn địa điểm" disabled={disabledPlace}>
                                {dataCity &&
                                  dataCity.length > 0 &&
                                  dataCity.map((data: City) => {
                                    return (
                                      <Select.Option key={data._id} value={data._id}>
                                        {data.name}
                                      </Select.Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  ) : (
                    // Cộng điểm cá nhân
                    <>
                      {/* Họ và tên */}
                      <Col span={20}>
                        <Form.Item
                          name="userId"
                          label="Họ và tên"
                          labelCol={{ span: 7 }}
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng tìm kiếm chọn người dùng',
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Tìm kiếm tên, Số điện thoại, Agent ID"
                            defaultActiveFirstOption={false}
                            filterOption={false}
                            onSearch={handleSearchName}
                            notFoundContent={null}
                            suffixIcon={<SearchOutlined />}
                            onChange={onChangeName}
                          >
                            {dataName.map((data: any, index: number) => (
                              <Option value={data._id} key={index}>
                                {data.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      {dataDetailCustomer && (
                        <>
                          {/* Số điện thoại */}
                          <Col span={20}>
                            <Form.Item label="Số điện thoại" labelCol={{ span: 7 }} labelAlign="left">
                              <b style={{ fontSize: '20px' }}> {dataDetailCustomer?.phone}</b>
                            </Form.Item>
                          </Col>
                          {/* Email */}
                          <Col span={20}>
                            <Form.Item label="Email" labelCol={{ span: 7 }} labelAlign="left">
                              <b style={{ fontSize: '20px' }}> {dataDetailCustomer?.email}</b>
                            </Form.Item>
                          </Col>
                          {/* Số CMND */}
                          <Col span={20}>
                            <Form.Item label="Số CMND" labelCol={{ span: 7 }} labelAlign="left">
                              <b style={{ fontSize: '20px' }}> {dataDetailCustomer?.identify}</b>
                            </Form.Item>
                          </Col>
                          {/* Số điểm khả dụng */}
                          <Col span={20}>
                            <Form.Item label="Số điểm khả dụng" labelCol={{ span: 7 }} labelAlign="left">
                              <b style={{ fontSize: '20px' }}>{formatNumber(dataDetailCustomer?.personalPoint)}</b>
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </>
                  )}

                  {/* Số điểm */}
                  <Col span={20}>
                    <Form.Item
                      name="point"
                      label="Số điểm"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: 'Số điểm không được trống',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                        parser={(value: any) => value.replace(/(\.*)/g, '')}
                        step={1000}
                        min={0}
                      />
                    </Form.Item>
                  </Col>
                  {/* Ghi chú */}
                  <Col span={20}>
                    <Form.Item
                      name="note"
                      label="Ghi chú"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          whitespace: true,
                          max: 500,
                          message: 'Ghi chú tối đa 500 ký tự',
                        },
                      ]}
                    >
                      <Input.TextArea rows={5} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="default" onClick={closeButton}>
                            {translate('action.close')}
                          </Button>
                        </Form.Item>
                      </Col>
                      <Col>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" disabled={disabled}>
                            {translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </ConfigProvider>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default PointPlusDetailScreen;
