/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable consistent-return */
/* 
  Created by no name at 01-06-2021 11:33:40
  Màn hình quản lí bán cho thuê
*/
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Table, Tooltip, Upload } from 'antd';
import Container from 'components/ContentLayout/Container';
import { ToastError, ToastSuccess } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusResponse } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import moment from 'moment';
import ApiHelper from 'network/ApiClient';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import '../css/SaleAndRental.scss';
import { listDepositeType, ListStatusSR } from '../modelSaleAndRental/SaleAndRental.type';

const { Option } = Select;

const { RangePicker } = DatePicker;
interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status: string) => void;
  onChangeStatusTypeBDS: (status: string, e?: any) => void;
  onChangeTypeEstate: (status: string, e?: any) => void;
  onCheckImport: (data: string) => void;

  onChangeCreate: (data?: string) => void;
  onChangeConfigType: (data?: string) => void;
  onDateRanger: (data?: [string, string]) => void;
}

export interface ExcelReq {
  search: string | undefined;
  realEstate: string | undefined;
  type: string | undefined;
  status: string | undefined;
}

function FilterSaleAndRental(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const {
    onSearch,
    onChangeStatus,
    onChangeTypeEstate,
    onChangeStatusTypeBDS,
    onCheckImport,
    onChangeCreate,
    onChangeConfigType,
    onDateRanger,
  } = props;
  const history = useHistory();

  const [fileExcel, setFileExel] = useState<any[]>([]);
  const [checkModal, setCheckModal] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateExisting, setUpdateExisting] = useState<string>('false');
  const [errorExcel, setErrorExcel] = useState<boolean>(false);
  const [totalError, setTotalError] = useState<number>(0);
  const [totalSuccess, setTotalSuccess] = useState<number>(0);
  const [checkExcel, setCheckExcel] = useState<boolean>(false);
  const [dataError, setDataError] = useState<any[]>([]);

  const handleDownloadTemplate = async () => {
    const res: any = await ApiHelper.fetch('/project-news/download-import-template', null, true);
    window.open(getUrlExport(res.data));
  };

  const onRemove = () => {
    setFileExel([]);
    setCheckExcel(false);
  };

  const closeModal = () => {
    setCheckModal(false);
    setIsModalVisible(false);
    setCheckExcel(false);
  };

  // Nhập Excel
  const showModal = () => {
    setIsModalVisible(true);
  };

  const onChangeExcel = (e: any) => {
    if (e.target.checked) {
      setUpdateExisting('true');
    } else {
      setUpdateExisting('false');
    }
  };

  const handleSubmitUpload = async (e: any) => {
    e.preventDefault();
    if (fileExcel.length === 0) {
      setCheckModal(true);
      return;
    }

    setCheckModal(false);
    const data = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    if (fileExcel?.length > 0) {
      data.append('file', fileExcel[0].originFileObj);
      data.append('updateExisting', updateExisting);
      const res: any = await ApiHelper.postFormData('/project-news/import', data, headers);
      if (res && res.status === 'FILE_IS_NOT_FORMULATED') {
        setErrorExcel(true);
        setTotalError(res.data.fail);
        setDataError(res.data.errors);
        return false;
      }
      setFileExel([]);
      setCheckModal(false);
      setCheckExcel(true);

      if (res && res.data && res.data.fail > 0) {
        setErrorExcel(true);
        setTotalError(res.data.fail);
        setDataError(res.data.errors);
        return false;
      }

      if (res && res.status === StatusResponse.SUCCESS && res.data) {
        setDataError(res.data.errors);
        setTotalError(res.data.fail);
        setTotalSuccess(res.data.success);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.import_success'),
        });

        closeModal();
        onCheckImport('success');
      }
    }
  };

  const handleChangeFileUpload = ({ fileList }: any) => {
    if (fileList && fileList.length > 0) {
      fileList.map((e: any) => {
        e.status = 'done';
        return e;
      });
      setFileExel(fileList);
      setCheckModal(false);
    } else {
      setCheckModal(true);
    }
    setCheckExcel(false);
  };

  const [filterExcel, setFilterExcel] = useState<ExcelReq>({
    search: undefined,
    realEstate: undefined,
    type: undefined,
    status: undefined,
  });
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();
  useEffect(() => {
    onFetchListParentChildRealeState();
  }, []);

  const onSearchSubmit = (e: any) => {
    onSearch(e.target.value);
    setFilterExcel({ ...filterExcel, search: e.target.value });
  };
  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
    setFilterExcel({ ...filterExcel, status: value });
  };

  const handChangeStatusEstate = (value: string) => {
    onChangeTypeEstate(value);
    setFilterExcel({ ...filterExcel, realEstate: value });
  };

  const handChangeStatusType = (value: string, e: any) => {
    onChangeStatusTypeBDS(e.key);
    setFilterExcel({ ...filterExcel, type: e.key });
  };

  const handChangeCreate = (value: string) => {
    onChangeCreate(value === 'ALL' ? undefined : value);
  };

  const handChangeConfig = (value: string) => {
    onChangeConfigType(value);
  };

  const addNew = () => {
    history.push(ROUTER_PATH.SALES_AND_RENTAL_CREATE);
  };

  // Xuất Excel
  const exportExcel = async () => {
    const res: any = await ApiHelper.post('/project-news/export-sell', filterExcel, true);
    if (res && res.status === 'NO_PROJECT_NEWS_EXISTS') {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('Không tồn tại dữ liệu nào để xuất'),
      });
    }
    window.open(getUrlExport(res.data));
  };

  const onChangeDate = (dates: [any, any], dateStrings: [string, string]) => {
    onDateRanger(dates || undefined);
  };

  const columns = [
    {
      title: 'STT',
      render: (_: any, data: any, index: number) => {
        return index + 1;
      },
    },
    {
      title: 'Mã tin đăng',
      dataIndex: 'code',
      render: (code: string) => {
        return code;
      },
    },
    {
      title: 'Lỗi',
      dataIndex: 'message',
      render: (message: string) => {
        return message;
      },
    },
  ];

  return (
    <>
      <Modal
        title="Nhập từ excel"
        visible={isModalVisible}
        onCancel={closeModal}
        cancelText="Đóng"
        okText="Tải lên"
        onOk={handleSubmitUpload}
      >
        <Container>
          <Row>
            <Col sm={24}>
              <Form.Item label="Tải xuống mẫu" labelCol={{ sm: 8 }} labelAlign="left">
                <DownloadOutlined style={{ fontSize: '25px' }} onClick={handleDownloadTemplate} />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                label="Tải lên file excel"
                labelAlign="left"
                labelCol={{ sm: 8 }}
                name="upload_file_excel"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Upload
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleChangeFileUpload}
                  fileList={fileExcel}
                  onRemove={onRemove}
                >
                  {fileExcel && fileExcel.length > 0 ? '' : <Button icon={<UploadOutlined />}>Tải lên</Button>}
                </Upload>
              </Form.Item>
              {checkModal ? (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                  <div>Vui lòng chọn File tải lên </div>
                </div>
              ) : (
                ''
              )}
            </Col>
            <Col span={24} style={{ marginBottom: '25px' }}>
              <Checkbox onChange={onChangeExcel}>Cập nhật tin đăng có sẵn</Checkbox>
            </Col>
            {checkExcel && (
              <>
                <Col span={12}>Thành công : {totalSuccess}</Col>
                <Col span={12}>Thất bại : {totalError}</Col>
              </>
            )}

            {errorExcel && (
              <Col span={24}>
                <Table dataSource={dataError || []} columns={columns} pagination={false} />
              </Col>
            )}
          </Row>
        </Container>
      </Modal>

      <Form form={form}>
        <Row className="header_filer">
          <Col md={{ span: 4 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item name="search">
                <Input size="small" placeholder={translate('project.search')} onChange={onSearchSubmit} />
              </Form.Item>
            </div>
          </Col>
          <Col md={{ span: 3 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item>
                <Select placeholder={translate('real_estate.type_real_estate')} onChange={handChangeStatusEstate}>
                  <Option value="">{translate('status.all')}</Option>
                  {ListParentChildRealeState.map((e: any) => {
                    return (
                      <Option key={e._id} value={e.name}>
                        <Tooltip placement="topLeft" title={translate(e.name) || e.child}>
                          {translate(e.name) || e.child}
                        </Tooltip>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col md={{ span: 3 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item>
                <Select placeholder="Loại tin đăng" onChange={handChangeConfig} allowClear>
                  <Option value="PAID_METHOD_1">Tin Diamond</Option>
                  <Option value="PAID_METHOD_2">Tin Gold</Option>
                  <Option value="FREE_METHOD"> Tin miễn phí</Option>
                  <Option value="PAG">Phúc An Gia</Option>
                  <Option value={undefined as any}>Tất cả</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col md={{ span: 3 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item>
                <Select placeholder="Người tạo" onChange={handChangeCreate}>
                  <Option value="ALL">Tất cả</Option>
                  <Option value="ADMIN">Admin</Option>
                  <Option value="APP">Người dùng App</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col md={{ span: 3 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item>
                <Select placeholder={translate('real_estate.type_deposit')} onChange={handChangeStatusType}>
                  <Option value="">{translate('status.all')}</Option>
                  {listDepositeType.map((e: any) => {
                    return (
                      <Option key={e._id} value={e.name}>
                        {translate(e.name) || e.child}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col md={{ span: 3 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item name="customer_status">
                <Select size="middle" {...props} placeholder={translate('status.name')} onChange={handChangeStatus}>
                  <Option value="">{translate('status.all')}</Option>
                  {ListStatusSR.map((e: any) => {
                    return (
                      <Option key={e._id} value={e._id}>
                        {translate(e.name) || e.child}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={3}>
            <Form.Item>
              <RangePicker
                onChange={onChangeDate}
                picker="date"
                placeholder={['Từ', 'Đến']}
                // format="MM/YYYY"
              />
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" md={{ span: 5 }} xl={5} xxl={3} sm={{ span: 12 }} xs={{ span: 24 }}>
            {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={showModal}>
                  Nhập Excel
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col> */}
        </Row>
        <Row gutter={24} justify="end">
          <Col className="gutter-row" md={{ span: 5 }} xl={5} xxl={3} sm={{ span: 12 }} xs={{ span: 24 }}>
            {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={showModal}>
                  Nhập Excel
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col md={{ span: 5 }} xl={5} xxl={3} sm={{ span: 12 }} xs={{ span: 24 }}>
            {/* Xuất excel */}
            {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).export ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={exportExcel}>
                  {translate('customer.export_excel')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col md={{ span: 5 }} xl={5} xxl={3} sm={{ span: 12 }} xs={{ span: 24 }}>
            {/* Thêm mới */}
            {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={addNew}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default FilterSaleAndRental;
