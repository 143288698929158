export const ConstructorType = {
    INVESTOR: "INVESTOR",    //  Chủ đầu tư
    DESIGN: "DESIGN",      //  Tư vấn thiết kế
    DEVELOPER: "DEVELOPER",   //  Đơn vị phát triển dự án
    CONTRACTOR: "CONTRACTOR",  //  Nhà thầu xây dựng chính
    MARKETING: "MARKETING",   //  Tiếp thị phân phối
    OPERATOR: "OPERATOR",    //  Ban quản lý vận hành
}

export const NAME_CONSTRUCTOR = {
    [ConstructorType.INVESTOR]: 'ConstructorType.INVESTOR',
    [ConstructorType.DESIGN]: 'ConstructorType.DESIGN',
    [ConstructorType.DEVELOPER]: 'ConstructorType.DEVELOPER',
    [ConstructorType.CONTRACTOR]: 'ConstructorType.CONTRACTOR',
    [ConstructorType.MARKETING]: 'ConstructorType.MARKETING',
    [ConstructorType.OPERATOR]: 'ConstructorType.OPERATOR',
}