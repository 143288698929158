import { applyMiddleware, createStore } from 'redux';
import rootReducers from './reducer/authReducer';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  stateReconciler: autoMergeLevel2,
  blacklist: ['navigation'] //không muốn lưu giá trị vào trong redux
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducers);

export default function configureStore() {
  const store = createStore(persistedReducer, applyMiddleware(thunk));

  let persistor = persistStore(store);
  return { store, persistor };
}
