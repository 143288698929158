/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Row, Table, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import images from 'images';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import useCommissionPlusService from './commission-plus.service';
import { AdminInfoCommission, CommissionPlusFilter, UserInfoComission } from './commission-plus.type';
import FilterCommissionComponent from './components/filter-commission.component';

const { Text, Link } = Typography;

function CommissionPlusScreen(): JSX.Element {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<CommissionPlusFilter>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    status: undefined,
    search: undefined,
  });

  const { onGetCommissionPlus, dataCommission, totalCommission, loading } = useCommissionPlusService();

  useEffect(() => {
    onGetCommissionPlus(filter);
  }, [filter]);

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_ADD).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.COMMISSION_PLUS_EDIT + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Tên người yêu cầu',
      dataIndex: 'adminInfo',
      render: (data: AdminInfoCommission) => {
        return (
          <>
            <Link href={`${ROUTER_PATH.EDIT_ADMIN}${data?._id}`} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {data?.name}
              </Text>
            </Link>
          </>
        );
      },
    },
    {
      title: 'Ngày gửi yêu cầu',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Số tiền yêu cầu',
      dataIndex: 'money',
      render: (money: number) => {
        return <span>{formatNumber(money)}</span>;
      },
    },
    {
      title: 'Người nhận hoa hồng',
      dataIndex: 'userInfo',
      render: (data: UserInfoComission) => {
        return (
          <>
            <Link href={`${ROUTER_PATH.EDIT_CUSTOMER}${data?._id}`} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {data?.name}
              </Text>
            </Link>
          </>
        );
      },
    },
    {
      title: 'SĐT người nhận',
      dataIndex: 'userInfo',
      render: (data: UserInfoComission) => {
        return <span>{data?.phone}</span>;
      },
    },
    {
      title: 'Ngày duyệt yêu cầu',
      dataIndex: 'receivedDate',
      render: (receivedDate: moment.Moment) => {
        return <span>{receivedDate && moment(receivedDate).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'PENDING_APPROVED':
            return 'Chờ duyệt';
            break;
          case 'APPROVED':
            return 'Đã duyệt';
            break;
          case 'CANCEL':
            return 'Từ chối';
            break;
          default:
            return '';
            break;
        }
      },
    },
    {
      title: 'Tùy chọn',
      dataIndex: '_id',
      render: renderAction,
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm
  const { run } = useDebounceFn(
    (data: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: data || undefined });
    },
    {
      wait: 500,
    },
  );

  // Trạng thái
  const onStatus = (data: string) => {
    setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, status: data || undefined });
  };

  return (
    <>
      <FilterCommissionComponent onSearch={(data: string) => run(data)} onStatus={onStatus} />
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataCommission || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalCommission}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default CommissionPlusScreen;
