import { User } from 'types/response/UserResponse';
import { LOGIN, RESET } from './actionType';

// data: any : Type sẽ lấy sau
export const onLOGIN = (data: User | undefined) => {
  console.log('onLOGIN -> data', data);
  return {
    type: LOGIN,
    payload: data,
  };
};
export const resetLogin = () => {
  return {
    type: RESET,
  };
};
