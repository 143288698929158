export const BASE_URL = {
  LOGIN: '/user/login-cms',
  STATISTIC: '/statistic',
  LOGOUT: '/logout',
  // Quản lý admin
  LIST_ADMIN: '/user/listEmployees',
  DELETE_ADMIN: '/user/deleteEmployee',
  CREATE_ADMIN: '/user/createEmployee',
  DETAIL_ADMIN: '/user/detailEmployee/',
  UPDATE_ADMIN: '/user/updateEmployee/',
  EXPORT_ADMIN: '/user/exportEmployees/',
  // Quản lý khách hàng
  LIST_CUSTOMER: '/user/listCustomers',
  DELETE_CUSTOMER: '/user/deleteCustomer',
  ADD_CUSTOMER: '/user/createCustomer',
  GET_REFERRER: '/user/referrer',
  DETAIL_CUSTOMER: '/user/detailCustomer/',
  UPDATE_CUSTOMER: '/user/updateCustomer/',
  EXPORT_CUSTOMER: '/user/exportCustomers/',
  // Upload hình ảnh
  UPLOAD_IMAGE: '/media',
  UPLOAD_IMAGE_MULTI: '/media/multi',
  // Nhóm phân quyền
  PERMISSION: '/permission/create',
  PERMISSION_DETAIL: '/permission/detail/',
  PERMISSION_UPDATE: '/permission/update/',
  LIST_PERMISSION_CREATE: '/permission/listPermissons',
  LIST_PERMISSION: '/permission/list',
  DELETE_PERMISSION: '/permission/delete/',
  MY_PERMISSIONS: '/permission/my-permissions/',
  // Quản lý loại bất động sản
  LIST_PARENT: '/realestate/listParents',
  LIST_PROPERTY: '/realestate/list',
  CREATE_PROPERTY: '/realestate/create',
  DELETE_PROPERTY: '/realestate/delete/',
  DETAIL_PROPERTY: '/realestate/detail/',
  UPDATE_PROPERTY: '/realestate/update/',
  // Quản lý căn hộ
  LIST_APARTMENT: '/apartment/list',
  DELETE_APARTMENT: '/apartment/delete/',
  CREATE_APARTMENT: '/apartment/create',
  DETAIL_APARTMENT: '/apartment/detail/',
  UPDATE_APARTMENT: '/apartment/update/',
  // Quản lý hướng nhà
  LIST_DIRECTION: '/direction/list',
  DELETE_DIRECTION: '/direction/delete/',
  CREATE_DIRECTION: '/direction/create',
  DETAIL_DIRECTION: '/direction/detail/',
  UPDATE_DIRECTION: '/direction/update/',
  // Đối tác xây dựng
  LIST_CONSTRUCTOR: '/constructor/list',
  DELETE_CONSTRUCTOR: '/constructor/delete/',
  CREATE_CONSTRUCTOR: '/constructor/create',
  DETAIL_CONSTRUCTOR: '/constructor/detail/',
  UPDATE_CONSTRUCTOR: '/constructor/update/',
  // Ngân hàng
  LIST_BANK: '/bank/list',
  DELETE_BANK: '/bank/delete/',
  CREATE_BANK: '/bank/create',
  DETAIL_BANK: '/bank/detail/',
  UPDATE_BANK: '/bank/update/',
  // Quản lý cấu hình điểm
  DETAIL_POINT: '/configuration/point',

  // Danh sách đầu số điện thoại
  PHONE_CODES: '/configuration/phoneCodes',
  IDENTIFY_CODES: '/configuration/identifyCodes',
  // Quản lý dự án
  LIST_PROJECT: '/project/list',
  DELETE_PROJECT: '/project/delete/',
  ADD_PROJECT: '/project/create',
  // Thêm mới khu đất
  ADD_BLOCK_AREA: '/block/create',
  DETAIL_AREA: '/block/detail/',
  UPDATE_AREA: '/block/update/',
  LIST_AREA: '/block/list',
  DELETE_AREA: '/block/delete/',
  // căn hộ mẫu
  LIST_HOUSE_TEMPLATE: '/houseTemplate/list',
  HOUSE_TEMPLATE: '/houseTemplate/create',
  DELETE_HOUSE_TEMPLATE: '/houseTemplate/delete/',
  DETAIL_HOUSE_TEMPLATE: '/houseTemplate/detail/',
  UPDATE_HOUSE_TEMPLATE: '/houseTemplate/update/',
  // Danh sách con loại bất động sản
  LIST_PARENT_CHILD: '/realestate/listParentChild',
  DETAIL_PROJECT: '/project/detail/',
  UPDATE_PROJECT: '/project/update/',
  // Upload video
  UPLOAD_VIDEO: '/media/video',
  // Upload multifile
  UPLOAD_MULTI_FILE: '/media/file',
  UPLOAD_MULTI_FILE_10MB: '/media/multi-10',

  // settings
  SETTINGS: '/settings',
  SETTINGS_NEW_CONFIGS: '/settings/news-configs',
  // quản lí bds lẻ
  PROJECT_NEWS: '/project-news',
  // thành phố quận huyện
  CITY: '/city',
  DISTRICT: '/district',
  WARD: '/ward',
  MESSAGE: '/message',

  CMS_PERMISSION: '/cms-permission',

  FLOORS_BLOCK: '/house/floors/',
  APARTMENT_FLOOR: '/house/by-floor-block/',
};
