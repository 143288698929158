import React, { useState } from 'react';

export interface AppContextInterface {
  dummy: any;
  collapsedSidebar: boolean;
  onUpdateContext: (...params: any) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  dummy: null,
  collapsedSidebar: false,
  onUpdateContext: context => context,
});

export const AppProvider = (children: any) => {
  const onUpdateContext = (context: any) => {
    const newContext = { ...state, ...context };
    setState(newContext);
  };
  const [state, setState] = useState({
    dummy: null,
    collapsedSidebar: false,
    onUpdateContext,
  });

  return <AppContext.Provider value={{ ...state }}>{children}</AppContext.Provider>;
};

export default AppProvider;
