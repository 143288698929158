import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { FilterCustomerProject, HouseTransactionsRes } from 'pages/CustomerProject/customer-project.type';

const URL = '/project/transaction-commission/';

function useProjectTransactionCommissionService() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<HouseTransactionsRes[]>([]);
  const [total, setTotal] = useState<number>();

  const onGetData = async (query: FilterCustomerProject) => {
    setLoading(true);
    const res: any = await ApiHelper.fetch(URL, query);
    if (res && res.status === StatusResponse.SUCCESS) {
      setData(res.data);
      setTotal(res.total);
    }
    setLoading(false);
  };

  return {
    data,
    total,
    loading,
    onGetData,
  };
}

export default useProjectTransactionCommissionService;
