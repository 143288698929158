/* eslint-disable object-shorthand */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useDeleteArea from 'hooks/BlockArea/useDeleteArea';
import useListArea from 'hooks/BlockArea/useListArea';
import images from 'images';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHeaderBlockArea from './components/FilterHeaderBlockArea';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export interface Filter {
  status?: string;
  search?: string;
  page: number;
  limit: number;
}

function index() {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const query: any = useQuery();
  const [filter, setFilter] = useState<Filter>({
    status: '',
    search: query.get('name') ? query.get('name') : '',
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  // Lấy danh sách Area
  const [onFetchListArea, listArea, loading] = useListArea();
  // Xóa Block khu đất
  const [onDeleteArea, loadingDelete] = useDeleteArea();

  useEffect(() => {
    onFetchListArea(filter);
  }, [filter, loadingDelete]);

  // Tìm kiếm Tên
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, search: search });
    },
    {
      wait: 500,
    },
  );
  // Select trạng thái
  const onChangeStatus = (status: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status: status });
  };

  // Action xóa
  const onDelete = (_id: string) => () => {
    onDeleteArea(_id);
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.EDIT_BLOCKS_AREA + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  // Action phân trang
  const onPageChange = (page: number) => {
    setFilter({ ...filter, page: page });
    setPage(page);
  };

  // Render Column Table
  const renderColumns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: any) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: translate('block_area.code'),
      key: '_id',
      render: (project: any) => {
        return <span>{(project && project.code) || ''}</span>;
      },
    },
    {
      title: translate('block_area.name_project'),
      key: '_id',
      dataIndex: 'project',
      render: (project: any) => {
        return <span>{project ? project.name : ''}</span>;
      },
    },
    {
      title: translate('block_area.name'),
      key: '_id',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
      width: '20%',
    },
    {
      title: translate('block_area.type'),
      key: '_id',
      dataIndex: 'realEstate',
      render: (realEstate: any) => {
        return <span>{realEstate ? realEstate.name : ''}</span>;
      },
    },
    {
      title: translate('block_area.number_house'),
      key: '_id',
      dataIndex: 'lots',
      render: (lots: number) => {
        return <span>{lots}</span>;
      },
    },
    {
      title: translate('block_area.number_floors'),
      key: '_id',
      dataIndex: 'floors',
      render: (floors: number) => {
        return <span>{floors}</span>;
      },
    },
    {
      title: translate('status.name'),
      key: '_id',
      dataIndex: 'status',
      render: (status: string) => {
        if (status === BaseStatusProject.INACTIVE) {
          return <span>{translate('BaseStatusProject.INACTIVE')}</span>;
        } else if (status === BaseStatusProject.NOT_FOR_SALE) {
          return <span>{translate('BaseStatusProject.NOT_FOR_SALE')}</span>;
        } else {
          return <span>{translate('BaseStatusProject.FOR_SALE')}</span>;
        }
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
    },
  ];

  const onCheckImport = (data: string) => {
    if (data === 'success') {
      setFilter({ ...filter });
    }
  };
  return (
    <>
      <FilterHeaderBlockArea
        onSearch={(value: string) => run(value)}
        onChangeStatus={onChangeStatus}
        onCheckImport={onCheckImport}
      />

      <Row>
        {loading || loadingDelete ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            {listArea && (
              <Table
                key={1}
                dataSource={listArea?.data || []}
                columns={renderColumns}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
              ></Table>
            )}
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={listArea?.total}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default index;
