/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import React, { useEffect, useState } from 'react';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import { Row, Form, Col, Button } from 'antd';
import { translate } from 'translate/language';
import { useHistory } from 'react-router-dom';
import useTermsOfUseService from './terms-of-use.service';

function TermsOfUseScreen(): JSX.Element {
  const [form] = Form.useForm();
  const history = useHistory();

  const { onGetTerm, dataTerm, onUpdateTerm } = useTermsOfUseService();

  useEffect(() => {
    onGetTerm();
  }, []);

  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (dataTerm) {
      setContent(dataTerm.content);
    }
  }, [dataTerm]);

  const onFinish = (value: { content: string }) => {
    onUpdateTerm(value);
  };

  const handleChangeContent = (event: any, editor: any) => {
    form.setFieldsValue({
      content: editor.getData(),
    });
  };

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={20} offset={3}>
            <Row>
              {/* Nội dung */}
              <Col span={20}>
                <Row>
                  <Col span={7}>
                    <div>
                      <span style={{ color: 'red' }}>*</span> Nội dung
                    </div>
                  </Col>
                  <Col span={17}>
                    <Form.Item
                      name="content"
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: 'Nội dung không được để trống',
                        },
                      ]}
                    >
                      <CKEEditor onChange={handleChangeContent} data={content} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={20}>
                <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Col>
                    <Form.Item>
                      <Button type="primary" style={{ backgroundColor: 'red' }} onClick={() => history.goBack()}>
                        {translate('action.close')}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {translate('action.save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default TermsOfUseScreen;
