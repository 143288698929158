/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';

interface Props {
  dateRange: { startDate: Date; endDate: Date; key: string; color: string };
  handleSelect: (item: any) => void;
}

const DateRangePickerScreen = (props: Props) => {
  return (
    <DateRangePicker
      ranges={[props.dateRange]}
      onChange={item => props.handleSelect(item.selection)}
      editableDateInputs={true}
      fixedHeight={true}
    />
  );
};

export default DateRangePickerScreen;
