/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastError, ToastSuccess } from './../../components/Toast/index';
import { StatusResponse } from './../../constants/Status';
import { translate } from './../../translate/language';
import { EmailTemplateReq, EmailTemplateRes } from './email.types';

const EMAIL_TEMPLATE = '/configuration/email-template';
const DETAIL_EMAIL_TEMPLATE = '/configuration/email-template/detail';

function useEmailService() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataEmail, setDataEmail] = useState<EmailTemplateRes[]>([]);
  const [detailEmail, setDetailEmail] = useState<EmailTemplateRes | any>(undefined);

  // Danh sách email template
  const onGetEmail = async () => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(EMAIL_TEMPLATE);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataEmail(res.data);
        setLoading(false);
      } else {
        setDataEmail([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Chi tiết Email
  const onDetailEmail = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(`${DETAIL_EMAIL_TEMPLATE}?emailTemplateId=${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailEmail(res.data);
      } else {
        setDetailEmail(undefined);
      }
    } catch (error) {
      setDetailEmail(undefined);
    }
  };

  // Cập nhật Email template
  const onUpdateEmail = async (data: EmailTemplateReq) => {
    try {
      const res: any = await ApiHelper.put(EMAIL_TEMPLATE, data);
      if (res && res.status === StatusResponse.VALIDATION_ERROR) {
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.VALIDATION_ERROR_EMAIL'),
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };
  return { onGetEmail, dataEmail, loading, onUpdateEmail, onDetailEmail, detailEmail };
}

export default useEmailService;
