/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { translate } from 'translate/language';

function useUploadMultiFile() {
  const [resUploadMultiFile, setResUpload] = useState<any>();
  const [messageErrorMulti, setMessageError] = useState<string>('');

  const onUploadMultiFile = async (request: FormData) => {
    try {
      const res = await ApiHelper.postFormData<any>(BASE_URL.UPLOAD_MULTI_FILE, request, {
        'Content-Type': 'multipart/form-data',
      });
      if (res.status === StatusResponse.SUCCESS) {
        setResUpload(res?.data);
      } else {
        setMessageError(`${translate('upload_fail')}`);
        setResUpload(null);
      }
    } catch (error) {
      setMessageError(`${translate('upload_fail')}`);
      setResUpload(null);
    }
  };
  return { onUploadMultiFile, resUploadMultiFile, messageErrorMulti };
}

export default useUploadMultiFile;
