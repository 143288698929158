export enum NewsConfigType {
  PAID_METHOD_1 = 'PAID_METHOD_1',
  PAID_METHOD_2 = 'PAID_METHOD_2',
  FREE_METHOD = 'FREE_METHOD',
}

export interface NewsConfigurations {
  type: string;
  name: string;
  point: number;
  showingDays: number;
  notifyDays: number;
  image: number;
  description: string;
}
