import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListApartmentReq {
    page?: number;
    limit?: string | number;
    search?: string;
}

function useListApartment(): [(request: ListApartmentReq) => Promise<void>, any, boolean] {
    const [loading, setLoading] = useState<boolean>(false);
    const [listApartment, getListApartment] = useState([]);

    const onFetchListApartment = async (request: ListApartmentReq) => {
        try {
            setLoading(true);
            const res = await ApiHelper.fetch<ListApartmentReq, any>(BASE_URL.LIST_APARTMENT, request)
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false)
                getListApartment(res)
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return [onFetchListApartment, listApartment, loading]
}

export default useListApartment;