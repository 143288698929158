/* eslint-disable no-fallthrough */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
import { Col, Pagination, Row, Table } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { formatNumber } from 'helpers/Utils';
import React, { useEffect, useState } from 'react';
import { translate } from 'translate/language';
import useActiveService from './active.service';

export interface Filter {
  page: number | string;
  limit: number | string;
}

function ActiveScreen(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<Filter>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  const { onGetActive, dataActive, totalActive, loading } = useActiveService();

  useEffect(() => {
    onGetActive(filter);
  }, [filter]);

  const renderColumns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Hoạt động',
      key: '_id',
      dataIndex: 'content',
      render: (content: string) => {
        const data = JSON.parse(content);
        switch (data.fromModel) {
          case 'PROJECT_NEWS':
            return (
              <span>
                <b>{data.causedBy?.name}</b>
                {` vừa đăng tin `}
                <b>{data.affectedTo?.name}</b>
                {data.affectedTo?.price && `, giá bán : ${formatNumber(data.affectedTo?.price)}`}
              </span>
            );
          case 'PROJECT':
            return (
              <span>
                {`Dự án `}
                <b>{data.affectedTo?.name}</b>
                {` sắp mở bán ( ngày mở bán dự kiến ${data.affectedTo?.projectOpenDay}). Nhận booking ngay hôm nay`}
              </span>
            );
          case 'NEWS':
            return <span>Có 1 tin tức mới</span>;
          case 'USER':
            if (data.action === 'HAVE_NEW_COMER') {
              return (
                <span>
                  {`Chào mừng `}
                  <b>{`${data.affectedTo?.name}`}</b>
                  {` mới gia nhập hệ thống`}
                </span>
              );
            }
            if (data.action === 'NEW_COMER_JOINED_NETWORK') {
              return (
                <span>
                  <b>{`${data.affectedTo?.name}`}</b>
                  {' đã gia nhập hệ thống của '}
                  <b>{`${data.causedBy?.name}`}</b>
                </span>
              );
            }
          case 'TRANSACTION':
            return (
              <span>
                {`Chúc mừng khách hàng `}
                <b>{`${data.affectedTo?.name}`}</b>
                {` có 1 giao dịch thành công`}
              </span>
            );
          default:
            return '';
        }
      },
    },
    {
      title: 'Thời gian',
      key: '_id',
      dataIndex: 'createdAt',
      render: (createdAt: string) => {
        return <span>{createdAt}</span>;
      },
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  return (
    <>
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={dataActive || []}
              columns={renderColumns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            ></Table>
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalActive}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default ActiveScreen;
