/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable no-useless-escape */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Image, Input, InputNumber, message, Row, Select, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import { ToastError } from 'components/Toast';
import { BaseStatusProject } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import useListApartment from 'hooks/Apartment/useListApartment';
import useListArea, { BlockStatus } from 'hooks/BlockArea/useListArea';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import { STATUS_TYPE } from 'pages/HouseTemplate/modelHouseTemplate/HouseTemplate.type';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import './house.scss';
import useHouseService from './house.service';
import { HouseReq } from './types/house.type';

const { Text, Link } = Typography;

const { Option } = Select;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

function HouseDetailScreen(): JSX.Element {
  const [ImageFile, setImageFile] = useState<Media[]>([]);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const [checkLengthImage, setCheckLengthImage] = useState<boolean>(false);

  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const query = useQuery();
  const [form] = Form.useForm();
  const history = useHistory();
  const { onHouseTemplate, listHouseTemplate, listProject, onListProject } = useLitsHouseTemplate();
  const [onFetchListArea, listArea] = useListArea();
  const {
    onFetchDirection,
    dataDirection,
    onCreateHouse,
    onDetailHouse,
    dataDetailHouse,
    onUpdateHouse,
  } = useHouseService();
  const [onFetchListApartment, listApartment] = useListApartment();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onUpload, resUpload, messageError] = useUploadMulti();
  const [idHouse, setIdHouse] = useState<string | undefined>(undefined);
  const [typeMB, setTypeMB] = useState<boolean>(false);

  useEffect(() => {
    // Danh sách dự án
    onListProject({ status: STATUS_TYPE.ACTIVE });
    // Danh sách hướng nhà
    onFetchDirection();
    // Số phòng ngủ
    onFetchListApartment({});
  }, []);

  // Khi chỉnh sửa
  useEffect(() => {
    if (isEdit) {
      onDetailHouse(params.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && dataDetailHouse) {
      if (dataDetailHouse.projectId) {
        onFetchListArea({ project: dataDetailHouse.projectId._id, status: BlockStatus.ACTIVE });
        onHouseTemplate({
          projectId: dataDetailHouse.projectId._id,
        });
      }

      form.setFieldsValue({
        name: dataDetailHouse.name,
        floor: dataDetailHouse.floor,
        code: dataDetailHouse.code,
        area: dataDetailHouse.area,
        price: dataDetailHouse.price,
        bathroom: dataDetailHouse.bathroom,
        bedroom: dataDetailHouse.bedroom ? dataDetailHouse.bedroom._id : '',
        status: dataDetailHouse.status,
        directionId: dataDetailHouse.directionId ? dataDetailHouse.directionId.map(data => data._id) : [],
        blockId: dataDetailHouse.blockInfo ? dataDetailHouse.blockInfo._id : '',
        projectId: dataDetailHouse.projectId ? dataDetailHouse.projectId._id : '',
        builtupArea: dataDetailHouse.builtupArea || 0,
        carpetArea: dataDetailHouse.carpetArea || 0,
        houseTemplateId: dataDetailHouse.houseTemplateId ? dataDetailHouse.houseTemplateId._id : '',
        // commission: dataDetailHouse.commission,
        media: dataDetailHouse.media ? dataDetailHouse.media?._id : null,
      });

      if (dataDetailHouse.houseTemplateId) {
        setIdHouse(dataDetailHouse.houseTemplateId._id);
      }
      if (dataDetailHouse.media) {
        setImageFile([dataDetailHouse.media]);
      }
      setTypeMB(dataDetailHouse.bedroom ? false : true);
    }
  }, [dataDetailHouse]);

  const onFinishSubmit = (values: HouseReq) => {
    if (!typeMB && !values.bedroom) {
      return ToastError({
        message: 'Thất bại',
        description: 'Vui lòng chọn số phòng ngủ',
      });
    }
    if (ImageFile && ImageFile.length > 0) {
      setCheckLengthImage(false);
      values.media = ImageFile[0]._id;
    } else {
      setCheckLengthImage(true);
      return;
    }

    values.bedroom = values.bedroom ? values.bedroom : null;
    values.floor = values.floor ? values.floor : '';

    // Trường hợp copy
    if (query.get('type') === 'copy') {
      return onCreateHouse(values);
    }
    if (isEdit && dataDetailHouse) {
      return onUpdateHouse(params.id, values);
    }
    onCreateHouse(values);
  };

  // Khi nhấn vào Button hủy
  const closeButton = () => {
    history.goBack();
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      const newfileListScaleDetail = [...ImageFile].filter(e => e._id);
      setImageFile([...newfileListScaleDetail, ...resUpload]);
    }
  }, [resUpload]);

  const onChangeImageFiles = (fileList: any) => {
    const newData: any = [...ImageFile, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh thứ ${index} vượt quá dung lượng`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    setImageFile(newData);
    onUpload(data);
  };

  const onRemoveImage = (item: Media | any) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => deleteImage(item),
    });
  };

  // Xóa hình ảnh
  const deleteImage = (item: Media | any) => {
    const newArrImg = ImageFile.filter(data => {
      return (data as Media)._id !== (item as Media)._id;
    });
    setImageFile(newArrImg);
    message.success('Xoá ảnh thành công');
  };

  // Xem chi tiết hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal preview
  const onCancel = () => {
    setModalVisible(false);
  };

  const [checkProject, setCheckProject] = useState(false);

  // Chọn dự án
  const onChangeProject = (project: string) => {
    form.setFieldsValue({
      blockId: '',
      houseTemplateId: '',
    });
    onFetchListArea({ project, status: BlockStatus.ACTIVE });
    // Danh sách căn hộ mẫu
    onHouseTemplate({
      projectId: project,
    });
    setCheckProject(false);
  };

  // Chọn căn hộ mẫu
  const onSelectHouse = (house: string) => {
    setIdHouse(house);
    setCheckProject(true);
  };

  const onChangeSelect = (value: any, data: any) => {
    let type: boolean = false;
    if (data.key?.substring(1) === 'MATBANG') {
      type = true;
    } else {
      type = false;
    }
    setTypeMB(type);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).create ||
      getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).edit ? (
        <Row>
          <Modal title="" visible={isModalVisible} onCancel={onCancel} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
          <Col span={18} offset={3}>
            <Form form={form} onFinish={onFinishSubmit}>
              {/* Hình ảnh */}
              <Row>
                <Col span={4}>
                  <div className="nameLabel">{translate('house_template.image')}</div>
                </Col>
                <Col span={12}>
                  <UploadButtonImages
                    accept={'.gif, .jpg, .png, .webp'}
                    files={ImageFile}
                    onPreview={handlePreview}
                    onChange={onChangeImageFiles}
                    onRemove={onRemoveImage}
                    imageLength={1}
                  />
                  {!ImageFile.length && checkLengthImage && (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                      <div role="alert">Hình ảnh không được để trống</div>
                    </div>
                  )}
                </Col>
              </Row>
              {/* Dự án */}
              <Form.Item
                name="projectId"
                label={translate('house.projectId')}
                rules={[{ required: true, message: 'Vui lòng chọn dự án' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
              >
                <Select placeholder={translate('house.projectId')} onChange={onChangeProject}>
                  {listProject &&
                    listProject.length > 0 &&
                    listProject.map(data => {
                      return (
                        <Option value={data._id}>
                          {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {/* Tên block khu đất */}
              <Form.Item
                name="blockId"
                label={translate('house.blockId')}
                rules={[{ required: true, message: 'Vui lòng chọn tên block khu đất' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
              >
                <Select placeholder={translate('house.blockId')} onChange={onChangeSelect}>
                  {listArea.data &&
                    listArea.data.length > 0 &&
                    listArea.data.map((data: any, key: number) => {
                      return (
                        <Option value={data?._id} key={`${key}${data?.realEstate?.parentId.note}`}>
                          {data?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {/* Số tầng */}
              <Form.Item
                name="floor"
                label={translate('house.floor')}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Tầng không được để trống',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* Tên căn hộ - lô đất */}
              <Form.Item
                name="name"
                label={translate('house.nameId')}
                rules={[{ whitespace: true, required: true, message: 'Tên căn hộ/lô đất không được để trống' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
              >
                <Input />
              </Form.Item>
              {/* Mã căn hộ - lô đất */}
              <Form.Item
                name="code"
                label={translate('house.code')}
                rules={[{ whitespace: true, required: true, message: 'Mã không được để trống' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
              >
                <Input />
              </Form.Item>
              {/* Diện tích */}
              <Form.Item
                name="area"
                label={translate('house.area')}
                rules={[
                  {
                    required: true,
                    message: 'Diện tích không được để trống',
                  },
                  {
                    pattern: /^(\d+(?:[\.]\d{1,2})?)$/, // Check số thập phân
                    message: translate('messages.integer_field', {
                      label: translate('house.area'),
                    }),
                  },
                ]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                initialValue={0}
              >
                <InputNumber step={1} min={0} className="inputNumber" />
              </Form.Item>
              {/* Diện tích tim đường */}
              <Form.Item
                name="builtupArea"
                label={translate('house.builtupArea')}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                rules={[
                  {
                    pattern: /^(\d+(?:[\.]\d{1,2})?)$/, // Check số thập phân
                    message: translate('messages.integer_field', {
                      label: translate('house.builtupArea'),
                    }),
                  },
                ]}
                initialValue={0}
              >
                <InputNumber step={1} min={0} className="inputNumber" />
              </Form.Item>
              {/* Diện tích thông thuỷ */}
              <Form.Item
                name="carpetArea"
                label={translate('house.carpetArea')}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                initialValue={0}
                rules={[
                  {
                    pattern: /^(\d+(?:[\.]\d{1,2})?)$/, // Check số thập phân
                    message: translate('messages.integer_field', {
                      label: translate('house.builtupArea'),
                    }),
                  },
                ]}
              >
                <InputNumber step={1} min={0} className="inputNumber" />
              </Form.Item>
              {/* Hướng nhà */}
              <Form.Item
                name="directionId"
                label={translate('house.direction')}
                rules={[{ required: true, message: 'Vui lòng chọn hướng nhà' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
              >
                <Select placeholder={translate('house.direction')} maxTagTextLength={30}>
                  {dataDirection &&
                    dataDirection.length > 0 &&
                    dataDirection.map(data => {
                      return (
                        <Option value={data._id}>
                          {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {/* Giá bán */}
              <Form.Item
                name="price"
                label="Giá bán"
                rules={[{ required: true, message: 'Giá bán không được để trống' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                initialValue={0}
              >
                <InputNumber
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                  parser={(value: any) => value.replace(/(\.*)/g, '')}
                  step={1000}
                  min={0}
                  className="inputNumber"
                />
              </Form.Item>
              {/* Số phòng ngủ */}
              <Form.Item name="bedroom" label={translate('house.bedroom')} labelCol={{ sm: 4 }} labelAlign="left">
                <Select placeholder={translate('house.bedroom')}>
                  {listApartment.data &&
                    listApartment.data.length > 0 &&
                    listApartment.data.map((data: any) => {
                      return (
                        <Option value={data._id}>
                          {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {/* Số phòng vệ sinh */}
              <Form.Item
                name="bathroom"
                label={translate('house.bathroom')}
                rules={[{ required: true, message: 'Số phòng vệ sinh không được bỏ trống' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                initialValue={0}
              >
                <InputNumber step={1} min={0} className="inputNumber" />
              </Form.Item>
              {/* Căn hộ mẫu */}
              <Form.Item
                name="houseTemplateId"
                label={translate('house.houseTemplate')}
                rules={[{ required: true, message: 'Vui lòng chọn căn hộ mẫu' }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
              >
                <Select placeholder={translate('house.houseTemplate')} onSelect={onSelectHouse}>
                  {listHouseTemplate &&
                    listHouseTemplate.length > 0 &&
                    listHouseTemplate.map(data => {
                      return (
                        <Option value={data._id}>
                          {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              {idHouse !== undefined && checkProject && (
                <Row style={{ marginTop: '-25px', marginBottom: '5px' }}>
                  <Col span={10} offset={4}>
                    <Link href={`${ROUTER_PATH.EDIT_PROJECTS_HOUSETEMPLATE}${idHouse}`} target="_blank">
                      <Text strong underline>
                        Chi tiết căn hộ mẫu
                      </Text>
                    </Link>
                  </Col>
                </Row>
              )}

              {/* Hoa hồng */}
              {/* <Form.Item
            name="commission"
            label="Hoa hồng"
            rules={[{ required: true, message: 'Hoa hồng không được để trống' }]}
            labelCol={{ sm: 4 }}
            labelAlign="left"
          >
            <InputNumber
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
              parser={(value: any) => value.replace(/(\.*)/g, '')}
              step={1000}
              min={0}
              className="inputNumber"
            />
            
          </Form.Item> */}
              {/* Trạng thái */}
              <Form.Item
                name="status"
                label={translate('house.status')}
                rules={[{ required: true }]}
                labelCol={{ sm: 4 }}
                labelAlign="left"
                initialValue={BaseStatusProject.FOR_SALE}
              >
                <Select placeholder={translate('house.status')}>
                  <Option value={BaseStatusProject.NOT_FOR_SALE}>{translate('BaseStatusProject.NOT_FOR_SALE')}</Option>
                  <Option value={BaseStatusProject.FOR_SALE}>{translate('BaseStatusProject.FOR_SALE')}</Option>
                  <Option value={BaseStatusProject.PAYMENT}>{translate('BaseStatusProject.PAYMENT')}</Option>
                  <Option value={BaseStatusProject.SOLD}>{translate('BaseStatusProject.SOLD')}</Option>
                  <Option value={BaseStatusProject.DEPOSITED}>{translate('BaseStatusProject.DEPOSITED')}</Option>
                </Select>
              </Form.Item>
              <Col span={24}>
                <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Col>
                    <Form.Item>
                      <Button type="primary" style={{ backgroundColor: 'red' }} onClick={closeButton}>
                        {translate('action.close')}
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {translate('action.save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Form>
          </Col>
        </Row>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default HouseDetailScreen;
