import React from "react";
import { Layout } from "antd";
import Styled from "./indexStyle";
const { Content } = Layout;

// const Container = (props: any) => {
//   return (
//     <>
//       <Styled.Container>
//         <Content className="content">{props.childrent}</Content>
//       </Styled.Container>
//     </>
//   );
// };

// export default Container;

export default function Container({ children }: any) {
  return (
    <Styled.Container>
      <Content className="content">{children}</Content>
    </Styled.Container>
  );
}
