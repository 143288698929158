/* eslint-disable no-restricted-globals */
/* eslint-disable dot-notation */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ConstructorType, NAME_CONSTRUCTOR } from 'constants/ConstructorType';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { IS_PHONE_NUMBER, LENGTH_BEGIN } from 'constants/SubstringPhone';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useAddConstructor from 'hooks/Constructor/useAddConstructor';
import useDetailConstructor from 'hooks/Constructor/useDetailConstructor';
import useUpdateConstructor from 'hooks/Constructor/useUpdateConstructor';
import usePhoneCheck from 'hooks/ValidateMatch/usePhoneCheck';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';

const { Option } = Select;

interface Props {}
function FormConstructorPage(props: Props) {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_CONSTRUCTOR);
  // Thêm mới dữ liệu
  const [onAddConstructor, loading] = useAddConstructor();
  // Lấy chi tiết Admin
  const [useGetDetailConstructor, detailConstructor] = useDetailConstructor();
  // Cập nhật dữ liệu
  const [onUpdateConstructor] = useUpdateConstructor();

  // Get danh sách số điện thoại
  const { onGetPhoneCode, listPhone, loadingPhone } = usePhoneCheck();
  useEffect(() => {
    onGetPhoneCode();
  }, []);

  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      useGetDetailConstructor(params.id);
    } else {
      form.setFieldsValue({
        description: cacheForm.description,
        email: cacheForm.email,
        name: cacheForm.name,
        phone: cacheForm.phone,
        status: cacheForm.status,
        type: cacheForm.type,
      });
    }
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailConstructor) {
      form.setFieldsValue({
        description: detailConstructor.description,
        email: detailConstructor.email,
        name: detailConstructor.name,
        phone: detailConstructor.phone,
        status: detailConstructor.status,
        type: detailConstructor.type,
      });
    }
  }, [detailConstructor]);

  const handleSubmit = () => {
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: StatusType.ACTIVE });
    form.validateFields().then(values => {
      const data = {
        description: values?.description,
        email: values?.email,
        name: values.name,
        phone: values?.phone,
        status: values.status,
        type: values.type,
      };
      if (isEdit) {
        const dataUpdate = { ...data, _id: params.id };
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_CONSTRUCTOR);
        onUpdateConstructor(params.id, dataUpdate);
      } else {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_CONSTRUCTOR);
        onAddConstructor(data);
      }
    });
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_CONSTRUCTOR, form.getFieldsValue());
  };

  // Validate dữ liệu
  const validateForm = {
    name: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('constructor.name'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('constructor.name_validate'),
      },
    ],
    description: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('constructor.description'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        max: 500,
        message: translate('constructor.description_validate'),
      },
    ],
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_ADMIN);
    history.back();
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).create ||
      getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).edit ? (
        <Form onFieldsChange={updateCacheForm} form={form}>
          <Row>
            <Col span={20} offset={3}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="status"
                    label={translate('status.name')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    initialValue={StatusType.ACTIVE}
                  >
                    <Select
                      placeholder={translate('admin.status_active')}
                      // defaultValue={StatusType.ACTIVE}
                    >
                      <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                      <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="type"
                    label={translate('constructor.type')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: translate('require_field', {
                          label: translate('constructor.type'),
                        }),
                        type: 'array',
                      },
                    ]}
                  >
                    <Select mode="multiple" placeholder={translate('constructor.type')}>
                      <Option value={ConstructorType.INVESTOR}>
                        {translate(NAME_CONSTRUCTOR[ConstructorType.INVESTOR])}
                      </Option>
                      <Option value={ConstructorType.DESIGN}>
                        {translate(NAME_CONSTRUCTOR[ConstructorType.DESIGN])}
                      </Option>
                      <Option value={ConstructorType.DEVELOPER}>
                        {' '}
                        {translate(NAME_CONSTRUCTOR[ConstructorType.DEVELOPER])}
                      </Option>
                      <Option value={ConstructorType.CONTRACTOR}>
                        {translate(NAME_CONSTRUCTOR[ConstructorType.CONTRACTOR])}
                      </Option>
                      <Option value={ConstructorType.MARKETING}>
                        {translate(NAME_CONSTRUCTOR[ConstructorType.MARKETING])}
                      </Option>
                      <Option value={ConstructorType.OPERATOR}>
                        {translate(NAME_CONSTRUCTOR[ConstructorType.OPERATOR])}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="name"
                      label={translate('constructor.name')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={validateForm.name}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="description"
                      label={translate('direction.description')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={validateForm.description}
                    >
                      <TextArea rows={7} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="phone"
                      label={translate('constructor.phone')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: translate('require_field', {
                            label: translate('constructor.phone'),
                          }),
                        },
                        ({ getFieldValue }) => ({
                          required: true,
                          validator(rule, value) {
                            if (typeof value !== 'undefined' && value != null) {
                              // Kiểm tra xem giá trị truyền vào có phải là số không
                              if (!value.match(/^-?\d+$/)) {
                                if (value.length > LENGTH_BEGIN.ZERO) {
                                  return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                }
                              }
                              // Nếu giá trị để trông => Cho qua
                              if (value.length === LENGTH_BEGIN.ZERO) {
                                return Promise.resolve();
                              }
                              // Nếu có giá trị kiểm tra có phải là số điện thoại không
                              if (value.length > LENGTH_BEGIN.ZERO) {
                                // Kiểm tra đầu có có đúng định dạng với dữ liệu có sẵn không
                                if (listPhone && listPhone.some(data => value.startsWith(data['code']))) {
                                  // Trường hợp đúng với dữ liệu => Check số điện thoại có đúng định dạng không
                                  if (value.length === IS_PHONE_NUMBER) {
                                    return Promise.resolve();
                                  }
                                  // Số điện thoại không đúng định dạng => Lỗi
                                  return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                } else {
                                  // Đầu số không đúng dữ liệu => Lỗi
                                  return Promise.reject(`${translate('constructor.phone_require_validate')}`);
                                }
                              }
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="email"
                      label={translate('constructor.email')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          type: 'email',
                          message: translate('constructor.email_validate'),
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" onClick={handleSubmit} htmlType="submit">
                          {isEdit ? translate('action.update') : translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormConstructorPage;
