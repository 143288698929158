/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
/* eslint-disable no-shadow */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable object-shorthand */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useDebounceFn } from 'ahooks';
import { Col, Form, Input, Pagination, Row, Table, Tag, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { UserInfo } from 'pages/RealEstateNewPage/real-estate-new.types';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { SearchOutlined } from '@ant-design/icons';
import { translate } from 'translate/language';
import { truncateString } from 'helpers/String';
import { formatNumberPrice } from 'helpers/FormatPrice';
import moment from 'moment';
import { FilterTransactions, ProjectNewsTransactionsRes } from 'pages/SaleAndRental/transactions.type';
import useProjectTransactionCommissionService from './service';

const { Text, Link } = Typography;

function ProjectNewsTransactionCommissionScreen(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterTransactions>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    projectNewsId: undefined,
  });
  const { data, total, onGetData, loading } = useProjectTransactionCommissionService();

  useEffect(() => {
    onGetData(filter);
  }, [filter]);

  const renderCommissionType = (type: string) => {
    switch (type) {
      case 'COMMISSION_RETAIL_REAL_ESTATE_INTRODUCER':
        return 'HH cho CTV giới thiệu';
      case 'COMMISSION_RETAIL_REAL_ESTATE_SELLER':
        return 'HH cho người bán';
      case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_INTRODUCE':
        return 'HH cho hệ thống CTV giới thiệu';
      case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_CUSTOMER':
        return 'HH cho hệ thống khách hàng';
      case 'COMMISSION_RETAIL_REAL_ESTATE_PAG':
        return 'HH cho Phúc An Gia';
      case 'COMMISSION_RETAIL_REAL_ESTATE_CUSTOMER':
        return 'HH cho người mua';
      default:
        return '';
    }
  };

  const renderCommissionStatus = (status: string) => {
    switch (status) {
      case 'PENDING_APPROVED':
        return 'Chờ duyệt';
      case 'APPROVED':
        return 'Đã duyệt';
      case 'CANCEL':
        return 'Đã huỷ';
      case 'PENDING_PAYMENT':
        return 'Chờ chi trả';
      default:
        return '';
    }
  };

  const columns = [
    {
      title: translate('customer_project.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
      fixed: 'left' as any,
      width: '5%',
    },
    {
      title: 'Mã giao dịch',
      key: '_id',
      render: (item: ProjectNewsTransactionsRes) => {
        return (
          <Link href={ROUTER_PATH.DETAIL_RELATED + item._id} target="_blank">
            <Tag style={{ color: '#12a0c0', textDecoration: 'underline', cursor: 'pointer' }}>{item.code}</Tag>
          </Link>
        );
      },
      fixed: 'left' as any,
      width: '5%',
    },
    {
      title: translate('customer_project.name_customer'),
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return (
          userInfo && (
            <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo._id} target="_blank">
              <Text underline style={{ color: '#12a0c0' }}>
                {userInfo.name}
              </Text>
            </Link>
          )
        );
      },
      fixed: 'left' as any,
    },
    {
      title: 'Sản Phẩm',
      key: '_id',
      render: (item: ProjectNewsTransactionsRes) => {
        return (
          <Link href={ROUTER_PATH.SALES_AND_RENTAL_EDIT + item.projectNewsId} target="_blank">
            <Text underline style={{ color: '#12a0c0' }}>
              {item.projectNewsInfo?.landName}
            </Text>
          </Link>
        );
      },
    },
    {
      title: 'Hoa hồng',
      key: '_id',
      render: (item: ProjectNewsTransactionsRes) => {
        return (
          <table style={{ border: '1px solid #e1e1e1', borderRadius: '4px', padding: '5px' }}>
            <thead>
              <tr>
                <th>Người nhận</th>
                <th>Số tiền</th>
                <th>Vai trò</th>
                <th>Trạng thái</th>
                <th>Thời gian nhận</th>
              </tr>
            </thead>
            <tbody>
              {item.commissionHistoryInfo.length > 0 &&
                item.commissionHistoryInfo.map((h: any) => {
                  return (
                    <tr key={h._id}>
                      <td style={{ padding: '2px' }}>
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + h.userInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }}>
                            {h.userInfo?.name}
                          </Text>
                        </Link>
                      </td>
                      <td>
                        <Tag>{formatNumberPrice(h.money)}</Tag>
                      </td>
                      <td style={{ fontSize: '11px' }}>{renderCommissionType(h.type)}</td>
                      <td>
                        <Tag>{renderCommissionStatus(h.status)}</Tag>
                      </td>
                      <td style={{ fontSize: '11px' }}>{moment(h.receivedDate).format('HH:mm DD/MM/YYYY')}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        );
      },
      align: 'center' as any,
    },
  ];

  const { run } = useDebounceFn(
    (data: string) => {
      if (data === '') {
        setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: undefined });
        return;
      } else {
        setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, search: data });
      }
    },
    {
      wait: 500,
    },
  );

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  return (
    <>
      {/* <Row>
        <Form.Item name="search">
          <Input
            size="small"
            placeholder="Tìm theo mã giao dịch"
            onChange={e => run(e.target.value)}
            suffix={<SearchOutlined />}
            style={{ width: '300px' }}
          />
        </Form.Item>
      </Row> */}
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <Col span={24}>
              <Table dataSource={data || []} columns={columns} pagination={false} scroll={{ x: 'auto' }} />
            </Col>
            <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div className="d-flex justify-content-end pagination">
                <Pagination
                  showSizeChanger={false}
                  total={total}
                  current={page}
                  defaultCurrent={page}
                  defaultPageSize={PaginateList.LIMIT_DEFAULT}
                  onChange={onPageChange}
                ></Pagination>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default ProjectNewsTransactionCommissionScreen;
