/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, InputNumber, Row } from 'antd';
import PageLoading from 'components/PageLoading';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { useEffect } from 'react';
import { translate } from 'translate/language';
import './commissions.scss';
import useCommissionService from './commissions.service';

function CommissionsScreen(): JSX.Element {
  const [form] = Form.useForm();
  const {
    onFetchProjectComission,
    dataCommission,
    loading,
    privateCommissionF1,
    privateCommissionNonF1,
    companyCommissionF1,
    companyCommissionNonF1,
    onUpdateProjectCommission,
  } = useCommissionService();

  useEffect(() => {
    onFetchProjectComission();
  }, []);

  const onFinish = (values: any) => {
    const data = {
      privateCommission: {
        f1Commission: {
          no1: {
            pag: values.no1_pag || 0,
            introducer: values.no1_introducer || 0,
            systemIntroducer: values.no1_systemIntroducer || 0,
            supporter: values.no1_supporter || 0,
            customer: values.no1_customer || 0,
            systemCustomer: values.no1_systemCustomer || 0,
          },
          no2: {
            pag: values.no2_pag || 0,
            introducer: values.no2_introducer || 0,
            systemIntroducer: values.no2_systemIntroducer || 0,
            supporter: values.no2_supporter || 0,
            customer: values.no2_customer || 0,
            systemCustomer: values.no2_systemCustomer || 0,
          },
          no3: {
            pag: values.no3_pag || 0,
            introducer: values.no3_introducer || 0,
            systemIntroducer: values.no3_systemIntroducer || 0,
            supporter: values.no3_supporter || 0,
            customer: values.no3_customer || 0,
            systemCustomer: values.no3_systemCustomer || 0,
          },
          no4: {
            pag: values.no4_pag || 0,
            introducer: values.no4_introducer || 0,
            systemIntroducer: values.no4_systemIntroducer || 0,
            supporter: values.no4_supporter || 0,
            customer: values.no4_customer || 0,
            systemCustomer: values.no4_systemCustomer || 0,
          },
          from5: {
            pag: values.from5_pag || 0,
            introducer: values.from5_introducer || 0,
            systemIntroducer: values.from5_systemIntroducer || 0,
            supporter: values.from5_supporter || 0,
            customer: values.from5_customer || 0,
            systemCustomer: values.from5_systemCustomer || 0,
          },
        },
        nonF1Commission: {
          no1: {
            pag: values.no1_pag_p || 0,
            introducer: values.no1_introducer_p || 0,
            systemIntroducer: values.no1_systemIntroducer_p || 0,
            supporter: values.no1_supporter_p || 0,
            customer: values.no1_customer_p || 0,
            systemCustomer: values.no1_systemCustomer_p || 0,
          },
          no2: {
            pag: values.no2_pag_p || 0,
            introducer: values.no2_introducer_p || 0,
            systemIntroducer: values.no2_systemIntroducer_p || 0,
            supporter: values.no2_supporter_p || 0,
            customer: values.no2_customer_p || 0,
            systemCustomer: values.no2_systemCustomer_p || 0,
          },
          no3: {
            pag: values.no3_pag_p || 0,
            introducer: values.no3_introducer_p || 0,
            systemIntroducer: values.no3_systemIntroducer_p || 0,
            supporter: values.no3_supporter_p || 0,
            customer: values.no3_customer_p || 0,
            systemCustomer: values.no3_systemCustomer_p || 0,
          },
          no4: {
            pag: values.no4_pag_p || 0,
            introducer: values.no4_introducer_p || 0,
            systemIntroducer: values.no4_systemIntroducer_p || 0,
            supporter: values.no4_supporter_p || 0,
            customer: values.no4_customer_p || 0,
            systemCustomer: values.no4_systemCustomer_p || 0,
          },
          from5: {
            pag: values.from5_pag_p || 0,
            introducer: values.from5_introducer_p || 0,
            systemIntroducer: values.from5_systemIntroducer_p || 0,
            supporter: values.from5_supporter_p || 0,
            customer: values.from5_customer_p || 0,
            systemCustomer: values.from5_systemCustomer_p || 0,
          },
        },
      },
      companyCommission: {
        f1Commission: {
          no1: {
            pag: values.no1_pag_c || 0,
            introducer: values.no1_introducer_c || 0,
            systemIntroducer: values.no1_systemIntroducer_c || 0,
            supporter: values.no1_supporter_c || 0,
            customer: values.no1_customer_c || 0,
            systemCustomer: values.no1_systemCustomer_c || 0,
          },
          no2: {
            pag: values.no2_pag_c || 0,
            introducer: values.no2_introducer_c || 0,
            systemIntroducer: values.no2_systemIntroducer_c || 0,
            supporter: values.no2_supporter_c || 0,
            customer: values.no2_customer_c || 0,
            systemCustomer: values.no2_systemCustomer_c || 0,
          },
          no3: {
            pag: values.no3_pag_c || 0,
            introducer: values.no3_introducer_c || 0,
            systemIntroducer: values.no3_systemIntroducer_c || 0,
            supporter: values.no3_supporter_c || 0,
            customer: values.no3_customer_c || 0,
            systemCustomer: values.no3_systemCustomer_c || 0,
          },
          no4: {
            pag: values.no4_pag_c || 0,
            introducer: values.no4_introducer_c || 0,
            systemIntroducer: values.no4_systemIntroducer_c || 0,
            supporter: values.no4_supporter_c || 0,
            customer: values.no4_customer_c || 0,
            systemCustomer: values.no4_systemCustomer_c || 0,
          },
          from5: {
            pag: values.from5_pag_c || 0,
            introducer: values.from5_introducer_c || 0,
            systemIntroducer: values.from5_systemIntroducer_c || 0,
            supporter: values.from5_supporter_c || 0,
            customer: values.from5_customer_c || 0,
            systemCustomer: values.from5_systemCustomer_c || 0,
          },
        },
        nonF1Commission: {
          no1: {
            pag: values.no1_pag_nonf1 || 0,
            introducer: values.no1_introducer_nonf1 || 0,
            systemIntroducer: values.no1_systemIntroducer_nonf1 || 0,
            supporter: values.no1_supporter_nonf1 || 0,
            customer: values.no1_customer_nonf1 || 0,
            systemCustomer: values.no1_systemCustomer_nonf1 || 0,
          },
          no2: {
            pag: values.no2_pag_nonf1 || 0,
            introducer: values.no2_introducer_nonf1 || 0,
            systemIntroducer: values.no2_systemIntroducer_nonf1 || 0,
            supporter: values.no2_supporter_nonf1 || 0,
            customer: values.no2_customer_nonf1 || 0,
            systemCustomer: values.no2_systemCustomer_nonf1 || 0,
          },
          no3: {
            pag: values.no3_pag_nonf1 || 0,
            introducer: values.no3_introducer_nonf1 || 0,
            systemIntroducer: values.no3_systemIntroducer_nonf1 || 0,
            supporter: values.no3_supporter_nonf1 || 0,
            customer: values.no3_customer_nonf1 || 0,
            systemCustomer: values.no3_systemCustomer_nonf1 || 0,
          },
          no4: {
            pag: values.no4_pag_nonf1 || 0,
            introducer: values.no4_introducer_nonf1 || 0,
            systemIntroducer: values.no4_systemIntroducer_nonf1 || 0,
            supporter: values.no4_supporter_nonf1 || 0,
            customer: values.no4_customer_nonf1 || 0,
            systemCustomer: values.no4_systemCustomer_nonf1 || 0,
          },
          from5: {
            pag: values.from5_pag_nonf1 || 0,
            introducer: values.from5_introducer_nonf1 || 0,
            systemIntroducer: values.from5_systemIntroducer_nonf1 || 0,
            supporter: values.from5_supporter_nonf1 || 0,
            customer: values.from5_customer_nonf1 || 0,
            systemCustomer: values.from5_systemCustomer_nonf1 || 0,
          },
        },
      },
    };
    onUpdateProjectCommission(data);
  };
  return (
    <>
      {loading ? (
        <PageLoading />
      ) : (
        <Form form={form} onFinish={onFinish}>
          <table id="project_commission">
            <tr>
              <th style={{ width: '140px', textAlign: 'center' }}>{translate('project_commission.role')}</th>
              <th>{translate('project_commission.buy_customer')}</th>
              <th>{translate('project_commission.deal')}</th>
              <th>{translate('project_commission.pag')}</th>
              <th>{translate('project_commission.introducer')}</th>
              <th>{translate('project_commission.systemIntroducer')}</th>
              <th>{translate('project_commission.supporter')}</th>
              <th>{translate('project_commission.customer')}</th>
              <th>{translate('project_commission.systemCustomer')}</th>
            </tr>
            {dataCommission && (
              <tbody>
                {/* Cá nhân */}
                <tr>
                  <td rowSpan={10}>
                    <b>{translate('project_commission.private')}</b>
                  </td>
                  {/* Có con */}
                  <td rowSpan={5}>
                    <b>{translate('project_commission.f1')}</b>
                  </td>
                  <td>
                    <b>1</b>
                  </td>
                  <td>
                    <Form.Item name="no1_pag" initialValue={privateCommissionF1?.no1.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} value={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_introducer"
                      initialValue={privateCommissionF1?.no1.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemIntroducer"
                      initialValue={privateCommissionF1?.no1.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_supporter"
                      initialValue={privateCommissionF1?.no1.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_customer"
                      initialValue={privateCommissionF1?.no1.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemCustomer"
                      initialValue={privateCommissionF1?.no1.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>2</b>
                  </td>
                  <td>
                    <Form.Item name="no2_pag" initialValue={privateCommissionF1?.no2.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_introducer"
                      initialValue={privateCommissionF1?.no2.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemIntroducer"
                      initialValue={privateCommissionF1?.no2.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_supporter"
                      initialValue={privateCommissionF1?.no2.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_customer"
                      initialValue={privateCommissionF1?.no2.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemCustomer"
                      initialValue={privateCommissionF1?.no2.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 3 */}
                <tr>
                  <td>
                    <b>3</b>
                  </td>
                  <td>
                    <Form.Item name="no3_pag" initialValue={privateCommissionF1?.no3.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_introducer"
                      initialValue={privateCommissionF1?.no3.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemIntroducer"
                      initialValue={privateCommissionF1?.no3.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_supporter"
                      initialValue={privateCommissionF1?.no3.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_customer"
                      initialValue={privateCommissionF1?.no3.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemCustomer"
                      initialValue={privateCommissionF1?.no3.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 4 */}
                <tr>
                  <td>
                    <b>4</b>
                  </td>
                  <td>
                    <Form.Item name="no4_pag" initialValue={privateCommissionF1?.no4.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_introducer"
                      initialValue={privateCommissionF1?.no4.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemIntroducer"
                      initialValue={privateCommissionF1?.no4.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_supporter"
                      initialValue={privateCommissionF1?.no4.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_customer"
                      initialValue={privateCommissionF1?.no4.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemCustomer"
                      initialValue={privateCommissionF1?.no4.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* from 5 */}
                <tr>
                  <td>
                    <b>{translate('project_commission.from_5')}</b>
                  </td>
                  <td>
                    <Form.Item name="from5_pag" initialValue={privateCommissionF1?.from5.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_introducer"
                      initialValue={privateCommissionF1?.from5.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemIntroducer"
                      initialValue={privateCommissionF1?.from5.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_supporter"
                      initialValue={privateCommissionF1?.from5.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_customer"
                      initialValue={privateCommissionF1?.from5.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemCustomer"
                      initialValue={privateCommissionF1?.from5.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* Không có con */}
                <tr>
                  <td rowSpan={5}>
                    <b>{translate('project_commission.non_f1')}</b>
                  </td>
                  <td>
                    <b>1</b>
                  </td>
                  <td>
                    <Form.Item name="no1_pag_p" initialValue={privateCommissionNonF1?.no1.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_introducer_p"
                      initialValue={privateCommissionNonF1?.no1.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemIntroducer_p"
                      initialValue={privateCommissionNonF1?.no1.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_supporter_p"
                      initialValue={privateCommissionNonF1?.no1.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_customer_p"
                      initialValue={privateCommissionNonF1?.no1.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemCustomer_p"
                      initialValue={privateCommissionNonF1?.no1.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 2 */}
                <tr>
                  <td>
                    <b>2</b>
                  </td>
                  <td>
                    <Form.Item name="no2_pag_p" initialValue={privateCommissionNonF1?.no2.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_introducer_p"
                      initialValue={privateCommissionNonF1?.no2.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemIntroducer_p"
                      initialValue={privateCommissionNonF1?.no2.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_supporter_p"
                      initialValue={privateCommissionNonF1?.no2.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_customer_p"
                      initialValue={privateCommissionNonF1?.no2.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemCustomer_p"
                      initialValue={privateCommissionNonF1?.no2.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 3 */}
                <tr>
                  <td>
                    <b>3</b>
                  </td>
                  <td>
                    <Form.Item name="no3_pag_p" initialValue={privateCommissionNonF1?.no3.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_introducer_p"
                      initialValue={privateCommissionNonF1?.no3.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemIntroducer_p"
                      initialValue={privateCommissionNonF1?.no3.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_supporter_p"
                      initialValue={privateCommissionNonF1?.no3.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_customer_p"
                      initialValue={privateCommissionNonF1?.no3.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemCustomer_p"
                      initialValue={privateCommissionNonF1?.no3.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 4 */}
                <tr>
                  <td>
                    <b>4</b>
                  </td>
                  <td>
                    <Form.Item name="no4_pag_p" initialValue={privateCommissionNonF1?.no4.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_introducer_p"
                      initialValue={privateCommissionNonF1?.no4.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemIntroducer_p"
                      initialValue={privateCommissionNonF1?.no4.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_supporter_p"
                      initialValue={privateCommissionNonF1?.no4.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_customer_p"
                      initialValue={privateCommissionNonF1?.no4.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemCustomer_p"
                      initialValue={privateCommissionNonF1?.no4.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* from 5 */}
                <tr>
                  <td>
                    <b>{translate('project_commission.from_5')}</b>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_pag_p"
                      initialValue={privateCommissionNonF1?.from5.pag}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_introducer_p"
                      initialValue={privateCommissionNonF1?.from5.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemIntroducer_p"
                      initialValue={privateCommissionNonF1?.from5.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_supporter_p"
                      initialValue={privateCommissionNonF1?.from5.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_customer_p"
                      initialValue={privateCommissionNonF1?.from5.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemCustomer_p"
                      initialValue={privateCommissionNonF1?.from5.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* ----------------------- Doanh nghiệp ------------------------- */}
                <tr className="borderTop">
                  <td rowSpan={10}>
                    <b>{translate('project_commission.company')}</b>
                  </td>
                  {/* Có con */}
                  <td rowSpan={5}>
                    <b>{translate('project_commission.f1')}</b>
                  </td>
                  <td>
                    <b>1</b>
                  </td>
                  <td>
                    <Form.Item name="no1_pag_c" initialValue={companyCommissionF1?.no1.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_introducer_c"
                      initialValue={companyCommissionF1?.no1.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemIntroducer_c"
                      initialValue={companyCommissionF1?.no1.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_supporter_c"
                      initialValue={companyCommissionF1?.no1.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_customer_c"
                      initialValue={companyCommissionF1?.no1.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemCustomer_c"
                      initialValue={companyCommissionF1?.no1.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 2 */}
                <tr>
                  <td>
                    <b>2</b>
                  </td>
                  <td>
                    <Form.Item name="no2_pag_c" initialValue={companyCommissionF1?.no2.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_introducer_c"
                      initialValue={companyCommissionF1?.no2.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemIntroducer_c"
                      initialValue={companyCommissionF1?.no2.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_supporter_c"
                      initialValue={companyCommissionF1?.no2.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_customer_c"
                      initialValue={companyCommissionF1?.no2.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemCustomer_c"
                      initialValue={companyCommissionF1?.no2.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 3 */}
                <tr>
                  <td>
                    <b>3</b>
                  </td>
                  <td>
                    <Form.Item name="no3_pag_c" initialValue={companyCommissionF1?.no3.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_introducer_c"
                      initialValue={companyCommissionF1?.no3.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemIntroducer_c"
                      initialValue={companyCommissionF1?.no3.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_supporter_c"
                      initialValue={companyCommissionF1?.no3.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_customer_c"
                      initialValue={companyCommissionF1?.no3.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemCustomer_c"
                      initialValue={companyCommissionF1?.no3.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 4 */}
                <tr>
                  <td>
                    <b>4</b>
                  </td>
                  <td>
                    <Form.Item name="no4_pag_c" initialValue={companyCommissionF1?.no4.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_introducer_c"
                      initialValue={companyCommissionF1?.no4.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemIntroducer_c"
                      initialValue={companyCommissionF1?.no4.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_supporter_c"
                      initialValue={companyCommissionF1?.no4.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_customer_c"
                      initialValue={companyCommissionF1?.no4.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemCustomer_c"
                      initialValue={companyCommissionF1?.no4.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* from 5 */}
                <tr>
                  <td>
                    <b>{translate('project_commission.from_5')}</b>
                  </td>
                  <td>
                    <Form.Item name="from5_pag_c" initialValue={companyCommissionF1?.from5.pag} className="marginNone">
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_introducer_c"
                      initialValue={companyCommissionF1?.from5.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemIntroducer_c"
                      initialValue={companyCommissionF1?.from5.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_supporter_c"
                      initialValue={companyCommissionF1?.from5.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_customer_c"
                      initialValue={companyCommissionF1?.from5.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemCustomer_c"
                      initialValue={companyCommissionF1?.from5.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* Không có con */}
                <tr>
                  <td rowSpan={5}>
                    <b>{translate('project_commission.non_f1')}</b>
                  </td>
                  <td>
                    <b>1</b>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_pag_nonf1"
                      initialValue={companyCommissionNonF1?.no1.pag}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_introducer_nonf1"
                      initialValue={companyCommissionNonF1?.no1.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemIntroducer_nonf1"
                      initialValue={companyCommissionNonF1?.no1.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_supporter_nonf1"
                      initialValue={companyCommissionNonF1?.no1.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_customer_nonf1"
                      initialValue={companyCommissionNonF1?.no1.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no1_systemCustomer_nonf1"
                      initialValue={companyCommissionNonF1?.no1.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 2 */}
                <tr>
                  <td>
                    <b>2</b>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_pag_nonf1"
                      initialValue={companyCommissionNonF1?.no2.pag}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_introducer_nonf1"
                      initialValue={companyCommissionNonF1?.no2.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemIntroducer_nonf1"
                      initialValue={companyCommissionNonF1?.no2.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_supporter_nonf1"
                      initialValue={companyCommissionNonF1?.no2.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_customer_nonf1"
                      initialValue={companyCommissionNonF1?.no2.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no2_systemCustomer_nonf1"
                      initialValue={companyCommissionNonF1?.no2.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 3 */}
                <tr>
                  <td>
                    <b>3</b>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_pag_nonf1"
                      initialValue={companyCommissionNonF1?.no3.pag}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_introducer_nonf1"
                      initialValue={companyCommissionNonF1?.no3.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemIntroducer_nonf1"
                      initialValue={companyCommissionNonF1?.no3.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_supporter_nonf1"
                      initialValue={companyCommissionNonF1?.no3.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_customer_nonf1"
                      initialValue={companyCommissionNonF1?.no3.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no3_systemCustomer_nonf1"
                      initialValue={companyCommissionNonF1?.no3.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* no 4 */}
                <tr>
                  <td>
                    <b>4</b>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_pag_nonf1"
                      initialValue={companyCommissionNonF1?.no4.pag}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_introducer_nonf1"
                      initialValue={companyCommissionNonF1?.no4.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemIntroducer_nonf1"
                      initialValue={companyCommissionNonF1?.no4.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_supporter_nonf1"
                      initialValue={companyCommissionNonF1?.no4.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_customer_nonf1"
                      initialValue={companyCommissionNonF1?.no4.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="no4_systemCustomer_nonf1"
                      initialValue={companyCommissionNonF1?.no4.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
                {/* from 5 */}
                <tr>
                  <td>
                    <b>{translate('project_commission.from_5')}</b>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_pag_nonf1"
                      initialValue={companyCommissionNonF1?.from5.pag}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_introducer_nonf1"
                      initialValue={companyCommissionNonF1?.from5.introducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemIntroducer_nonf1"
                      initialValue={companyCommissionNonF1?.from5.systemIntroducer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_supporter_nonf1"
                      initialValue={companyCommissionNonF1?.from5.supporter}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_customer_nonf1"
                      initialValue={companyCommissionNonF1?.from5.customer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                  <td>
                    <Form.Item
                      name="from5_systemCustomer_nonf1"
                      initialValue={companyCommissionNonF1?.from5.systemCustomer}
                      className="marginNone"
                    >
                      <InputNumber size="small" step={1} min={0} />
                    </Form.Item>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          {getCheckPermissionUser(PERMISSION_NAME.CONFIG_COMMISSIONS_PROJECT).edit ? (
            <Row style={{ marginTop: '10px' }}>
              <Col span={24}>
                <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Col>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        {translate('action.save')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </Form>
      )}
    </>
  );
}

export default CommissionsScreen;
