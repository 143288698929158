import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { ToastError, ToastSuccess } from 'components/Toast';

const LIST_URL = '/user/list-deleted-from-phonebook';
const UPDATE_PARENT_URL = '/user/add-new-parent-for-user';

function usePhoneBookRemovedCustomerService() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>();

  const onGetData = async (query: any) => {
    setLoading(true);
    const res: any = await ApiHelper.fetch(LIST_URL, query);
    if (res && res.status === StatusResponse.SUCCESS) {
      setData(res.data);
      setTotal(res.total);
    }
    setLoading(false);
  };

  const onAddNewParent = async (id: string, body: { newReferralCode: string }) => {
    const res: any = await ApiHelper.put(`${UPDATE_PARENT_URL}/${id}`, body);
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: 'Thành công',
        description: 'Cập nhật thành công',
      });
    } else {
      ToastError({
        message: 'Thất bại',
        description: res.message,
      });
    }
  };

  return {
    data,
    total,
    loading,
    onGetData,
    onAddNewParent,
  };
}

export default usePhoneBookRemovedCustomerService;
