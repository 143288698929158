/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { FilterHistory, HistoryCommissionRes, DetailHistory } from './history-types';

const URL_HISTORY = '/project/history-commission';
const URL_PAG_HISTORY = '/project/history-pag-commission';
const URL_USER = '/user/detailCustomer/';

function useHistoryService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataHistory, setDataHistory] = useState<HistoryCommissionRes[]>([]);
  const [totalHistory, setTotalHistory] = useState<number>();
  const [dataPagHistory, setDataPagHistory] = useState<any>();
  const [totalPagHistory, setTotalPagHistory] = useState<number>();
  const [calPagHistory, setCalTotalPagHistory] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [detailHistoryComission, setDetailHistoryComission] = useState<any>();
  const [loadingDetail, setLoadingDetail] = useState<boolean>(false);

  // Danh sách lịch sử
  const onGetHistory = async (param: FilterHistory) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL_HISTORY, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataHistory(res.data);
        setTotalHistory(res.total);
        setLoading(false);
      } else {
        setDataHistory([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDataHistory([]);
    }
  };

  const onGetPagHistory = async (param: FilterHistory) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL_PAG_HISTORY, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataPagHistory(res.data);
        setTotalPagHistory(res.total);
        setCalTotalPagHistory(res.calculate);
        setLoading(false);
      } else {
        setDataPagHistory([]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setDataHistory([]);
    }
  };

  // Chi tiết 1 userId
  const onDetailUser = async (id: string) => {
    const res: any = await ApiHelper.fetch(URL_USER + id);
    if (res && res.status === StatusResponse.SUCCESS) {
      setUserData(res.data);
    }
  };

  // Chi tiết lịch sử nhận hoa hồng
  const onDetailHistoryComission = async (param: DetailHistory, id: string) => {
    setLoadingDetail(true);
    try {
      const res: any = await ApiHelper.fetch(URL_HISTORY + `/${id}`, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailHistoryComission(res);
        setLoadingDetail(false);
      }
    } catch (error) {
      setLoadingDetail(false);
    }
  };

  return {
    onGetHistory,
    dataHistory,
    totalHistory,
    loading,
    onDetailUser,
    userData,
    onDetailHistoryComission,
    detailHistoryComission,
    loadingDetail,
    onGetPagHistory,
    dataPagHistory,
    totalPagHistory,
    calPagHistory,
  };
}

export default useHistoryService;
