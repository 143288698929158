import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { translate } from 'translate/language';

const DELETE_LIST_PERMISSION = 'permission/deleteMany';
function useDeletePermission(): [(id: string) => Promise<void>, (id: string[]) => Promise<void>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);

  const onDeletePermission = async (id: string) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete<string, any>(`${BASE_URL.DELETE_PERMISSION}${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      } else {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  // Xóa danh dách phân quyền
  const onDeleteListPermission = async (body: string[]) => {
    try {
      setLoading(true);
      const res = await ApiHelper.delete(DELETE_LIST_PERMISSION, { ids: body });
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      } else {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return [onDeletePermission, onDeleteListPermission, loading];
}

export default useDeletePermission;
