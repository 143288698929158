/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Col, Form, Input, Row, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useAddApartment from 'hooks/Apartment/useAddApartment';
import useDetailApartment from 'hooks/Apartment/useDetailApartment';
import useUpdateApartment from 'hooks/Apartment/useUpdateApartment';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { translate } from 'translate/language';

const { Option } = Select;

interface Props {}
function FormApartmentPage(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_APARTMENT);
  // Thêm mới dữ liệu
  const [onAddApartment, loading] = useAddApartment();
  // Lấy chi tiết Admin
  const [useGetDetailApartment, detailApartment] = useDetailApartment();
  // Cập nhật dữ liệu
  const [onUpdateApartment] = useUpdateApartment();

  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      useGetDetailApartment(params.id);
    } else {
      form.setFieldsValue({
        type: cacheForm.type,
        description: cacheForm.description,
        status: cacheForm.status,
      });
    }
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailApartment) {
      form.setFieldsValue({
        type: detailApartment.name,
        description: detailApartment.description,
        status: detailApartment.status,
      });
    }
  }, [detailApartment]);

  const handleSubmit = () => {
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: StatusType.ACTIVE });
    form.validateFields().then(values => {
      const data = {
        name: values.type,
        description: values.description,
        status: values.status,
      };
      if (isEdit) {
        const dataUpdate = { ...data, _id: params.id };
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_APARTMENT);
        onUpdateApartment(params.id, dataUpdate);
      } else {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_APARTMENT);
        onAddApartment(data);
      }
    });
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_APARTMENT, form.getFieldsValue());
  };

  // Validate dữ liệu
  const validateForm = {
    type: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('apartment.type'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        min: 2,
        max: 200,
        message: translate('apartment.type_validate'),
      },
    ],
    description: [
      {
        whitespace: true,
        required: true,
        message: translate('require_field', {
          label: translate('apartment.description'),
        }),
      },
      {
        transform: (value: string) => {
          return value?.trim();
        },
        max: 500,
        message: translate('apartment.description_validate'),
      },
    ],
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_APARTMENT);
    history.back();
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_APARTMENT).create ||
      getCheckPermissionUser(PERMISSION_NAME.CATEGORY_APARTMENT).edit ? (
        <Form onFieldsChange={updateCacheForm} form={form}>
          <Row>
            <Col span={20} offset={3}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="status"
                    label={translate('status.name')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    initialValue={StatusType.ACTIVE}
                  >
                    <Select
                      placeholder={translate('admin.status_active')}
                      // defaultValue={StatusType.ACTIVE}
                    >
                      <Option value={StatusType.ACTIVE}>{translate('status.show')}</Option>
                      <Option value={StatusType.INACTIVE}>{translate('status.hidden')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="type"
                      label={translate('apartment.type')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={validateForm.type}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="description"
                      label={translate('apartment.description')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={validateForm.description}
                    >
                      <TextArea rows={7} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" onClick={handleSubmit} htmlType="submit">
                          {isEdit ? translate('action.update') : translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormApartmentPage;
