/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button, Col, Form, Input, Row } from 'antd';
import PageLoading from 'components/PageLoading';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useGetPoint from 'hooks/Point/useGetPoint';
import useUpdatePoint from 'hooks/Point/useUpdatePoint';
import React, { useEffect } from 'react';
import { translate } from 'translate/language';

interface Props {}
function index(props: Props) {
  const [form] = Form.useForm();

  const { onGetPoint, detailPoint, loading } = useGetPoint();
  const { onUpdatePoint, loadingUpdate } = useUpdatePoint();
  // Lấy chi tiết cấu hình điểm
  useEffect(() => {
    onGetPoint();
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailPoint) {
      form.setFieldsValue({
        USER_FIRST_UPDATED_POINT: detailPoint.USER_FIRST_UPDATED_POINT,
        VIEW_SELLER_INFO_POINT: detailPoint.VIEW_SELLER_INFO_POINT,
        NEW_CHILD_POINT: detailPoint.NEW_CHILD_POINT,
      });
    }
  }, [detailPoint]);

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const data = {
        USER_FIRST_UPDATED_POINT: values.USER_FIRST_UPDATED_POINT,
        VIEW_SELLER_INFO_POINT: values.VIEW_SELLER_INFO_POINT,
        NEW_CHILD_POINT: values.NEW_CHILD_POINT,
      };
      onUpdatePoint(data);
    });
  };

  return (
    <>
      {loading || loadingUpdate ? (
        <PageLoading />
      ) : (
        <Form form={form}>
          <Row>
            <Col span={20} offset={3}>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="USER_FIRST_UPDATED_POINT"
                      label={translate('interaction_point.update_profile_success')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                    >
                      <Input type="number" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="VIEW_SELLER_INFO_POINT"
                      label={translate('interaction_point.info_new')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                    >
                      <Input type="number" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="NEW_CHILD_POINT"
                      label={translate('interaction_point.category')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                    >
                      <Input type="number" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {getCheckPermissionUser(PERMISSION_NAME.CONFIG_INTERACTIVE_POINTS).edit ? (
                <Row>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Form.Item>
                          <Button type="primary" onClick={handleSubmit} htmlType="submit">
                            {translate('action.save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}

export default index;
