/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ToastError } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject, StatusResponse } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import ApiHelper from 'network/ApiClient';
import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status: string) => void;
}

export interface FilterExcel {
  status?: string;
  search?: string;
}

const EXPORT_PROJECT = '/project/export';

function FilterHeaderProject(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, onChangeStatus } = props;
  const history = useHistory();
  const [filterExcel, setFilterExcel] = useState<FilterExcel>({
    status: '',
    search: '',
  });

  // Tìm kiếm theo Tên,ID,Số điện thoại
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
    setFilterExcel({ ...filterExcel, search: e.target.value });
  };

  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
    setFilterExcel({ ...filterExcel, status: value });
  };
  // Thêm mới dự án
  const addNew = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PROJECT);
    history.push(ROUTER_PATH.ADD_PROJECT);
  };

  // Xuất Excel
  const exportExcel = async () => {
    const res: any = await ApiHelper.post(EXPORT_PROJECT, filterExcel, true);
    if (res.status === StatusResponse.SUCCESS) {
      window.open(getUrlExport(res.data));
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.error'),
      });
    }
  };
  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder={translate('project.search')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>

          <Col className="gutter-row" span={4}></Col>
          <Col className="gutter-row" span={6}>
            {/* Xuất Excel */}
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS).export ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={exportExcel}>
                  {translate('customer.export_excel')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className="gutter-row" span={4}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('status.name')} onChange={handChangeStatus} allowClear>
                  <Option value={BaseStatusProject.INACTIVE}>{translate('BaseStatusProject.INACTIVE')}</Option>
                  <Option value={BaseStatusProject.FOR_SALE}>{translate('BaseStatusProject.FOR_SALE')}</Option>
                  <Option value={BaseStatusProject.NOT_FOR_SALE}>{translate('BaseStatusProject.NOT_FOR_SALE')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={3}>
            {/* Thêm mới */}
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" htmlType="submit" onClick={addNew}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterHeaderProject;
