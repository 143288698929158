import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { translate } from 'translate/language';
import { User } from 'types/response/UserResponse';
import { ReqCreatePermission, ReqUpdatePer, ResPermissionDetail, ResPermissionTable } from 'types/type/PermissionType';

function useAddPermissionHooks(history: any) {
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingGetDetail] = useState(false);
  const [dataPermission, setDataPermission] = useState<ResPermissionTable[]>([]);
  const [dataPermission2, setDataPermission2] = useState<ResPermissionTable[]>([]);
  const [dataGroup, setDataGroup] = useState<ResPermissionDetail>();
  const [userPermission, setUserPermission] = useState<User[] | undefined>();
  // Thêm mới quyền
  const onAddPermission = async (data: ReqCreatePermission) => {
    try {
      setLoading(true);
      const res = await ApiHelper.post(BASE_URL.PERMISSION, data);
      if (res && res.status === StatusResponse.PERMISSION_ALREADY_EXISTS) {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.PERMISSION_ALREADY_EXISTS'),
        });
        return;
      }

      if (res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
        setLoading(false);
        history.goBack();
      } else {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.error'),
        });
      }
    } catch (error) {
      setLoading(false);
      const response = error;
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.error'),
      });
    }
  };

  //  Chi tiết phân quyền
  const onGetPemissionDetail = async (id: string) => {
    try {
      setLoadingGetDetail(true);
      const res = await ApiHelper.fetch(BASE_URL.PERMISSION_DETAIL + id);
      if (res && res.status === StatusResponse.SUCCESS && res.data) {
        setLoadingGetDetail(false);
        setDataGroup(res.data);
        setUserPermission(res?.users);
      } else {
        setLoadingGetDetail(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('failed_Chi_tiết'),
        });
      }
    } catch (error) {
      setLoadingGetDetail(false);
      const response = error;
      ToastError({
        message: translate('message_type.failed'),
        description: translate('message_type.failed'),
      });
    }
  };

  // Update phân quyền
  const onUpdatePermission = async (id: string, data: ReqUpdatePer) => {
    try {
      setLoading(true);
      const res = await ApiHelper.put(BASE_URL.PERMISSION_UPDATE + id, data);
      if (res && res.status === StatusResponse.PERMISSION_ALREADY_EXISTS) {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.PERMISSION_ALREADY_EXISTS'),
        });
        return;
      }
      if (res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
        history.goBack();
      } else {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('message_type.failed'),
        });
      }
    } catch (error) {
      setLoading(false);
      const response = error;
      ToastError({
        message: translate('message_type.failed'),
        description: translate('message_type.failed'),
      });
    }
  };

  // Danh sách phân quyền
  const onGetPermission = async () => {
    try {
      setLoadingGetDetail(true);
      const res = await ApiHelper.fetch(BASE_URL.LIST_PERMISSION_CREATE, null);
      if (res && res.status === StatusResponse.SUCCESS && res.data) {
        setLoadingGetDetail(false);
        setDataPermission(res.data);
        setDataPermission2(res.data);
      } else {
        setLoadingGetDetail(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('failed_danh_sách'),
        });
      }
    } catch (error) {
      setLoadingGetDetail(false);
      const response = error;
      ToastError({
        message: translate('message_type.failed'),
        description: translate('message_type.failed'),
      });
    }
  };

  return {
    onAddPermission,
    loading,
    dataPermission,
    dataPermission2,
    onGetPermission,
    loadingDetail,
    onUpdatePermission,
    onGetPemissionDetail,
    dataGroup,
    userPermission,
  };
}

export default useAddPermissionHooks;
