import { useState } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import ApiHelper from 'network/ApiClient';
import { ToastError, ToastSuccess } from '../../../components/Toast/index';
import { StatusResponse } from '../../../constants/Status';
import { translate } from '../../../translate/language';
import { ConfigOtherReq } from './config-other.type';

const SETTING_URL = '/settings';

function useConfigOtherService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataConfigOther, setDataConfigOther] = useState<any>();

  const onGetConfigOther = async () => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(SETTING_URL);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataConfigOther(res.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Cập nhật cấu hình khác
  const onUpdateSetting = async (data: ConfigOtherReq) => {
    try {
      const res: any = await ApiHelper.put(SETTING_URL, data);
      if (res && res.status === 'VALIDATION_ERROR') {
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('Vui lòng tìm kiếm chọn CTV hỗ trợ'),
        });
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.update_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return { onGetConfigOther, dataConfigOther, loading, onUpdateSetting };
}

export default useConfigOtherService;
