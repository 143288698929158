/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-else-return */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject, HouseType, StatusType } from 'constants/Status';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useAddArea from 'hooks/BlockArea/useAddArea';
import useDetailArea from 'hooks/BlockArea/useDetailArea';
import useUpdateArea from 'hooks/BlockArea/useUpdateArea';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import useLitsProject from 'hooks/Project/useLitsProject';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Text, Link } = Typography;
const { Option } = Select;

interface Props {}
function FormBlockAreaPage(props: Props) {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_BLOCK_AREA);
  const history = useHistory();
  // Thêm mới dữ liệu
  const { onAddBlockArea, loadingBlock } = useAddArea();
  // Lấy chi tiết Admin
  const [useGetDetailArea, detailArea] = useDetailArea();
  // Cập nhật dữ liệu
  const [onUpdateArea] = useUpdateArea();

  // Danh sách dự án
  const { onListProject, listProject, loading } = useLitsProject();

  // Loại bất động sản
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();

  useEffect(() => {
    onListProject({
      status: StatusType.ACTIVE,
    });
    onFetchListParentChildRealeState();
  }, []);
  // Check dữ liệu thêm mới
  useEffect(() => {
    if (isEdit) {
      useGetDetailArea(params.id);
    } else {
      form.setFieldsValue({
        floors: cacheForm.floors,
        lots: cacheForm.lots,
        name: cacheForm.name,
        project: cacheForm.project,
        realEstate: cacheForm.realEstate,
        status: cacheForm.status,
        type: cacheForm.type,
      });
    }
  }, []);

  // Set giá trị vào form
  useEffect(() => {
    if (detailArea) {
      // TODO API
      form.setFieldsValue({
        floors: detailArea.floors,
        lots: detailArea.lots,
        name: detailArea.name,
        project: detailArea.project,
        realEstate: detailArea.realEstate?._id,
        status: detailArea.status,
        type: detailArea.houseType,
      });
    }
  }, [detailArea]);

  const handleSubmit = () => {
    if (!form.getFieldValue('status')) form.setFieldsValue({ status: BaseStatusProject.INACTIVE });
    if (!form.getFieldValue('type')) form.setFieldsValue({ type: HouseType.HIGH });

    form.validateFields().then(values => {
      const data = {
        floors: values.floors,
        lots: values.lots,
        name: values.name,
        project: values.project,
        realEstate: values.realEstate,
        status: values.status,
        houseType: values.type,
      };
      if (isEdit) {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_BLOCK_AREA);
        onUpdateArea(params.id, data);
        return;
      } else {
        localStorage.removeItem(StorageKey.CACHE_FORM_CREATE_BLOCK_AREA);
        onAddBlockArea(data);
        return;
      }
    });
  };

  const updateCacheForm = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_BLOCK_AREA, form.getFieldsValue());
  };

  // Action quay lại
  const closeButton = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_BLOCK_AREA);
    history.goBack();
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).create ||
      getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).edit ? (
        <Form onFieldsChange={updateCacheForm} form={form}>
          <Row>
            <Col span={20} offset={3}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="project"
                    label={translate('block_area.project')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: translate('require_field', {
                          label: translate('block_area.project'),
                        }),
                      },
                    ]}
                  >
                    <Select placeholder={translate('block_area.project')}>
                      {listProject && listProject.data
                        ? listProject.data.map((data: any, key: number) => {
                            return (
                              <Option value={data._id} key={data._id} title={data.name}>
                                {data.name.length > 40 ? `${data.name.substring(0, 40)} ...` : data.name}
                              </Option>
                            );
                          })
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="realEstate"
                    label={translate('block_area.realEstate')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: translate('require_field', {
                          label: translate('block_area.realEstate'),
                        }),
                      },
                    ]}
                  >
                    <Select placeholder={translate('block_area.realEstate')}>
                      {ListParentChildRealeState
                        ? ListParentChildRealeState.map((data: any, key: number) => {
                            return (
                              <Option value={data._id} key={data._id} title={data.child}>
                                {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                              </Option>
                            );
                          })
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="name"
                      label={translate('block_area.name')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          whitespace: true,
                          required: true,
                          message: translate('require_field', {
                            label: translate('block_area.name'),
                          }),
                        },
                        {
                          transform: (value: string) => {
                            return value?.trim();
                          },
                          min: 1,
                          max: 100,
                          message: translate('block_area.name_invalid'),
                        },
                      ]}
                    >
                      <Input autoComplete="off" />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="floors"
                      label={translate('block_area.floors')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: translate('require_field', {
                            label: translate('block_area.floors'),
                          }),
                        },
                        {
                          pattern: /^\d+$/,
                          message: translate('messages.integer_field', {
                            label: translate('block_area.floors'),
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="lots"
                      label={translate('block_area.lots')}
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          required: true,
                          message: translate('require_field', {
                            label: translate('block_area.lots'),
                          }),
                        },
                        {
                          pattern: /^\d+$/,
                          message: translate('messages.integer_field', {
                            label: translate('block_area.lots'),
                          }),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="type"
                    label={translate('block_area.type_create')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: translate('require_field', {
                          label: translate('block_area.type_create'),
                        }),
                      },
                    ]}
                  >
                    <Select placeholder={translate('block_area.type_create')}>
                      <Option value={HouseType.HIGH}>{translate('HouseType.HIGH')}</Option>
                      <Option value={HouseType.LOW}>{translate('HouseType.LOW')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={20}>
                  <Form.Item name="status" label={translate('status.name')} labelCol={{ sm: 7 }} labelAlign="left">
                    <Select placeholder={translate('admin.status_active')}>
                      <Option value={BaseStatusProject.INACTIVE}>{translate('BaseStatusProject.INACTIVE')}</Option>
                      <Option value={BaseStatusProject.NOT_FOR_SALE}>
                        {translate('BaseStatusProject.NOT_FOR_SALE')}
                      </Option>
                      <Option value={BaseStatusProject.FOR_SALE}>{translate('BaseStatusProject.FOR_SALE')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {isEdit && detailArea && (
                <Row>
                  <Col span={20}>
                    <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Col>
                        <Link
                          href={`${ROUTER_PATH.HOUSE_LOTS}?idBlock=${params.id}&idProject=${detailArea.project}`}
                          target="_blank"
                          style={{
                            backgroundColor: '#5491da',
                            display: 'inline-block',
                            marginBottom: '20px',
                            padding: '15px 25px',
                            color: '#ffffff',
                            fontWeight: 'bold',
                          }}
                        >
                          {translate('block_area.detail')}
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              <Row>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" onClick={handleSubmit} htmlType="submit">
                          {isEdit ? translate('action.update') : translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormBlockAreaPage;
