/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { SearchOutlined } from '@ant-design/icons';
import { useDebounce } from 'ahooks';
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { formatNumber } from 'helpers/Utils';
import useListCustomer from 'hooks/Customer/useListCustomer';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useCommissionPlusService from './commission-plus.service';
import { CommissionPlusReq } from './commission-plus.type';

const { Option } = Select;

function CommissionPlusDetailScreen(): JSX.Element {
  const param: { id: string } = useParams();
  const isEdit = param && param.id;

  const [form] = Form.useForm();
  const history = useHistory();
  const [dataName, setDataName] = useState<any[]>([]);
  // Người dùng khi select
  const [dataDetailCustomer, setDataDetailCustomer] = useState<any>();

  const [valueName, setValueName] = useState<string>();
  const debouncedValue = useDebounce(valueName, { wait: 500 });
  const [onFetchListCustomer, listCustomer] = useListCustomer();

  // Hoa hồng khả dụng
  const {
    onGetCommissionPoint,
    totalCommissionPoint,
    onCreateCommissionPoint,
    onDetailCommission,
    detailCommission,
    onUpdateCommission,
  } = useCommissionPlusService();

  // Trường hợp cập nhật
  useEffect(() => {
    if (isEdit) {
      onDetailCommission(param.id);
    }
  }, [isEdit]);

  useEffect(() => {
    if (detailCommission) {
      form.setFieldsValue({
        userId: detailCommission.userId,
        note: detailCommission.noteCMS,
        status: detailCommission.status,
        money: detailCommission.money,
      });
      if (detailCommission.userInfo) {
        setDataName([
          {
            _id: detailCommission.userInfo._id,
            name: detailCommission.userInfo.name,
          },
        ]);
        setDataDetailCustomer({
          phone: detailCommission.userInfo.phone,
          email: detailCommission.userInfo.email,
          identify: detailCommission.userInfo.identify,
        });
      }
      onGetCommissionPoint(detailCommission.userId);
    }
  }, [detailCommission]);

  useEffect(() => {
    if (debouncedValue) {
      onFetchListCustomer({ search: debouncedValue, status: 'ACTIVE' });
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (listCustomer) {
      setDataName(listCustomer.data);
    }
  }, [listCustomer]);

  // Cộng điểm cá nhân
  const handleSearchName = (value: string) => {
    setValueName(value);
  };

  const onChangeName = (value: any, option: any) => {
    setDataDetailCustomer(listCustomer?.data[option.key]);
    onGetCommissionPoint(value);
  };

  const onFinish = (value: CommissionPlusReq) => {
    if (isEdit && detailCommission) {
      return onUpdateCommission(detailCommission._id, value);
    }
    onCreateCommissionPoint(value);
  };

  const closeButton = () => {
    history.goBack();
  };

  return (
    <>
      {' '}
      {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_ADD).edit ? (
        <Form form={form} onFinish={onFinish}>
          <Row gutter={24}>
            <Col span={20} offset={3}>
              <Row>
                {/* Họ và tên */}
                <Col span={20}>
                  <Form.Item
                    name="userId"
                    label="Người nhận"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Vui lòng tìm kiếm chọn người dùng',
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Tìm kiếm tên, Số điện thoại, Agent ID"
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={handleSearchName}
                      notFoundContent={null}
                      suffixIcon={<SearchOutlined />}
                      onChange={onChangeName}
                    >
                      {dataName.map((data: any, index: number) => (
                        <Option value={data._id} key={index}>
                          {data.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {dataDetailCustomer && (
                  <>
                    {/* Số điện thoại */}
                    <Col span={20}>
                      <Form.Item label="Số điện thoại" labelCol={{ span: 7 }} labelAlign="left">
                        <b style={{ fontSize: '20px' }}> {dataDetailCustomer?.phone}</b>
                      </Form.Item>
                    </Col>
                    {/* Email */}
                    <Col span={20}>
                      <Form.Item label="Email" labelCol={{ span: 7 }} labelAlign="left">
                        <b style={{ fontSize: '20px' }}> {dataDetailCustomer?.email}</b>
                      </Form.Item>
                    </Col>
                    {/* Số CMND */}
                    <Col span={20}>
                      <Form.Item label="Số CMND" labelCol={{ span: 7 }} labelAlign="left">
                        <b style={{ fontSize: '20px' }}> {dataDetailCustomer?.identify}</b>
                      </Form.Item>
                    </Col>
                    {/* Hoa hồng khả dụng */}
                    <Col span={20}>
                      <Form.Item label="Hoa hồng khả dụng" labelCol={{ span: 7 }} labelAlign="left">
                        <b style={{ fontSize: '20px' }}>{formatNumber(totalCommissionPoint || 0)}</b>
                      </Form.Item>
                    </Col>
                  </>
                )}

                {/* Cộng hoa hồng */}
                <Col span={20}>
                  <Form.Item
                    name="money"
                    label="Cộng hoa hồng (VNĐ)"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Cộng hoa hồng không được trống',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                      parser={(value: any) => value.replace(/(\.*)/g, '')}
                      step={1000}
                      min={0}
                    />
                  </Form.Item>
                </Col>
                {/* Ghi chú */}
                <Col span={20}>
                  <Form.Item
                    name="note"
                    label="Ghi chú"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        whitespace: true,
                        max: 500,
                        message: 'Ghi chú tối đa 500 ký tự',
                      },
                    ]}
                  >
                    <Input.TextArea rows={5} />
                  </Form.Item>
                </Col>

                {/* Trạng thái */}
                <Col span={20}>
                  <Form.Item
                    name="status"
                    label="Trạng thái"
                    labelCol={{ span: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Trạng thái không được để trống',
                      },
                    ]}
                  >
                    <Select>
                      <Option value="PENDING_APPROVED">Chờ xét duyệt</Option>
                      <Option value="APPROVED">Đã duyệt</Option>
                      <Option value="CANCEL">Từ chối</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={20}>
                  <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col>
                      <Form.Item>
                        <Button type="default" onClick={closeButton}>
                          {translate('action.close')}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {translate('action.save')}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default CommissionPlusDetailScreen;
