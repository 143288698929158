import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { translate } from 'translate/language';

function useUpload(): [(request: FormData) => Promise<void>, any, string] {
  const [resUpload, setResUpload] = useState<any>();
  const [messageError, setMessageError] = useState<string>("");

  const onUpload = async (request: FormData) => {
    try {
      const res = await ApiHelper.postFormData<any>(
        BASE_URL.UPLOAD_IMAGE,
        request,
        {
          "Content-Type": "multipart/form-data",
        }
      );
      if (res.status === StatusResponse.SUCCESS) {
        setResUpload(res?.data);
      } else {
        setMessageError(`${translate("upload_fail")}`);
        setResUpload(null);
      }
    } catch (error) {
      setMessageError(`${translate("upload_fail")}`);
      setResUpload(null);
    }
  };
  return [onUpload, resUpload, messageError]
}

export default useUpload;