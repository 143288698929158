import { LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { onLOGIN, resetLogin } from 'redux/actions';
import { translate } from 'translate/language';
import { LoginUserRequest } from 'types/requests/UserRequest';
import Styled from './indexStyled';
import './css/login.scss';
import useLogin from 'hooks/Login/useLogin';

interface Props {}
const LoginFormImpl: React.FC<Props> = (props: Props) => {
  let errorMessages: any[] = [];
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [loginCMS, loading, user] = useLogin();

  useEffect(() => {
    // dispatch(resetLogin());
    // onLoginFail()
  }, []);

  // Validate dữ liệu
  const validateFormLogin = {
    phone_number: [
      {
        whitespace: true,
        required: true,
        message: translate('login.err_phone_number'),
      },
    ],
    password: [
      {
        whitespace: true,
        required: true,
        message: translate('login.err_pass'),
      },
      {
        min: 6,
        message: translate('login.min_pass'),
      },
    ],
  };

  const onLoginFail = () => {
    notification.error({
      placement: 'topRight',
      message: translate('message_type.failed'),
      description: translate('login.message_login_fail'),
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values: LoginUserRequest) => {
        loginCMS(values);
      })
      .catch(e => e);
  };

  return (
    <Styled.Container>
      <div className="container">
        <h3 className="title">{translate('login.login')}</h3>
        <ul className="text-danger">
          {errorMessages.map((el, i) => {
            return <li key={i}>{el}</li>;
          })}
        </ul>
        <Form className="login-form" form={form}>
          <Form.Item initialValue={''} name="phone" rules={validateFormLogin.phone_number}>
            <Input
              type="number"
              autoFocus={true}
              prefix={<PhoneOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={translate('login.phone_number')}
              style={{ padding: '0px 12px' }}
            />
          </Form.Item>
          <Form.Item name="password" initialValue={''} rules={validateFormLogin.password}>
            <Input.Password
              style={{ padding: '0px 12px' }}
              prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder={translate('login.password')}
            />
          </Form.Item>
          <Form.Item>
            <Button onClick={handleSubmit} type="primary" htmlType="submit" className="login-form-button">
              {translate('login.login')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Styled.Container>
  );
};

const LoginPage = LoginFormImpl;
export default React.memo(LoginPage);
