import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

function usePhoneCheck() {
    const [loadingPhone, setLoading] = useState(false);
    const [listPhone, setListPhone] = useState<[] | undefined>();

    const onGetPhoneCode = async () => {
        try {
            setLoading(true);
            const res = await ApiHelper.fetch(BASE_URL.PHONE_CODES);
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false);
                setListPhone(res?.data)
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return { onGetPhoneCode, listPhone, loadingPhone }
}

export default usePhoneCheck;