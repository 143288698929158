/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-brace-presence */
import { Button, Col, Form, Input, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Container from 'components/ContentLayout/Container';
import { ToastError, ToastSuccess } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusResponse } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { BASE_URL } from 'network/BaseURL';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { translate } from 'translate/language';
import { BaseResponse } from 'types/response/BaseResponse';
import ApiHelper from '../../../network/ApiClient';
import InputForm from './post-form-component-input';
import InputAreaForm from './post-form-component-inputarea';
import { NewsConfigType, NewsConfigurations } from './post-form-type';

interface FormPost {
  nameMethod1: string;
  postingFee1: string;
  numMaxPostday1: string;
  maxImageUpload1: string;
  timeSendnoti1: string;
  description1: string;

  nameMethod2: string;
  postingFee2: string;
  numMaxPostday2: string;
  maxImageUpload2: string;
  timeSendnoti2: string;
  description2: string;

  maxImageUpload3: string;
  timeSendnoti3: string;
  description3: string;
}

function PostFormManage() {
  const [form] = useForm<FormPost>();
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    getDetailConfig();
    return () => {};
  }, []);

  const getDetailConfig = async () => {
    const res = await ApiHelper.fetch<any, BaseResponse>(BASE_URL.SETTINGS_NEW_CONFIGS, null, true);
    if (res.status === StatusResponse.SUCCESS && res.data && res.data.length > 0) {
      res.data.forEach((element: NewsConfigurations) => {
        if (element.type === NewsConfigType.PAID_METHOD_1) {
          form.setFieldsValue({
            nameMethod1: String(element.name),
            postingFee1: String(element.point),
            numMaxPostday1: String(element.showingDays),
            maxImageUpload1: String(element.image),
            timeSendnoti1: String(element.notifyDays),
            description1: String(element.description),
          });
        }
        if (element.type === NewsConfigType.PAID_METHOD_2) {
          form.setFieldsValue({
            nameMethod2: String(element.name),
            postingFee2: String(element.point),
            numMaxPostday2: String(element.showingDays),
            maxImageUpload2: String(element.image),
            timeSendnoti2: String(element.notifyDays),
            description2: String(element.description),
          });
        }
        if (element.type === NewsConfigType.FREE_METHOD) {
          form.setFieldsValue({
            maxImageUpload3: String(element.image),
            timeSendnoti3: String(element.notifyDays),
            description3: String(element.description),
          });
        }
      });
    }
  };

  const handleSubmit = async () => {
    setIsEdit(false);
    if (isEdit) return;
    form.validateFields().then(async (value: FormPost) => {
      const data = [
        {
          type: NewsConfigType.PAID_METHOD_1,
          name: value.nameMethod1,
          point: Number(value.postingFee1),
          showingDays: Number(value.numMaxPostday1),
          notifyDays: Number(value.timeSendnoti1),
          image: Number(value.maxImageUpload1),
          description: value.description1,
        },
        {
          type: NewsConfigType.PAID_METHOD_2,
          name: value.nameMethod2,
          point: Number(value.postingFee2),
          showingDays: Number(value.numMaxPostday2),
          notifyDays: Number(value.timeSendnoti2),
          image: Number(value.maxImageUpload2),
          description: value.description2,
        },
        {
          type: NewsConfigType.FREE_METHOD,
          point: 0,
          showingDays: 0,
          name: 'Miễn phí',
          image: Number(value.maxImageUpload3),
          notifyDays: Number(value.timeSendnoti3),
          description: value.description3,
        },
      ];

      const res: any = await ApiHelper.put<any, BaseResponse>(BASE_URL.SETTINGS, { newsConfigurations: data }, true);
      if (res && res.status === 'NOTIFY_DAYS_MUST_BE_LESS_THAN_SHOWING_DAYS') {
        return ToastError({
          message: 'Thất bại',
          description: 'Thời gian gửi thông báo sắp hết hạn phải nhỏ hơn số ngày đăng tối thiểu',
        });
      }
      if (res.status === StatusResponse.SUCCESS) {
        setIsEdit(true);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('post_form.create_success'),
        });
      } else {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('post_form.create_err'),
        });
      }
    });
  };

  const closeButton = () => {
    getDetailConfig();
    setIsEdit(true);
  };
  return (
    <Container>
      <Form form={form}>
        <h1 style={{ fontSize: 25, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline' }}>
          Hình thức 1
        </h1>
        <InputForm isEdit={isEdit} name={'nameMethod1'} label={translate('post_form.name_method')} />
        <InputForm isEdit={isEdit} name={'postingFee1'} isNumber label={translate('post_form.posting_fee')} />
        <InputForm isEdit={isEdit} name={'numMaxPostday1'} isNumber label={translate('post_form.day_post_num_max')} />
        <InputForm isEdit={isEdit} name={'maxImageUpload1'} isNumber label={translate('post_form.max_image_upload')} />
        <InputForm isEdit={isEdit} name={'timeSendnoti1'} isNumber label={translate('post_form.time_send_notice')} />
        <InputAreaForm isEdit={isEdit} name={'description1'} label={translate('post_form.description')} />
        <h1 style={{ fontSize: 25, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline' }}>
          Hình thức 2
        </h1>
        <InputForm isEdit={isEdit} name={'nameMethod2'} label={translate('post_form.name_method')} />
        <InputForm isEdit={isEdit} name={'postingFee2'} isNumber label={translate('post_form.posting_fee')} />
        <InputForm isEdit={isEdit} name={'numMaxPostday2'} isNumber label={translate('post_form.day_post_num_max')} />
        <InputForm isEdit={isEdit} name={'maxImageUpload2'} isNumber label={translate('post_form.max_image_upload')} />
        <InputForm isEdit={isEdit} name={'timeSendnoti2'} isNumber label={translate('post_form.time_send_notice')} />
        <InputAreaForm isEdit={isEdit} name={'description2'} label={translate('post_form.description')} />
        <h1 style={{ fontSize: 25, fontWeight: 'bold', fontStyle: 'italic', textDecoration: 'underline' }}>
          Hình thức miễn phí
        </h1>
        <InputForm isEdit={isEdit} name={'maxImageUpload3'} isNumber label={translate('post_form.max_image_upload')} />
        <InputForm isEdit={isEdit} name={'timeSendnoti3'} isNumber label={translate('post_form.time_send_notice')} />
        <InputAreaForm isEdit={isEdit} name={'description3'} label={translate('post_form.description')} />
        <Row>
          <Col span={20}>
            <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!isEdit && (
                <Col>
                  <Form.Item>
                    <Button type="default" onClick={closeButton}>
                      {translate('action.close')}
                    </Button>
                  </Form.Item>
                </Col>
              )}

              {getCheckPermissionUser(PERMISSION_NAME.CONFIG_NEWS_METHOD).edit ? (
                <Col>
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      style={{ backgroundColor: 'rgb(58, 200, 110)' }}
                      htmlType={!isEdit ? 'submit' : 'submit'}
                    >
                      {!isEdit ? translate('action.update') : translate('edit')}
                    </Button>
                  </Form.Item>
                </Col>
              ) : (
                ''
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}

export default PostFormManage;
