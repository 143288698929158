/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
import { useDebounceFn } from 'ahooks';
import { Col, Form, Input, Row, Image } from 'antd';
import { FALLBACK } from 'constants/Default';
import { getUrlImage } from 'helpers/getImageUrl';
import useReferrer from 'hooks/Customer/useReferrer';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

interface Props {
  checkReferrer: string;
  phoneReferrer?: string;
}
function ReferrerPage(props: Props): JSX.Element {
  const { checkReferrer, phoneReferrer } = props;

  const [useGetReferrer, resReferrer, loadingRef, messageReferrer] = useReferrer();
  const history = useHistory();

  // Lấy ra người giới thiệu
  const { run } = useDebounceFn(
    (search: any) => {
      useGetReferrer({
        referralCode: search,
      });
    },
    {
      wait: 500,
    },
  );

  useEffect(() => {
    if (checkReferrer && phoneReferrer) {
      useGetReferrer({
        referralCode: phoneReferrer,
      });
    }
  }, [checkReferrer, phoneReferrer]);

  // Action màn chi tiết khách hàng
  const detailCustomer = (id: string) => {
    window.open(ROUTER_PATH.EDIT_CUSTOMER + id, '_blank');
  };
  return (
    <>
      <Row>
        <Col span={20}>
          <div>
            <Form.Item
              name="code_introduce"
              label={translate('customer.code_introduce')}
              labelAlign="left"
              labelCol={{ span: 7 }}
            >
              <Input style={{ padding: '0px 12px' }} onChange={(e: any) => run(e.target.value)} />
            </Form.Item>
          </div>
        </Col>
      </Row>
      {messageReferrer && resReferrer && (
        <Row gutter={20} style={{ display: 'flex', alignItems: 'center' }}>
          <Col span={6}>
            <div>
              {resReferrer?.avatarId ? (
                <Image width={150} height={150} src={getUrlImage(resReferrer?.avatarId)} />
              ) : (
                <Image width={150} height={150} src="error" fallback={FALLBACK} />
              )}
            </div>
          </Col>
          <Col span={14}>
            <Row>
              <Col span={12}>
                <div>{translate('customer.full_name')}</div>
              </Col>
              <Col span={12}>
                <div
                  style={{
                    color: '#6b6bff',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => detailCustomer(resReferrer?._id)}
                >
                  {resReferrer?.name}
                </div>
              </Col>
            </Row>
            <Row style={{ margin: '20px 0px' }}>
              <Col span={12}>
                <div>{translate('customer.email')}</div>
              </Col>
              <Col span={12}>
                <div>{resReferrer?.email}</div>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <div>{translate('customer.phone')}</div>
              </Col>
              <Col span={12}>
                <div>{resReferrer?.phone}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ReferrerPage;
