/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { BaseResponse } from 'types/response/BaseResponse';
import { FilterCustomerProject } from './customer-project.type';
import { MessageViewInput } from './ViewMember.entity';

const LIST_TRANSACTIONS = '/project/reservation/list';
const DETAIL_TRANSACTION = 'project/reservation/';
const UPDATE_STATUS = 'project/reservation/';
const UPDATE_VIEW_MESSAGE = '/project/transactions/update-view-message';
const URL = '/project/transaction/history/';
const UPDATE_SERIAL = '/project/reservation/change-serial-number/';

function useCustomerProjectService() {
  const [loading, setLoading] = useState(false);
  const [dataHouseTransactions, setDataHouseTransactions] = useState<any[]>([]);
  const [totalTransactions, setTotalTransactions] = useState<number>();
  const [detailTransaction, setDetailTransaction] = useState<any | undefined>();

  const [dataRelated, setDataRelated] = useState<any[]>([]);
  const [totalRelated, setTotalRelated] = useState<number>(0);

  const [dataHistory, setDataHistory] = useState<any>();

  const [ListParentChildRealeState, setListParentChildRealeState] = useState([]);

  const [ListBlock, setListBlock] = useState([]);

  const [ListFloor, setListFloor] = useState([]);

  const [ListHouse, setListHouse] = useState([]);
  const history = useHistory();

  const onGetRelated = async (id: string, param: any) => {
    const res: any = await ApiHelper.fetch(`project/reservation/transaction-related?transactionId=${id}`, param);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataRelated(res.data);
      setTotalRelated(res.total);
    } else {
      setDataRelated([]);
    }
  };

  const onGetHistory = async (id: string) => {
    const res: any = await ApiHelper.fetch(URL + id);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataHistory(res.data);
    }
  };

  // danh sách loại bất động sản
  // const onFetchListParentChildRealeState = async () => {
  //   try {
  //     const res: any = await ApiHelper.fetch(BASE_URL.LIST_PARENT_CHILD);
  //     if (res && res.status === StatusResponse.SUCCESS) {
  //       setListParentChildRealeState(res.data);
  //     }
  //   } catch (error) {
  //     setListParentChildRealeState([]);
  //   }
  // };

  const onGetListTransactions = async (request: FilterCustomerProject) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch<FilterCustomerProject, any>(LIST_TRANSACTIONS, request);
      console.log(res);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataHouseTransactions(res.data);
        setTotalTransactions(res.total);
        setLoading(false);
      }
    } catch (error) {
      setDataHouseTransactions([]);
      setLoading(false);
    }
  };

  const onDetailProject = async (id: string) => {
    try {
      const res = await ApiHelper.fetch<string, any>(BASE_URL.DETAIL_PROJECT + id);
      if (res && res.status === StatusResponse.SUCCESS) {
        ListParentChildRealeStateOfProject(res.data.realEstate);
      } else {
      }
    } catch (error) {}
  };

  // lấy danh sách block
  const listBlock = async (id: string, childRealEstateBooking: string) => {
    const request = {
      project: id,
    };
    try {
      const res: any = await ApiHelper.fetch(BASE_URL.LIST_AREA, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        const newdata = res.data.filter((item: any) => {
          if (item.realEstate._id === childRealEstateBooking) {
            return item;
          }
        });
        setListBlock(newdata);
      } else {
      }
    } catch (error) {}
  };

  // lấy danh sách tầng
  const getFloor = async (blockId: string) => {
    const res: any = await ApiHelper.fetch(BASE_URL.FLOORS_BLOCK, { blockId });
    if (res && res.status === StatusResponse.SUCCESS) {
      setListFloor(res.data);
    }
  };

  // lấy danh sách căn hộ
  const getHouse = async (blockId: string, floorName: string) => {
    const res: any = await ApiHelper.fetch(BASE_URL.APARTMENT_FLOOR, {
      blockId,
      floor: floorName,
    });
    if (res && res.status === StatusResponse.SUCCESS) {
      const newData = res.data.filter((item: any) => {
        if (item.status !== 'SOLD') {
          return item;
        }
      });
      setListHouse(sortName(newData));
    }
  };
  // Loại bất động sản cho từng dự án
  const ListParentChildRealeStateOfProject = async (array: string[]) => {
    const res: any = await ApiHelper.fetch(BASE_URL.LIST_PARENT_CHILD);
    if (res && res.status === StatusResponse.SUCCESS) {
      const newArray = res.data.filter((item: any) => {
        if (array.includes(item._id)) {
          return item;
        }
      });
      setListParentChildRealeState(newArray);
    }
  };
  // Chi tiết khách hàng book căn
  const onDetailTransaction = async (transactionId: string) => {
    try {
      const res: any = await ApiHelper.fetch(DETAIL_TRANSACTION + transactionId);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailTransaction(res.data);
        onDetailProject(res.data.projectId);
        if (res.data.childRealEstateBooking) {
          listBlock(res.data.projectId, res.data.childRealEstateBooking);
        }
      }
    } catch (error) {
      setDetailTransaction(undefined);
    }
  };

  // Cập nhật trạng thái
  const updateStatus = async (id: string, body: any) => {
    const res: any = await ApiHelper.put(UPDATE_STATUS + id, body);
    if (res && res.status === 'NOT_ALLOWED_CHANGE_STATUS') {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('Thay đổi trạng thái không hợp hợp. Vui lòng kiểm tra lại'),
      });
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('Thay đổi trạng thái thành công'),
      });
      history.goBack();
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('Thay đổi trạng thái thất bại'),
      });
    }
  };

  const updateViewMessage = async (body: MessageViewInput) => {
    try {
      const res = await ApiHelper.post<any, BaseResponse>(UPDATE_VIEW_MESSAGE, body);
      if (res.status === StatusResponse.SUCCESS) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        if (res.data._id === detailTransaction!._id) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          setDetailTransaction({ ...detailTransaction!, areView: res.data.areView });
        }
      }
    } catch (error) {}
  };

  const sortName = (array: any) => {
    const newVals = array.sort(
      (
        a: { name: { replace: (arg0: RegExp, arg1: string) => number } },
        b: { name: { replace: (arg0: RegExp, arg1: string) => number } },
      ) => {
        return a.name.replace(/\D(.*?)$/g, '') - b.name.replace(/\D(.*?)$/g, '');
      },
    );
    return newVals;
  };

  const updateSerial = async (id: string, body: { newSerial: number }) => {
    const res: any = await ApiHelper.post(UPDATE_SERIAL + id, body);
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('Thay đổi số đặt chỗ thành công'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: res.message,
      });
    }
  };

  return {
    onGetListTransactions,
    dataHouseTransactions,
    totalTransactions,
    loading,
    updateViewMessage,
    onDetailTransaction,
    detailTransaction,
    updateStatus,
    onGetHistory,
    dataHistory,
    onGetRelated,
    dataRelated,
    totalRelated,
    ListParentChildRealeState,
    ListBlock,
    getFloor,
    ListFloor,
    getHouse,
    ListHouse,
    updateSerial,
  };
}

export default useCustomerProjectService;
