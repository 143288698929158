/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useEffect } from 'react';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useSystemTreeService from './system-tree.service';

const { Panel } = Collapse;
interface Props {
  phone: string;
}

function SystemChild(props: Props): JSX.Element {
  const { phone } = props;
  const { onGetChild, dataChild } = useSystemTreeService();

  useEffect(() => {
    if (phone) {
      onGetChild(phone);
    }
  }, [phone]);

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.SYSTEM_TREE).read ? (
        <>
          {dataChild &&
            dataChild.children.map((data: any) => {
              return (
                <div>
                  {data.childrenCount > 0 ? (
                    <Collapse
                      bordered={false}
                      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                      className="site-collapse-custom-collapse"
                    >
                      <Panel
                        header={
                          <>
                            <div>
                              {data.customerType === 'COMPANY' ? data.businessName : data.name}
                              <span
                                style={{
                                  background: '#0485fa',
                                  color: 'white',
                                  padding: '0px 10px',
                                  marginLeft: '5px',
                                  borderRadius: '10px',
                                  position: 'relative',
                                  top: '2px',
                                }}
                              >
                                {data.childrenCount}
                              </span>
                            </div>
                          </>
                        }
                        key={data._id}
                      >
                        <SystemChild phone={data.phone} />
                      </Panel>
                    </Collapse>
                  ) : (
                    <div style={{ marginLeft: 15, paddingBottom: 10 }}>
                      {data.customerType === 'COMPANY' ? data.businessName : data.name}
                      <span
                        style={{
                          background: '#0485fa',
                          color: 'white',
                          padding: '0px 10px',
                          marginLeft: '5px',
                          borderRadius: '10px',
                          position: 'relative',
                          top: '2px',
                        }}
                      >
                        {data.childrenCount}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
        </>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default SystemChild;
