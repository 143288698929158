import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListCustomerData {
  total: number;
  data: any;
}
export interface ListCustomerReq {
  status?: string;
  page?: number;
  limit?: string | number;
  search?: string;
  customerType?: string;
}

function useListCustomer(): [(request: ListCustomerReq) => Promise<void>, ListCustomerData | undefined, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [listCustomer, getListCustomer] = useState<ListCustomerData>();

  const onFetchListCustomer = async (request: ListCustomerReq) => {
    setLoading(true);
    try {
      const res = await ApiHelper.fetch<ListCustomerReq, any>(BASE_URL.LIST_CUSTOMER, request);
      setLoading(false);
      if (res && res.status === StatusResponse.SUCCESS) {
        getListCustomer({ data: res.data, total: res.total });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return [onFetchListCustomer, listCustomer, loading];
}

export default useListCustomer;
