/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-named-default */
// import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
// @ts-ignore
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
// @ts-ignore
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
// @ts-ignore
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
// @ts-ignore
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
// @ts-ignore
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
// @ts-ignore
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
// @ts-ignore
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
// @ts-ignore
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
// @ts-ignore
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// @ts-ignore
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// @ts-ignore
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize';
// @ts-ignore
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
// @ts-ignore
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
// @ts-ignore
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
// @ts-ignore
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// @ts-ignore
import Image from '@ckeditor/ckeditor5-image/src/image';
// @ts-ignore
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// @ts-ignore
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// @ts-ignore
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';
// @ts-ignore
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// @ts-ignore
import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative';
// @ts-ignore
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload';
// @ts-ignore
import LinkImage from '@ckeditor/ckeditor5-link/src/linkimage';
// @ts-ignore
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// @ts-ignore
import Link from '@ckeditor/ckeditor5-link/src/link';
// @ts-ignore
import List from '@ckeditor/ckeditor5-list/src/list';
// @ts-ignore
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// @ts-ignore
import CKEditor5 from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Table from '@ckeditor/ckeditor5-table/src/table';
// @ts-ignore
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
// @ts-ignore
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
// @ts-ignore
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// @ts-ignore
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
// @ts-ignore
import Mention from '@ckeditor/ckeditor5-mention/src/mention';
// @ts-ignore
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak';

import { default as React } from 'react';
import { MyCustomUploadAdapterPlugin } from 'helpers/CKUploader';

interface Props {
  data?: string;
  disabled?: boolean;
  onChange: any;
  MentionEmail?: any;
}

function CKEEditor(props: Props): JSX.Element {
  return (
    <CKEditor5
      editor={ClassicEditor}
      onChange={props.onChange}
      data={props.data}
      disabled={props.disabled || false}
      config={{
        extraPlugins: [MyCustomUploadAdapterPlugin],
        plugins: [
          Mention,
          Essentials,
          FontFamily,
          FontSize,
          FontColor,
          FontBackgroundColor,
          SimpleUploadAdapter,
          Autoformat,
          Bold,
          Italic,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          BlockQuote,
          Heading,
          Image,
          // ImageCaption,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          LinkImage,
          Link,
          List,
          Paragraph,
          Indent,
          Table,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          Alignment,
          ImageResize,
          PageBreak,
        ],
        mention: {
          feeds: [
            {
              marker: '@',
              feed: props.MentionEmail ? props.MentionEmail : [],
              minimumCharacters: 1,
            },
          ],
        },
        toolbar: [
          'heading',
          'undo',
          'redo',
          '|',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'superscript',
          'subscript',
          '|',
          'bulletedList',
          'numberedList',
          'alignment',
          'indent',
          'outdent',
          '|',
          'link',
          'linkImage',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          'pageBreak',
        ],
        image: {
          // Configure the available styles.
          styles: ['alignLeft', 'alignCenter', 'alignRight'],

          // Configure the available image resize options.
          resizeOptions: [
            {
              name: 'imageResize:original',
              label: 'Original',
              value: null,
            },
            {
              name: 'imageResize:50',
              label: '50%',
              value: '50',
            },
            {
              name: 'imageResize:75',
              label: '75%',
              value: '75',
            },
          ],
          toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageResize',
            'imageStyle:full',
            'imageStyle:side',
            '|',
            'imageTextAlternative',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'],
        },
      }}
    />
  );
}

export default CKEEditor;
