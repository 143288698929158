import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListDirectionReq {
  page?: number;
  limit?: string | number;
  search?: string;
}

function useListDirection(): [(request: ListDirectionReq) => Promise<void>, any, boolean, any, any] {
  const [loading, setLoading] = useState<boolean>(false);
  const [listDirection, getListDirection] = useState<any>([]);
  const [listDirectionData, setListDirectionData] = useState<any>([]);
  const [stopLoad, setStoploadmore] = useState<boolean>(false);
  const onFetchListDirection = async (request: ListDirectionReq) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<ListDirectionReq, any>(BASE_URL.LIST_DIRECTION, request);
      if (res && res.status == StatusResponse.SUCCESS) {
        // const newData = request.page! > 1 ? [...listDirectionData, ...res.data] : res.data;
        setLoading(false);
        setListDirectionData(res.data);
        getListDirection(res);
        setStoploadmore(true);
        setTimeout(() => {
          setStoploadmore(false);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return [onFetchListDirection, listDirection, loading, listDirectionData, stopLoad];
}

export default useListDirection;
