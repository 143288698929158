/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, InputNumber, Row } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { useEffect } from 'react';
import { translate } from 'translate/language';
import './commissions-realestate.scss';
import useCommissionRealService from './commissions.service';

function CommissionsRealestateScreen(): JSX.Element {
  const [form] = Form.useForm();
  const { onFetchProjectComission, dataCommission, onUpdateProjectCommission } = useCommissionRealService();

  // Danh sách hoa hồng BĐS lẻ
  useEffect(() => {
    onFetchProjectComission();
  }, []);

  useEffect(() => {
    if (dataCommission) {
      form.setFieldsValue({
        // hàng 1
        h1_1: dataCommission.owner?.f1Poster.f1Buyer.selfBooking.pag,
        h1_2: dataCommission.owner?.f1Poster.f1Buyer.selfBooking.ctvSellers,
        h1_3: dataCommission.owner?.f1Poster.f1Buyer.selfBooking.ctvBuyers,
        h1_4: dataCommission.owner?.f1Poster.f1Buyer.selfBooking.ctvBuyersSystem,
        h1_6: dataCommission.owner?.f1Poster.f1Buyer.selfBooking.customer,
        h1_5: dataCommission.owner?.f1Poster.f1Buyer.selfBooking.systemCustomer,
        // hàng 2
        h2_1: dataCommission.owner?.f1Poster.f1Buyer.agencyBooking.pag,
        h2_2: dataCommission.owner?.f1Poster.f1Buyer.agencyBooking.ctvSellers,
        h2_3: dataCommission.owner?.f1Poster.f1Buyer.agencyBooking.ctvBuyers,
        h2_4: dataCommission.owner?.f1Poster.f1Buyer.agencyBooking.ctvBuyersSystem,
        h2_5: dataCommission.owner?.f1Poster.f1Buyer.agencyBooking.customer,
        h2_6: dataCommission.owner?.f1Poster.f1Buyer.agencyBooking.systemCustomer,
        // hàng 3
        h3_1: dataCommission.owner?.f1Poster.nonF1Buyer.selfBooking.pag,
        h3_2: dataCommission.owner?.f1Poster.nonF1Buyer.selfBooking.ctvSellers,
        h3_3: dataCommission.owner?.f1Poster.nonF1Buyer.selfBooking.ctvBuyers,
        h3_4: dataCommission.owner?.f1Poster.nonF1Buyer.selfBooking.ctvBuyersSystem,
        h3_5: dataCommission.owner?.f1Poster.nonF1Buyer.selfBooking.customer,
        h3_6: dataCommission.owner?.f1Poster.nonF1Buyer.selfBooking.systemCustomer,
        // hàng 4
        h4_1: dataCommission.owner?.f1Poster.nonF1Buyer.agencyBooking.pag,
        h4_2: dataCommission.owner?.f1Poster.nonF1Buyer.agencyBooking.ctvSellers,
        h4_3: dataCommission.owner?.f1Poster.nonF1Buyer.agencyBooking.ctvBuyers,
        h4_4: dataCommission.owner?.f1Poster.nonF1Buyer.agencyBooking.ctvBuyersSystem,
        h4_5: dataCommission.owner?.f1Poster.nonF1Buyer.agencyBooking.customer,
        h4_6: dataCommission.owner?.f1Poster.nonF1Buyer.agencyBooking.systemCustomer,
        // hàng 5
        h5_1: dataCommission.owner?.nonF1Poster.f1Buyer.selfBooking.pag,
        h5_2: dataCommission.owner?.nonF1Poster.f1Buyer.selfBooking.ctvSellers,
        h5_3: dataCommission.owner?.nonF1Poster.f1Buyer.selfBooking.ctvBuyers,
        h5_4: dataCommission.owner?.nonF1Poster.f1Buyer.selfBooking.ctvBuyersSystem,
        h5_5: dataCommission.owner?.nonF1Poster.f1Buyer.selfBooking.customer,
        h5_6: dataCommission.owner?.nonF1Poster.f1Buyer.selfBooking.systemCustomer,
        // hàng 6
        h6_1: dataCommission.owner?.nonF1Poster.f1Buyer.agencyBooking.pag,
        h6_2: dataCommission.owner?.nonF1Poster.f1Buyer.agencyBooking.ctvSellers,
        h6_3: dataCommission.owner?.nonF1Poster.f1Buyer.agencyBooking.ctvBuyers,
        h6_4: dataCommission.owner?.nonF1Poster.f1Buyer.agencyBooking.ctvBuyersSystem,
        h6_5: dataCommission.owner?.nonF1Poster.f1Buyer.agencyBooking.customer,
        h6_6: dataCommission.owner?.nonF1Poster.f1Buyer.agencyBooking.systemCustomer,
        // hàng 7
        h7_1: dataCommission.owner?.nonF1Poster.nonF1Buyer.selfBooking.pag,
        h7_2: dataCommission.owner?.nonF1Poster.nonF1Buyer.selfBooking.ctvSellers,
        h7_3: dataCommission.owner?.nonF1Poster.nonF1Buyer.selfBooking.ctvBuyers,
        h7_4: dataCommission.owner?.nonF1Poster.nonF1Buyer.selfBooking.ctvBuyersSystem,
        h7_5: dataCommission.owner?.nonF1Poster.nonF1Buyer.selfBooking.customer,
        h7_6: dataCommission.owner?.nonF1Poster.nonF1Buyer.selfBooking.systemCustomer,
        // hàng 8
        h8_1: dataCommission.owner?.nonF1Poster.nonF1Buyer.agencyBooking.pag,
        h8_2: dataCommission.owner?.nonF1Poster.nonF1Buyer.agencyBooking.ctvSellers,
        h8_3: dataCommission.owner?.nonF1Poster.nonF1Buyer.agencyBooking.ctvBuyers,
        h8_4: dataCommission.owner?.nonF1Poster.nonF1Buyer.agencyBooking.ctvBuyersSystem,
        h8_5: dataCommission.owner?.nonF1Poster.nonF1Buyer.agencyBooking.customer,
        h8_6: dataCommission.owner?.nonF1Poster.nonF1Buyer.agencyBooking.systemCustomer,
        // hàng 9
        h9_1: dataCommission.agency?.f1Poster.f1Buyer.selfBooking.pag,
        h9_2: dataCommission.agency?.f1Poster.f1Buyer.selfBooking.ctvSellers,
        h9_3: dataCommission.agency?.f1Poster.f1Buyer.selfBooking.ctvBuyers,
        h9_4: dataCommission.agency?.f1Poster.f1Buyer.selfBooking.ctvBuyersSystem,
        h9_5: dataCommission.agency?.f1Poster.f1Buyer.selfBooking.customer,
        h9_6: dataCommission.agency?.f1Poster.f1Buyer.selfBooking.systemCustomer,
        // hàng 10
        h10_1: dataCommission.agency?.f1Poster.f1Buyer.agencyBooking.pag,
        h10_2: dataCommission.agency?.f1Poster.f1Buyer.agencyBooking.ctvSellers,
        h10_3: dataCommission.agency?.f1Poster.f1Buyer.agencyBooking.ctvBuyers,
        h10_4: dataCommission.agency?.f1Poster.f1Buyer.agencyBooking.ctvBuyersSystem,
        h10_5: dataCommission.agency?.f1Poster.f1Buyer.agencyBooking.customer,
        h10_6: dataCommission.agency?.f1Poster.f1Buyer.agencyBooking.systemCustomer,
        // hàng 11
        h11_1: dataCommission.agency?.f1Poster.nonF1Buyer.selfBooking.pag,
        h11_2: dataCommission.agency?.f1Poster.nonF1Buyer.selfBooking.ctvSellers,
        h11_3: dataCommission.agency?.f1Poster.nonF1Buyer.selfBooking.ctvBuyers,
        h11_4: dataCommission.agency?.f1Poster.nonF1Buyer.selfBooking.ctvBuyersSystem,
        h11_5: dataCommission.agency?.f1Poster.nonF1Buyer.selfBooking.customer,
        h11_6: dataCommission.agency?.f1Poster.nonF1Buyer.selfBooking.systemCustomer,
        // hàng 12
        h12_1: dataCommission.agency?.f1Poster.nonF1Buyer.agencyBooking.pag,
        h12_2: dataCommission.agency?.f1Poster.nonF1Buyer.agencyBooking.ctvSellers,
        h12_3: dataCommission.agency?.f1Poster.nonF1Buyer.agencyBooking.ctvBuyers,
        h12_4: dataCommission.agency?.f1Poster.nonF1Buyer.agencyBooking.ctvBuyersSystem,
        h12_5: dataCommission.agency?.f1Poster.nonF1Buyer.agencyBooking.customer,
        h12_6: dataCommission.agency?.f1Poster.nonF1Buyer.agencyBooking.systemCustomer,
      });
    }
  }, [dataCommission]);

  const onFinish = (values: any) => {
    const data = {
      owner: {
        f1Poster: {
          f1Buyer: {
            selfBooking: {
              pag: values.h1_1 || 0,
              ctvSellers: values.h1_2 || 0,
              ctvBuyers: values.h1_3 || 0,
              ctvBuyersSystem: values.h1_4 || 0,
              customer: values.h1_5 || 0,
              systemCustomer: values.h1_6 || 0,
            },
            agencyBooking: {
              pag: values.h2_1 || 0,
              ctvSellers: values.h2_2 || 0,
              ctvBuyers: values.h2_3 || 0,
              ctvBuyersSystem: values.h2_4 || 0,
              customer: values.h2_5 || 0,
              systemCustomer: values.h2_6 || 0,
            },
          },
          nonF1Buyer: {
            selfBooking: {
              pag: values.h3_1 || 0,
              ctvSellers: values.h3_2 || 0,
              ctvBuyers: values.h3_3 || 0,
              ctvBuyersSystem: values.h3_4 || 0,
              customer: values.h3_5 || 0,
              systemCustomer: values.h3_6 || 0,
            },
            agencyBooking: {
              pag: values.h4_1 || 0,
              ctvSellers: values.h4_2 || 0,
              ctvBuyers: values.h4_3 || 0,
              ctvBuyersSystem: values.h4_4 || 0,
              customer: values.h4_5 || 0,
              systemCustomer: values.h4_6 || 0,
            },
          },
        },
        nonF1Poster: {
          f1Buyer: {
            selfBooking: {
              pag: values.h5_1 || 0,
              ctvSellers: values.h5_2 || 0,
              ctvBuyers: values.h5_3 || 0,
              ctvBuyersSystem: values.h5_4 || 0,
              customer: values.h5_5 || 0,
              systemCustomer: values.h5_6 || 0,
            },
            agencyBooking: {
              pag: values.h6_1 || 0,
              ctvSellers: values.h6_2 || 0,
              ctvBuyers: values.h6_3 || 0,
              ctvBuyersSystem: values.h6_4 || 0,
              customer: values.h6_5 || 0,
              systemCustomer: values.h6_6 || 0,
            },
          },
          nonF1Buyer: {
            selfBooking: {
              pag: values.h7_1 || 0,
              ctvSellers: values.h7_2 || 0,
              ctvBuyers: values.h7_3 || 0,
              ctvBuyersSystem: values.h7_4 || 0,
              customer: values.h7_5 || 0,
              systemCustomer: values.h7_6 || 0,
            },
            agencyBooking: {
              pag: values.h8_1 || 0,
              ctvSellers: values.h8_2 || 0,
              ctvBuyers: values.h8_3 || 0,
              ctvBuyersSystem: values.h8_4 || 0,
              customer: values.h8_5 || 0,
              systemCustomer: values.h8_6 || 0,
            },
          },
        },
      },
      agency: {
        f1Poster: {
          f1Buyer: {
            selfBooking: {
              pag: values.h9_1 || 0,
              ctvSellers: values.h9_2 || 0,
              ctvBuyers: values.h9_3 || 0,
              ctvBuyersSystem: values.h9_4 || 0,
              customer: values.h9_5 || 0,
              systemCustomer: values.h9_6 || 0,
            },
            agencyBooking: {
              pag: values.h10_1 || 0,
              ctvSellers: values.h10_2 || 0,
              ctvBuyers: values.h10_3 || 0,
              ctvBuyersSystem: values.h10_4 || 0,
              customer: values.h10_5 || 0,
              systemCustomer: values.h10_6 || 0,
            },
          },
          nonF1Buyer: {
            selfBooking: {
              pag: values.h11_1 || 0,
              ctvSellers: values.h11_2 || 0,
              ctvBuyers: values.h11_3 || 0,
              ctvBuyersSystem: values.h11_4 || 0,
              customer: values.h11_5 || 0,
              systemCustomer: values.h11_6 || 0,
            },
            agencyBooking: {
              pag: values.h12_1 || 0,
              ctvSellers: values.h12_2 || 0,
              ctvBuyers: values.h12_3 || 0,
              ctvBuyersSystem: values.h12_4 || 0,
              customer: values.h12_5 || 0,
              systemCustomer: values.h12_6 || 0,
            },
          },
        },
      },
    };
    onUpdateProjectCommission(data);
  };
  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <table id="project_commission">
          <tr>
            <th style={{ width: '6%', textAlign: 'center' }}>Vai trò của người đăng</th>
            <th style={{ width: '10%', textAlign: 'center' }}>Người đăng</th>
            <th style={{ width: '10%', textAlign: 'center' }}>Khách mua</th>
            <th style={{ width: '8%', textAlign: 'center' }}>Phương thức book căn</th>
            <th>PAG (%)</th>
            <th>CTV bên bán (%)</th>
            <th>CTV bên mua (%)</th>
            <th style={{ width: '8%', textAlign: 'center' }}>Hệ thống CTV bên mua (%)</th>
            <th>Khách hàng (%)</th>
            <th>Hệ thống KH (%)</th>
          </tr>

          <tbody>
            {/* Chính chủ */}
            {/* Hàng 1 */}
            <tr>
              <td rowSpan={8} className="tdBold">
                Chính chủ
              </td>
              <td rowSpan={4} className="tdBold">
                Có con
              </td>
              <td rowSpan={2} className="tdBold">
                Có con
              </td>
              <td className="tdBold">Tự book</td>
              <td>
                <Form.Item name="h1_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h1_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h1_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h1_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h1_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h1_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 2 */}
            <tr>
              <td className="tdBold">Môi giới</td>
              <td>
                <Form.Item name="h2_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h2_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h2_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h2_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h2_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h2_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 3 */}
            <tr>
              <td rowSpan={2} className="tdBold">
                Không có con
              </td>
              <td className="tdBold">Tự book</td>
              <td>
                <Form.Item name="h3_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h3_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h3_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h3_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h3_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h3_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 4 */}
            <tr>
              <td className="tdBold">Môi giới</td>
              <td>
                <Form.Item name="h4_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h4_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h4_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h4_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h4_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h4_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Không có con */}
            {/* Hàng 5 */}
            <tr>
              <td rowSpan={4} className="tdBold">
                Không có con
              </td>
              <td rowSpan={2} className="tdBold">
                Có con
              </td>
              <td className="tdBold">Tự book</td>
              <td>
                <Form.Item name="h5_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h5_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h5_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h5_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h5_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h5_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 6 */}
            <tr>
              <td className="tdBold">Môi giới</td>
              <td>
                <Form.Item name="h6_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h6_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h6_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h6_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h6_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h6_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 7 */}
            <tr>
              <td rowSpan={2} className="tdBold">
                Không có con
              </td>
              <td className="tdBold">Tự book</td>
              <td>
                <Form.Item name="h7_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h7_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h7_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h7_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h7_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h7_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 8 */}
            <tr>
              <td className="tdBold">Môi giới</td>
              <td>
                <Form.Item name="h8_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h8_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h8_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h8_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h8_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h8_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>

            {/* MÔI GIỚI */}
            {/* hàng 9 */}
            <tr className="borderTop">
              <td rowSpan={8} className="tdBold">
                Môi giới
              </td>
              <td rowSpan={4} className="tdBold">
                Có con
              </td>
              <td rowSpan={2} className="tdBold">
                Có con
              </td>
              <td className="tdBold">Tự book</td>
              <td>
                <Form.Item name="h9_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h9_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h9_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h9_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h9_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h9_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 10 */}
            <tr>
              <td className="tdBold">Môi giới</td>
              <td>
                <Form.Item name="h10_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h10_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h10_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h10_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h10_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h10_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 11 */}
            <tr>
              <td rowSpan={2} className="tdBold">
                Không có con
              </td>
              <td className="tdBold">Tự book</td>
              <td>
                <Form.Item name="h11_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h11_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h11_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h11_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h11_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h11_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
            {/* Hàng 12 */}
            <tr>
              <td className="tdBold">Môi giới</td>
              <td>
                <Form.Item name="h12_1" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h12_2" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h12_3" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h12_4" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h12_5" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
              <td>
                <Form.Item name="h12_6" initialValue={0} className="marginNone">
                  <InputNumber size="small" step={1} min={0} />
                </Form.Item>
              </td>
            </tr>
          </tbody>
        </table>
        {getCheckPermissionUser(PERMISSION_NAME.CONFIG_COMMISSIONS_REALESTATE).edit ? (
          <Row style={{ marginTop: '10px' }}>
            <Col span={24}>
              <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {translate('action.save')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </Form>
    </>
  );
}

export default CommissionsRealestateScreen;
