import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { Ctv, ListCtvReq } from 'pages/Ctv/types/ctv.types';
import { useState } from 'react';

function useLitsCtv() {
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState<Ctv[]>([]);
  const [dataTotal, setDataTotal] = useState<number>(0);

  const fetchData = async (request: ListCtvReq) => {
    try {
      setLoading(true);
      // @ts-ignore
      const res = await ApiHelper.fetch<ListCtvReq, ListCtvRes>('/collaborator/list', request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        setDataList(res.data);
        setDataTotal(res.total);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return { fetchData, dataList, loading, setDataList, dataTotal };
}

export default useLitsCtv;
