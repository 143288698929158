/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastError, ToastSuccess } from '../../components/Toast/index';
import { translate } from '../../translate/language';
import { City, PointPlusBody, PointPlusReq, PointPlusRes } from './point-plus.type';

const city = '/city';
const PACKAGE_POINT_URL = '/point-packages/plus';
const UPDATE_PACKAGE_POINT_URL = '/point-packages/change-plus-point';

function usePointPlusService() {
  const history = useHistory();
  const [dataCity, setDataCity] = useState<City[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataPoint, setDataPoint] = useState<PointPlusRes[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPoint, setTotalPoint] = useState<number>();

  // Danh sách cộng điểm
  const onGetPackagePoint = async (params: PointPlusReq) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(PACKAGE_POINT_URL, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalPrice(res.totalPoint);
        setTotalPoint(res.total);
        setDataPoint(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Thêm mới cộng điểm
  const onCreatePointPlus = async (data: PointPlusBody) => {
    try {
      setDisabled(true);
      const res: any = await ApiHelper.post(PACKAGE_POINT_URL, data);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
        history.goBack();
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: res.message,
        });
        setDisabled(false);
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
      setDisabled(false);
    }
  };

  // Danh sách thành phố
  const onGetCity = async () => {
    try {
      const res: any = await ApiHelper.fetch(city);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataCity(res.data);
      } else {
        setDataCity([]);
      }
    } catch (error) {
      setDataCity([]);
    }
  };

  const onUpdatePointPlus = async (id: string, point: number) => {
    try {
      const res: any = await ApiHelper.post(`${UPDATE_PACKAGE_POINT_URL}/${id}`, { point });
      if (res && res.status === StatusResponse.SUCCESS && res.data === true) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: 'Cập nhật thành công',
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: res.message,
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: 'Cập nhật không thành công',
      });
    }
  };

  return {
    onGetCity,
    dataCity,
    onGetPackagePoint,
    dataPoint,
    totalPrice,
    totalPoint,
    loading,
    onCreatePointPlus,
    onUpdatePointPlus,
    disabled,
    setDisabled,
  };
}

export default usePointPlusService;
