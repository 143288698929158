/* 
  Created by no name at 01-06-2021 11:33:40
  Màn hình quản lí bán cho thuê
*/
import React, { useEffect, useState } from 'react';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { ReqListsalesandRent } from '../modelSaleAndRental/SaleAndRental.type';

function useLitsSaleAndRental() {
  const [loading, setLoading] = useState(false);
  const [listSaleAndRental, setListSaleAndRental] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const onSaleAndRental = async (request: ReqListsalesandRent) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<ReqListsalesandRent, any>(BASE_URL.PROJECT_NEWS, request);
      console.log('TCL: res', res);
      if (res && res.status == StatusResponse.SUCCESS) {
        setLoading(false);
        setListSaleAndRental(res.data);
        setTotal(res.total);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return { onSaleAndRental, listSaleAndRental, total, loading };
}
export default useLitsSaleAndRental;
