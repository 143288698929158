import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { translate } from 'translate/language';

interface PropsInput {
  label: string;
  rules?: any;
  name: string;
  isNumber?: boolean;
  isEdit?: boolean;
}

function InputForm(props: PropsInput) {
  const [state, setstate] = useState('state');
  const { label, rules, name, isNumber, isEdit } = props;
  return (
    <Form.Item
      name={name}
      label={label}
      labelCol={{ md: 14, xl: 8, xs: 24 }}
      labelAlign="left"
      required
      wrapperCol={{ md: 12, xs: 24 }}
      rules={[
        {
          required: true,
          message: translate(label) + ' ' + translate('require'),
        },
        isNumber
          ? {
              pattern: /^[0-9]*$/,
              message: translate(label) + ' ' + translate('not_valid'),
            }
          : {},
      ]}
    >
      <Input disabled={isEdit} style={{ backgroundColor: 'white' }} />
    </Form.Item>
  );
}

export default InputForm;
