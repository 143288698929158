import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import { useState } from 'react';

export interface ListPermissionData {
  total: number;
  data: {
    createdAt: string;
    deletedAt?: string;
    name: string;
    permission: any[];
    status: string;
    _id: string;
  }[];
}

export interface RequestPermission {
  page?: number;
  limit?: number;
  status?: string;
  search?: string;
}
function useGetPermission(): [(request: RequestPermission) => Promise<void>, ListPermissionData | undefined, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [listPermission, getListPermission] = useState<ListPermissionData>();

  const onFetchListPermission = async (request: RequestPermission) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<RequestPermission, any>(BASE_URL.LIST_PERMISSION, request);
      if (res && res.status === StatusResponse.SUCCESS) {
        setLoading(false);
        getListPermission({ data: res.data, total: res.total });
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return [onFetchListPermission, listPermission, loading];
}

export default useGetPermission;
