import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { translate } from 'translate/language';
import { FilterNotification, NotificationRes } from './notification.type';

const useNotificationService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<NotificationRes[]>([]);
  const [dataDetail, setDataDetail] = useState<NotificationRes | undefined>(undefined);
  const [totalData, setTotalData] = useState<number>(0);

  const onGetNotification = async (params: FilterNotification) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch('settings/notification-rule', params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setData(res.data);
        setTotalData(res.total);
        setLoading(false);
      } else {
        setData(res.data);
        setTotalData(res.total);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onDetailNotification = async (id: string) => {
    const res: any = await ApiHelper.fetch('settings/notification-rule/' + id);
    if (res && res.status === StatusResponse.SUCCESS) {
      setDataDetail(res.data);
    }
  };

  const onDeleteNotification = async (id: string[]) => {
    const res: any = await ApiHelper.delete('settings/notification-rule', { ids: id });
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.delete_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return { onGetNotification, data, totalData, loading, onDetailNotification, dataDetail, onDeleteNotification };
};

export default useNotificationService;
