import { message } from 'antd';
/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';

const URL_SYSTEM = '/user/getAppTreeNodes';

function useSystemTreeService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSystem, setDataSystem] = useState<any | undefined>(undefined);
  const [dataChild, setDataChild] = useState<any | undefined>(undefined);

  const onGetSystemTree = async () => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(URL_SYSTEM);
      if (res && res.status === 'DEFAULT_INTRODUCER_NOT_SET') {
        setLoading(false);
        return message.error('Hệ thống chưa set người giới thiệu mặc định');
      }
      if (res && res.status === 'SUCCESS') {
        setDataSystem(res.data);
        setLoading(false);
      } else {
        setDataSystem(undefined);
        setLoading(false);
      }
    } catch (error) {
      setDataSystem(undefined);
      setLoading(false);
    }
  };

  const onGetChild = async (phone: string) => {
    try {
      const res: any = await ApiHelper.fetch(URL_SYSTEM, { parentPhone: phone });
      if (res && res.status === 'SUCCESS') {
        setDataChild(res.data);
      } else {
        setDataChild(undefined);
      }
    } catch (error) {
      setDataChild(undefined);
    }
  };

  return { onGetSystemTree, dataSystem, loading, onGetChild, dataChild };
}

export default useSystemTreeService;
