import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListBankReq {
    page?: number;
    limit?: string | number;
    search?: string;
    status?: string;
}

function useListBank(): [(request: ListBankReq) => Promise<void>, any, boolean] {
    const [loading, setLoading] = useState<boolean>(false);
    const [listBank, getListBank] = useState([]);

    const onFetchListBank = async (request: ListBankReq) => {
        try {
            setLoading(true);
            const res = await ApiHelper.fetch<ListBankReq, any>(BASE_URL.LIST_BANK, request)
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false)
                getListBank(res)
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return [onFetchListBank, listBank, loading]
}

export default useListBank;