/* eslint-disable prettier/prettier */
import { Layout } from 'antd';
import GlobalHeaderRight from 'components/GlobalHeader/RightContent';
import Sidebar from 'components/Layouts/Sidebar';
import { AppContext } from 'contexts/AppContext';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import renderRoutes from 'routes/router';
import useSocketIO from 'socketIO/useSocketIO';
import styled from 'styled-components';
import VERSION from 'version';

const { Content, Header, Footer } = Layout;
interface BaseProps extends RouteComponentProps<any, any> {}

const StyledContainer = styled.div``;
const StyledHeader = styled(Header)`
  background-color: #fff;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
`;

const App = React.memo((props: BaseProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // setInterval(() => {
  useSocketIO();
  // }, 5000);
  // dispatch(resetLogin())
  let isLogin: any = null;
  const _refreshToken = localStorage.getItem('_refreshToken');
  const user = useSelector((state: any) => {
    const u = state.userReducer;
    return u;
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // isLogin = !!user;
  isLogin = !!((user && user._id) || (user && user.userReducer));

  return (
    <StyledContainer>
      <AppContext.Consumer>
        {({ collapsedSidebar }) => {
          if (isLogin) {
            return (
              <Layout style={{ minHeight: '100vh' }}>
                {/* <Helmet titleTemplate={'Ecoshare Wallet'} defaultTitle={'Home'} meta={[]} /> */}
                <Sidebar pathname={props.location.pathname} />
                <Layout style={{ paddingLeft: collapsedSidebar ? 80 : 256 }}>
                  <StyledHeader
                    style={{
                      paddingRight: 20,
                      backgroundColor: '#fff',
                      alignItems: 'center',
                    }}
                  >
                    <GlobalHeaderRight />
                  </StyledHeader>
                  <Content style={{ margin: '24px', paddingTop: 70 }}>{renderRoutes(isLogin)}</Content>
                </Layout>
              </Layout>
            );
          }
          if (!isLogin) {
            return <Content style={{ margin: '24px', paddingTop: 70 }}>{renderRoutes(isLogin)}</Content>;
          }
          return <div>Loading....</div>;
        }}
      </AppContext.Consumer>
      <Footer style={{ textAlign: 'center' }}>
        CMS Phúc An Gia <strong>Vinsofts</strong> v{VERSION || '__'}
      </Footer>
    </StyledContainer>
  );
});

export default withRouter(App);
