/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { StatusBuy } from 'constants/Status';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import useHouseService from 'pages/House/house.service';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import useRealEstateNewService from './real-estate-new.service';
import { RealEstateNewBody } from './real-estate-new.types';

const { Option } = Select;
const { Title } = Typography;

function RealEstateNewDetailScreen(): JSX.Element {
  const [form] = Form.useForm();
  const params: { id: string } = useParams();
  const isEdit = params && params.id;
  const history = useHistory();

  const { onDetailRealEstateNew, detailRealEstateNew, onUpdateRealEstateNew } = useRealEstateNewService();
  const { onFetchDirection, dataDirection } = useHouseService();
  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();

  useEffect(() => {
    if (isEdit) {
      // Danh sách hướng nhà
      onFetchDirection();
      // Loại bất động sản
      onFetchListParentChildRealeState();
      // Chi tiết
      onDetailRealEstateNew({
        _id: params.id,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (detailRealEstateNew) {
      form.setFieldsValue({
        status: detailRealEstateNew.status,
        type: detailRealEstateNew.type,
        childRealEstateIds: detailRealEstateNew.childRealEstateIds,
        addressName: detailRealEstateNew?.location?.addressName,
        minArea: detailRealEstateNew.minArea,
        maxArea: detailRealEstateNew.maxArea,
        minPrice: detailRealEstateNew.minPrice,
        maxPrice: detailRealEstateNew.maxPrice,
        directionIds: detailRealEstateNew.directionIds,
        note: detailRealEstateNew.note,
      });
    }
  }, [detailRealEstateNew]);

  const closeButton = () => {
    history.goBack();
  };

  const onFinish = (values: any) => {
    console.log('Success:', values);
    const data: RealEstateNewBody = {
      _id: params.id,
      directionIds: values.directionIds,
      minPrice: values.minPrice,
      maxPrice: values.maxPrice,
      minArea: values.minArea,
      maxArea: values.maxArea,
      childRealEstateIds: values.childRealEstateIds,
      note: values.note,
      type: values.type,
      addressName: values.addressName,
      status: values.status,
    };
    onUpdateRealEstateNew(data);
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).create ||
      getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).edit ? (
        <Form form={form} onFinish={onFinish}>
          <Row>
            <Col span={20} offset={3}>
              {/* Trạng thái */}
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="status"
                    label={translate('status.name')}
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    initialValue={StatusBuy.PENDING}
                    rules={[
                      {
                        required: true,
                        message: 'Trạng thái không được để trống',
                      },
                    ]}
                  >
                    <Select placeholder={translate('admin.status_active')}>
                      <Option value={StatusBuy.PENDING}>{translate('StatusBuy.PENDING')}</Option>
                      <Option value={StatusBuy.SUPPORTING}>{translate('StatusBuy.SUPPORTING')}</Option>
                      <Option value={StatusBuy.SUPPORTED}>{translate('StatusBuy.SUPPORTED')}</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Loại kí gửi */}
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="type"
                    label="Loại ký gửi"
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Loại ký gửi không được để trống',
                      },
                    ]}
                  >
                    <Select placeholder={translate('admin.status_active')}>
                      <Option value="BUY">Mua</Option>
                      <Option value="RENTAL">Thuê</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Loại bất động sản */}
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="childRealEstateIds"
                    label="Loại bất động sản"
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Loại bất động sản không được để trống',
                        type: 'array',
                      },
                    ]}
                  >
                    <Select placeholder="Loại bất động sản" mode="multiple">
                      {ListParentChildRealeState
                        ? ListParentChildRealeState.map((data: any) => {
                            return (
                              <Option value={data._id} key={data._id} title={data.child}>
                                {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                              </Option>
                            );
                          })
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Khu vực */}
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item
                      name="addressName"
                      label="Khu vực"
                      labelCol={{ span: 7 }}
                      labelAlign="left"
                      rules={[
                        {
                          whitespace: true,
                          required: true,
                          message: 'Khu vực không được để trống',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              {/* Diện tích */}
              <Row>
                <Col span={6} style={{ marginLeft: '-10px', paddingLeft: '10px' }}>
                  Diện tích (m2)
                </Col>
                <Col span={10}>
                  <Input.Group size="large">
                    <Row gutter={4}>
                      <Col span={12}>
                        <Form.Item
                          name="minArea"
                          rules={[
                            {
                              pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                              message: translate('messages.integer_field', {
                                label: 'Diện tích',
                              }),
                            },
                          ]}
                        >
                          <InputNumber className="width100" step={1} min={0} placeholder="từ" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="maxArea"
                          rules={[
                            {
                              pattern: /^(\d+(?:[\.]\d{1,2})?)$/,
                              message: translate('messages.integer_field', {
                                label: 'Diện tích',
                              }),
                            },
                          ]}
                        >
                          <InputNumber className="width100" step={1} min={0} placeholder="từ" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Col>
              </Row>
              {/* Khoảng giá */}
              <Row>
                <Col span={6} style={{ marginLeft: '-10px', paddingLeft: '10px' }}>
                  Khoảng giá
                </Col>
                <Col span={10}>
                  <Input.Group size="large">
                    <Row gutter={4}>
                      <Col span={12}>
                        <Form.Item name="minPrice">
                          <InputNumber
                            className="width100"
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            parser={(value: any) => value.replace(/(\.*)/g, '')}
                            step={1000}
                            min={0}
                            placeholder="từ"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item name="maxPrice">
                          <InputNumber
                            className="width100"
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            parser={(value: any) => value.replace(/(\.*)/g, '')}
                            step={1000}
                            min={0}
                            placeholder="đến"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Input.Group>
                </Col>
              </Row>
              {/* Hướng nhà */}
              <Row>
                <Col span={20}>
                  <Form.Item
                    name="directionIds"
                    label="Hướng nhà"
                    labelCol={{ sm: 7 }}
                    labelAlign="left"
                    rules={[
                      {
                        required: true,
                        message: 'Hướng nhà không được để trống',
                        type: 'array',
                      },
                    ]}
                  >
                    <Select placeholder="Hướng nhà" mode="multiple">
                      {dataDirection &&
                        dataDirection.length > 0 &&
                        dataDirection.map(data => {
                          return (
                            <Option value={data._id}>
                              {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Ghi chú */}
              <Row>
                <Col span={20}>
                  <div>
                    <Form.Item name="note" label="Ghi chú" labelCol={{ span: 7 }} labelAlign="left">
                      <Input.TextArea rows={5} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <hr />
            </Col>
          </Row>
          {detailRealEstateNew && detailRealEstateNew.userInfo && (
            <Row>
              <Col span={18} offset={3}>
                <Title level={3} underline>
                  Thông tin người đăng
                </Title>
                <Row gutter={24}>
                  {/* họ và tên */}
                  <Col span={12}>Họ và tên:</Col>
                  <Col span={12}>
                    <Title level={5}>{detailRealEstateNew.userInfo.name}</Title>
                  </Col>
                  {/* Số điện thoại */}
                  <Col span={12}>Số điện thoại:</Col>
                  <Col span={12}>
                    <Title level={5}>{detailRealEstateNew.userInfo.phone}</Title>
                  </Col>
                  {/* Email */}
                  <Col span={12}>Email:</Col>
                  <Col span={12}>
                    <Title level={5}>{detailRealEstateNew.userInfo.email}</Title>
                  </Col>
                  {/* Số CMND */}
                  <Col span={12}>Số CMND :</Col>
                  <Col span={12}>
                    <Title level={5}>{detailRealEstateNew.userInfo.identify}</Title>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          <Row>
            <Col span={20}>
              <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Col>
                  <Form.Item>
                    <Button type="default" onClick={closeButton}>
                      {translate('action.close')}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {isEdit ? translate('action.update') : translate('action.save')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default RealEstateNewDetailScreen;
