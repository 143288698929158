import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { ToastError, ToastSuccess } from 'components/Toast';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { NotificationFilter, NotificationRes, NotificationReq } from './notification.type';

const NOTIFICATION_SYSTEM = '/notifications/system';

function useNotificationService() {
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataNotification, setDataNotification] = useState<NotificationRes[]>([]);
  const [totalNotification, setTotalNotification] = useState<number>();
  const [loadingDel, setLoadingDel] = useState<boolean>(false);
  const [detailNotification, setDetailNotification] = useState<NotificationRes | undefined>(undefined);

  // Danh sách thông báo
  const onGetNotification = async (param: NotificationFilter) => {
    setLoading(true);
    try {
      const res: any = await ApiHelper.fetch(NOTIFICATION_SYSTEM, param);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataNotification(res.data);
        setTotalNotification(res.total);
        setLoading(false);
      }
    } catch (error) {
      setDataNotification([]);
      setLoading(false);
    }
  };

  // Thêm mới thông báo
  const onCreateNotification = async (data: NotificationReq) => {
    try {
      const res: any = await ApiHelper.post(NOTIFICATION_SYSTEM, data);
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.add_new_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.add_new_failed'),
      });
    }
  };

  // Chi tiết thông báo
  const onDetailNotification = async (id: string) => {
    try {
      const res: any = await ApiHelper.fetch(`${NOTIFICATION_SYSTEM}/${id}`);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDetailNotification(res.data);
      }
    } catch (error) {
      setDetailNotification(undefined);
    }
  };

  // Cập nhật thông báo
  const onUpdateNotification = async (id: string, data: NotificationReq) => {
    const res: any = await ApiHelper.put(`${NOTIFICATION_SYSTEM}/${id}`, data);
    try {
      if (res && res.status === StatusResponse.SUCCESS) {
        history.goBack();
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.update_failed'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  // Xóa thông báo
  const onDeleteNotification = async (id: string[]) => {
    setLoadingDel(true);
    try {
      const res: any = await ApiHelper.delete(NOTIFICATION_SYSTEM, { ids: id });
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
        setLoadingDel(false);
      } else {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
        setLoadingDel(false);
      }
    } catch (error) {
      setLoadingDel(false);
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };
  return {
    onGetNotification,
    dataNotification,
    totalNotification,
    loading,
    onDeleteNotification,
    loadingDel,
    onCreateNotification,
    onDetailNotification,
    detailNotification,
    onUpdateNotification,
  };
}

export default useNotificationService;
