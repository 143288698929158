/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable object-shorthand */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table, Tag, Typography } from 'antd';
import _ from 'lodash';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusBuy } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { formatNumber } from 'helpers/Utils';
import images from 'images';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterRealEstateNewComponent from './components/filter-real-estate-new.component';
import useRealEstateNewService from './real-estate-new.service';
import {
  ChildRealEstates,
  FilterRealEstateNew,
  UserInfo,
  Location,
  DirectionsInfo,
  RealEstateRes,
} from './real-estate-new.types';

const { Text, Link } = Typography;
const TYPE = {
  BUY: 'BUY',
  RENTAL: 'RENTAL',
};

function RealEstateNewScreen(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<FilterRealEstateNew>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    search: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    minArea: undefined,
    maxArea: undefined,
    childRealEstateId: undefined,
    type: undefined,
    status: undefined,
  });

  const history = useHistory();
  const {
    onListRealEstateNew,
    dataRealEstateNew,
    totalData,
    loading,
    onDeleteRealEstateNew,
    loadingDel,
    deleteArray,
  } = useRealEstateNewService();

  useEffect(() => {
    onListRealEstateNew(filter);
  }, [filter, loadingDel]);

  const onDelete = (id: string) => {
    onDeleteRealEstateNew(id);
  };

  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.DETAIL_REAL_ESTATE_NEWS + _id);
            }}
            alt=""
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={() => onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  const columns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Loại kí gửi',
      dataIndex: 'type',
      render: (type: string) => {
        return <span>{type === TYPE.BUY ? 'Mua' : 'Thuê'}</span>;
      },
    },
    {
      title: 'Loại BĐS',
      key: '_id',
      dataIndex: 'childRealEstates',
      width: '15%',
      render: (childRealEstates: ChildRealEstates[]) => {
        return (
          <>
            {childRealEstates &&
              childRealEstates.length > 0 &&
              childRealEstates.map((data: ChildRealEstates) => {
                return (
                  <Tag style={{ marginBottom: '5px' }} key={data._id}>
                    {data.name}
                  </Tag>
                );
              })}
          </>
        );
      },
    },
    {
      title: 'Người tạo',
      dataIndex: 'userInfo',
      render: (userInfo: UserInfo) => {
        return (
          <>
            {userInfo ? (
              <>
                <div>
                  <Link href={ROUTER_PATH.EDIT_CUSTOMER + userInfo._id} target="_blank">
                    <Text underline style={{ color: '#12a0c0' }}>
                      {userInfo.name}
                    </Text>
                  </Link>
                </div>
                <div>
                  <Text>{userInfo.phone}</Text>
                </div>
              </>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      render: (createdAt: any) => {
        return <span>{moment(createdAt).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Khu vực',
      dataIndex: 'location',
      width: '15%',
      render: (location: Location) => {
        return <span>{location.addressName || ''}</span>;
      },
    },
    {
      title: 'Hướng nhà',
      dataIndex: 'directionsInfo',
      width: '15%',
      render: (directionsInfo: DirectionsInfo[]) => {
        return (
          <>
            {directionsInfo &&
              directionsInfo.length > 0 &&
              directionsInfo.map((data: DirectionsInfo) => {
                return (
                  <Tag style={{ marginBottom: '5px' }} key={data._id}>
                    {data.name}
                  </Tag>
                );
              })}
          </>
        );
      },
    },
    {
      title: 'Diện tích',
      render: (data: RealEstateRes) => {
        return (
          <Text>
            {data.minArea} - {data.maxArea}
          </Text>
        );
      },
    },
    {
      title: 'Giá',
      render: (data: RealEstateRes) => {
        return (
          <Text>
            {formatNumber(data.minPrice)} - {formatNumber(data.maxPrice)}
          </Text>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        if (status === StatusBuy.PENDING) {
          return translate('StatusBuy.PENDING');
        }
        if (status === StatusBuy.SUPPORTING) {
          return translate('StatusBuy.SUPPORTING');
        }
        if (status === StatusBuy.SUPPORTED) {
          return translate('StatusBuy.SUPPORTED');
        }
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  // Tìm kiếm Tên
  const { run } = useDebounceFn(
    (search: string, type: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      if (type === 'search') {
        if (search === '') {
          setFilter({ ...filter, search: undefined });
        } else {
          setFilter({ ...filter, search });
        }
      }
      // Từ diện tích
      if (type === 'minArea') {
        setFilter({ ...filter, minArea: search });
      }
      // Đến diện tích
      if (type === 'maxArea') {
        setFilter({ ...filter, maxArea: search });
      }
      // Từ giá
      if (type === 'minPrice') {
        setFilter({ ...filter, minPrice: search });
      }
      // Đến giá
      if (type === 'maxPrice') {
        setFilter({ ...filter, maxPrice: search });
      }
    },
    {
      wait: 500,
    },
  );

  // Select loại kí gửi
  const onType = (type: string) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, type: type });
  };

  // Select loại BĐS
  const onRealEstates = (type: string) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, childRealEstateId: type });
  };

  // Select trạng thái
  const onStatus = (status: string) => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, status: status });
  };

  // Action Xóa nhiều
  const [dataDelete, setDataDelete] = useState<string[]>([]);

  const onDeleteAll = () => {
    deleteArray(dataDelete);
    setDataDelete([]);
    setFilter({ ...filter, page: 1 });
  };

  const rowSelection = {
    selectedRowKeys: dataDelete,
    onChange: (selectedRowKeys: string[]) => {
      const arrDelete = dataDelete.filter(_id => !dataRealEstateNew.find(item => _id === item._id));
      setDataDelete(_.uniq([...arrDelete, ...selectedRowKeys]));
    },
  };

  return (
    <>
      <FilterRealEstateNewComponent
        onSearch={(data: string) => run(data, 'search')}
        onType={onType}
        onRealEstates={onRealEstates}
        onStatus={onStatus}
        onMinArea={(value?: any) => run(value, 'minArea')}
        onMaxArea={(value?: any) => run(value, 'maxArea')}
        onMinPrice={(value?: any) => run(value, 'minPrice')}
        onMaxPrice={(value?: any) => run(value, 'maxPrice')}
      />
      <Row>
        {loading || loadingDel ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <div style={{ marginBottom: '10px', cursor: 'pointer' }}>
              {dataDelete && dataDelete.length > 0 && (
                <Popconfirm
                  placement="top"
                  title="Bạn có chắc muốn xóa"
                  onConfirm={() => onDeleteAll()}
                  okText={translate('action.yes')}
                  cancelText={translate('action.no')}
                >
                  <img className="img-style" src={images.ic_delete} alt="" />
                  <span
                    style={{ position: 'relative', top: '3px', color: 'red', fontWeight: 'bold', marginLeft: '3px' }}
                  >
                    Xóa nhiều
                  </span>
                </Popconfirm>
              )}
              <span style={{ position: 'relative', top: '3px', marginLeft: '10px' }}>
                Đã chọn {dataDelete.length || 0}
              </span>
            </div>

            <Table
              rowKey="_id"
              dataSource={dataRealEstateNew || []}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalData}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default RealEstateNewScreen;
