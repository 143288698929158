export const ROUTER_PATH = {
  HOME: '/',
  LOGIN: '/login',
  BCT_ANALYSTS: '/bct-analysts',
  ACCOUNT: 'account',
  // Quản lý admin
  ACCOUNT_ADMIN: '/account/admin',
  EDIT_ADMIN: '/account/edit-admin/',
  ADD_ADMIN: '/account/admin/add_admin',
  // Quản lý khách hàng
  ACCOUNT_CUSTOMER: '/account/customer',
  EDIT_CUSTOMER: '/account/edit-customer/',
  ADD_CUSTOMER: '/account/customer/add_customer',
  PHONEBOOK_REMOVED_CUSTOMER: '/account/customer/removed-from-phonebook',
  // Quản lý phân quyền
  GROUP_PERMISSION: '/account/group_permission',
  ADD_PERMISSION: '/account/group_permission/add_permison',
  EDIT_PERMISSION: '/account/edit_permission/',
  // Quản lý dự án
  PROJECT_MANAGER: 'project-manager',
  // Dự án
  PROJECT: '/project-manager/project',
  PROJECTS_HOUSETEMPLATE: '/project-manager/project-house-template',
  ADD_PROJECT: '/project-manager/add_project',
  EDIT_PROJECT: '/project-manager/edit_project/',
  ADD_PROJECTS_HOUSETEMPLATE: '/project-manager/project-house-template/create',
  EDIT_PROJECTS_HOUSETEMPLATE: '/project-manager/project-house-template/edit/',
  // Căn hộ, Lô đất
  HOUSE_LOTS: '/project-manager/project-house-lots',
  ADD_HOUSE_LOTS: '/project-manager/project-house-lots/create',
  EDIT_HOUSE_LOTS: '/project-manager/project-house-lots/edit/',
  // cộng tác viên
  CTV_LIST: '/project-manager/ctv',

  // block, khu đất
  BLOCKS_AREA: '/project-manager/block_area',
  ADD_BLOCKS_AREA: '/project-manager/add_block_area',
  EDIT_BLOCKS_AREA: '/project-manager/edit_block_area/',

  // Giao dịch
  CUSTOMER_PROJECT: '/project-manager/customer_project',
  DETAIL_PROJECT_CUSTOMER: '/project-manager/customer_project/',
  PROJECT_TRANSACTION_COMMISSION: '/project-manager/transaction-commission',
  PROJECT_NEWS_TRANSACTION_COMMISSION: '/real-estate-manager/transaction-commission',

  // Khách hàng xếp số
  CUSTOMER_RESERVATION: '/project-manager/reservation',
  DETAIL_RESERVATION: '/project-manager/detail_reservation/',

  // Quản lý danh mục
  CATEGORY_MANAGER: 'category-manager',
  // Loại bất động sản
  PROPERTY_TYPE: '/category-manager/property_type',
  ADD_PROPERTY: '/category-manager/property_type/add_property_type',
  EDIT_PROPERTY: '/category-manager/property_type/edit_property_type/',
  EDIT_PARENT: '/category-manager/property_type/edit_parent/',
  // Loại căn hộ
  APARTMENT_TYPE: '/category-manager/apartment_type',
  ADD_APARTMENT: '/category-manager/apartment_type/add_apartment_type',
  EDIT_APARTMENT: '/category-manager/apartment_type/edit_apartment_type/',
  // Hướng nhà
  DIRECTION: '/category-manager/direction',
  ADD_DIRECTION: '/category-manager/direction/add_direction',
  EDIT_DIRECTION: '/category-manager/direction/edit_direction/',

  // Đối tác xây dựng
  CONSTRUCTOR: '/category-manager/constructor',
  ADD_CONSTRUCTOR: '/category-manager/constructor/add_constructor',
  EDIT_CONSTRUCTOR: '/category-manager/constructor/edit_constructor/',

  // Quản lý ngân hàng
  BANK: '/category-manager/bank',
  ADD_BANK: '/category-manager/bank/add_bank',
  EDIT_BANK: '/category-manager/edit_bank/',

  // Quản lý cấu hình
  CONFIGURATION_MANAGER: 'configuration_manager',
  INTERACTION: '/configuration_manager/interaction_point',
  PROJECT_COMMISSIONS: '/configuration_manager/commissions',
  POST_FORM: '/configuration_manager/post-form',
  EMAIL_MANAGER: '/configuration_manager/email',
  DETAIL_EMAIL_MANAGER: '/configuration_manager/detail_email/',
  LIST_NOTIFICATION: '/configuration_manager/notification',
  DETAIL_NOTIFICATION: '/configuration_manager/notification/',

  // quản lí bất động sản riêng lẻ
  SALES_AND_RENTAL: 'real-estate-manager',
  SALES_AND_RENTAL_LIST: '/real-estate-manager/list',
  SALES_AND_RENTAL_EDIT: '/real-estate-manager/edit/',
  SALES_AND_RENTAL_CREATE: '/real-estate-manager/create',
  DETAIL_RELATED: '/real-estate-manager/detail-related/',
  PROJECT_NEWS_TRANSACTION: '/real-estate-manager/transactions',
  // Quản lý hoa hồng BĐS
  PROJECT_COMMISSIONS_BĐS: '/configuration_manager/commissions_bđs',

  // Cấu hình khác
  CONFIG_OTHER: '/configuration_manager/config_other',

  // Tin mua thuê BĐS lẻ
  REAL_ESTATE_NEWS: '/real-estate-manager/real_estate_news',
  DETAIL_REAL_ESTATE_NEWS: '/real-estate-manager/real_estate_news/detail/',
  FEED_BACK: '/real-estate-manager/feed_back',

  // Quản lý hoa hồng
  ROSE_MANAGER: 'commission',
  COMMISSION_HISTORY: '/commission/history',
  COMMISSION_PAG_HISTORY: '/commission/pag-commission',
  DETAIL_COMMISSION_HISTORY: '/commission/history-detail/',
  COMMISSION_WITHDRAW: '/commission/commission-withdraw',
  DETAIL_COMMISSION_WITHDRAW: '/commission/commission-withdraw-detail/',

  // Cộng hoa hồng
  COMMISSION_PLUS: '/commission/commission-plus',
  COMMISSION_PLUS_ADD: '/commission/commission-plus/create',
  COMMISSION_PLUS_EDIT: '/commission/commission-plus/edit/',

  // Quản lý gói điểm
  POINT_MANAGER: 'point_manager',
  POINT_LIST: '/point_manager/point/list',
  POINT_DETAIL: '/point_manager/point/edit/',
  POINT_CREATE: '/point_manager/point/create',

  // Lịch sử mua gói điểm
  POINT_HISTORY_LIST: '/point_manager/history_buy_point',
  USED_POINT_HISTORY_LIST: '/point_manager/history-used-point',
  // Cộng điểm
  POINT_PLUS: '/point_manager/point_plus',
  ADD_POINT_PLUS: '/point_manager/point_plus_create',

  // Quản lý nội dung
  CONTENT_MANAGER: 'content_manager',
  NEWS: '/content_manager/news',
  NEW_CREATE: '/content_manager/add_new',
  EDIT_NEWS: '/content_manager/edit_new/',
  NOTIFICATION_SYSTEM: '/content_manager/notification_system',
  ADD_NOTIFICATION_SYSTEM: '/content_manager/notification_system/add',
  EDIT_NOTIFICATION_SYSTEM: '/content_manager/notification_system/edit/',
  ACTIVE: '/content_manager/active',
  SUPPORT: '/content_manager/support',
  ADD_SUPPORT: '/content_manager/support/add',
  DETAIL_SUPPORT: '/content_manager/support/edit/',
  TERMS_OF_USE: '/content_manager/terms_of_use',
  CONTENT_GUIDE: '/content_manager/content_guide',
  FORBIDDEN_KEYWORDS: '/content_manager/forbidden_keywords',

  // Cây hệ thống
  SYSTEM_TREE: '/system_tree',
};
