/* 
  Created by cuong tran at 11-27-2020 17:55:56
  Màn hình can ho mau
*/

export interface HouseTemplateProps {}

export enum STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
