import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import Icon, {
  AlignRightOutlined,
  ApartmentOutlined,
  BookOutlined,
  ClusterOutlined,
  HomeOutlined,
  LineChartOutlined,
  ProjectOutlined,
  SettingOutlined,
  SketchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { translate } from 'translate/language';
import { ROUTER_PATH } from 'routes/Path';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { AppContext } from '../../contexts/AppContext';

const { Sider } = Layout;

const { SubMenu } = Menu;

const StyledLogo = styled.div`
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
  h1 {
    display: inline-block;
    margin: 0;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    vertical-align: middle;
  }
  img {
    display: inline-block;
    height: 32px;
    vertical-align: middle;
  }
`;

const StyledContainer = styled.div``;

interface BaseProps {
  pathname: string;
}

const Sidebar = React.memo((props: BaseProps) => {
  const [activePath, setActivePath] = useState<string[]>([]);
  const history = useHistory();
  useEffect(() => {
    if (window.location.pathname) {
      setActivePath(window.location.pathname.split('/'));
    }
  }, []);

  const changePath = (item: any) => {
    if (item.key) {
      history.push(item.key);
      setActivePath(item.key.split('/'));
    } else {
      setActivePath(item);
    }
  };
  return (
    <StyledContainer>
      <AppContext.Consumer>
        {({ collapsedSidebar, onUpdateContext }) => {
          return (
            <Sider
              width={256}
              style={{
                overflow: 'auto',
                height: '100vh',
                zIndex: 100,
                position: 'fixed',
                left: 0,
              }}
            >
              <StyledLogo>
                <a href="/">
                  <img src={require('../../images/logoApp.svg')} alt="" />
                  <h1>CMS Phúc An Gia</h1>
                </a>
              </StyledLogo>
              <Menu
                style={{ marginTop: -5 }}
                defaultSelectedKeys={[props.pathname]}
                mode="inline"
                theme="dark"
                onClick={item => changePath(item)}
                onOpenChange={item => changePath(item)}
                openKeys={activePath}
              >
                {/* Quản lý Thống kê */}
                {getCheckPermissionUser(PERMISSION_NAME.STATISTICS) &&
                getCheckPermissionUser(PERMISSION_NAME.STATISTICS).read ? (
                  <Menu.Item key={ROUTER_PATH.HOME}>
                    <LineChartOutlined />
                    <span>{translate('sidebar.statistical')}</span>
                  </Menu.Item>
                ) : (
                  ''
                )}

                {/* Quản lý tài khoản */}
                {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).read ||
                getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).read ||
                getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_PERMISSIONS).read ? (
                  <SubMenu
                    key={ROUTER_PATH.ACCOUNT}
                    title={
                      <span>
                        <UserOutlined />
                        <span>{translate('sidebar.account_management')}</span>
                      </span>
                    }
                    onTitleClick={item => setActivePath([ROUTER_PATH.ACCOUNT])}
                  >
                    {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).read && (
                      <Menu.Item key={ROUTER_PATH.ACCOUNT_ADMIN}>{translate('sidebar.admin_account')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).read && (
                      <Menu.Item key={ROUTER_PATH.ACCOUNT_CUSTOMER}>{translate('sidebar.customer_account')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_PERMISSIONS).read && (
                      <Menu.Item key={ROUTER_PATH.GROUP_PERMISSION}>{translate('sidebar.group_permission')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_APP).read && (
                      <Menu.Item key={ROUTER_PATH.PHONEBOOK_REMOVED_CUSTOMER}>Chờ đặt lại cấp cha</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Quản lý dự án */}
                {getCheckPermissionUser(PERMISSION_NAME.PROJECTS).read ||
                getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).read ||
                getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).read ||
                getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).read ||
                getCheckPermissionUser(PERMISSION_NAME.PROJECTS_VOLUNTEERS).read ||
                getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_BOOKING).read ? (
                  <SubMenu
                    key={ROUTER_PATH.PROJECT_MANAGER}
                    title={
                      <span>
                        <ProjectOutlined />
                        <span>{translate('sidebar.project_manager')}</span>
                      </span>
                    }
                    onTitleClick={item => setActivePath([ROUTER_PATH.PROJECT_MANAGER])}
                  >
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECT}>{translate('sidebar.project')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_BLOCKS_AREA).read && (
                      <Menu.Item key={ROUTER_PATH.BLOCKS_AREA}>{translate('sidebar.block_area')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECTS_HOUSETEMPLATE}>
                        {translate('sidebar.project_housetemplate')}
                      </Menu.Item>
                    )}

                    {/* Căn hộ,lô đất */}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_APARTMENTS_LAND).read && (
                      <Menu.Item key={ROUTER_PATH.HOUSE_LOTS}>{translate('sidebar.project_houselots')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_VOLUNTEERS).read && (
                      <Menu.Item key={ROUTER_PATH.CTV_LIST}>{translate('sidebar.ctv')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_BOOKING).read && (
                      <Menu.Item key={ROUTER_PATH.CUSTOMER_PROJECT}>{translate('sidebar.customer_project')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_BOOKING).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECT_TRANSACTION_COMMISSION}>Hoa hồng giao dịch</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_SEQUENCE).read && (
                      <Menu.Item key={ROUTER_PATH.CUSTOMER_RESERVATION}>Khách hàng xếp số</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Quản lý bất động sản */}
                {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).read ||
                getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).read ? (
                  <SubMenu
                    key={ROUTER_PATH.SALES_AND_RENTAL}
                    title={
                      <span>
                        <HomeOutlined />
                        <span>{translate('sidebar.realEstate')}</span>
                      </span>
                    }
                    onTitleClick={item => setActivePath([ROUTER_PATH.SALES_AND_RENTAL_LIST])}
                  >
                    {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).read && (
                      <Menu.Item key={ROUTER_PATH.SALES_AND_RENTAL_LIST}>
                        {translate('sidebar.sale_and_rental')}
                      </Menu.Item>
                    )}

                    {/* Tin mua/ thuê BĐS lẻ */}
                    {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_BUY).read && (
                      <Menu.Item key={ROUTER_PATH.REAL_ESTATE_NEWS}>{translate('sidebar.real_estate_news')}</Menu.Item>
                    )}

                    {/* Phản hồi của User */}
                    <Menu.Item key={ROUTER_PATH.FEED_BACK}>Phản hồi của User</Menu.Item>

                    {/* Giao dịch */}
                    {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECT_NEWS_TRANSACTION}>
                        {translate('sidebar.customer_project')}
                      </Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.REALESTATE_SELL).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECT_NEWS_TRANSACTION_COMMISSION}>Hoa hồng giao dịch</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Quản lý Danh mục */}
                {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).read ||
                getCheckPermissionUser(PERMISSION_NAME.CATEGORY_APARTMENT).read ||
                getCheckPermissionUser(PERMISSION_NAME.CATEGORY_HOUSE_DIRECTIONS).read ||
                getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).read ||
                getCheckPermissionUser(PERMISSION_NAME.CATEGORY_BANKS).read ? (
                  <SubMenu
                    key={ROUTER_PATH.CATEGORY_MANAGER}
                    title={
                      <span>
                        <BookOutlined />
                        <span>{translate('sidebar.category_manager')}</span>
                      </span>
                    }
                    onTitleClick={item => setActivePath([ROUTER_PATH.CATEGORY_MANAGER])}
                  >
                    {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_REALESTATE).read && (
                      <Menu.Item key={ROUTER_PATH.PROPERTY_TYPE}>{translate('sidebar.property_type')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_APARTMENT).read && (
                      <Menu.Item key={ROUTER_PATH.APARTMENT_TYPE}>{translate('sidebar.apartment_type')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_HOUSE_DIRECTIONS).read && (
                      <Menu.Item key={ROUTER_PATH.DIRECTION}>{translate('sidebar.direction')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).read && (
                      <Menu.Item key={ROUTER_PATH.CONSTRUCTOR}>{translate('sidebar.constructor')}</Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_BANKS).read && (
                      <Menu.Item key={ROUTER_PATH.BANK}>{translate('sidebar.bank')}</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Quản lý cấu hình */}
                {getCheckPermissionUser(PERMISSION_NAME.CONFIG_INTERACTIVE_POINTS).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONFIG_COMMISSIONS_PROJECT).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONFIG_COMMISSIONS_REALESTATE).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONFIG_EMAILS_CONTRACTS).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONFIG_NEWS_METHOD).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONFIG_GENERAL).read ? (
                  <SubMenu
                    key={ROUTER_PATH.CONFIGURATION_MANAGER}
                    title={
                      <span>
                        <SettingOutlined />
                        <span>{translate('sidebar.configuration_manager')}</span>
                      </span>
                    }
                    onTitleClick={item => setActivePath([ROUTER_PATH.CONFIGURATION_MANAGER])}
                  >
                    <Menu.Item key={ROUTER_PATH.LIST_NOTIFICATION}>Thông báo luật chơi</Menu.Item>

                    {getCheckPermissionUser(PERMISSION_NAME.CONFIG_INTERACTIVE_POINTS).read && (
                      <Menu.Item key={ROUTER_PATH.INTERACTION}>{translate('sidebar.interaction_point')}</Menu.Item>
                    )}

                    {/* Hình thức đăng tin */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONFIG_NEWS_METHOD).read && (
                      <Menu.Item key={ROUTER_PATH.POST_FORM}>{translate('sidebar.post_form')}</Menu.Item>
                    )}
                    {/* Email, Hợp đồng */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONFIG_EMAILS_CONTRACTS).read && (
                      <Menu.Item key={ROUTER_PATH.EMAIL_MANAGER}>{translate('sidebar.email_manager')}</Menu.Item>
                    )}

                    {getCheckPermissionUser(PERMISSION_NAME.CONFIG_COMMISSIONS_PROJECT).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECT_COMMISSIONS}>
                        {translate('sidebar.project_commissions')}
                      </Menu.Item>
                    )}
                    {getCheckPermissionUser(PERMISSION_NAME.CONFIG_COMMISSIONS_REALESTATE).read && (
                      <Menu.Item key={ROUTER_PATH.PROJECT_COMMISSIONS_BĐS}>
                        {translate('sidebar.project_commissions_bđs')}
                      </Menu.Item>
                    )}
                    {/* Cấu hình khác */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONFIG_GENERAL).read && (
                      <Menu.Item key={ROUTER_PATH.CONFIG_OTHER}>Cấu hình khác</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Hoa hồng */}
                {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_HISTORY).read ||
                getCheckPermissionUser(PERMISSION_NAME.COMMISSION_WITHDRAW).read ||
                getCheckPermissionUser(PERMISSION_NAME.COMMISSION_ADD).read ? (
                  <SubMenu
                    key={ROUTER_PATH.ROSE_MANAGER}
                    title={
                      <span>
                        <SketchOutlined />
                        <span>{translate('sidebar.rose')}</span>
                      </span>
                    }
                  >
                    {/* Lịch sử nhận hoa hồng */}
                    {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_HISTORY).read && (
                      <Menu.Item key={ROUTER_PATH.COMMISSION_HISTORY}>LS nhận hoa hồng của User</Menu.Item>
                    )}

                    {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_HISTORY).read && (
                      <Menu.Item key={ROUTER_PATH.COMMISSION_PAG_HISTORY}>Lịch sử hoa hồng PAG</Menu.Item>
                    )}

                    {/* Yêu cầu rút tiền */}
                    {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_WITHDRAW).read && (
                      <Menu.Item key={ROUTER_PATH.COMMISSION_WITHDRAW}>Yêu cầu rút tiền</Menu.Item>
                    )}

                    {/* Cộng hoa hồng */}
                    {getCheckPermissionUser(PERMISSION_NAME.COMMISSION_ADD).read && (
                      <Menu.Item key={ROUTER_PATH.COMMISSION_PLUS}>Cộng hoa hồng</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Quản lý gói điển */}
                {/* Hoa hồng */}
                {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).read ||
                getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES_BUY_HISTORY).read ||
                getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGE_ADD_POINT).read ? (
                  <SubMenu
                    key={ROUTER_PATH.POINT_MANAGER}
                    title={
                      <span>
                        <ClusterOutlined />
                        <span>Quản lý gói điểm</span>
                      </span>
                    }
                  >
                    {/* Gói điểm */}
                    {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).read && (
                      <Menu.Item key={ROUTER_PATH.POINT_LIST}>Gói điểm</Menu.Item>
                    )}
                    {/* Lịch sử mua gói điểm */}
                    {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES_BUY_HISTORY).read && (
                      <Menu.Item key={ROUTER_PATH.POINT_HISTORY_LIST}>Lịch sử mua gói điểm</Menu.Item>
                    )}
                    {/* Lịch sử sử dụng điểm */}
                    {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGES).read && (
                      <Menu.Item key={ROUTER_PATH.USED_POINT_HISTORY_LIST}>Lịch sử sử dụng điểm</Menu.Item>
                    )}
                    {/* Cộng điểm */}
                    {getCheckPermissionUser(PERMISSION_NAME.POINT_PACKAGE_ADD_POINT).read && (
                      <Menu.Item key={ROUTER_PATH.POINT_PLUS}>Cộng điểm</Menu.Item>
                    )}
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Quản lý nội dung */}
                {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NEWS).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONTENT_INTRO_TERMS).read ||
                getCheckPermissionUser(PERMISSION_NAME.CONTENT_GUIDE).read ||
                getCheckPermissionUser(PERMISSION_NAME.FORBIDDEN_KEYWORDS).read ? (
                  <SubMenu
                    key={ROUTER_PATH.CONTENT_MANAGER}
                    title={
                      <span>
                        <ApartmentOutlined />
                        <span>Quản lý nội dung</span>
                      </span>
                    }
                  >
                    {/* Tin tức, sự kiện */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NEWS).read && (
                      <Menu.Item key={ROUTER_PATH.NEWS}>Tin tức, sự kiện</Menu.Item>
                    )}
                    {/* Thông báo hệ thống */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NOTIFICATIONS).read && (
                      <Menu.Item key={ROUTER_PATH.NOTIFICATION_SYSTEM}>Thông báo hệ thống</Menu.Item>
                    )}
                    {/* Hoạt động */}
                    <Menu.Item key={ROUTER_PATH.ACTIVE}>Hoạt động</Menu.Item>

                    {/* Điều khoản sử dụng */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONTENT_INTRO_TERMS).read && (
                      <Menu.Item key={ROUTER_PATH.TERMS_OF_USE}>
                        <div style={{ position: 'absolute', left: '20px' }}>Giới thiệu và điều khoản sử dụng</div>
                      </Menu.Item>
                    )}

                    {/* Hướng dẫn sử dụng */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONTENT_GUIDE).read && (
                      <Menu.Item key={ROUTER_PATH.CONTENT_GUIDE}>Hướng dẫn sử dụng</Menu.Item>
                    )}

                    {/* Liên hệ hỗ trợ */}
                    {getCheckPermissionUser(PERMISSION_NAME.CONTENT_CONTACT).read && (
                      <Menu.Item key={ROUTER_PATH.SUPPORT}>Liên hệ hỗ trợ</Menu.Item>
                    )}

                    {/* Từ khóa cấm */}
                    {/* {getCheckPermissionUser(PERMISSION_NAME.FORBIDDEN_KEYWORDS).read && (
                      <Menu.Item key={ROUTER_PATH.FORBIDDEN_KEYWORDS}>
                        <div style={{ position: 'absolute', left: '20px' }}>Từ khóa không được dùng</div>
                      </Menu.Item>
                    )} */}
                    <Menu.Item key={ROUTER_PATH.FORBIDDEN_KEYWORDS}>Từ khóa không được dùng</Menu.Item>
                  </SubMenu>
                ) : (
                  ''
                )}

                {/* Cây hệ thống */}
                {getCheckPermissionUser(PERMISSION_NAME.SYSTEM_TREE) &&
                getCheckPermissionUser(PERMISSION_NAME.SYSTEM_TREE).read ? (
                  <Menu.Item key={ROUTER_PATH.SYSTEM_TREE}>
                    <AlignRightOutlined />
                    <span>Cây hệ thống</span>
                  </Menu.Item>
                ) : (
                  ''
                )}
              </Menu>
            </Sider>
          );
        }}
      </AppContext.Consumer>
    </StyledContainer>
  );
});

export default Sidebar;
