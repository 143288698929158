/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-nested-ternary */
import { Button, Col, Form, Input, Row, Select, Switch, Table, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import { StatusType as StatusTypeResponse } from 'constants/Status';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import useAddPermissionHooks from 'hooks/Permission/useAddPermissionHooks';
import { truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { NAME_PERMISSION, ReqCreatePermission, ResPermissionTable } from 'types/type/PermissionType';
import './css/Permission.scss';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 20 },
};

function FormPermissionPage() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [dataTable, setDataTable] = useState<ResPermissionTable[]>([]);

  const params: { id: string } = useParams();
  const isEdit = params && params.id;

  const {
    onAddPermission,
    loading,
    dataPermission,
    dataPermission2,
    onGetPermission,
    loadingDetail,
    onUpdatePermission,
    onGetPemissionDetail,
    dataGroup,
    userPermission,
  } = useAddPermissionHooks(history);

  // Cache dữ liệu
  const cacheForm = LocalStorageHelper.getObject(StorageKey.CACHE_FORM_CREATE_PERMISSION);

  useEffect(() => {
    onGetPermission();
    if (isEdit) {
      onGetPemissionDetail(params.id);
    } else {
      form.setFieldsValue(cacheForm);
    }
  }, []);

  useEffect(() => {
    if (dataGroup) {
      form.setFieldsValue({
        name: dataGroup.name.trim(),
        description: dataGroup.description,
        status: dataGroup.status,
      });
      setDataTable(dataGroup.permission);
    }
  }, [dataGroup]);

  // Action lưu
  const handleSubmit = (e: any) => {
    e.preventDefault();
    form
      .validateFields()
      .then(values => {
        const body: ReqCreatePermission = {
          name: values.name,
          status: values.status,
          permission: dataTable,
        };

        if (values.description) body.description = values.description;

        if (isEdit) {
          LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PERMISSION);
          return onUpdatePermission(params.id, body);
        }
        onAddPermission(body);
        LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PERMISSION);
      })
      .catch(e => {
        console.log(e, 'errr');
      });
  };

  // Action quay lại
  const onBack = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_PERMISSION);
    history.goBack();
  };

  // Lưu Localstorage
  const updateCacheFormCreate = () => {
    LocalStorageHelper.saveObject(StorageKey.CACHE_FORM_CREATE_PERMISSION, form.getFieldsValue());
  };

  const onChangeValue = (item: ResPermissionTable, type: string, check?: any) => {
    const newItem = { ...item };
    newItem.read = newItem.read && type === 'read' ? 1 : !newItem.read ? -1 : 0;
    newItem.create = newItem.create && type === 'create' ? 1 : !newItem.create ? -1 : 0;
    newItem.edit = newItem.edit && type === 'edit' ? 1 : !newItem.edit ? -1 : 0;
    newItem.delete = newItem.delete && type === 'delete' ? 1 : !newItem.delete ? -1 : 0;
    newItem.export = newItem.export && type === 'export' ? 1 : !newItem.export ? -1 : 0;
    delete newItem.type;

    const isExits = dataTable.filter(e => e._id === newItem._id);
    const newData = [...dataTable];
    let dataNew: any[] = [];
    if (isExits.length > 0) {
      newData.map(e => {
        if (e._id === newItem._id) {
          if (type === 'read') {
            e.read = check ? 1 : !newItem.read ? -1 : 0;
            return { ...e };
          }
          if (type === 'create') {
            e.create = check ? 1 : !newItem.create ? -1 : 0;
            return { ...e };
          }
          if (type === 'edit') {
            e.edit = check ? 1 : !newItem.edit ? -1 : 0;
            return { ...e };
          }
          if (type === 'delete') {
            e.delete = check ? 1 : !newItem.delete ? -1 : 0;
            return { ...e };
          }
          if (type === 'export') {
            e.export = check ? 1 : !newItem.export ? -1 : 0;
            return { ...e };
          }
        }
      });
      dataNew = newData;
    } else {
      dataNew = newData.concat(newItem);
    }
    setDataTable(dataNew);
  };

  const findData = (item: ResPermissionTable) => dataTable.filter(e => e._id === item._id) || [];

  const renderColumns = () => {
    return [
      {
        title: translate('permission.name_permission'),
        render: (item: ResPermissionTable) => {
          return (
            <Tooltip placement="top" title={translate(NAME_PERMISSION[item.type!])}>
              <span style={{ cursor: 'pointer' }}>
                {truncate(translate(NAME_PERMISSION[item.type!]), {
                  length: 60,
                  separator: '...',
                })}
              </span>
            </Tooltip>
          );
        },
        width: 300,
        key: 'name',
      },
      {
        title: translate('configuration.view'),
        render: (text: ResPermissionTable, item: ResPermissionTable, index: any) => {
          return (
            <Switch
              checked={
                findData(item).length > 0 && findData(item)[0]._id == item._id && findData(item)[0].read > 0
                  ? true
                  : false
              }
              onChange={e => {
                onChangeValue(text, 'read', e);
              }}
              disabled={!item.read}
            />
          );
        },
        width: 200,
        key: 'name',
        align: 'center' as 'center',
      },
      {
        title: translate('configuration.add_new'),
        render: (text: ResPermissionTable, item: ResPermissionTable, index: any) => {
          return (
            <Switch
              checked={
                findData(item).length > 0 && findData(item)[0]._id === item._id && findData(item)[0].create > 0
                  ? true
                  : false
              }
              onChange={e => onChangeValue(text, 'create', e)}
              disabled={!item.create}
            />
          );
        },
        width: 200,
        key: 'name',
        align: 'center' as 'center',
      },
      {
        title: translate('configuration.edit'),
        render: (text: ResPermissionTable, item: ResPermissionTable, index: any) => {
          return (
            <Switch
              checked={
                findData(item).length > 0 && findData(item)[0]._id == item._id && findData(item)[0].edit > 0
                  ? true
                  : false
              }
              onChange={e => onChangeValue(text, 'edit', e)}
              disabled={!item.edit}
            />
          );
        },
        width: 200,
        key: 'name',
        align: 'center' as 'center',
      },
      {
        title: translate('configuration.delete'),
        render: (text: ResPermissionTable, item: ResPermissionTable, index: any) => {
          return (
            <Switch
              checked={
                findData(item).length > 0 && findData(item)[0]._id == item._id && findData(item)[0].delete > 0
                  ? true
                  : false
              }
              onChange={e => onChangeValue(text, 'delete', e)}
              disabled={!item.delete}
            />
          );
        },
        width: 200,
        key: 'name',
        align: 'center' as 'center',
      },
      {
        title: translate('configuration.export'),
        render: (text: ResPermissionTable, item: ResPermissionTable, index: any) => {
          return (
            <Switch
              checked={
                findData(item).length > 0 && findData(item)[0]._id == item._id && findData(item)[0].export > 0
                  ? true
                  : false
              }
              onChange={e => onChangeValue(text, 'export', e)}
              disabled={!item.export}
            />
          );
        },
        width: 200,
        key: 'name',
        align: 'center' as 'center',
      },
    ];
  };

  // Render danh sách user thuộc nhóm quyền
  const renderColumsUser = [
    {
      title: translate('admin.name_admin'),
      key: '_id',
      dataIndex: 'name',
      render: (name: string) => {
        return <span>{name}</span>;
      },
    },
    {
      title: translate('admin.phone'),
      key: '_id',
      dataIndex: 'phone',
      render: (phone: string) => {
        return <span>{phone}</span>;
      },
    },
    {
      title: translate('admin.email'),
      key: '_id',
      dataIndex: 'email',
      render: (email: string) => {
        return <span>{email}</span>;
      },
    },
  ];

  const onChangeAll = (data: any) => {
    if (data) {
      const dataAll = dataPermission2.map((value: any) => {
        data = {
          create: value.create ? 1 : -1,
          delete: value.delete ? 1 : -1,
          edit: value.edit ? 1 : -1,
          export: value.export ? 1 : -1,
          read: value.read ? 1 : -1,
          _id: value._id,
        };
        return data;
      });

      setDataTable(dataAll);
    } else {
      const dataAll = dataPermission2.map((value: any) => {
        data = {
          create: value.create ? 0 : -1,
          delete: value.delete ? 0 : -1,
          edit: value.edit ? 0 : -1,
          export: value.export ? 0 : -1,
          read: value.read ? 0 : -1,
          _id: value._id,
        };
        return data;
      });

      setDataTable(dataAll);
    }
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_PERMISSIONS).read ? (
        <Form
          {...layout}
          labelAlign="left"
          name="nest-messages"
          className="form-item"
          onFieldsChange={updateCacheFormCreate}
          form={form}
        >
          <Form.Item
            name={['name']}
            className="form-item"
            label={translate('configuration.name_group')}
            rules={[
              {
                required: true,
                whitespace: true,
                message: translate('require_field', {
                  label: translate('configuration.name_group'),
                }),
              },
              {
                transform: (value: string) => {
                  return value?.trim();
                },
                min: 2,
                max: 100,
                message: translate('configuration.name_group_invalid'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['description']}
            className="form-item"
            label={translate('configuration.description')}
            rules={[
              {
                transform: (value: string) => {
                  return value?.trim();
                },
                max: 1000,
                message: translate('configuration.description_invalid'),
              },
            ]}
          >
            <Input.TextArea style={{ minHeight: '150px' }} />
          </Form.Item>
          <div>
            <Switch onChange={onChangeAll} />
          </div>
          <Form.Item name={['table']} label={translate('configuration.group_per')} className="form-item">
            <Table
              dataSource={[...(dataPermission || [])]}
              bordered
              columns={renderColumns()}
              pagination={false}
              scroll={{ x: 'auto' }}
            />
          </Form.Item>

          <Form.Item name="status" label={translate('status.name')} initialValue={StatusTypeResponse.ACTIVE}>
            <Select placeholder={translate('admin.status_active')}>
              <Option value={StatusTypeResponse.ACTIVE}>{translate('status.show')}</Option>
              <Option value={StatusTypeResponse.INACTIVE}>{translate('status.hidden')}</Option>
            </Select>
          </Form.Item>

          {userPermission && (
            <Form.Item label={translate('permission.list_user_permission')}>
              <Table
                key={1}
                dataSource={userPermission || []}
                columns={renderColumsUser}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
              ></Table>
            </Form.Item>
          )}

          <Form.Item wrapperCol={{ ...layout.wrapperCol, span: 23 }}>
            <Row gutter={20}>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button type="primary" style={{ backgroundColor: 'red', marginRight: '10px' }} onClick={onBack}>
                  {translate('action.close')}
                </Button>

                <Button type="primary" onClick={handleSubmit} loading={loading} htmlType="submit">
                  {translate(isEdit ? translate('action.update') : translate('action.save'))}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default FormPermissionPage;
