export const MentionEmail1 = [
  '@Tên',
  '@Tên doanh nghiệp',
  '@Số điện thoại',
  '@Email',
  '@Ngày sinh',
  '@Số CMND',
  '@Mã số thuế',
  '@Mã số hợp đồng',
];
export const MentionEmail2 = [
  '@Tên dự án',
  '@Tên CTV',
  '@Tên doanh nghiệp CTV',
  '@Tuổi CTV',
  '@Địa chỉ CTV',
  '@Số điện thoại CTV',
  '@Email CTV',
  '@Ngày sinh CTV',
  '@Giới tinh CTV',
  '@Ngân hàng CTV',
  '@Số tài khoản CTV',
  '@Chi nhánh ngân hàng CTV',
  '@Số CMND CTV',
  '@Ngày cấp CMND CTV',
  '@Nơi cấp CMND CTV',
  '@Mã số thuế CTV',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Số thứ tự HĐ',
  '@Chủ đầu tư',
  '@Mã số hợp đồng',
  '@Chức vụ',
  '@Tên doanh nghiệp CTV/ Tên CTV',
];
export const MentionEmail3 = [
  '@Tên dự án',
  '@Chủ đầu tư',
  '@Tên CTV',
  '@Tên doanh nghiệp CTV',
  '@Tuổi CTV',
  '@Địa chỉ CTV',
  '@Số điện thoại CTV',
  '@Email CTV',
  '@Ngày sinh CTV',
  '@Giới tính CTV',
  '@Ngân hàng CTV',
  '@Số tài khoản CTV',
  '@Chi nhánh ngân hàng CTV',
  '@Số CMND',
  '@Ngày cấp CMND CTV ',
  '@Nơi cấp CMND CTV',
  '@Mã số thuế CTV',
  '@Tên doanh nghiệp CTV/ Tên CTV',
  '@Ngày', // ngay theo hop dong so 2
  '@Tháng', // theo hop dong so 2
  '@Năm', // theo hop dong so 2
  '@Chữ ký ',
  '@Mã số hợp đồng', // theo hop dong so 2
];
export const MentionEmail4 = [
  '@Tên dự án',
  '@Loại bất động sản',
  '@Tên CTV',
  '@Địa chỉ CTV',
  '@Số điện thoại CTV',
  '@Email CTV',
  '@Ngày sinh CTV',
  '@Số CMND CTV',
  '@Ngày cấp CMND CTV',
  '@Nơi cấp CMND CTV',
  '@Tên khách hàng',
  '@Địa chỉ khách hàng',
  '@Số điện thoại khách hàng',
  '@Email khách hàng',
  '@Ngày sinh khách hàng',
  '@Số CMND khách hàng',
  '@Ngày cấp CMND khách hàng',
  '@Nơi cấp CMND khách hàng',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Tiền đặt chỗ bằng số',
  '@Tiền đặt chỗ bằng chữ',
  '@Mã số hợp đồng',
  '@Số thứ tự',
  '@Số lượng căn giữ chỗ',
  '@Mã căn hộ',
];
export const MentionEmail5 = [
  '@Tên dự án',
  '@Loại bất động sản',
  '@Tên CTV',
  '@Địa chỉ CTV',
  '@Số điện thoại CTV',
  '@Email CTV',
  '@Ngày sinh CTV',
  '@Số CMND CTV',
  '@Ngày cấp CMND CTV',
  '@Nơi cấp CMND CTV',
  '@Tên khách hàng',
  '@Địa chỉ khách hàng',
  '@Số điện thoại khách hàng',
  '@Email khách hàng',
  '@Ngày sinh khách hàng',
  '@Số CMND khách hàng',
  '@Ngày cấp CMND khách hàng',
  '@Nơi cấp CMND khách hàng',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Mã số hợp đồng',
  '@Số tài khoản khách hàng',
  '@Ngân hàng khách hàng',
  '@Tiền đặt cọc bằng số',
  '@Tiền đặt cọc bằng chữ',
  '@Mã căn hộ',
  '@Giá căn hộ bằng số',
  '@Giá căn hộ bằng chữ',
  '@Thời gian giữ căn',
  '@Chữ ký bên đặt cọc',
  '@Chữ ký bên nhận đặt cọc',
  '@Chủ đầu tư',
  '@Chi nhánh ngân hàng khách hàng',
  '@Block',
  '@Tầng',
  '@Block.Tầng.Mã căn hộ',
];
export const MentionEmail6 = [
  '@Tên người giới thiệu',
  '@Tên doanh nghiệp',
  '@Địa chỉ người giới thiệu',
  '@Số điện thoại người giới thiệu',
  '@Email người giới thiệu',
  '@Ngày sinh người giới thiệu',
  '@Số CMND người giới thiệu',
  '@Ngày cấp CMND người giới thiệu',
  '@Nơi cấp CMND người giới thiệu',
  '@Mã tin đăng',
  '@Tên bđs',
  '@Tên dự án',
  '@Loại ký gửi',
  '@Loại bđs',
  '@Địa chỉ bđs',
  '@Block',
  '@Mã căn',
  '@Tầng',
  '@Số phòng ngủ',
  '@Số phòng tắm',
  '@Diện tích',
  '@Độ cao',
  '@Chiều dài',
  '@Chiều ngang',
  '@Giá',
  '@Phần trăm hoa hồng',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Mã số hợp đồng',
  '@Mã số thuế',
  '@Số tài khoản người giới thiệu',
  '@Chức vụ',
  '@Ngân hàng người giới thiệu',
  '@Chi nhánh ngân hàng người giới thiệu',
  '@Block.Tầng.Mã căn hộ',
  '@Ngày ký HDMG',
  '@Tháng ký HDMG',
  '@Năm ký HDMG',
];
export const MentionEmail7 = [
  '@Tên dự án',
  '@Loại bất động sản',
  '@Tên CTV',
  '@Địa chỉ CTV',
  '@Số điện thoại CTV',
  '@Email CTV',
  '@Ngày sinh CTV',
  '@Số CMND CTV',
  '@Ngày cấp CMND CTV',
  '@Nơi cấp CMND CTV',
  '@Tên khách hàng',
  '@Địa chỉ khách hàng',
  '@Số điện thoại khách hàng',
  '@Email khách hàng',
  '@Ngày sinh khách hàng',
  '@Số CMND khách hàng',
  '@Ngày cấp CMND khách hàng',
  '@Nơi cấp CMND khách hàng',
  '@Mã tin đăng',
  '@Tên bđs',
  '@Tên dự án',
  '@Loại ký gửi',
  '@Loại bđs',
  '@Địa chỉ bđs',
  '@Block',
  '@Mã căn',
  '@Tầng',
  '@Số phòng ngủ',
  '@Số phòng tắm',
  '@Diện tích',
  '@Độ cao',
  '@Chiều dài',
  '@Chiều ngang',
  '@Phần trăm hoa hồng',
  '@Tiền giữ chỗ bằng số',
  '@Tiền giữ chỗ bằng chữ',
  '@Giá bằng số',
  '@Giá bằng chữ',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký bên đặt chỗ',
  '@Chữ ký bên nhận đặt cọc',
  '@Mã số hợp đồng',
  '@Tên chủ sở hữu',
  '@Số CMND chủ sở hữu',
  '@Ngày cấp CMND chủ sở hữu',
  '@Số hợp đồng mô giới',
  '@Phí mô giới',
  '@Phí mô giới bằng chữ',
  '@Block.Tầng.Mã căn hộ',
  '@Ngày ký HDMG',
  '@Tháng ký HDMG',
  '@Năm ký HDMG',
];
export const MentionEmail8 = [
  '@Tên dự án',
  '@Loại bất động sản',
  '@Mã căn hộ',
  '@Tên CTV',
  '@Địa chỉ CTV',
  '@Số điện thoại CTV',
  '@Email CTV',
  '@Ngày sinh CTV',
  '@Số CMND CTV',
  '@Ngày cấp CMND CTV',
  '@Nơi cấp CMND CTV',
  '@Tên khách hàng',
  '@Địa chỉ khách hàng',
  '@Số điện thoại khách hàng',
  '@Email khách hàng',
  '@Ngày sinh khách hàng',
  '@Số CMND khách hàng',
  '@Ngày cấp CMND khách hàng',
  '@Nơi cấp CMND khách hàng',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Tiền đặt chỗ bằng số',
  '@Tiền đặt chỗ bằng chữ',
  '@Giá căn hộ bằng số',
  '@Giá căn hộ bằng chữ',
  '@Mã số hợp đồng',
  '@Ngày ký hợp đồng đặt chỗ',
  '@Tháng ký hợp dồng đặt chỗ',
  '@Năm ký hợp đồng đặt chỗ',
  '@Số thứ tự hợp đồng đặt chỗ',
  '@Mã số hợp đồng đặt chỗ',
  '@Số thứ tự hiện tại',
  '@Số lượng căn giữ chỗ',
  '@Giá căn hộ hiện tại bằng số',
  '@Giá căn hộ hiện tại bằng chữ',
  '@Mã căn hộ hiện tại',
  '@Tiền đặt chỗ hiện tại bằng số',
  '@Tiền đặt chỗ hiện tại bằng chữ',
];
export const MentionEmail9 = [
  '@Tên người đăng',
  '@Tên doanh nghiệp',
  '@Địa chỉ người đăng',
  '@Số điện thoại người đăng',
  '@Email người đăng',
  '@Ngày sinh người đăng',
  '@Số CMND người đăng',
  '@Ngày cấp CMND người đăng',
  '@Nơi cấp CMND người đăng',
  '@Mã tin đăng',
  '@Tên bđs',
  '@Tên dự án',
  '@Loại ký gửi',
  '@Loại bđs',
  '@Địa chỉ bđs',
  '@Block',
  '@Mã căn',
  '@Tầng',
  '@Số phòng ngủ',
  '@Số phòng tắm',
  '@Diện tích',
  '@Chiều dài',
  '@Chiều ngang',
  '@Phần trăm hoa hồng',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Mã số thuế',
  '@Chức vụ',
  '@Tài khoản người bán',
  '@Ngân hàng người bán',
  '@Chi nhánh ngân hàng người bán',
  '@Block.Tầng.Mã căn hộ',
  '@Độ cao',
  '@Mã số hợp đồng',
  '@Ngày ký HDMG',
  '@Tháng ký HDMG',
  '@Năm ký HDMG',
];
export const MentionEmail10 = [
  '@Tên người đăng',
  '@Tên doanh nghiệp',
  '@Tên chủ nhà',
  '@Địa chỉ chủ nhà',
  '@Số điện thoại chủ nhà',
  '@Email chủ nhà',
  '@Ngày sinh chủ nhà',
  '@Số CMND chủ nhà',
  '@Ngày cấp CMND chủ nhà',
  '@Nơi cấp CMND chủ nhà',
  '@Mã tin đăng',
  '@Tên bđs',
  '@Tên dự án',
  '@Loại bđs',
  '@Địa chỉ bđs',
  '@Block',
  '@Mã căn',
  '@Tầng',
  '@Số phòng ngủ',
  '@Số phòng tắm',
  '@Diện tích',
  '@Độ cao',
  '@Chiều dài',
  '@Chiều ngang',
  '@Giá',
  '@Giá bằng chữ',
  '@Phần trăm hoa hồng',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Mua bán/cho thuê',
  '@1% giá bán/1 tháng tiền thuê',
  '@Mua/thuê',
  '@Người bán/người cho thuê',
  '@Khách hàng mua/người đi thuê',
  '@Diện tích sử dụng',
  '@Diện tích xây dựng',
  '@Hoa hồng nhận được',
  '@Hoa hồng nhận được bằng chữ',
  '@Mã số hợp đồng',
  '@Block.Tầng.Mã căn hộ',
];
export const MentionEmail11 = [
  '@Tên dự án',
  '@Tên khách hàng',
  '@Địa chỉ khách hàng',
  '@Số điện thoại khách hàng',
  '@Ngày sinh khách hàng',
  '@Số CMND khách hàng',
  '@Ngày cấp CMND khách hàng',
  '@Nơi cấp CMND khách hàng',
  '@Ngân hàng khách hàng',
  '@Số tài khoản khách hàng',
  '@Chi nhánh ngân hàng khách hàng',
  '@Tiền giữ chỗ bằng số',
  '@Tiền giữ chỗ bằng chữ',
  '@Ngày',
  '@Tháng',
  '@Năm',
  '@Chữ ký',
  '@Tên bđs cha',
  '@Tên bđs con',
  '@Mã số hợp đồng',
  '@Số thứ tự',
  '@Loại bất động sản',
  '@Số HĐGC',
  '@Ngày HĐGC',
  '@Tháng HĐGC',
  '@Năm HĐGC',
];
