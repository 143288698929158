import { Form, Input } from 'antd';
import React, { useState } from 'react';
import { translate } from 'translate/language';

interface PropsInput {
  label: string;
  name: string;
  isEdit: boolean;
}

function InputAreaForm(props: PropsInput) {
  const [state, setstate] = useState('state');
  const { label, name, isEdit } = props;
  return (
    <Form.Item
      name={name}
      label={label}
      labelCol={{ md: 14, xl: 8, xs: 24 }}
      labelAlign="left"
      required
      wrapperCol={{ md: 12, xs: 24 }}
      rules={[
        {
          required: true,
          message: translate(label) + ' ' + translate('require'),
        },
        {
          max: 500,
          message: translate(label) + ' ' + translate('post_form.max_des'),
        },
      ]}
    >
      <Input.TextArea disabled={isEdit} rows={4} style={{ resize: 'none', backgroundColor: 'white' }} />
    </Form.Item>
  );
}

export default InputAreaForm;
