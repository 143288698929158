/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

export interface Props {
  onSearch: (search: string) => void;
}
function FilterHeaderPage(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch } = props;
  const history = useHistory();

  // Tìm kiếm theo Tên
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  // Thêm mới người dùng
  const addNewCustomer = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_BANK);
    history.push(ROUTER_PATH.ADD_BANK);
  };
  return (
    <div>
      <Form form={form}>
        <Row gutter={20}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder={translate('bank.name_bank')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={14}></Col>
          <Col className="gutter-row" span={3}>
            {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_BANKS).create ? (
              <div>
                <Button
                  style={{ width: '100%', display: 'inline-flex', justifyContent: 'center' }}
                  type="primary"
                  onClick={addNewCustomer}
                >
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default FilterHeaderPage;
