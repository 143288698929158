/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useDebounceFn } from 'ahooks';
import { Col, Pagination, Popconfirm, Row, Table, Tag, Tooltip } from 'antd';
import PageLoading from 'components/PageLoading';
import { ConstructorType, NAME_CONSTRUCTOR } from 'constants/ConstructorType';
import PaginateList from 'constants/PaginateList';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import useDeleteConstructor from 'hooks/Constructor/useDeleteConstructor';
import useListConstructor from 'hooks/Constructor/useListConstructor';
import images from 'images';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import FilterHeaderPage from './components/FilterHeaderPage';

export interface Filter {
  search?: string;
  page: number;
  limit: number;
  type?: string;
  status?: string;
}

function index() {
  const history = useHistory();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<Filter>({
    search: '',
    type: '',
    status: '',
    page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  // Danh sách
  const [onFetchListConstructor, listConstructor, loading] = useListConstructor();
  // Xóa
  const [onDeleteConstructor, loadingDelete] = useDeleteConstructor();

  useEffect(() => {
    onFetchListConstructor(filter);
  }, [filter, loadingDelete]);

  // Tìm kiếm Tên
  const { run } = useDebounceFn(
    (search: string) => {
      setPage(PaginateList.PAGE_DEFAULT);
      setFilter({ ...filter, page: 1, search });
    },
    {
      wait: 500,
    },
  );

  // Select trạng thái
  const onChangeStatus = (status: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, page: 1, status });
  };

  // Selecti
  const onChangeConstructorType = (type: string | '') => {
    setPage(PaginateList.PAGE_DEFAULT);
    setFilter({ ...filter, page: 1, type });
  };

  // Action xóa
  const onDelete = (_id: string) => () => {
    onDeleteConstructor(_id);
  };

  // Action xóa + sửa
  const renderAction = (_id: string) => {
    return (
      <div className="flex render-action-style justiv">
        {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).edit ? (
          <img
            className="img-style"
            style={{ marginRight: '20px' }}
            src={images.ic_edit}
            onClick={() => {
              history.push(ROUTER_PATH.EDIT_CONSTRUCTOR + _id);
            }}
          />
        ) : (
          ''
        )}
        {getCheckPermissionUser(PERMISSION_NAME.CATEGORY_CONSTRUCTOR).delete ? (
          <Popconfirm
            placement="top"
            title={translate('action.name')}
            onConfirm={onDelete(_id)}
            okText={translate('action.yes')}
            cancelText={translate('action.no')}
          >
            <img className="img-style" src={images.ic_delete} alt="" />
          </Popconfirm>
        ) : (
          ''
        )}
      </div>
    );
  };

  // Action phân trang
  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  // Render Column Table
  const renderColumns = [
    {
      title: translate('customer.stt'),
      key: '_id',
      render: (record: any, item: any, index: any) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: translate('constructor.name'),
      key: '_id',
      dataIndex: 'name',
      render: (name: string) => {
        return (
          <span>
            <Tooltip placement="topLeft" title={name}>
              <span>
                <span>{name && name.length > 40 ? `${name.substring(0, 40)} ...` : name}</span>
              </span>
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: translate('constructor.description'),
      key: '_id',
      dataIndex: 'description',
      render: (description: string) => {
        return (
          <span>
            <Tooltip placement="topLeft" title={description}>
              <span>
                <span>
                  {description && description.length > 40 ? `${description.substring(0, 40)} ...` : description}
                </span>
              </span>
            </Tooltip>
          </span>
        );
      },
    },
    {
      title: translate('constructor.type'),
      dataIndex: 'type',
      key: '_id',
      width: 500,
      render: (type: []) => {
        return (
          <>
            {type.map((data: any, index: number) => {
              return (
                <Tag style={{ marginBottom: '5px' }} key={index}>
                  {translate(NAME_CONSTRUCTOR[data])}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: translate('status.name'),
      key: '_id',
      dataIndex: 'status',
      render: (status: string) => {
        return <span>{status === StatusType.ACTIVE ? translate('status.show') : translate('status.hidden')}</span>;
      },
    },
    {
      title: translate('action.name'),
      dataIndex: '_id',
      key: '_id',
      render: renderAction,
    },
  ];

  return (
    <>
      <FilterHeaderPage
        onSearch={(value: string) => run(value)}
        onChangeStatus={onChangeStatus}
        onChangeConstructorType={onChangeConstructorType}
      />
      <Row>
        {loading || loadingDelete ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            {listConstructor && (
              <Table
                key={1}
                dataSource={listConstructor?.data || []}
                columns={renderColumns}
                pagination={false}
                scroll={{ x: 'auto' }}
                rowKey="_id"
              ></Table>
            )}
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={listConstructor?.total}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            ></Pagination>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default index;
