/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { ToastError, ToastSuccess } from '../../../components/Toast/index';
import { StatusResponse } from '../../../constants/Status';
import { translate } from '../../../translate/language';

const DELETE_PROJECT_NEW = 'project-news';
function useDeleteSaleAndRental() {
  const [loadingDel, setLoadingDel] = useState<boolean>(false);

  const onDeleteProjectNew = async (id: string) => {
    try {
      setLoadingDel(true);
      const res: any = await ApiHelper.delete(`${DELETE_PROJECT_NEW}?_id=${id}`);

      if (res && res.status === 'THIS_PROJECT_NEWS_WAS_AVAILABLE_IN_A_TRANSACTION') {
        setLoadingDel(false);
        return ToastError({
          message: translate('message_type.failed'),
          description: 'Tin bán cho thuê đã tồn tại giao dịch',
        });
      }

      if (res && res.status === StatusResponse.SUCCESS) {
        setLoadingDel(false);
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.delete_success'),
        });
      } else {
        setLoadingDel(false);
        return ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.delete_failed'),
        });
      }
    } catch (error) {
      setLoadingDel(false);
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  const deleteArray = async (id: string[]) => {
    const res: any = await ApiHelper.delete('project-news/delete-many', { ids: id });
    if (res && res.status === 'PROJECT_NEWS_DOES_NOT_EXISTS') {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Tin đăng đã có trong giao dịch',
      });
    }

    if (res && res.status === 'THIS_PROJECT_NEWS_WAS_AVAILABLE_IN_A_TRANSACTION') {
      return ToastError({
        message: translate('message_type.failed'),
        description: 'Tin đăng đã có trong giao dịch',
      });
    }

    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.delete_success'),
      });
    } else {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return { onDeleteProjectNew, loadingDel, deleteArray };
}

export default useDeleteSaleAndRental;
