/* eslint-disable import/no-cycle */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-shorthand */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Image, Input, message, Row, Select } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import Modal from 'antd/lib/modal/Modal';
import CKEEditor from 'components/Ckeditor/CKEEditor';
import { ToastError } from 'components/Toast';
import { BaseStatusProject } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import useUploadMulti from 'hooks/Upload/useUploadMulti';
import InputAddphoto3d from 'pages/ProjectPage/InputAddphoto3d';
import UploadButtonImages from 'pages/ProjectPage/UploadButtonImages';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { InputComponent, SelectInput } from './components/input-select.component';
import './css/HouseTemplate.scss';
import { STATUS_TYPE } from './modelHouseTemplate/HouseTemplate.type';
import { HouseTemplateReq } from './types/house-template.types';
import useLitsHouseTemplate from './useHouseTemplatehooks/useHouseTemplatehooks';

export interface Media {
  _id: string;
  url: string | undefined;
  name?: string;
}

export interface Params {
  id: string;
}
export interface Upload2d {
  title: string;
  url: string;
}
export interface SelectType {
  key?: string;
  value: string;
  children: string;
}

function CreateHouseTemplate(): JSX.Element {
  const DATA_STATUS = [
    {
      _id: BaseStatusProject.FOR_SALE,
      name: translate('status.show'),
    },
    {
      _id: BaseStatusProject.NOT_FOR_SALE,
      name: translate('status.hidden'),
    },
  ];

  const {
    listProject,
    onListProject,
    ListParentChildRealeState,
    onFetchListParentChildRealeState,
    ListDirection,
    stopLoadmore,
    onFetchListDirectionRealeState,
    onFetchListApartment,
    listApartment,
    onCreateHouseTemplate,
    onDetailHouseTemplate,
    dataDetailHouse,
    onUpdateHouseTemplate,
  } = useLitsHouseTemplate();

  const [onUpload, resUpload, messageError] = useUploadMulti();
  const [filterProject, setFilterProject] = useState({ limit: 10, page: 1, status: STATUS_TYPE.ACTIVE }); // status: STATUS_TYPE.ACTIVE
  const [filterDirectTion, setFilterDirection] = useState({ limit: 10, page: 1 });
  const [filterApartment, setFilterApartment] = useState({ limit: 10, page: 1 });
  const [form] = Form.useForm();
  const history = useHistory();
  const [ImageFile, setImageFile] = useState<Media[]>([]);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [urlPreview, setUrlPreview] = useState<any>(null);
  const [checkLengthImage, setCheckLengthImage] = useState<boolean>(false);
  const param: Params = useParams();
  const isEdit = param && param.id;
  const [typeMB, setTypeMB] = useState<boolean>(false);
  const [listLinkImage, setlistLinkImage] = useState<Upload2d[]>([]);

  useEffect(() => {
    onListProject(filterProject);
  }, [filterProject]);

  useEffect(() => {
    onFetchListApartment(filterApartment);
  }, [filterApartment]);

  useEffect(() => {
    onFetchListDirectionRealeState(filterDirectTion);
  }, [filterDirectTion]);

  useEffect(() => {
    onFetchListParentChildRealeState();
  }, []);

  // Chi tiết căn hộ mẫu
  useEffect(() => {
    if (isEdit) {
      onDetailHouseTemplate(param.id);
    }
  }, [isEdit]);

  // remove ảnh 3d
  const onRemoveItem = (item: any) => {
    confirm({
      title: translate('delete_video'),
      onOk: () => {
        const newFileVideo = [...listLinkImage].filter(e => e !== item);
        setlistLinkImage(newFileVideo);
      },
      onCancel: () => {},
    });
  };

  /// select ảnh 3d
  const onChangeInputFileVideo = (data: any) => {
    const newData = listLinkImage;
    newData.push(data);
    setlistLinkImage(newData);
  };

  useEffect(() => {
    if (dataDetailHouse) {
      const realestateId: string[] = [];
      if (dataDetailHouse.realestateId && dataDetailHouse.realestateId.length > 0) {
        dataDetailHouse.realestateId.map((data: any) => realestateId.push(data._id));
      }
      form.setFieldsValue({
        projectId: dataDetailHouse.projectId ? dataDetailHouse.projectId._id : '',
        realestateId: realestateId,
        name: dataDetailHouse.name,
        area: dataDetailHouse.area,
        builtupArea: dataDetailHouse.builtupArea,
        carpetArea: dataDetailHouse.carpetArea,
        directionId: dataDetailHouse.directionId[0] ? dataDetailHouse.directionId[0]._id : '',
        bedroom: dataDetailHouse.bedroom ? dataDetailHouse.bedroom._id : '',
        bathroom: dataDetailHouse.bathroom,
        otherInformation: dataDetailHouse.otherInformation,
        status: dataDetailHouse.status,
      });
      setContent(dataDetailHouse.otherInformation || '');
      if (dataDetailHouse.mediaIds && dataDetailHouse.mediaIds.length > 0) {
        setImageFile(dataDetailHouse.mediaIds);
      }
      if (dataDetailHouse.imageLinks && dataDetailHouse.imageLinks.length > 0) {
        setlistLinkImage(dataDetailHouse.imageLinks);
      }
      setTypeMB(dataDetailHouse.bedroom ? false : true);
    }
  }, [dataDetailHouse]);

  const handSubmit = (values: HouseTemplateReq) => {
    if (!typeMB && !values.bedroom) {
      return ToastError({
        message: 'Thất bại',
        description: 'Vui lòng chọn số phòng ngủ',
      });
    }
    const imageId: string[] = [];
    if (ImageFile && ImageFile.length > 0) {
      setCheckLengthImage(false);
      ImageFile.map(data => imageId.push(data._id));
    } else {
      setCheckLengthImage(true);
      return;
    }
    let imageLinks: Upload2d[] = [];
    if (listLinkImage && listLinkImage.length > 0) {
      imageLinks = listLinkImage;
    }
    const data: HouseTemplateReq = {
      projectId: values.projectId,
      realestateId: values.realestateId,
      name: values.name,
      area: values.area,
      builtupArea: values.builtupArea,
      carpetArea: values.carpetArea,
      directionId: values.directionId ? [values.directionId] : [],
      bedroom: values.bedroom ? values.bedroom : null,
      bathroom: values.bathroom,
      mediaIds: imageId,
      otherInformation: values.otherInformation || '',
      status: values.status,
      imageLinks: imageLinks,
    };
    if (isEdit && dataDetailHouse) {
      return onUpdateHouseTemplate(param.id, data);
    }
    onCreateHouseTemplate(data);
  };

  const onPopUpscrollProject = (e: any) => {
    if (stopLoadmore) return;
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      setFilterProject({ ...filterProject, page: filterProject.page + 1 });
    }
  };

  const onPopUpscrollApartment = (e: any) => {
    if (stopLoadmore) return;
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      setFilterApartment({ ...filterApartment, page: filterApartment.page + 1 });
    }
  };

  const onPopUpscrollDirection = (e: any) => {
    if (stopLoadmore) return;
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      setFilterDirection({ ...filterDirectTion, page: filterDirectTion.page + 1 });
    }
  };

  useEffect(() => {
    if (resUpload && resUpload.length > 0) {
      const newfileListScaleDetail = [...ImageFile].filter(e => e._id);
      setImageFile([...newfileListScaleDetail, ...resUpload]);
    }
  }, [resUpload]);

  const onChangeImageFiles = (fileList: any) => {
    const newData: any = [...ImageFile, ...fileList.currentTarget.files];
    const data = new FormData();
    for (let index = 0; index < [...fileList.currentTarget.files].length; index++) {
      const e = [...fileList.currentTarget.files][index];
      const isJpgOrPng = e.type === 'image/jpeg' || e.type === 'image/png';
      if (!isJpgOrPng) {
        return message.error('Bạn chỉ có thể tải lên tệp JPG / PNG!');
      }
      if (e.size > 5000000) {
        return message.warning(`Hình ảnh thứ ${index} vượt quá dung lượng`);
      }
      if (e) {
        data.append('files', e);
      }
    }
    setImageFile(newData);
    onUpload(data);
  };

  const onRemoveImage = (item: Media | any) => {
    confirm({
      title: 'Bạn có chắc muốn xóa ảnh này',
      onOk: () => deleteImage(item),
    });
  };

  // Xóa hình ảnh
  const deleteImage = (item: Media | any) => {
    const newArrImg = ImageFile.filter(data => {
      return (data as Media)._id !== (item as Media)._id;
    });
    setImageFile(newArrImg);
    message.success('Xoá ảnh thành công');
  };

  // Xem chi tiết hình ảnh
  const handlePreview = (file: any) => {
    if (file && file.url) {
      setUrlPreview(file);
      setModalVisible(true);
    }
  };

  // Đóng Modal preview
  const onCancel = () => {
    setModalVisible(false);
  };

  const onChangeSelect = (value: any, data: SelectType[]) => {
    const checkType = data.map((e: SelectType) => {
      return e.key?.substring(1);
    });
    let type: boolean = false;
    const typeBT = checkType.includes('BIETTHU');
    const typeNP = checkType.includes('NHAPHO');
    const typeSN = checkType.includes('SANGNHUONG');
    const typeCC = checkType.includes('CHUNGCU');
    if (typeBT || typeNP || typeSN || typeCC) {
      type = false;
    } else {
      type = true;
    }
    setTypeMB(type);
  };

  const [content, setContent] = useState<string>('');

  const handleChangeContentEmail = (event: any, editor: any) => {
    form.setFieldsValue({
      otherInformation: editor.getData(),
    });
  };

  return (
    <>
      {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).create ||
      getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).edit ? (
        <Row>
          <Modal title="" visible={isModalVisible} onCancel={onCancel} footer={false}>
            <div style={{ textAlign: 'center' }}>{urlPreview && <Image src={getUrlImage(urlPreview)} />}</div>
          </Modal>
          <Col span={24} offset={3}>
            <Form form={form} onFinish={handSubmit} style={{ margin: 20 }}>
              <SelectInput
                onPopupScroll={onPopUpscrollProject}
                name="projectId"
                rules={[]}
                label={translate('house_template.project')}
                data={listProject}
              />
              {/* Loại bất động sản */}
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div className="nameLabel">{translate('house_template.type_bds')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="realestateId"
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Loại bất động sản là bắt buộc ',
                        type: 'array',
                      },
                    ]}
                  >
                    <Select size="middle" mode="multiple" onChange={onChangeSelect}>
                      {ListParentChildRealeState && ListParentChildRealeState.length > 0
                        ? ListParentChildRealeState.map((data: any, key: any) => (
                            <Select.Option value={data._id} key={`${key}${data.parent?.note}`}>
                              {data.child}
                            </Select.Option>
                          ))
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <InputComponent
                name="name"
                rules={[
                  {
                    transform: (value: any) => {
                      return value?.trim();
                    },
                    min: 2,
                    max: 200,
                    message: 'Tên căn hộ chứa từ 2- 100 ký tự',
                  },
                ]}
                label={translate('house_template.name_house_template')}
              />

              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div>{translate('house_template.area')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="area"
                    initialValue={0}
                    rules={[
                      {
                        // pattern: /^(\d+(?:[\.\,]\d{2})?)$/,
                        pattern: /^(\d+(?:[\.]\d{1,2})?)$/, // Check số thập phân
                        message: translate('messages.integer_field', {
                          label: translate('house_template.area'),
                        }),
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>
              {/* Diện tích tim đương */}
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div>{translate('house_template.heart_wall_area')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="builtupArea"
                    rules={[
                      {
                        // pattern: /^(\d+(?:[\.\,]\d{2})?)$/,
                        pattern: /^(\d+(?:[\.]\d{1,2})?)$/, // Check số thập phân
                        message: translate('messages.integer_field', {
                          label: translate('house_template.heart_wall_area'),
                        }),
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>
              {/* Diện tích thông thủy */}
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div>{translate('house_template.clearance_area')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="carpetArea"
                    initialValue={'0'}
                    rules={[
                      {
                        // pattern: /^(\d+(?:[\.\,]\d{2})?)$/,
                        pattern: /^(\d+(?:[\.]\d{1,2})?)$/, // Check số thập phân
                        message: translate('messages.integer_field', {
                          label: translate('house_template.clearance_area'),
                        }),
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>

              <SelectInput
                onPopupScroll={onPopUpscrollDirection}
                name="directionId"
                label={translate('house_template.house_direction')}
                data={ListDirection}
              />

              {/* Số phòng ngủ */}
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div>{translate('house_template.numb_bedRooms')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="bedroom" style={{ width: '100%' }}>
                    <Select size="middle">
                      {listApartment && listApartment.length > 0
                        ? listApartment.map((data: any, key: any) => (
                            <Select.Option value={data._id}>{data.name}</Select.Option>
                          ))
                        : ''}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/* Số phòng vệ sinh */}
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div>{translate('house_template.numb_bathRooms')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item
                    name="bathroom"
                    initialValue={0}
                    rules={[
                      {
                        pattern: /^\d+$/,
                        message: translate('messages.integer_field', {
                          label: translate('house_template.numb_bathRooms'),
                        }),
                      },
                    ]}
                  >
                    <Input size="small" />
                  </Form.Item>
                </Col>
              </Row>

              {/* Upload ảnh 3d, 2d, 360 độ */}
              <Row>
                <Col span={20}>
                  <Form.Item name="imageLinks" label={'Ảnh 3d'} labelCol={{ span: 6 }} labelAlign="left">
                    <InputAddphoto3d
                      files={listLinkImage}
                      onRemove={onRemoveItem}
                      onChange={onChangeInputFileVideo}
                      lengthVideo={15}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div className="nameLabel">{translate('house_template.image')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <UploadButtonImages
                    accept={'.gif, .jpg, .png, .webp'}
                    files={ImageFile}
                    onPreview={handlePreview}
                    onChange={onChangeImageFiles}
                    onRemove={onRemoveImage}
                    multiple
                  />
                  {!ImageFile.length && checkLengthImage && (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                      <div role="alert">Hình ảnh không được để trống</div>
                    </div>
                  )}
                </Col>
              </Row>
              <SelectInput
                name="status"
                rules={[]}
                label={translate('house_template.status')}
                data={DATA_STATUS}
                initialValueSelect={'FOR_SALE'}
              />
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
                  <div>{translate('house_template.other_info')}</div>
                </Col>
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="otherInformation" labelAlign="left">
                    <CKEEditor onChange={handleChangeContentEmail} data={content} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col md={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }} />
                <Col md={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 24 }}>
                  <Row justify="end">
                    <Button
                      size="large"
                      className="BtnCancel"
                      type="primary"
                      style={{ background: '#F34949', margin: '10px 10px 10px 0px' }}
                      onClick={() => history.goBack()}
                      htmlType="button"
                    >
                      Huỷ
                    </Button>
                    <Form.Item>
                      <Button
                        size="large"
                        style={{ background: '#3AC86E', margin: '10px 0px 1px 10px' }}
                        className="BtnSave"
                        type="primary"
                        htmlType="submit"
                      >
                        Lưu
                      </Button>
                    </Form.Item>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default CreateHouseTemplate;
