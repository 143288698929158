import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListPropertyReq {}

function useListProperty(): [(request: ListPropertyReq) => Promise<void>, any, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const [listProperty, getListProperty] = useState();

  const onFetchListProperty = async (request: ListPropertyReq) => {
    try {
      setLoading(true);
      const res = await ApiHelper.fetch<ListPropertyReq, any>(BASE_URL.LIST_PROPERTY, request);
      console.log('TCL: res', res);
      if (res && res.status == StatusResponse.SUCCESS) {
        setLoading(false);
        getListProperty(res);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return [onFetchListProperty, listProperty, loading];
}

export default useListProperty;
