/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { translate } from 'translate/language';

export interface Body {
  content: string;
  listVideos: [
    {
      videoId: string;
      title: string;
    },
  ];
}

const URL = '/user-guide';

function useContentGuideService() {
  const [data, setData] = useState<any>();

  const onData = async () => {
    const res: any = await ApiHelper.fetch(URL);
    if (res && res.status === StatusResponse.SUCCESS) {
      setData(res.data);
    }
  };

  const onDataUpdate = async (dataReq: Body) => {
    const res: any = await ApiHelper.put(URL, dataReq);
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.update_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return { onData, data, onDataUpdate };
}

export default useContentGuideService;
