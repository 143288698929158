export interface ReqUpdatePer {
  name: string;
  description?: string;
  status: string;
  permission?: any;
}
export interface ReqCreatePermission {
  name: string;
  description?: string;
  status: string;
  permission?: any;
}
export interface ResPermissionTable {
  _id: string;
  type?: string;
  read: boolean | number;
  create: boolean | number;
  edit: boolean | number;
  delete: boolean | number;
  export: boolean | number;
}

export interface ReqPermissionList {
  name: string;
  limit?: string | number;
  page?: string | number;
}

export interface ResPermissionDetail {
  permission: ResPermissionTable & ResPermissionTable[];
  createdAt: string;
  deletedAt?: string;
  _id: string;
  name: string;
  description: string;
  status: string;
}

export const PERMISSION = {
  STATISTICS: 'STATISTICS',
  ACCOUNTS_CMS: 'ACCOUNTS_CMS',
  ACCOUNTS_APP: 'ACCOUNTS_APP',
  ACCOUNTS_PERMISSIONS: 'ACCOUNTS_PERMISSIONS',
  PROJECTS: 'PROJECTS',
  PROJECTS_BLOCKS_AREA: 'PROJECTS_BLOCKS_AREA',
  PROJECTS_APARTMENTS_LAND: 'PROJECTS_APARTMENTS_LAND',
  PROJECTS_HOUSETEMPLATES: 'PROJECTS_HOUSETEMPLATES',
  PROJECTS_VOLUNTEERS: 'PROJECTS_VOLUNTEERS',
  PROJECTS_CUSTOMERS_BOOKING: 'PROJECTS_CUSTOMERS_BOOKING',
  PROJECTS_CUSTOMERS_SEQUENCE: 'PROJECTS_CUSTOMERS_SEQUENCE',
  REALESTATE_SELL: 'REALESTATE_SELL',
  REALESTATE_BUY: 'REALESTATE_BUY',
  COMMISSION_HISTORY: 'COMMISSION_HISTORY',
  COMMISSION_WITHDRAW: 'COMMISSION_WITHDRAW',
  COMMISSION_ADD: 'COMMISSION_ADD',
  POINT_PACKAGE_ADD_POINT: 'POINT_PACKAGE_ADD_POINT',
  POINT_PACKAGES: 'POINT_PACKAGES',
  POINT_PACKAGES_BUY_HISTORY: 'POINT_PACKAGES_BUY_HISTORY',
  CONTENT_NEWS: 'CONTENT_NEWS',
  CONTENT_NOTIFICATIONS: 'CONTENT_NOTIFICATIONS',
  CONTENT_INTRO_TERMS: 'CONTENT_INTRO_TERMS',
  CONTENT_GUIDE: 'CONTENT_GUIDE',
  CONTENT_CONTACT: 'CONTENT_CONTACT',
  CATEGORY_REALESTATE: 'CATEGORY_REALESTATE',
  CATEGORY_APARTMENT: 'CATEGORY_APARTMENT',
  CATEGORY_HOUSE_DIRECTIONS: 'CATEGORY_HOUSE_DIRECTIONS',
  CATEGORY_CONSTRUCTOR: 'CATEGORY_CONSTRUCTOR',
  CATEGORY_BANKS: 'CATEGORY_BANKS',
  CONFIG_AUTO_NOTIFICATIONS: 'CONFIG_AUTO_NOTIFICATIONS',
  CONFIG_INTERACTIVE_POINTS: 'CONFIG_INTERACTIVE_POINTS',
  CONFIG_NEWS_METHOD: 'CONFIG_NEWS_METHOD',
  CONFIG_EMAILS_CONTRACTS: 'CONFIG_EMAILS_CONTRACTS',
  CONFIG_COMMISSIONS_PROJECT: 'CONFIG_COMMISSIONS_PROJECT',
  CONFIG_COMMISSIONS_REALESTATE: 'CONFIG_COMMISSIONS_REALESTATE',
  CONFIG_GENERAL: 'CONFIG_GENERAL',
  SYSTEM_TREE: 'SYSTEM_TREE',
};

export const NAME_PERMISSION = {
  [PERMISSION.STATISTICS]: 'configuration.STATISTICS',
  [PERMISSION.ACCOUNTS_CMS]: 'configuration.ACCOUNTS_CMS',
  [PERMISSION.ACCOUNTS_APP]: 'configuration.ACCOUNTS_APP',
  [PERMISSION.ACCOUNTS_PERMISSIONS]: 'configuration.ACCOUNTS_PERMISSIONS',
  [PERMISSION.PROJECTS]: 'configuration.PROJECTS',
  [PERMISSION.PROJECTS_BLOCKS_AREA]: 'configuration.PROJECTS_BLOCKS_AREA',
  [PERMISSION.PROJECTS_APARTMENTS_LAND]: 'configuration.PROJECTS_APARTMENTS_LAND',
  [PERMISSION.PROJECTS_HOUSETEMPLATES]: 'configuration.PROJECTS_HOUSETEMPLATES',
  [PERMISSION.PROJECTS_VOLUNTEERS]: 'configuration.PROJECTS_VOLUNTEERS',
  [PERMISSION.PROJECTS_CUSTOMERS_BOOKING]: 'configuration.PROJECTS_CUSTOMERS_BOOKING',
  [PERMISSION.PROJECTS_CUSTOMERS_SEQUENCE]: 'configuration.PROJECTS_CUSTOMERS_SEQUENCE',
  [PERMISSION.REALESTATE_SELL]: 'configuration.REALESTATE_SELL',
  [PERMISSION.REALESTATE_BUY]: 'configuration.REALESTATE_BUY',
  [PERMISSION.COMMISSION_HISTORY]: 'configuration.COMMISSION_HISTORY',
  [PERMISSION.COMMISSION_WITHDRAW]: 'configuration.COMMISSION_WITHDRAW',
  [PERMISSION.COMMISSION_ADD]: 'configuration.COMMISSION_ADD',
  [PERMISSION.POINT_PACKAGE_ADD_POINT]: 'configuration.POINT_PACKAGE_ADD_POINT',
  [PERMISSION.POINT_PACKAGES]: 'configuration.POINT_PACKAGES',
  [PERMISSION.POINT_PACKAGES_BUY_HISTORY]: 'configuration.POINT_PACKAGES_BUY_HISTORY',
  [PERMISSION.CONTENT_NEWS]: 'configuration.CONTENT_NEWS',
  [PERMISSION.CONTENT_NOTIFICATIONS]: 'configuration.CONTENT_NOTIFICATIONS',
  [PERMISSION.CONTENT_INTRO_TERMS]: 'configuration.CONTENT_INTRO_TERMS',
  [PERMISSION.CONTENT_GUIDE]: 'configuration.CONTENT_GUIDE',
  [PERMISSION.CONTENT_CONTACT]: 'configuration.CONTENT_CONTACT',
  [PERMISSION.CATEGORY_REALESTATE]: 'configuration.CATEGORY_REALESTATE',
  [PERMISSION.CATEGORY_APARTMENT]: 'configuration.CATEGORY_APARTMENT',
  [PERMISSION.CATEGORY_HOUSE_DIRECTIONS]: 'configuration.CATEGORY_HOUSE_DIRECTIONS',
  [PERMISSION.CATEGORY_CONSTRUCTOR]: 'configuration.CATEGORY_CONSTRUCTOR',
  [PERMISSION.CATEGORY_BANKS]: 'configuration.CATEGORY_BANKS',
  [PERMISSION.CONFIG_AUTO_NOTIFICATIONS]: 'configuration.CONFIG_AUTO_NOTIFICATIONS',
  [PERMISSION.CONFIG_INTERACTIVE_POINTS]: 'configuration.CONFIG_INTERACTIVE_POINTS',
  [PERMISSION.CONFIG_NEWS_METHOD]: 'configuration.CONFIG_NEWS_METHOD',
  [PERMISSION.CONFIG_EMAILS_CONTRACTS]: 'configuration.CONFIG_EMAILS_CONTRACTS',
  [PERMISSION.CONFIG_COMMISSIONS_PROJECT]: 'configuration.CONFIG_COMMISSIONS_PROJECT',
  [PERMISSION.CONFIG_COMMISSIONS_REALESTATE]: 'configuration.CONFIG_COMMISSIONS_REALESTATE',
  [PERMISSION.CONFIG_GENERAL]: 'configuration.CONFIG_GENERAL',
  [PERMISSION.SYSTEM_TREE]: 'configuration.SYSTEM_TREE',
};
