/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, ConfigProvider, DatePicker, Form, Pagination, Row, Table, Typography, Checkbox } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { formatNumber } from 'helpers/Utils';
import moment from 'moment';
import 'moment/locale/vi';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { translate } from 'translate/language';
import { ROUTER_PATH } from 'routes/Path';
import { DetailHistory } from './history-types';
import useHistoryService from './history.service';

const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

interface RedirectOption {
  code: string;
  transactionId: string;
  type: string;
}

function HistoryDetailScreen(): JSX.Element {
  const [form] = Form.useForm();
  const history = useHistory();
  const param: { id: string } = useParams();
  const isEdit = param && param.id;

  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<DetailHistory>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    type: [],
    minDate: undefined,
    maxDate: undefined,
  });

  const {
    onDetailUser,
    userData,
    onDetailHistoryComission,
    detailHistoryComission,
    loadingDetail,
  } = useHistoryService();

  const detailTransaction = (id: string, type: string) => {
    switch (type) {
      case 'INTRODUCER':
      case 'SUPPORTER':
      case 'CUSTOMER':
      case 'TREE':
        return history.push(`${ROUTER_PATH.DETAIL_PROJECT_CUSTOMER}${id}?type=PAG`);
      case 'COMMISSION_RETAIL_REAL_ESTATE_INTRODUCER':
      case 'COMMISSION_RETAIL_REAL_ESTATE_CUSTOMER':
      case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_INTRODUCE':
      case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_CUSTOMER':
      case 'COMMISSION_RETAIL_REAL_ESTATE_SELLER':
        return history.push(ROUTER_PATH.DETAIL_RELATED + id);
      default:
        return '#';
    }
  };
  useEffect(() => {
    if (isEdit) {
      onDetailHistoryComission(filter, param.id);
    }
  }, [filter]);

  useEffect(() => {
    if (isEdit) {
      onDetailUser(param.id);
    }
  }, []);

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'receivedDate',
      render: (receivedDate: moment.Moment) => {
        return <span>{moment(receivedDate).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Hoa hồng nhận',
      dataIndex: 'money',
      render: (money: number) => {
        return <span>{formatNumber(money || 0)}</span>;
      },
    },
    {
      title: 'Loại hoa hồng nhận',
      dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case 'CMS':
            return 'Hoa hồng từ CMS';
          case 'INTRODUCER':
            return 'Hoa hồng dự án';
          case 'SUPPORTER':
            return 'Hoa hồng dự án';
          case 'CUSTOMER':
            return 'Hoa hồng dự án';
          case 'TREE':
            return 'Hoa hồng hệ thống';
          case 'PAYMENT':
            return 'Rút tiền';
          case 'RETAIL_REAL_ESTATE':
          case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_INTRODUCE':
          case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_CUSTOMER':
          case 'COMMISSION_RETAIL_REAL_ESTATE_CUSTOMER':
          case 'COMMISSION_RETAIL_REAL_ESTATE_INTRODUCER':
          case 'COMMISSION_RETAIL_REAL_ESTATE_SELLER':
            return 'Hoa hồng BĐS lẻ';
          default:
            return '';
        }
      },
    },
    {
      title: 'Giao dịch liên quan',
      dataIndex: 'code',
      render: (value: string, { code, transactionId, type }: RedirectOption) => {
        return (
          <>
            <Link target="_blank" onClick={() => detailTransaction(transactionId, type)}>
              <Text underline style={{ color: '#12a0c0' }}>
                {code}
              </Text>
            </Link>
          </>
        );
      },
    },
    {
      title: 'Vai trò',
      dataIndex: 'type',
      render: (type: string) => {
        switch (type) {
          case 'INTRODUCER':
          case 'COMMISSION_RETAIL_REAL_ESTATE_INTRODUCER':
            return 'CTV giới thiệu';
          case 'SUPPORTER':
            return 'CTV hỗ trợ';
          case 'CUSTOMER':
          case 'COMMISSION_RETAIL_REAL_ESTATE_CUSTOMER':
            return 'Khách hàng';
          case 'TREE':
          case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_INTRODUCE':
          case 'COMMISSION_RETAIL_REAL_ESTATE_SYSTEM_CUSTOMER':
            return 'Hệ thống';
          case 'PAYMENT':
            return 'Rút tiền';
          case 'COMMISSION_RETAIL_REAL_ESTATE_SELLER':
            return 'Người bán';
          default:
            return '';
        }
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'noteCMS',
      render: (noteCMS: string) => {
        return <span>{noteCMS}</span>;
      },
    },
  ];

  const closeButton = () => {
    history.goBack();
  };

  // Action phân trang
  const onPageChange = (_page: number) => {
    setPage(_page);
    setFilter({ ...filter, page: _page });
  };

  const onChangeRangePicker = (dates: any, dateStrings: [string, string]) => {
    console.log(dates, '12121', dateStrings);
    setFilter({
      ...filter,
      minDate: dateStrings[0] || undefined,
      maxDate: dateStrings[1] || undefined,
      page: PaginateList.PAGE_DEFAULT,
    });
  };

  const onChangeCheckbox = (checkedValues: any) => {
    if (checkedValues.length <= 0) {
      setFilter({ ...filter, type: [] });
    } else {
      setFilter({ ...filter, type: checkedValues });
    }
  };
  return (
    <>
      <ConfigProvider locale={locale}>
        <Form form={form}>
          <Row gutter={24}>
            <Col span={20} offset={3}>
              <Row>
                {/* Họ và tên */}
                <Col span={16}>
                  <Form.Item name="name" label="Họ và tên" labelCol={{ span: 7 }} labelAlign="left">
                    <div>
                      <b>{userData && userData?.name}</b>
                    </div>
                  </Form.Item>
                </Col>
                {/* Số điện thoại */}
                <Col span={16}>
                  <Form.Item label="Số điện thoại" labelCol={{ span: 7 }} labelAlign="left">
                    <div>
                      <b>{userData && userData?.phone}</b>
                    </div>
                  </Form.Item>
                </Col>
                {/* Email */}
                <Col span={16}>
                  <Form.Item label="Email" labelCol={{ span: 7 }} labelAlign="left">
                    <div>
                      <b>{userData && userData?.email}</b>
                    </div>
                  </Form.Item>
                </Col>
                {/* Số CMND */}
                <Col span={16}>
                  <Form.Item label="Số CMND" labelCol={{ span: 7 }} labelAlign="left">
                    <div>
                      <b>{userData && userData?.identify}</b>
                    </div>
                  </Form.Item>
                </Col>
                {/* Thời gian nhận */}
                <Col span={16}>
                  <Form.Item label="Thời gian nhận từ" labelCol={{ span: 7 }} labelAlign="left">
                    <RangePicker
                      picker="month"
                      style={{ width: '100%' }}
                      placeholder={['Tháng/Năm', 'Tháng/Năm']}
                      format="MM/YYYY"
                      onChange={onChangeRangePicker}
                    />
                  </Form.Item>
                </Col>

                <Checkbox.Group style={{ width: '100%', marginBottom: '20px' }} onChange={onChangeCheckbox}>
                  <Row>
                    <Col span={12}>
                      <Checkbox style={{ width: '100%', marginBottom: '20px' }} value="TREE">
                        Hoa hồng hệ thống
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox style={{ width: '100%' }} value="CUSTOMER">
                        Hoa hồng dự án
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox style={{ width: '100%', marginBottom: '20px' }} value="RETAIL_REAL_ESTATE">
                        Hoa hồng BĐS lẻ
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox style={{ width: '100%' }} value="CMS">
                        Hoa hồng từ CMS
                      </Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox style={{ width: '100%' }} value="PAYMENT">
                        Rút tiền
                      </Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>

                {loadingDetail ? (
                  <PageLoading />
                ) : (
                  <>
                    {/* Bảng thông tin */}
                    {detailHistoryComission && (
                      <>
                        <Col span={16}>
                          <Table dataSource={detailHistoryComission?.data || []} columns={columns} pagination={false} />
                          <Col
                            span={24}
                            style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}
                          >
                            <div className="d-flex justify-content-end pagination">
                              <Pagination
                                showSizeChanger={false}
                                total={detailHistoryComission.total}
                                current={page}
                                defaultCurrent={page}
                                defaultPageSize={PaginateList.LIMIT_DEFAULT}
                                onChange={onPageChange}
                              />
                            </div>
                          </Col>
                        </Col>
                        {/* Tiền hoa hồng */}
                        <Col span={12} style={{ textAlign: 'center' }}>
                          <div style={{ fontSize: '16px' }}>
                            Tổng hoa hồng nhận :
                            <b> {formatNumber(detailHistoryComission?.totalCommissionReceived || 0)}</b>
                          </div>
                          <div style={{ fontSize: '16px' }}>
                            Tổng hoa hồng đã rút : <b>{formatNumber(detailHistoryComission?.payment || 0)}</b>
                          </div>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </ConfigProvider>
    </>
  );
}

export default HistoryDetailScreen;
