import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import { resProjectResponse } from 'types/response/ProjectResponse';

function useUpdateProject() {
    const [loadingUpdateProject, setLoadingUpdateProject] = useState(false)
    const history = useHistory();

    const onUpdateProject = async (id: string, request: any) => {
        try {
            setLoadingUpdateProject(true);
            const res = await ApiHelper.put<any, resProjectResponse>(
                BASE_URL.UPDATE_PROJECT + id,
                request
            )
            if (res && res.status == StatusResponse.PROJECT_DO_NOT_EXISTS) {
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.data_do_not_exists"),
                });
                setLoadingUpdateProject(false)
                history.push(ROUTER_PATH.PROJECT)
                return;
            }
            if (res && res.status == StatusResponse.SUCCESS) {
                ToastSuccess({
                    message: translate("message_type.success"),
                    description: translate("messages.update_success"),
                });
                setLoadingUpdateProject(true)
                history.push(ROUTER_PATH.PROJECT)
            } else {
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.update_failed"),
                });
                setLoadingUpdateProject(false)
            }
        } catch (error) {
            ToastError({
                message: translate("message_type.failed"),
                description: translate("messages.update_failed"),
            });
            setLoadingUpdateProject(false)
        }
    }
    return { onUpdateProject, loadingUpdateProject };
}

export default useUpdateProject;