/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { BaseStatusProject } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React, { ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import '../css/HouseTemplate.scss';

const { Option } = Select;
/* 
  Created by cuong tran at 11-27-2020 17:55:56
  Màn hình can ho mau
*/
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status: string) => void;
  dataProject: any[];
  onChangeProject: (status: string) => void;
}
function FilterHouseTemplate(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, dataProject, onChangeProject, onChangeStatus } = props;
  const history = useHistory();
  const query: any = useQuery();

  // Tìm kiếm
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
  };
  // Select dự án
  const handChangeProject = (value: string) => {
    onChangeProject(value);
  };

  const addNew = () => {
    history.push(ROUTER_PATH.ADD_PROJECTS_HOUSETEMPLATE);
  };
  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]} className="header_filer">
          <Col md={{ span: 7 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder={translate('project.name_house_template')}
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>
          <Col md={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item name="project">
                <Select
                  defaultValue={query.get('idProject') ? query.get('idProject') : ''}
                  placeholder={translate('sidebar.project')}
                  onChange={handChangeProject}
                >
                  <Option value="">{translate('sidebar.project')}</Option>
                  {dataProject.map(e => {
                    return <Option value={e._id}>{e.name}</Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col md={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('status.name')} onChange={handChangeStatus}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value={BaseStatusProject.FOR_SALE}>{translate('status.show')}</Option>
                  <Option value={BaseStatusProject.NOT_FOR_SALE}>{translate('status.hidden')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col md={{ span: 7 }} xl={5} xxl={3} sm={{ span: 12 }} xs={{ span: 24 }}>
            {/* Thêm mới */}
            {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_HOUSETEMPLATES).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={addNew}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}
export default FilterHouseTemplate;
