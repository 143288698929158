/* eslint-disable dot-notation */
/* eslint-disable prefer-template */
/* eslint-disable consistent-return */
import { StatusResponse } from './../constants/Status';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import LocalStorageHelper, { StorageKey } from '../helpers/LocalStorageHelper';
import { BaseResponse } from '../types/response/BaseResponse';

const URL_MAP_API = 'https://maps.googleapis.com/maps/api/place/';

const REQ_TIMEOUT = 5 * 60 * 1000;
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_DOMAIN,
  timeout: REQ_TIMEOUT,
});
const instanceMap = axios.create({
  baseURL: URL_MAP_API,
  timeout: REQ_TIMEOUT,
});

instance.interceptors.request.use(_config => requestHandler(_config));
instanceMap.interceptors.request.use(_config => requestHandler(_config));

const requestHandler = (request: AxiosRequestConfig) => {
  console.log(`Request API ===> ${request.url}`, request.params, request.data);

  return request;
};

instance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
);
instanceMap.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
);

const errorHandler = (error: any) => {
  console.log(error, 'error');
  // const originalRequest = error.config;
  // const refreshToken = LocalStorageHelper.getRefreshToken();
  // if (
  //     error.response.status === UNAUTHENTICATED &&
  //     !originalRequest._retry &&
  //     refreshToken !== ''
  // ) {
  //     originalRequest._retry = true;
  //     return instance
  //         .post(Endpoint.REFRESH_TOKEN, {
  //             refreshToken
  //         })
  //         .then((res) => {
  //             // Save new token to local storage
  //             LocalStorageHelper.updateAccessToken(res.data.idToken);
  //             // Change Authorization header
  //             originalRequest.headers = {
  //                 ...originalRequest.headers,
  //                 Authorization: `Bearer ${res.data.idToken}`
  //             };
  //             // Return originalRequest object with Axios
  //             return instance(originalRequest);
  //         })
  //         .catch((error) => {
  //             EventBus.getInstance().post<LogoutPayloadType>({
  //                 type: EventBusName.LOGOUT_EVENT
  //             });
  //         });
  // }
  // return Promise.reject({ ...error });
};

const successHandler = async (response: AxiosResponse) => {
  console.log(`Response API <=== ${response.config.url}`, response.data);

  const originalRequest = response.config;
  const data: any = response.data;

  // @ts-ignore
  if (data.status === 'INVALID_TOKEN' && !originalRequest._retry) {
    // @ts-ignore
    originalRequest._retry = true;
    const refreshToken: string | null = LocalStorageHelper.get(StorageKey.REFRESH_TOKEN);

    const refreshRes: any = await instance.post('/user/refresh-token', {
      refreshToken: `Bearer ${refreshToken}`,
    });
    if (refreshRes && refreshRes.status === StatusResponse.SUCCESS) {
      LocalStorageHelper.save(StorageKey.TOKEN, refreshRes.token);
      LocalStorageHelper.save(StorageKey.REFRESH_TOKEN, refreshRes.refreshToken);

      originalRequest.headers['Authorization'] = 'Bearer ' + refreshRes.token;
      return instance(originalRequest);
    }
  }

  return data;
};

async function fetch<ReqType, ResType extends BaseResponse | any>(
  url: string,
  params?: ReqType,
  customHeaders: any = {},
): Promise<ResType> {
  const headers = getHeader(customHeaders);
  return instance.get(url, { params, headers });
}
async function fetchGGmap<ReqType, ResType extends any>(
  url: string,
  params?: ReqType,
  customHeaders: any = {},
): Promise<ResType> {
  const headers = getHeader(customHeaders);
  return instanceMap.get(url, { params, headers });
}

async function post<ReqType, ResType extends BaseResponse | any>(
  url: string,
  data?: ReqType,
  customHeaders: any = {},
): Promise<ResType> {
  const headers = getHeader(customHeaders);
  return instance.post(url, { ...data }, { headers });
}

async function postFormData<ResType extends BaseResponse>(
  url: string,
  data?: FormData,
  customHeaders: any = {},
): Promise<ResType> {
  const headers = getHeader(customHeaders);
  return instance.post(url, data, { headers });
}

async function put<ReqType, ResType extends BaseResponse | any>(
  url: string,
  data?: ReqType,
  customHeaders: any = {},
): Promise<ResType> {
  const headers = getHeader(customHeaders);
  return instance.put(url, data, { headers });
}
async function remove<ReqType, ResType extends BaseResponse>(
  url: string,
  data?: ReqType,
  customHeaders: any = {},
): Promise<ResType> {
  const headers = getHeader(customHeaders);
  return instance.delete(url, { data, headers });
}

function getHeader(customHeaders: any = {}): any {
  const token: string | null = LocalStorageHelper.get(StorageKey.TOKEN);
  if (token) {
    return {
      ...customHeaders,
      Authorization: `Bearer ${token}`,
    };
  }

  return { ...customHeaders };
}
const ApiHelper = { fetch, post, postFormData, put, delete: remove, fetchGGmap, getHeader };

export default ApiHelper;
