/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusType } from 'constants/Status';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

export interface Props {
  onSearch: (data?: string) => void;
  onStatus: (data: string) => void;
}

function FilterNewsComponent(props: Props): JSX.Element {
  const { onSearch, onStatus } = props;
  const history = useHistory();
  const [form] = Form.useForm();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value ? event.target.value : undefined);
  };

  // Thay đổi trạng thái
  const handChangeStatus = (value: any) => {
    onStatus(value !== '' ? value : undefined);
  };

  // Thêm mới
  const addNew = () => {
    history.push(ROUTER_PATH.NEW_CREATE);
  };

  return (
    <>
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item name="search">
              <Input size="small" placeholder="Tiêu đề" onChange={handleSearch} suffix={<SearchOutlined />} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="customer_type">
              <Select placeholder="Trạng thái" onChange={handChangeStatus}>
                <Option value="">Tất cả</Option>
                <Option value={StatusType.ACTIVE}>Hoạt động</Option>
                <Option value={StatusType.INACTIVE}>Không hoạt động</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={14}>
            {getCheckPermissionUser(PERMISSION_NAME.CONTENT_NEWS).create ? (
              <div>
                <Button style={{ display: 'block', float: 'right' }} type="primary" onClick={addNew}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterNewsComponent;
