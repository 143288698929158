/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-useless-path-segments */
import { ToastError, ToastSuccess } from 'components/Toast';
import ApiHelper from 'network/ApiClient';
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useState } from 'react';
import { translate } from 'translate/language';
import { StatusResponse } from './../../constants/Status';
import { FeedbackRes, FilterFeedback } from './feedback.type';

const FEED_BACK = '/project-news/feedback';

function useFeedbackService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataFeedBack, setDataFeedBack] = useState<FeedbackRes[]>([]);
  const [totalFeedBack, setTotalFeedBack] = useState<number>();

  const onGetFeedBack = async (params: FilterFeedback) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(FEED_BACK, params);
      if (res && res.status === StatusResponse.SUCCESS) {
        setTotalFeedBack(res.total);
        setDataFeedBack(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteArray = async (id: string[]) => {
    const res: any = await ApiHelper.delete('project-news/feedback', { ids: id });
    if (res && res.status === 'FEEDBACK_NOT_EXISTS') {
      return ToastError({
        message: translate('message_type.success'),
        description: 'Có phản hồi không hợp lệ',
      });
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.delete_success'),
      });
    } else {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.delete_failed'),
      });
    }
  };

  return { onGetFeedBack, dataFeedBack, totalFeedBack, loading, deleteArray };
}

export default useFeedbackService;
