/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */
import { Col, Form, Image, Row, Select, Spin } from 'antd';
import { StatusType } from 'constants/Status';
import { getUrlImage } from 'helpers/getImageUrl';
import useDetailCustomer from 'hooks/Customer/useDetailCustomer';
import useListCustomer from 'hooks/Customer/useListCustomer';
import React, { useEffect } from 'react';
import { translate } from 'translate/language';

const { Option } = Select;
interface Props {
  checkInCharge: string;
  idInCharge?: string;
}

function InChargeProject(props: Props) {
  // Lấy danh sách người phụ trách ( Tài khoản khách hàng )
  const [onFetchListCustomer, listCustomer, loadingCustomer] = useListCustomer();
  // Chi tiết người phụ trách PAG
  const [onGetDetailCustomer, detailCustomer, loadingDetailCustomer, messageCheck] = useDetailCustomer();

  const { checkInCharge, idInCharge } = props;

  useEffect(() => {
    onFetchListCustomer({
      status: StatusType.ACTIVE,
    });
  }, []);

  // Select hiển thị chi tiết người phụ trách PAG
  const onchangeInCharge = (value: string) => {
    onGetDetailCustomer(value);
  };

  useEffect(() => {
    if (checkInCharge && idInCharge) {
      onGetDetailCustomer(idInCharge);
    }
  }, [checkInCharge, idInCharge]);

  return (
    <>
      <Row style={{ marginTop: '15px' }}>
        <Col span={20}>
          <Form.Item
            name="inCharge"
            label={translate('project.inCharge')}
            labelCol={{ span: 7 }}
            labelAlign="left"
            rules={[
              {
                required: true,
                message: translate('require_field', {
                  label: translate('project.inCharge'),
                }),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={translate('project.inCharge')}
              onChange={onchangeInCharge}
              optionFilterProp="children"
              filterOption={(input: string, option: any | undefined) =>
                option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {listCustomer && listCustomer.data
                ? listCustomer.data.map((data: any, index: number) => (
                    <Option value={data?._id} key={index + 1}>
                      {data?.name}
                    </Option>
                  ))
                : ''}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {/* Thông tin người phụ  trách */}
      {loadingDetailCustomer ? (
        <div style={{ width: '100%', paddingTop: 120, textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        messageCheck &&
        detailCustomer && (
          <Row gutter={20} style={{ display: 'flex', alignItems: 'center' }}>
            <Col span={20}>
              <Row>
                <Col span={7}></Col>
                <Col span={6}>
                  <div>
                    {detailCustomer?.avatar ? (
                      <Image width={150} height={150} src={getUrlImage(detailCustomer?.avatar)} />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
                <Col
                  span={10}
                  style={{
                    border: '1px solid #c6b8b8',
                    padding: '15px',
                  }}
                >
                  <Row style={{ margin: '20px 0px' }}>
                    <Col span={6}>
                      <b>{translate('customer.email')} :</b>
                    </Col>
                    <Col span={12}>
                      <div>{detailCustomer?.email}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10}>
                      <b>{translate('customer.phone')} :</b>
                    </Col>
                    <Col span={12}>
                      <div>{detailCustomer?.phone}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      )}
    </>
  );
}

export default InChargeProject;
