/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-native/no-inline-styles */
import { RingProgress } from '@ant-design/charts';
import { Button, Col, ConfigProvider, DatePicker, Pagination, Row, Select, Table, Typography } from 'antd';
import locale from 'antd/lib/locale/vi_VN';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { StatusTransactions } from 'constants/Status';
import { formatNumber } from 'helpers/Utils';
import useListParentChildRealeState from 'hooks/Project/useListParentChildRealeState';
import moment from 'moment';
import useEmailService from 'pages/EmailPage/email.service';
import React, { useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import useAnalysisService from '../analysis.service';

const { RangePicker } = DatePicker;
const { Title, Text, Link } = Typography;

export interface EmailFilter {
  page: number | string;
  limit: number | string;
  minDate: string;
  maxDate: string;
  typeEmail?: string;
  status?: string;
  typeProject?: string;
  childRealEstateId?: string;
  exportExcel?: boolean;
}

function EmailComponent(): JSX.Element {
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<EmailFilter>({
    page: page,
    limit: PaginateList.LIMIT_DEFAULT,
    minDate: moment()
      .add(-30, 'days')
      .format('DD/MM/YYYY'),
    maxDate: moment()
      .add(-1, 'days')
      .format('DD/MM/YYYY'),
    typeEmail: undefined,
    status: undefined,
    typeProject: undefined,
    childRealEstateId: undefined,
    exportExcel: undefined,
  });

  const { onFetchListParentChildRealeState, ListParentChildRealeState } = useListParentChildRealeState();
  useEffect(() => {
    onFetchListParentChildRealeState();
  }, []);

  const onChangeStatus = (data: string) => {
    setFilter({ ...filter, status: data, page: PaginateList.PAGE_DEFAULT });
  };

  const onChangeProject = (data: string) => {
    setFilter({ ...filter, typeProject: data, page: PaginateList.PAGE_DEFAULT });
  };

  const onChangeChildRealeState = (data: string) => {
    setFilter({ ...filter, childRealEstateId: data, page: PaginateList.PAGE_DEFAULT });
  };

  const onChangePicker = (data: any, dataString: any) => {
    if (dataString && dataString.length > 0) {
      setFilter({ ...filter, page: PaginateList.PAGE_DEFAULT, minDate: dataString[0], maxDate: dataString[1] });
    }
  };

  const onChangeEmail = (data: string) => {
    setFilter({ ...filter, typeEmail: data });
  };

  const { onGetEmail, dataEmail } = useEmailService();

  const { onGetEmailHome, dataEmailHome, totalEmail, loadingEmail } = useAnalysisService();

  useEffect(() => {
    onGetEmail();
  }, []);

  useEffect(() => {
    onGetEmailHome(filter);
  }, [filter]);

  const columns = [
    {
      title: 'STT',
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
    },
    {
      title: 'Số hợp đồng',
      dataIndex: 'code',
      render: (code: string) => {
        return <span>{code}</span>;
      },
    },
    {
      title: 'Giao dịch liên quan',
      dataIndex: 'transactionRelated',
      render: (transactionRelated: string) => {
        return <span>{transactionRelated || ''}</span>;
      },
    },
    {
      title: 'Tên dự án/BĐS lẻ',
      dataIndex: 'projectName',
      render: (projectName: string) => {
        return projectName;
      },
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      render: (price: number) => {
        return <span>{formatNumber(price)}</span>;
      },
    },
    {
      title: 'CTV Hỗ trợ',
      render: (data: any) => {
        return (
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + data?.supporterId} target="_blank">
            <Text underline style={{ color: '#12a0c0' }}>
              {data?.supporterName
                ? data?.supporterName.length > 30
                  ? `${data?.supporterName.substring(0, 20)} ...`
                  : data?.supporterName
                : ''}
            </Text>
          </Link>
        );
      },
    },
    {
      title: 'SĐT CTV Hỗ trợ',
      dataIndex: 'supporterPhone',
      render: (data: string) => {
        return data;
      },
    },
    {
      title: 'Phân khúc',
      dataIndex: 'typeProject',
      render: (typeProject: string) => {
        switch (typeProject) {
          case 'PROJECT_NEWS':
            return 'Bất động sản lẻ';
          case 'PROJECT':
            return 'Dự án';
          default:
            return '';
        }
      },
    },
    {
      title: 'Loại BĐS',
      dataIndex: 'childRealEstateName',
      render: (childRealEstateName: string) => {
        return childRealEstateName;
      },
    },
    {
      title: 'Thời gian gửi',
      dataIndex: 'createdAtMiliseconds',
      render: (createdAtMiliseconds: number) => {
        return <span>{moment(createdAtMiliseconds).format('HH:mm DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusTransaction',
      render: (statusTransaction: string) => {
        switch (statusTransaction) {
          case StatusTransactions.INTRODUCED:
            return <span>{translate('transaction.INTRODUCED')}</span>;
          case StatusTransactions.FOUND_SUPPORTER:
            return <span>{translate('transaction.FOUND_SUPPORTER')}</span>;
          case StatusTransactions.CONFIRMED_INFO:
            return <span>{translate('transaction.CONFIRMED_INFO')}</span>;
          case StatusTransactions.SUPPORTED:
            return <span>{translate('transaction.SUPPORTED')}</span>;
          case StatusTransactions.BOOKING:
            return <span>{translate('transaction.BOOKING')}</span>;
          case StatusTransactions.BOOKED:
            return <span>{translate('transaction.BOOKED')}</span>;
          case StatusTransactions.DEPOSIT_PENDING:
            return <span>{translate('transaction.DEPOSIT_PENDING')}</span>;
          case StatusTransactions.DEPOSIT:
            return <span>{translate('transaction.DEPOSIT')}</span>;
          case StatusTransactions.SUCCESS:
            return <span>{translate('transaction.SUCCESS')}</span>;
          case StatusTransactions.CANCEL_BOOKING:
            return <span>{translate('transaction.CANCEL_BOOKING')}</span>;
          case StatusTransactions.CANCEL_DEPOSIT:
            return <span>{translate('transaction.CANCEL_DEPOSIT')}</span>;
          case StatusTransactions.CUSTOMER_CANCEL:
            return <span>{translate('transaction.CUSTOMER_CANCEL')}</span>;
          default:
            return '';
        }
      },
    },
  ];

  const onPageChange = (page: number) => {
    setPage(page);
    setFilter({ ...filter, page: page });
  };

  const config: any = {
    height: 250,
    width: 250,
    autoFit: false,
    percent:
      dataEmailHome?.totalContractConfirm /
      (dataEmailHome?.totalContractUnConfirm + dataEmailHome?.totalContractConfirm),
    color: ['#5B8FF9', '#E8EDF3'],
  };

  const onClickExcel = () => {
    setFilter({ ...filter, exportExcel: true });
    setTimeout(() => {
      setFilter({ ...filter, exportExcel: undefined });
    }, 1000);
  };

  return (
    <>
      <Row>
        <Col span={24} style={{ marginBottom: '20px' }}>
          <Title level={2}>Xác nhận hợp đồng</Title>
        </Col>
        <Col span={4} style={{ marginBottom: '20px' }}>
          Thời gian
        </Col>
        <Col span={8} style={{ marginBottom: '20px' }}>
          <ConfigProvider locale={locale}>
            <RangePicker
              onChange={onChangePicker}
              allowClear={false}
              style={{ width: '300px' }}
              placeholder={['Thời gian bắt đầu', 'Thời gian kết thúc']}
              defaultValue={[
                moment(moment().add(-30, 'days'), 'DD/MM/YYYY') as any,
                moment(moment().add(-1, 'days'), 'DD/MM/YYYY') as any,
              ]}
              format="DD/MM/YYYY"
            />
          </ConfigProvider>
        </Col>
        <Col span={8} style={{ marginBottom: '20px' }}>
          {dataEmail && dataEmail.length > 0 && (
            <Select style={{ width: '100%' }} placeholder="Tất cả loại hợp đồng" onChange={onChangeEmail} allowClear>
              {dataEmail.map((e: any, i: number) => {
                if (i === 0) return null;
                return <Select.Option value={e.code}>{e.name}</Select.Option>;
              })}
            </Select>
          )}
        </Col>
        <Col span={11} style={{ display: 'grid', justifyContent: ' center' }}>
          {/* @ts-ignore */}
          <RingProgress {...config} />
          <div style={{ marginTop: '20px', fontSize: '20px', textAlign: 'center' }}>
            <b>Tỉ lệ xác nhận hợp đồng (%)</b>
          </div>
        </Col>
        <Col span={11} offset={2}>
          <div style={{ marginBottom: '20px', fontSize: '20px' }}>
            <b style={{ display: 'inline-block', width: '300px' }}>Tổng số hợp đồng đã gửi đi</b>
            <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
              {dataEmailHome?.totalContractUnConfirm + dataEmailHome?.totalContractConfirm}
            </span>
          </div>
          <div style={{ marginBottom: '20px', fontSize: '20px' }}>
            <b style={{ display: 'inline-block', width: '300px' }}>Hợp đồng đã xác nhận</b>
            <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
              {dataEmailHome?.totalContractConfirm || 0}
            </span>
          </div>
          <div style={{ marginBottom: '20px', fontSize: '20px' }}>
            <b style={{ display: 'inline-block', width: '300px' }}>Hợp đồng chưa xác nhận</b>
            <span style={{ color: '#c31615', fontWeight: 'bold', marginLeft: '80px', fontSize: '20px' }}>
              {dataEmailHome?.totalContractUnConfirm || 0}
            </span>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={20} style={{ fontSize: '20px' }}>
          <b>Danh sách các hợp đồng chưa xác nhận </b>
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={onClickExcel}>
            Xuất file excel
          </Button>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={6} style={{ marginBottom: '20px' }}>
          <Select style={{ width: '100%' }} placeholder="Trạng thái" onChange={onChangeStatus} allowClear>
            <Select.Option value={StatusTransactions.INTRODUCED}>{translate('transaction.INTRODUCED')}</Select.Option>
            <Select.Option value={StatusTransactions.FOUND_SUPPORTER}>
              {translate('transaction.FOUND_SUPPORTER')}
            </Select.Option>
            <Select.Option value={StatusTransactions.CONFIRMED_INFO}>
              {translate('transaction.CONFIRMED_INFO')}
            </Select.Option>
            <Select.Option value={StatusTransactions.SUPPORTED}>{translate('transaction.SUPPORTED')}</Select.Option>
            <Select.Option value={StatusTransactions.BOOKING}>{translate('transaction.BOOKING')}</Select.Option>
            <Select.Option value={StatusTransactions.BOOKED}>{translate('transaction.BOOKED')}</Select.Option>
            <Select.Option value={StatusTransactions.DEPOSIT_PENDING}>
              {translate('transaction.DEPOSIT_PENDING')}
            </Select.Option>
            <Select.Option value={StatusTransactions.DEPOSIT}>{translate('transaction.DEPOSIT')}</Select.Option>
            <Select.Option value={StatusTransactions.SUCCESS}>{translate('transaction.SUCCESS')}</Select.Option>
            <Select.Option value={StatusTransactions.CANCEL_BOOKING}>
              {translate('transaction.CANCEL_BOOKING')}
            </Select.Option>
            <Select.Option value={StatusTransactions.CANCEL_DEPOSIT}>
              {translate('transaction.CANCEL_DEPOSIT')}
            </Select.Option>
            <Select.Option value={StatusTransactions.CUSTOMER_CANCEL}>
              {translate('transaction.CUSTOMER_CANCEL')}
            </Select.Option>
          </Select>
        </Col>
        <Col span={6} style={{ marginBottom: '20px' }}>
          <Select style={{ width: '100%' }} placeholder="Phân khúc" onChange={onChangeProject} allowClear>
            <Select.Option value="PROJECT_NEWS">Bất động sản lẻ</Select.Option>
            <Select.Option value="PROJECT">Dự án</Select.Option>
          </Select>
        </Col>
        <Col span={6} style={{ marginBottom: '20px' }}>
          {ListParentChildRealeState && ListParentChildRealeState.length > 0 && (
            <Select style={{ width: '100%' }} placeholder="Loại BĐS" onChange={onChangeChildRealeState} allowClear>
              {ListParentChildRealeState.map((data: any) => {
                return (
                  <Select.Option value={data._id}>
                    {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                  </Select.Option>
                );
              })}
            </Select>
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: '15px' }}>
        {loadingEmail ? (
          <PageLoading />
        ) : (
          <Col span={24}>
            <Table
              key={1}
              dataSource={(dataEmailHome && dataEmailHome.data) || []}
              columns={columns}
              pagination={false}
              scroll={{ x: 'auto' }}
              rowKey="_id"
            />
          </Col>
        )}
        <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <div className="d-flex justify-content-end pagination">
            <Pagination
              showSizeChanger={false}
              total={totalEmail}
              current={page}
              defaultCurrent={page}
              defaultPageSize={PaginateList.LIMIT_DEFAULT}
              onChange={onPageChange}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default EmailComponent;
