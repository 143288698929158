/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Form, Select } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { StatusType } from 'constants/Status';
import useLitsHouseTemplate from 'pages/HouseTemplate/useHouseTemplatehooks/useHouseTemplatehooks';
import React, { ChangeEvent, useEffect } from 'react';
import { translate } from 'translate/language';
import { BaseStatusCollab } from '../types/ctv.types';
import { CtvSelect } from './CtvSelect';

const { Option } = Select;
interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status?: BaseStatusCollab) => void;
  onChangeProject: (projectId: SelectValue) => void;
}

function HeaderCtv(props: Props): JSX.Element {
  const { onSearch, onChangeStatus, onChangeProject } = props;
  const { listProject, onListProject } = useLitsHouseTemplate();

  // Danh sách dự án
  useEffect(() => {
    onListProject({
      status: StatusType.ACTIVE,
    });
  }, []);

  // Tìm kiếm theo Tên,ID,Số điện thoại
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  // Select Trạng thái hoạt động
  const handChangeStatus = (value: BaseStatusCollab | '') => {
    onChangeStatus(value !== '' ? value : undefined);
  };

  // Select dự án theo ID
  const handChangeProject = (value: SelectValue) => {
    onChangeProject(value);
  };

  return (
    <>
      <Row gutter={20} style={{ paddingBottom: 20 }}>
        <Col className="gutter-row" span={7}>
          <Input
            size="small"
            placeholder={translate('ctv.search')}
            suffix={<SearchOutlined />}
            onChange={handleSearch}
          />
        </Col>
        <Col className="gutter-row" span={4}>
          <div>
            <Form.Item name="customer_status">
              <Select placeholder="Dự án" onChange={handChangeProject}>
                <Option value="">{translate('status.all')}</Option>
                {listProject &&
                  listProject.length > 0 &&
                  listProject.map(data => {
                    return (
                      <Option value={data._id}>
                        {data.name.length > 100 ? `${data.name.substring(0, 120)} ...` : data.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col className="gutter-row" span={4}>
          <CtvSelect
            status={undefined}
            onChangeStatus={handChangeStatus}
            showAll
            isFillter
            isCollaborator={undefined}
          />
        </Col>
      </Row>
    </>
  );
}

export default HeaderCtv;
