import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { FilterTransactions, ProjectNewsTransactionsRes } from 'pages/SaleAndRental/transactions.type';

const URL = '/project-news/transaction/commission/';

function useProjectNewsTransactionCommissionService() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProjectNewsTransactionsRes[]>([]);
  const [total, setTotal] = useState<number>();

  const onGetData = async (query: FilterTransactions) => {
    setLoading(true);
    const res: any = await ApiHelper.fetch(URL, query);
    if (res && res.status === StatusResponse.SUCCESS) {
      setData(res.data);
      setTotal(res.total);
    }
    setLoading(false);
  };

  return {
    data,
    total,
    loading,
    onGetData,
  };
}

export default useProjectNewsTransactionCommissionService;
