import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

function useCreateCustomer(): [(request: any) => Promise<void>, boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const onCreateCustomer = async (request: any) => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.post(BASE_URL.ADD_CUSTOMER, request);

      setLoading(false);
      if (res && res.status === 'USER_INTRODUCE_NOT_FOUND') {
        ToastError({
          message: translate('message_type.failed'),
          description: res.message,
        });
        return;
      }

      if (res && res.status === StatusResponse.TAX_CODE_ALREADY_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.TAX_CODE_ALREADY_EXISTS'),
        });
        return;
      }
      if (res && res.status === StatusResponse.REFERRAL_CODE_DO_NOT_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.REFERRAL_CODE_DO_NOT_EXISTS'),
        });
        return;
      }
      if (res && res.status === StatusResponse.PHONE_NUMBER_ALREADY_EXISTS) {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.PHONE_NUMBER_ALREADY_EXISTS'),
        });
        return;
      }
      if (res && res.status === StatusResponse.EMAIL_ALREADY_EXISTS) {
        setLoading(false);
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.EMAIL_ALREADY_EXISTS'),
        });
        return;
      }
      if (res && res.status === StatusResponse.IDENTIFY_ALREADY_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('messages.IDENTIFY_ALREADY_EXISTS'),
        });
        return;
      }
      if (res && res.status === StatusResponse.AGENT_ID_ALREADY_EXISTS) {
        ToastError({
          message: translate('message_type.failed'),
          description: translate('AgentID đã tồn tại'),
        });
        return;
      }
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.add_new_success'),
        });
        history.push(ROUTER_PATH.ACCOUNT_CUSTOMER);
        return;
      }
      ToastError({
        message: translate('message_type.failed'),
        description: 'Lỗi khi tạo khách hàng',
      });
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: 'Lỗi khi tạo khách hàng',
      });
      setLoading(false);
    }
  };
  return [onCreateCustomer, loading];
}

export default useCreateCustomer;
