/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
import { Form, Select, Table, Typography } from 'antd';
import { Option } from 'antd/lib/mentions';
import useListCustomer from 'hooks/Customer/useListCustomer';
import _ from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { ROUTER_PATH } from 'routes/Path';
import { User } from 'types/response/UserResponse';
import styles from '../css/sale-and-rent-create.module.scss';

interface Props {
  className?: any;
}

function SelectContactComponent({ className }: Props, ref: any): ReactElement {
  const [idContact, setIdContact] = useState<string[]>([]);
  const [dataContact, setDataContact] = useState<any[]>([]);
  const { Text, Link } = Typography;

  const onHandChangeContact = (value: any) => {
    setIdContact(value);
  };
  const [onFetchListCustomer, listCustomer] = useListCustomer();

  useEffect(() => {
    onFetchListCustomer({ status: 'ACTIVE' });
  }, []);

  React.useImperativeHandle(
    ref,
    () => ({
      onHandChangeContact: onHandChangeContact,
    }),
    [],
  );

  useEffect(() => {
    if (listCustomer && listCustomer.data) {
      const dataMap = idContact.map((data: string) => {
        const temp = listCustomer.data.find((e: any) => e._id === data);
        return temp;
      });
      setDataContact(_.uniq([...dataMap, ...dataContact]));
    }
  }, [idContact, listCustomer]);

  const onDeselect = (e: any) => {
    if (listCustomer && listCustomer.data) {
      const dataMap = listCustomer.data.map((item: User) => item._id !== e);

      setDataContact(dataMap);
    }
  };

  const columnsContact = [
    {
      title: 'Họ tên',
      render: (data: any) => {
        return (
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + data?._id} target="_blank">
            <Text underline style={{ color: '#12a0c0' }}>
              {data?.name}
            </Text>
          </Link>
        );
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      render: (phone: number) => {
        return <Text>{phone}</Text>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email: string) => {
        return <Text>{email}</Text>;
      },
    },
  ];

  return (
    <>
      {/* <Row justify="space-between" align="middle" style={{ width: '84%' }}> */}
      <Form.Item
        labelCol={{ md: 8, xl: 8, xs: 24 }}
        wrapperCol={{ md: 12, xs: 24 }}
        name="contacts"
        style={{ justifyContent: 'center', display: 'flex', marginTop: 20 }}
        label="Thông tin liên hệ"
        labelAlign="left"
        rules={[
          {
            message: 'Vui lòng chọn thông tin liên hệ',
            type: 'array',
          },
        ]}
      >
        <Select
          placeholder="Thông tin liên hệ"
          mode="multiple"
          maxTagTextLength={50}
          onChange={onHandChangeContact}
          onDeselect={onDeselect}
          optionFilterProp="children"
          filterOption={(input: string, option: any | undefined) =>
            option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {listCustomer &&
            listCustomer.data &&
            listCustomer.data.map((data: any) => {
              return (
                <Option value={data._id} key={data._id}>
                  {data.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      {/* </Row> */}
      {/* Table thông tin liên hệ */}
      {dataContact && dataContact.length > 0 && (
        <Form.Item
          labelAlign="left"
          className={styles.StylesCenter}
          label="   "
          style={{ justifyContent: 'center', display: 'flex' }}
          labelCol={{ md: 8, xl: 8, xs: 24 }}
          wrapperCol={{ md: 12, xs: 24 }}
        >
          <Table scroll={{ x: 'auto' }} dataSource={dataContact} columns={columnsContact} pagination={false} />
        </Form.Item>
      )}
    </>
  );
}

export default React.forwardRef(SelectContactComponent);
