/* eslint-disable no-useless-concat */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-multi-assign */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getUrlImageCk } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';

export function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return new MyUploadAdapter(loader);
  };
}

export class MyUploadAdapter {
  loader: any = undefined;
  xhr: any = undefined;

  constructor(props: any) {
    // CKEditor 5's FileLoader instance.
    this.loader = props;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      this._initRequest();
      this._initListeners(resolve, reject);
      this._sendRequest();
    });
  }

  // Aborts the upload process.
  abort() {
    this.xhr?.abort();
  }

  // Example implementation using XMLHttpRequest.
  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());

    xhr.open('POST', `${process.env.REACT_APP_API_DOMAIN}/media`, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');

    const token = `Bearer ${LocalStorageHelper.get(StorageKey.TOKEN)}`;
    xhr.setRequestHeader('Authorization', token);
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve: any, reject: any) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = "Couldn't upload file:" + ` ${loader.file.name}.`;
    xhr.addEventListener('error', () => reject(genericErrorText));
    xhr.addEventListener('abort', () => reject());
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText);
      }
      console.log(response.data);
      // Đường dẫn hình ảnh
      resolve({
        default: getUrlImageCk(response.data),
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt: any) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest() {
    const data = new FormData();
    this.loader.file.then((result: any) => {
      console.log(result);
      data.append('file', result);
      this.xhr.send(data);
    });
  }
}
