/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/self-closing-comp */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { ToastError } from 'components/Toast';
import { PERMISSION_NAME } from 'constants/PermissionName';
import { StatusResponse } from 'constants/Status';
import { getUrlExport } from 'helpers/getImageUrl';
import LocalStorageHelper, { StorageKey } from 'helpers/LocalStorageHelper';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

const { Option } = Select;

interface Props {
  onSearch: (search: string) => void;
  onChangeStatus: (status: string) => void;
}

function FilterHeaderAdmin(props: Props): JSX.Element {
  const [form] = Form.useForm();
  const { onSearch, onChangeStatus } = props;
  const history = useHistory();

  // Tìm kiếm theo Tên,ID,Số điện thoại
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  // export admin
  const ExportAdmin = async () => {
    const res: any = await ApiHelper.post(BASE_URL.EXPORT_ADMIN, null, true);
    if (res.status === StatusResponse.SUCCESS) {
      window.open(getUrlExport(res.data));
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.error'),
      });
    }
  };

  // Select Trạng thái hoạt động
  const handChangeStatus = (value: string) => {
    onChangeStatus(value);
  };
  // Thêm mới người dùng
  const addNewCustomer = () => {
    LocalStorageHelper.remove(StorageKey.CACHE_FORM_CREATE_ADMIN);
    history.push(ROUTER_PATH.ADD_ADMIN);
  };
  return (
    <>
      <Form form={form}>
        <Row gutter={20}>
          <Col className="gutter-row" span={7}>
            <div>
              <Form.Item name="search">
                <Input
                  size="small"
                  placeholder="Tên nhân viên, SĐT"
                  onChange={handleSearch}
                  suffix={<SearchOutlined />}
                />
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div>
              <Form.Item name="customer_status">
                <Select placeholder={translate('status.name')} onChange={handChangeStatus}>
                  <Option value="">{translate('status.all')}</Option>
                  <Option value="ACTIVE">{translate('status.show')}</Option>
                  <Option value="INACTIVE">{translate('status.hidden')}</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col className="gutter-row" span={4}></Col>
          <Col className="gutter-row" span={6}>
            {/* Xuất Excel */}
            {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).export ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={ExportAdmin}>
                  {translate('customer.export_excel')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col className="gutter-row" span={3}>
            {/* Thêm mới */}
            {getCheckPermissionUser(PERMISSION_NAME.ACCOUNTS_CMS).create ? (
              <div>
                <Button style={{ width: '100%' }} type="primary" onClick={addNewCustomer}>
                  {translate('action.add_new')}
                </Button>
              </div>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default FilterHeaderAdmin;
