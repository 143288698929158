/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-native/no-inline-styles */
import { Button, Col, Form, Input, List, Pagination, Row, Select, Space, Table, Tag, Typography } from 'antd';
import PaginateList from 'constants/PaginateList';
import { StatusTransactions } from 'constants/Status';
import { formatNumberPrice } from 'helpers/FormatPrice';
import moment from 'moment';
import { useQuery } from 'pages/House/house.screen';
import React, { useEffect, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';
import CustomerItem from './components/customer-chat-item';
import InputChatItem from './components/customer-project-item';
import useCustomerProjectChatService from './customer-project-transaction-chat.services';
import './customer-project.scss';
import useCustomerProjectService from './customer-project.service';
import { ListHouseReservationInfo } from './customer-project.type';
import { Message } from './MessageType';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ToastError } from 'components/Toast';
import { getCheckPermissionUser } from 'helpers/tokenHelpers';
import { PERMISSION_NAME } from 'constants/PermissionName';

const { Text, Link, Title } = Typography;
const { Option } = Select;

function ReservationProjectDetail(): JSX.Element {
  const params: { id: string } = useParams();
  const type = useQuery().get('type');
  const [form] = Form.useForm();
  const isEdit = params && params.id;
  const [filterMessage, setFilterMessage] = React.useState<any>({ transactionId: params.id, limit: 20, type: 'CMS' });

  const [page, setPage] = React.useState<number>(PaginateList.PAGE_DEFAULT);
  const [statusTransaction, setStatusTransaction] = React.useState<string>('');
  const [isChange, setIsChange] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<any>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
  });

  const [feeDeposit, setFeeDeposit] = React.useState<any[]>([]);

  const [blockId, setBlockId] = React.useState<string>('');
  const {
    onDetailTransaction,
    updateViewMessage,
    detailTransaction,
    updateStatus,
    onGetHistory,
    dataHistory,
    onGetRelated,
    dataRelated,
    totalRelated,
    ListParentChildRealeState,
    ListBlock,
    getFloor,
    ListFloor,
    getHouse,
    ListHouse,
  } = useCustomerProjectService();

  useEffect(() => {
    if (isEdit) {
      onGetRelated(params.id, filter);
    }
  }, [isEdit, filter]);

  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  const {
    history,
    onGetListHistoryMess,
    stopLoadmore,
    ListMember,
    getListMemberPag,
    updateMessage,
  } = useCustomerProjectChatService();

  useEffect(() => {
    if (isEdit) {
      onDetailTransaction(params.id);

      if (type === 'PAG') {
        getListMemberPag(params.id);
      }
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && filterMessage) {
      onGetListHistoryMess(filterMessage);
    }
  }, [filterMessage]);

  const onLoadMore = (e: any) => {
    if (e && !stopLoadmore) {
      setFilterMessage({
        ...filterMessage,
        after: history[history.length - 1]._id,
      });
    }
  };

  useEffect(() => {
    if (isEdit) {
      onGetHistory(params.id);
    }
  }, [isEdit]);

  const onUpdateMessage = (idMessage: string, isHide: boolean) => {
    const parasm = {
      transactionId: params.id,
      idMessage,
      isHide,
    };
    updateMessage(parasm);
  };
  const onUpdateViewMessage = (userId: string) => {
    const newParams = {
      transactionId: params.id,
      userId,
    };
    updateViewMessage(newParams);
  };

  const columns = [
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status: string) => {
        switch (status) {
          case 'INTRODUCED':
            return translate('transaction.INTRODUCED');
          case 'FOUND_SUPPORTER':
            return translate('transaction.FOUND_SUPPORTER');
          case 'CONFIRMED_INFO':
            return translate('transaction.CONFIRMED_INFO');
          case 'SUPPORTED':
            return translate('transaction.SUPPORTED');
          case 'BOOKING':
            return translate('transaction.BOOKING');
          case 'BOOKED':
            return translate('transaction.BOOKED');
          case 'DEPOSIT_PENDING':
            return translate('transaction.DEPOSIT_PENDING');
          case 'DEPOSIT':
            return translate('transaction.DEPOSIT');
          case 'SUCCESS':
            return translate('transaction.SUCCESS');
          case 'CANCEL_BOOKING':
            return translate('transaction.CANCEL_BOOKING');
          case 'CANCEL_DEPOSIT':
            return translate('transaction.CANCEL_DEPOSIT');
          case 'CUSTOMER_CANCEL':
            return translate('transaction.CUSTOMER_CANCEL');
          case 'REQUEST_DEPOSIT_PENDING':
            return 'Đang xác nhận chuyển cọc';
          case 'REQUEST_DEPOSIT':
            return 'Đã Xác Nhận chuyển cọc';
          default:
            return '';
        }
      },
    },
    {
      title: 'Ngày được set',
      dataIndex: 'createdAt',
      render: (createdAt: moment.Moment) => {
        return <>{moment(createdAt).format('HH:mm DD-MM-YYYY')}</>;
      },
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
    },
  ];

  const columnsDeal = [
    {
      title: 'STT',
      dataIndex: 'currentReservationNumber',
      render: (currentReservationNumber: any) => {
        return <>{currentReservationNumber || ''}</>;
      },
    },
    {
      title: 'Block',
      dataIndex: 'houseInfo',
      render: (houseInfo: any) => {
        return <span>{houseInfo && houseInfo.blockInfo ? houseInfo.blockInfo.name : ''}</span>;
      },
    },
    {
      title: 'Tầng',
      dataIndex: 'houseInfo',
      render: (houseInfo: any) => {
        return <span>{houseInfo && houseInfo.floor ? houseInfo.floor : ''}</span>;
      },
    },
    {
      title: 'Mã căn',
      dataIndex: 'houseInfo',
      render: (houseInfo: any) => {
        return <span>{houseInfo && houseInfo.code ? houseInfo.code : ''}</span>;
      },
    },
    {
      title: 'Giao dịch',
      dataIndex: 'code',
      render: (code: string, data: any) => {
        return (
          <>
            {data && (
              <Link href={ROUTER_PATH.DETAIL_PROJECT_CUSTOMER + data._id} target="_blank">
                <Text underline style={{ color: '#12a0c0' }}>
                  {code}
                </Text>
              </Link>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (isEdit && detailTransaction) {
      form.setFieldsValue({
        status: detailTransaction.status,
        note: detailTransaction.note,
      });
    }
    setStatusTransaction(detailTransaction?.status);
  }, [detailTransaction]);

  const onFinish = (value: any) => {
    const body: any = {
      status: value.status,
      note: value.note,
    };
    if (
      (value.status === StatusTransactions.DEPOSIT_PENDING || value.status === StatusTransactions.DEPOSIT) &&
      !value.house &&
      detailTransaction.houseDepositInfo.length < 1
    ) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('Vui lòng chọn căn hộ'),
      });
      return;
    }
    if (value.status === StatusTransactions.BOOKING && !value.house) {
      body.childRealEstateBookingId = detailTransaction.childRealEstateBooking;
      body.status = StatusTransactions.BOOKED;
    }
    if (value.realEstate) {
      body.childRealEstateBookingId = value.realEstate;
      body.status =
        value.status === StatusTransactions.SUPPORTED ? StatusTransactions.SUPPORTED : StatusTransactions.BOOKED;
    }
    if (
      !value.house &&
      (value.status === StatusTransactions.DEPOSIT_PENDING || value.status === StatusTransactions.DEPOSIT) &&
      detailTransaction.houseDepositInfo.length > 0
    ) {
      const newdata = detailTransaction.houseDepositInfo.map((item: any) => {
        return item._id;
      });
      body.houseIds = newdata;
      if (value.status === StatusTransactions.DEPOSIT_PENDING) {
        body.status = StatusTransactions.DEPOSIT;
        body.sendContractEmail = true;
      }
      if (value.status === StatusTransactions.DEPOSIT) {
        body.status = StatusTransactions.DEPOSIT;
        body.sendContractEmail = false;
      }
    }
    if (value.house) {
      const newdata = value.house.map((item: any) => {
        return item.houseId;
      });
      body.houseIds = newdata;
      if (value.status === StatusTransactions.DEPOSIT_PENDING) {
        body.status = StatusTransactions.DEPOSIT;
        body.sendContractEmail = true;
      }
      if (value.status === StatusTransactions.DEPOSIT) {
        body.status = StatusTransactions.DEPOSIT;
        body.sendContractEmail = false;
      }
    }
    console.log('body: ', body);
    updateStatus(params.id, body);
  };

  const historyBack = useHistory();

  const backHistory = () => {
    historyBack.goBack();
  };

  const serialQuantity = (qty: number, serial: number) => {
    let text: any = '';
    for (let index = serial; index < serial + qty; index++) {
      text += `${index}-`;
    }

    return <span>{text.slice(0, -1)}</span>;
  };

  const onClick = (value: string) => {
    setStatusTransaction(value);
    setIsChange(true);
  };

  const onSelectBlock = (value: string) => {
    setBlockId(value);
    getFloor(value);
  };

  const onSelectFloor = (value: string) => {
    getHouse(blockId, value);
  };

  const onSelectHouse = (value: string, arrr: any) => {
    // setFeeDeposit(arrr.key);
    // console.log('arrr: ', arrr);
    // console.log('value: ', value);
    if (!value) return;

    const house: any = ListHouse.find((it: any) => it._id === value);

    if (!house) return;

    const houses = [...form.getFieldValue('house')];

    const index = houses.findIndex((it: any) => it.houseId === value);

    if (index > -1) {
      houses.splice(index, 1, {
        ...houses[index],
        housePrice: house.depositMoney,
      });

      form.setFieldsValue({
        house: houses,
      });

      setFeeDeposit(houses);
    }
  };

  return (
    <>
      {' '}
      {getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_SEQUENCE).create ||
      getCheckPermissionUser(PERMISSION_NAME.PROJECTS_CUSTOMERS_SEQUENCE).edit ? (
        <Row>
          <Col span={24}>
            {detailTransaction && (
              <Form form={form} onFinish={onFinish}>
                {/* Tên dự án */}
                <Row justify="center">
                  <Col span={8} style={{ textAlign: 'right', marginBottom: '20px' }}>
                    Số thứ tự :
                    {detailTransaction.quantity > 1 && detailTransaction.serial ? (
                      <b style={{ fontSize: '30px' }}>
                        {serialQuantity(detailTransaction.quantity, detailTransaction.serial)}
                      </b>
                    ) : (
                      <b style={{ fontSize: '30px' }}>{detailTransaction.serial || '--'}</b>
                    )}
                  </Col>
                  <Col span={20}>
                    <Row>
                      <Col span={8}>{translate('customer_project.name_project')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.projectInfo && detailTransaction.projectInfo.name}</b>
                      </Col>
                    </Row>

                    {/* Tên khách hàng */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.name_customer')}</Col>
                      <Col span={14}>
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailTransaction.userInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }} className="fontSize20">
                            {detailTransaction.userInfo && detailTransaction.userInfo.name}
                          </Text>
                        </Link>
                      </Col>
                    </Row>

                    {/* Số điện thoại */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.phone')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.userInfo && detailTransaction.userInfo.phone}</b>
                      </Col>
                    </Row>

                    {/* Số CMND */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.CMND')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.userInfo && detailTransaction.userInfo.identify}</b>
                      </Col>
                    </Row>

                    {/* CTV giới thiệu */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.ctv_introducer')}</Col>
                      <Col span={14} className="fontSize20">
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailTransaction.introducerInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }}>
                            {detailTransaction.introducerInfo && detailTransaction.introducerInfo.name}
                          </Text>
                        </Link>
                      </Col>
                    </Row>

                    {/* Số điện thoại CTV giới thiệu */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.phone_ctv_introducer')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.introducerInfo && detailTransaction.introducerInfo.phone}</b>
                      </Col>
                    </Row>

                    {/* CTV hỗ trợ */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.ctv_supporter')}</Col>
                      <Col span={14} className="fontSize20">
                        <Link href={ROUTER_PATH.EDIT_CUSTOMER + detailTransaction.supporterInfo?._id} target="_blank">
                          <Text underline style={{ color: '#12a0c0' }}>
                            {detailTransaction.supporterInfo && detailTransaction.supporterInfo.name}
                          </Text>
                        </Link>
                      </Col>
                    </Row>

                    {/* Số điện thoại CTV hỗ trợ */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.phone_ctv_supporter')}</Col>
                      <Col span={14} className="fontSize20">
                        <b>{detailTransaction.supporterInfo && detailTransaction.supporterInfo.phone}</b>
                      </Col>
                    </Row>

                    {/* Loại bất động sản */}
                    {(statusTransaction === StatusTransactions.SUPPORTED &&
                      !isChange &&
                      !detailTransaction.chidRealEstateInfo) ||
                    (statusTransaction === StatusTransactions.BOOKING &&
                      isChange &&
                      !detailTransaction.chidRealEstateInfo) ? (
                      <Row className="marginTop">
                        <Col span={19}>
                          <Form.Item
                            name="realEstate"
                            label="Loại bất động sản"
                            labelCol={{ sm: 10 }}
                            labelAlign="left"
                            rules={[{ required: true, message: 'Loại bất động sản không để trống' }]}
                          >
                            <Select placeholder={translate('block_area.realEstate')} allowClear>
                              {ListParentChildRealeState
                                ? ListParentChildRealeState.map((data: any, key: number) => {
                                    return (
                                      <Option value={data._id} key={data._id} title={data.child}>
                                        {data.child.length > 40 ? `${data.child.substring(0, 40)} ...` : data.child}
                                      </Option>
                                    );
                                  })
                                : ''}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="marginTop">
                        <Col span={8}>Loại bất động sản</Col>
                        <Col span={14} className="fontSize20">
                          {(detailTransaction.chidRealEstateInfo && (
                            <b>{detailTransaction.chidRealEstateInfo.name}</b>
                          )) ||
                            (detailTransaction.realEstateInfo && detailTransaction.realEstateInfo.length > 0 && (
                              <b>{detailTransaction.realEstateInfo[0].name}</b>
                            ))}
                        </Col>
                      </Row>
                    )}

                    {/* Mã căn hộ */}
                    {(((statusTransaction === StatusTransactions.DEPOSIT ||
                      statusTransaction === StatusTransactions.DEPOSIT_PENDING) &&
                      isChange) ||
                      (statusTransaction === StatusTransactions.BOOKED && !isChange)) &&
                    detailTransaction.houseDepositInfo.length < 1 ? (
                      <Row>
                        <Col span={8}>Mã căn</Col>
                        <Col span={14}>
                          <Form.List name="house">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(({ key, name, fieldKey, ...restField }: any, index: number) => {
                                  return (
                                    <Fragment key={key}>
                                      <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'Block']}
                                          fieldKey={[fieldKey, 'Block']}
                                          rules={[{ required: true, message: 'Block không để trống' }]}
                                        >
                                          <Select
                                            style={{ width: 200 }}
                                            placeholder="Block"
                                            onSelect={onSelectBlock}
                                            allowClear
                                          >
                                            {ListBlock
                                              ? ListBlock.map((data: any) => {
                                                  return (
                                                    <Option value={data._id} key={data._id} title={data.name}>
                                                      {data.name}
                                                    </Option>
                                                  );
                                                })
                                              : ''}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          style={{ width: 150 }}
                                          name={[name, 'floorId']}
                                          fieldKey={[fieldKey, 'floorId']}
                                          rules={[{ required: true, message: 'Tầng không để trống' }]}
                                        >
                                          <Select placeholder="Tầng" allowClear onSelect={onSelectFloor}>
                                            {ListFloor
                                              ? ListFloor.map((data: any) => {
                                                  return (
                                                    <Option value={data.name} key={data.name} title={data.name}>
                                                      {data.name}
                                                    </Option>
                                                  );
                                                })
                                              : ''}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          {...restField}
                                          style={{ width: 150 }}
                                          name={[name, 'houseId']}
                                          fieldKey={[fieldKey, 'houseId']}
                                          rules={[{ required: true, message: 'Căn hộ không được trống' }]}
                                        >
                                          <Select
                                            placeholder="Căn hộ"
                                            allowClear
                                            // onSelect={onSelectHouse}
                                            onChange={onSelectHouse}
                                          >
                                            {ListHouse
                                              ? ListHouse.map((data: any, index: number) => {
                                                  return (
                                                    <Option key={data._id} value={data._id} title={data.name}>
                                                      {data.name}
                                                    </Option>
                                                  );
                                                })
                                              : ''}
                                          </Select>
                                        </Form.Item>
                                        <MinusCircleOutlined
                                          onClick={() => {
                                            remove(index);
                                            try {
                                              const feeDepositTemp = [...feeDeposit];
                                              feeDepositTemp.splice(index, 1);
                                              setFeeDeposit(feeDepositTemp);
                                            } catch (error) {}
                                          }}
                                        />
                                      </Space>

                                      {feeDeposit.length > index && feeDeposit[index]?.housePrice && (
                                        <Row style={{ marginBottom: 20, marginTop: -20 }}>
                                          <Col>Tiền cọc tạm tính: </Col>
                                          <Col style={{ color: 'red', marginLeft: 10 }}>
                                            {formatNumberPrice(feeDeposit[index].housePrice)}
                                          </Col>
                                        </Row>
                                      )}
                                    </Fragment>
                                  );
                                })}
                                {fields.length < detailTransaction.quantity ? (
                                  <Form.Item>
                                    <Button
                                      type="primary"
                                      style={{ width: 200 }}
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}
                                    >
                                      Thêm
                                    </Button>
                                  </Form.Item>
                                ) : null}
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </Row>
                    ) : (
                      // <Row className="marginTop">
                      //   <Col span={8}>Mã căn</Col>
                      //   <Col span={14} className="fontSize20">
                      //     {(detailTransaction.listHouseReservationInfo &&
                      //       detailTransaction.listHouseReservationInfo.length > 0 &&
                      //       detailTransaction.listHouseReservationInfo.map((item: ListHouseReservationInfo) => {
                      //         return (
                      //           <>
                      //             <Link href={ROUTER_PATH.EDIT_HOUSE_LOTS + item._id} target="_blank">
                      //               <Text underline style={{ color: '#12a0c0' }}>
                      //                 <span key={item._id}>{item.code}</span>
                      //               </Text>
                      //             </Link>
                      //           </>
                      //         );
                      //       })) ||
                      //       '--'}
                      //   </Col>
                      // </Row>
                      <Row className="marginTop">
                        <Col span={8}>Mã căn</Col>
                        <Col span={16} className="fontSize20">
                          {(detailTransaction.houseDepositInfo &&
                            detailTransaction.houseDepositInfo.length > 0 &&
                            detailTransaction.houseDepositInfo.map((item: any) => {
                              return (
                                <>
                                  <Row>
                                    <Form.Item style={{ marginRight: 10 }}>
                                      <Input
                                        value={item.blockInfo.name}
                                        disabled
                                        style={{ color: '#333333', fontWeight: 'bold' }}
                                      />
                                    </Form.Item>
                                    <Form.Item style={{ marginRight: 10 }}>
                                      <Input
                                        value={item.floor}
                                        disabled
                                        style={{ color: '#333333', fontWeight: 'bold' }}
                                      />
                                    </Form.Item>
                                    <Form.Item style={{ marginRight: 10 }}>
                                      <Input
                                        value={item.name}
                                        disabled
                                        style={{ color: '#333333', fontWeight: 'bold' }}
                                      />
                                    </Form.Item>
                                  </Row>
                                  <Row style={{ marginBottom: 20, marginTop: -20 }}>
                                    <Col>Tiền cọc tạm tính: </Col>
                                    <Col style={{ color: 'red', marginLeft: 10 }}>
                                      {formatNumberPrice(item.depositMoney)}
                                    </Col>
                                  </Row>
                                </>
                              );
                            })) ||
                            '--'}
                        </Col>
                      </Row>
                    )}

                    {/* Giá bán */}
                    {/* <Row className="marginTop">
                  <Col span={8}>Khoảng giá</Col>
                  {detailTransaction.price ? (
                    <Col span={14} className="fontSize20">
                      <b>
                        {formatNumberPrice(detailTransaction.price?.minPrice)} -
                        {formatNumberPrice(detailTransaction.price?.maxPrice)}
                      </b>
                    </Col>
                  ) : (
                    <b>__</b>
                  )}
                </Row> */}

                    {/* <Row className="marginTop">
                  <Col span={8}>Khoảng diện tích</Col>
                  {detailTransaction.area ? (
                    <Col span={14} className="fontSize20">
                      <b>
                        {formatNumberPrice(detailTransaction.area?.minArea)} -
                        {formatNumberPrice(detailTransaction.area?.maxArea)}
                      </b>
                    </Col>
                  ) : (
                    <b>__</b>
                  )}
                </Row> */}

                    <Row className="marginTop">
                      <Col span={8}>Số lượng căn</Col>
                      <Col span={14} className="fontSize20">
                        <b> {detailTransaction.quantity}</b>
                      </Col>
                    </Row>

                    <Row className="marginTop">
                      <Col span={8}>Tiền dặt giữ chỗ</Col>
                      {detailTransaction.booking && (
                        <Col span={14} className="fontSize20">
                          <b> {formatNumberPrice(detailTransaction.booking.bookingPrice || 0)}</b>
                        </Col>
                      )}
                    </Row>

                    {/* Trạng thái giao dịch */}
                    <Row className="marginTop">
                      <Col span={8}>{translate('customer_project.status_detail')}</Col>
                      <Col span={5}>
                        <Form.Item name="status">
                          <Select style={{ width: '100%' }} onSelect={onClick}>
                            <Option
                              value={StatusTransactions.INTRODUCED}
                              disabled={detailTransaction.status === StatusTransactions.BOOKED}
                            >
                              {translate('transaction.INTRODUCED')}
                            </Option>
                            {/* Giới thiệu khách hàng */}
                            <Option
                              value={StatusTransactions.FOUND_SUPPORTER}
                              disabled={detailTransaction.status === StatusTransactions.BOOKED}
                            >
                              {translate('transaction.FOUND_SUPPORTER')}
                            </Option>
                            {/* Yêu cầu hỗ trợ */}
                            <Option
                              value={StatusTransactions.CONFIRMED_INFO}
                              disabled={detailTransaction.status === StatusTransactions.BOOKED}
                            >
                              {translate('transaction.CONFIRMED_INFO')}
                            </Option>
                            {/* CTV hỗ trợ xác minh */}
                            <Option
                              value={StatusTransactions.SUPPORTED}
                              disabled={detailTransaction.status === StatusTransactions.BOOKED}
                            >
                              {translate('transaction.SUPPORTED')}
                            </Option>
                            {/* Hỗ trợ và tư vấn bán hàng */}
                            <Option
                              value={StatusTransactions.BOOKING}
                              disabled={
                                detailTransaction.status === StatusTransactions.FOUND_SUPPORTER ||
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.BOOKING')}
                            </Option>
                            {/* Yêu cầu giữ chỗ */}
                            <Option
                              value={StatusTransactions.BOOKED}
                              disabled={
                                detailTransaction.status === StatusTransactions.FOUND_SUPPORTER ||
                                detailTransaction.status === StatusTransactions.CONFIRMED_INFO ||
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.DEPOSIT_PENDING ||
                                detailTransaction.status === StatusTransactions.DEPOSIT ||
                                detailTransaction.status === StatusTransactions.SUCCESS ||
                                detailTransaction.status === StatusTransactions.CANCEL_BOOKING ||
                                detailTransaction.status === StatusTransactions.CANCEL_DEPOSIT ||
                                detailTransaction.status === StatusTransactions.CUSTOMER_CANCEL
                              }
                            >
                              {translate('transaction.BOOKED')}
                            </Option>
                            {/* Đã giữ chỗ */}
                            <Option
                              value={StatusTransactions.DEPOSIT_PENDING}
                              disabled={detailTransaction.status === StatusTransactions.SUPPORTED}
                            >
                              {/* {translate('transaction.DEPOSIT_PENDING')} */}
                              Đặt cọc & gửi email thành công
                            </Option>
                            {/* Yêu cầu đặt cọc */}
                            <Option
                              value={StatusTransactions.DEPOSIT}
                              disabled={detailTransaction.status === StatusTransactions.SUPPORTED}
                            >
                              {translate('transaction.DEPOSIT')}
                            </Option>
                            {/* Đã đặt cọc */}
                            <Option value={StatusTransactions.SUCCESS} disabled>
                              {translate('transaction.SUCCESS')}
                            </Option>
                            {/* Giao dịch thành công */}
                            <Option
                              value={StatusTransactions.CANCEL_BOOKING}
                              disabled={detailTransaction.status === StatusTransactions.SUPPORTED}
                            >
                              Hủy giữ chỗ không gửi email
                            </Option>
                            {/* Hủy giữ chỗ */}
                            <Option value={StatusTransactions.CANCEL_DEPOSIT} disabled>
                              {translate('transaction.CANCEL_DEPOSIT')}
                            </Option>
                            {/* Hủy đặt cọc */}
                            <Option value={StatusTransactions.CUSTOMER_CANCEL} disabled>
                              {translate('transaction.CUSTOMER_CANCEL')}
                            </Option>

                            <Option
                              value="REQUEST_CANCEL_BOOKING"
                              disabled={detailTransaction.status === StatusTransactions.SUPPORTED}
                            >
                              Hủy giữ chỗ và gửi email
                            </Option>
                            <Option
                              value="REQUEST_DEPOSIT_PENDING"
                              disabled={
                                detailTransaction.status === StatusTransactions.BOOKED ||
                                detailTransaction.status === StatusTransactions.SUPPORTED
                              }
                            >
                              Đang xác nhận chuyển cọc
                            </Option>
                            <Option
                              value="REQUEST_DEPOSIT"
                              disabled={
                                detailTransaction.status === StatusTransactions.SUPPORTED ||
                                detailTransaction.status === StatusTransactions.BOOKED
                              }
                            >
                              Đã Xác Nhận chuyển cọc
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* Ghi chú */}
                    <Row className="marginTop">
                      <Col span={8}>Ghi chú</Col>
                      <Col span={16} className="fontSize20">
                        <Form.Item name="note">
                          <Input.TextArea rows={5} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={24}>
                        <Row gutter={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Col>
                            <Form.Item>
                              <Button type="primary" style={{ backgroundColor: 'red' }} onClick={backHistory}>
                                {translate('action.close')}
                              </Button>
                            </Form.Item>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button type="primary" style={{ backgroundColor: 'rgb(58, 200, 110)' }} htmlType="submit">
                                {translate('action.save')}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row className="marginTop">
                      <Col span={8} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        Lịch sử giao dịch
                      </Col>
                    </Row>
                    <Row className="marginTop">
                      <Col span={24}>
                        <Table dataSource={dataHistory || []} columns={columns} pagination={false} />
                      </Col>
                    </Row>

                    {/* Giao dịch liên quan */}
                    <Row className="marginTop" style={{ marginTop: '20px' }}>
                      <Col span={8} style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        Giao dịch liên quan
                      </Col>
                    </Row>
                    {/* totalRelated, */}
                    <Row className="marginTop">
                      <Col span={24}>
                        <Table dataSource={dataRelated || []} columns={columnsDeal} pagination={false} />
                      </Col>
                      <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <div className="d-flex justify-content-end pagination">
                          <Pagination
                            showSizeChanger={false}
                            total={totalRelated}
                            current={page}
                            defaultCurrent={page}
                            defaultPageSize={PaginateList.LIMIT_DEFAULT}
                            onChange={onPageChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* Thảo luận liên quan */}
                <Row className="marginTop">
                  <hr style={{ borderTop: '2px solid black', width: '100%' }} />
                </Row>

                <Row>
                  <Col span={24}>
                    <Title level={3} style={{ textAlign: 'center' }}>
                      Thảo luận liên quan
                    </Title>
                  </Col>
                </Row>
                {/* Danh sách thảo luận   */}
                <Row className="marginTop">
                  <Col span={15}>
                    <div className="demo-infinite-container">
                      <InfiniteScroll initialLoad={false} pageStart={0} loadMore={onLoadMore} hasMore useWindow={false}>
                        <List
                          className="comment-list"
                          itemLayout="horizontal"
                          dataSource={history}
                          renderItem={(item: Message) => (
                            <InputChatItem onUpdateMessage={onUpdateMessage} item={item} />
                          )}
                        />
                      </InfiniteScroll>
                    </div>
                  </Col>

                  <Col span={4} offset={1}>
                    <List
                      className="comment-list"
                      itemLayout="horizontal"
                      dataSource={ListMember}
                      renderItem={(item: any) => (
                        <CustomerItem
                          detailTransaction={detailTransaction}
                          onUpdateViewMessage={onUpdateViewMessage}
                          item={item}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      ) : (
        'Permission Denied'
      )}
    </>
  );
}

export default ReservationProjectDetail;
