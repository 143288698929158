import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ReferralCodeReq {
    referralCode?: string
}
function useReferrer(): [(request: ReferralCodeReq) => Promise<void>, any, boolean, boolean] {
    const [loading, setLoading] = useState<boolean>(false);
    const [resReferrer, setReferrer] = useState({});
    const [messageReferrer, setMessageReferrer] = useState<boolean>(false)

    const useGetReferrer = async (request: ReferralCodeReq) => {
        try {
            setLoading(true);
            const res = await ApiHelper.fetch<ReferralCodeReq, any>(
                BASE_URL.GET_REFERRER,
                request
            )
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false)
                setMessageReferrer(true);
                setReferrer(res.data)
            } else {
                setMessageReferrer(false);
            }
        } catch (error) {
            setMessageReferrer(false);
            setLoading(false);
        }
    }
    return [useGetReferrer, resReferrer, loading, messageReferrer];
}

export default useReferrer;