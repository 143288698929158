import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';

export interface ListConstructorReq {
    page?: number;
    limit?: string | number;
    search?: string;
    status?: string;
    type?: string;
}

function useListConstructor(): [(request: ListConstructorReq) => Promise<void>, any, boolean] {
    const [loading, setLoading] = useState<boolean>(false);
    const [listConstructor, getListConstructor] = useState([]);

    const onFetchListConstructor = async (request: ListConstructorReq) => {
        try {
            setLoading(true);
            const res = await ApiHelper.fetch<ListConstructorReq, any>(BASE_URL.LIST_CONSTRUCTOR, request)
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false)
                getListConstructor(res)
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return [onFetchListConstructor, listConstructor, loading]
}

export default useListConstructor;