/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ToastError, ToastSuccess } from 'components/Toast';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { translate } from 'translate/language';
import { StatusResponse } from './../../constants/Status';
import { TermRes } from './forbidden-keywords.type';

const BLOCK_WORK = '/block-keyword';

function blockWorksService() {
  const [dataBlockWork, setDataBlockWork] = useState<TermRes | undefined>();

  const onGetBlockWork = async () => {
    try {
      const res: any = await ApiHelper.fetch(BLOCK_WORK);
      if (res && res.status === StatusResponse.SUCCESS) {
        setDataBlockWork(res.data);
      }
    } catch (error) {
      setDataBlockWork(undefined);
    }
  };

  const onUpdateBlockKeyWord = async (data: { content: string }) => {
    try {
      const res: any = await ApiHelper.post(BLOCK_WORK, data);
      if (res && res.status === StatusResponse.SUCCESS) {
        ToastSuccess({
          message: translate('message_type.success'),
          description: translate('messages.update_success'),
        });
      }
    } catch (error) {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return {
    onUpdateBlockKeyWord,
    onGetBlockWork,
    dataBlockWork,
  };
}

export default blockWorksService;
