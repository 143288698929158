/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Select } from 'antd';
import { Option } from 'antd/lib/mentions';
import useListCustomer from 'hooks/Customer/useListCustomer';
import React, { ReactElement, RefObject, useEffect, useState } from 'react';
import { User } from 'types/response/UserResponse';
import { InputText } from './InputSelect';

interface Props {
  validateCheck: boolean;
  onChangeHouse: (data: string) => void;
  onChangeCustomOwnerName: (data: string) => void;
  onChangeCustomOwnerPhone: (data: string) => void;
  onChangeCustomOwnerEmail: (data: string) => void;
  onChangeCustomOwnerIdentify: (data: string) => void;
  dataHouse: any;
  isPAG: boolean;
}

function SelectHostInfo(props: Props, ref: RefObject<any>): ReactElement {
  const {
    validateCheck,
    onChangeHouse,
    onChangeCustomOwnerName,
    onChangeCustomOwnerPhone,
    onChangeCustomOwnerEmail,
    onChangeCustomOwnerIdentify,
    dataHouse,
    isPAG,
  } = props;

  const [value, setIdContact] = useState<string>();
  const [valueInfoHost, setValueInfoHouseOwner] = useState<User>();

  const [dataHouseInfo, setDataHouseInfo] = useState<any>();
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    setDataHouseInfo(dataHouse);
    setCheck(true);
  }, [dataHouse]);

  const [onFetchListCustomer, listCustomer] = useListCustomer();

  useEffect(() => {
    onFetchListCustomer({ status: 'ACTIVE' });
  }, []);

  const onHandChangeContact = (value: any) => {
    setIdContact(value);
    onChangeHouse(value);
    if (check) {
      setDataHouseInfo(undefined);
    }
  };

  React.useImperativeHandle(
    ref,
    () => ({
      onHandChangeContact: onHandChangeContact,
      onChangeCustomOwnerName: onChangeCustomOwnerName,
      onChangeCustomOwnerPhone: onChangeCustomOwnerPhone,
      onChangeCustomOwnerEmail: onChangeCustomOwnerEmail,
      onChangeCustomOwnerIdentify: onChangeCustomOwnerIdentify,
    }),
    [],
  );

  useEffect(() => {
    const dataAdmin = [...((listCustomer && listCustomer.data) || [])];
    if (value && dataAdmin.length > 0) {
      const index =
        listCustomer &&
        listCustomer.data.findIndex((e: User) => {
          return e._id === value;
        });

      if (index > -1) {
        setValueInfoHouseOwner(dataAdmin[index]);
      }
    }
  }, [value, listCustomer]);

  return (
    <div>
      {!isPAG && (
        <Form.Item
          labelCol={{ md: 8, xl: 8, xs: 24 }}
          wrapperCol={{ md: 12, xs: 24 }}
          rules={[
            {
              required: validateCheck,
              message: 'Thông tin liên hệ là bắt buộc',
            },
          ]}
          style={{ justifyContent: 'center', display: 'flex', marginTop: 20 }}
          label="Thông tin chủ nhà"
          labelAlign="left"
          name="ownerId"
        >
          <Select
            placeholder="Thông tin chủ nhà"
            maxTagTextLength={50}
            onChange={onHandChangeContact}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option: any | undefined) =>
              option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {listCustomer &&
              listCustomer.data &&
              listCustomer.data.map((data: any) => {
                return (
                  <Option value={data._id} key={data._id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
      )}

      <InputText
        value={dataHouseInfo?.name || (valueInfoHost && valueInfoHost?.name) || ''}
        disabled={!isPAG}
        label="Họ và tên"
        name={isPAG ? 'customOwnerName' : undefined}
        onChange={onChangeCustomOwnerName}
      />
      <InputText
        value={dataHouseInfo?.phone || (valueInfoHost && String(valueInfoHost?.phone)) || ''}
        disabled={!isPAG}
        label="Số điện thoại"
        name={isPAG ? 'customOwnerPhone' : undefined}
        onChange={onChangeCustomOwnerPhone}
      />
      <InputText
        value={dataHouseInfo?.email || (valueInfoHost && valueInfoHost?.email) || ''}
        disabled={!isPAG}
        label="Email"
        name={isPAG ? 'customOwnerEmail' : undefined}
        onChange={onChangeCustomOwnerEmail}
      />
      <InputText
        value={dataHouseInfo?.identify || (valueInfoHost && String(valueInfoHost?.identify)) || ''}
        disabled={!isPAG}
        label="Số CMND"
        name={isPAG ? 'customOwnerIdentify' : undefined}
        onChange={onChangeCustomOwnerIdentify}
      />
    </div>
  );
}

export default React.forwardRef(SelectHostInfo);
