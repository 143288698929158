/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
import ActiveScreen from 'pages/ActivePage/active.screen';
import CommissionsScreen from 'pages/ConfigurationManagerPage/Commissions/commissions.screen';
import CommissionsRealestateScreen from 'pages/ConfigurationManagerPage/CommissionsRealestate/commissions-realestate.screen';
import ConfigOtherScreen from 'pages/ConfigurationManagerPage/ConfigOther/config-other.screen';
import PostFormManage from 'pages/ConfigurationManagerPage/PostFormManage/post-form-manager';
import ContentGuideScreen from 'pages/ContentGuidePage/content-guide.screen';
import CtvPage from 'pages/Ctv/CtvPage';
import CustomerProjectDetail from 'pages/CustomerProject/customer-project-detail';
import CustomerProjectScreen from 'pages/CustomerProject/customer-project.screen';
import EmailDetailScreen from 'pages/EmailPage/email-detail.screen';
import EmailScreen from 'pages/EmailPage/email.screen';
import FeedbackScreen from 'pages/FeedbackPage/feedback.screen';
import HouseDetailScreen from 'pages/House/house-detail.screen';
import HouseScreen from 'pages/House/house.screen';
import HouseTemplatepage from 'pages/HouseTemplate';
import CreateHouseTemplate from 'pages/HouseTemplate/house-template.create';
import NewsDetailScreen from 'pages/NewPage/news-detail.screen';
import NewsSceen from 'pages/NewPage/news.sceen';
import ListNotificationPage from 'pages/Notification/list-notification.page';
import DetailNotification from 'pages/Notification/detail-notification';
import NotificationDetailScreen from 'pages/NotificationPage/notification-detail.screen';
import NotificationScreen from 'pages/NotificationPage/notification.screen';
import PointDetailScreen from 'pages/PointPage/point-detail.screen';
import PointScreen from 'pages/PointPage/point.screen';
import PointPlusDetailScreen from 'pages/PointPlus/point-plus-detail.screen';
import PointPlusScreen from 'pages/PointPlus/point-plus.screen';
import RealEstateNewDetailScreen from 'pages/RealEstateNewPage/real-estate-new-detail.screen';
import RealEstateNewScreen from 'pages/RealEstateNewPage/real-estate-new.screen';
import ReservationProjectDetail from 'pages/ReservationProject/reservation-project-detail';
import ReservationProjectScreen from 'pages/ReservationProject/reservation-project.screen';
import CommissionPlusDetailScreen from 'pages/RosePage/CommissionPlusPage/commission-plus-detail.screen';
import CommissionPlusScreen from 'pages/RosePage/CommissionPlusPage/commission-plus.screen';
import HistoryDetailScreen from 'pages/RosePage/HistoryPage/history-detail.screen';
import HistoryScreen from 'pages/RosePage/HistoryPage/history.screen';
import HistoryPAGScreen from 'pages/RosePage/HistoryPage/history-pag.screen';
import WithDrawDetailScreen from 'pages/RosePage/WithdrawPage/with-draw-detail.screen';
import WithDrawScreen from 'pages/RosePage/WithdrawPage/with-draw.screen';
import SaleAndRentalpage from 'pages/SaleAndRental';
import DetailRelatedScreen from 'pages/SaleAndRental/FormCreate/detail-related.screen';
import CreateElstate from 'pages/SaleAndRental/FormCreate/form-create-realestate';
import ProjectNewsTransactionScreen from 'pages/SaleAndRental/transactions.screen';
import DetailSupportScreen from 'pages/SupportPage/detail-support.screen';
import SupportScreen from 'pages/SupportPage/support.screen';
import SystemTreeScreen from 'pages/SystemTreePage/system-tree.screen';
import TermsOfUseScreen from 'pages/TermOfUsePage/terms-of-use.screen';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ForbiddenKeywordsScreen from 'pages/ForbiddenKeywordsPage/forbidden-keywords.screen';
import ProjectTransactionCommissionScreen from 'pages/ProjectTransactionCommission';
import ProjectNewsTransactionCommissionScreen from 'pages/ProjectNewsTransactionCommission';
import PhoneBookRemovedCustomer from 'pages/PhonebookRemovedCustomer';
import AdminPage from '../pages/AdminPage';
import FormAdminPage from '../pages/AdminPage/FormAdminPage';
import ApartmentTypePage from '../pages/ApartmentTypePage';
import FormApartmentPage from '../pages/ApartmentTypePage/FormApartmentPage';
import BankPage from '../pages/BankPage';
import FormBankPage from '../pages/BankPage/FormBankPage';
import BlockAreaPage from '../pages/BlockAreaPage';
import FormBlockAreaPage from '../pages/BlockAreaPage/FormBlockAreaPage';
import InteractionPoin from '../pages/ConfigurationManagerPage/InteractionPoint';
import ConstructorPage from '../pages/ConstructorPage';
import FormConstructorPage from '../pages/ConstructorPage/FormConstructorPage';
import CustomerPage from '../pages/CustomerPage';
import FormCustomerPage from '../pages/CustomerPage/FormCustommerPage';
import DirectionPage from '../pages/DirectionPage';
import FormDirectionPage from '../pages/DirectionPage/FormDirectionPage';
import HistoryPointScreen from '../pages/HistoryBuyPoint/history-point.screen';
import HomePage from '../pages/HomePage';
import LoginPage from '../pages/LoginPage';
import PermissionPage from '../pages/PermissionPage';
import FormPermissionPage from '../pages/PermissionPage/FormPermissionPage';
import ProjectPage from '../pages/ProjectPage';
import FormProjectPage from '../pages/ProjectPage/FormProjectPage';
import PropertyTypePage from '../pages/PropertyTypePage';
import FormEditParent from '../pages/PropertyTypePage/FormEditParent';
import FormPropertyPage from '../pages/PropertyTypePage/FormPropertyPage';
import { ROUTER_PATH } from './Path';
import LoginPageStatistic from '../pages/BctAnalysts';
import HistoryUsedPointScreen from '../pages/HistoryUsedPoint';

const PageLoading = () => (
  <div
    style={{
      width: '100vw',
      position: 'absolute',
      height: '100vh',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h6>Loading...</h6>
  </div>
);

const ProtectedRoute = ({ Component = null, path, children = null, user = null, isLogin = false, ...rest }: any) => {
  if (!isLogin) return <Redirect to={ROUTER_PATH.LOGIN} />;
  if (children) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }
  return <Route path={path} {...rest} render={props => <Component {...props} />} />;
};

const renderRoutes = (isLogin: boolean) => {
  return (
    <Switch>
      <Route exact path={ROUTER_PATH.LOGIN} component={LoginPage} />
      <Route exact path={ROUTER_PATH.BCT_ANALYSTS} component={LoginPageStatistic} />
      <ProtectedRoute exact path={ROUTER_PATH.HOME} component={HomePage} isLogin={isLogin} />
      {/* Quản lý Admin */}
      <ProtectedRoute exact path={ROUTER_PATH.ACCOUNT_ADMIN} component={AdminPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_ADMIN} component={FormAdminPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_ADMIN + ':id'} component={FormAdminPage} isLogin={isLogin} />
      {/* Quản lý khách hàng */}
      <ProtectedRoute exact path={ROUTER_PATH.ACCOUNT_CUSTOMER} component={CustomerPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_CUSTOMER} component={FormCustomerPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_CUSTOMER + ':id'} component={FormCustomerPage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.PHONEBOOK_REMOVED_CUSTOMER}
        component={PhoneBookRemovedCustomer}
        isLogin={isLogin}
      />

      {/* Quản lý phân quyền */}
      <ProtectedRoute exact path={ROUTER_PATH.GROUP_PERMISSION} component={PermissionPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_PERMISSION} component={FormPermissionPage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.EDIT_PERMISSION + ':id'}
        component={FormPermissionPage}
        isLogin={isLogin}
      />
      {/* Quản lý dự án */}
      <ProtectedRoute exact path={ROUTER_PATH.PROJECT} component={ProjectPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_PROJECT} component={FormProjectPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_PROJECT + ':id'} component={FormProjectPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.CTV_LIST} component={CtvPage} isLogin={isLogin} />
      {/* Căn hộ, lô đất */}
      <ProtectedRoute exact path={ROUTER_PATH.HOUSE_LOTS} component={HouseScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_HOUSE_LOTS} component={HouseDetailScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.EDIT_HOUSE_LOTS + ':id'}
        component={HouseDetailScreen}
        isLogin={isLogin}
      />
      {/* Block, khu đất */}
      <ProtectedRoute exact path={ROUTER_PATH.BLOCKS_AREA} component={BlockAreaPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_BLOCKS_AREA} component={FormBlockAreaPage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.EDIT_BLOCKS_AREA + ':id'}
        component={FormBlockAreaPage}
        isLogin={isLogin}
      />
      {/* Căn hộ mẫu */}
      <ProtectedRoute exact path={ROUTER_PATH.PROJECTS_HOUSETEMPLATE} component={HouseTemplatepage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.ADD_PROJECTS_HOUSETEMPLATE}
        component={CreateHouseTemplate}
        isLogin={isLogin}
      />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.EDIT_PROJECTS_HOUSETEMPLATE + ':id'}
        component={CreateHouseTemplate}
        isLogin={isLogin}
      />
      {/* Giao dịch */}
      <ProtectedRoute exact path={ROUTER_PATH.CUSTOMER_PROJECT} component={CustomerProjectScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.PROJECT_NEWS_TRANSACTION}
        component={ProjectNewsTransactionScreen}
        isLogin={isLogin}
      />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_PROJECT_CUSTOMER + ':id'}
        component={CustomerProjectDetail}
        isLogin={isLogin}
      />

      {/* Hoa hồng giao dịch */}
      <ProtectedRoute
        exact
        path={ROUTER_PATH.PROJECT_TRANSACTION_COMMISSION}
        component={ProjectTransactionCommissionScreen}
        isLogin={isLogin}
      />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.PROJECT_NEWS_TRANSACTION_COMMISSION}
        component={ProjectNewsTransactionCommissionScreen}
        isLogin={isLogin}
      />

      {/* Khách hàng xếp số */}
      <ProtectedRoute
        exact
        path={ROUTER_PATH.CUSTOMER_RESERVATION}
        component={ReservationProjectScreen}
        isLogin={isLogin}
      />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_RESERVATION + ':id'}
        component={ReservationProjectDetail}
        isLogin={isLogin}
      />

      {/* Quản lý danh mục */}
      <ProtectedRoute exact path={ROUTER_PATH.BANK} component={BankPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_BANK} component={FormBankPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_BANK + ':id'} component={FormBankPage} isLogin={isLogin} />
      {/* Quản lý loại bất động sản */}
      <ProtectedRoute exact path={ROUTER_PATH.PROPERTY_TYPE} component={PropertyTypePage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_PROPERTY} component={FormPropertyPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_PROPERTY + ':id'} component={FormPropertyPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_PARENT + ':id'} component={FormEditParent} isLogin={isLogin} />
      {/* Quản lý loại căn hộ */}
      <ProtectedRoute exact path={ROUTER_PATH.APARTMENT_TYPE} component={ApartmentTypePage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_APARTMENT} component={FormApartmentPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_APARTMENT + ':id'} component={FormApartmentPage} isLogin={isLogin} />
      {/* Quản lý hường nhà */}
      <ProtectedRoute exact path={ROUTER_PATH.DIRECTION} component={DirectionPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_DIRECTION} component={FormDirectionPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_DIRECTION + ':id'} component={FormDirectionPage} isLogin={isLogin} />
      {/* Quản lý đối tác xây dựng */}
      <ProtectedRoute exact path={ROUTER_PATH.CONSTRUCTOR} component={ConstructorPage} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_CONSTRUCTOR} component={FormConstructorPage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.EDIT_CONSTRUCTOR + ':id'}
        component={FormConstructorPage}
        isLogin={isLogin}
      />
      {/* Quản lý cấu hình/Quản lý điểm tương tác */}
      <ProtectedRoute exact path={ROUTER_PATH.INTERACTION} component={InteractionPoin} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.PROJECT_COMMISSIONS} component={CommissionsScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.POST_FORM} component={PostFormManage} isLogin={isLogin} />
      {/* Cấu hình khác */}
      <ProtectedRoute exact path={ROUTER_PATH.CONFIG_OTHER} component={ConfigOtherScreen} isLogin={isLogin} />
      {/* Email hợp đồng */}
      <ProtectedRoute exact path={ROUTER_PATH.EMAIL_MANAGER} component={EmailScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_EMAIL_MANAGER + ':id'}
        component={EmailDetailScreen}
        isLogin={isLogin}
      />

      {/* Danh sách thông báo luật chơi */}
      <ProtectedRoute exact path={ROUTER_PATH.LIST_NOTIFICATION} component={ListNotificationPage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_NOTIFICATION + ':id'}
        component={DetailNotification}
        isLogin={isLogin}
      />

      {/* Quản lý hoa hồng BĐS bán lẻ */}
      <ProtectedRoute
        exact
        path={ROUTER_PATH.PROJECT_COMMISSIONS_BĐS}
        component={CommissionsRealestateScreen}
        isLogin={isLogin}
      />
      {/* Quản lý bất động sản riêng lẻ */}
      <ProtectedRoute exact path={ROUTER_PATH.SALES_AND_RENTAL_LIST} component={SaleAndRentalpage} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.SALES_AND_RENTAL_EDIT + ':id'}
        component={CreateElstate}
        isLogin={isLogin}
      />
      <ProtectedRoute exact path={ROUTER_PATH.SALES_AND_RENTAL_CREATE} component={CreateElstate} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_RELATED + ':id'}
        component={DetailRelatedScreen}
        isLogin={isLogin}
      />
      {/* Tin mua thuê BĐS lẻ */}
      <ProtectedRoute exact path={ROUTER_PATH.REAL_ESTATE_NEWS} component={RealEstateNewScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_REAL_ESTATE_NEWS + ':id'}
        component={RealEstateNewDetailScreen}
        isLogin={isLogin}
      />

      {/* Phản hồi của User */}
      <ProtectedRoute exact path={ROUTER_PATH.FEED_BACK} component={FeedbackScreen} isLogin={isLogin} />

      {/* Hoa hồng */}
      <ProtectedRoute exact path={ROUTER_PATH.COMMISSION_HISTORY} component={HistoryScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.COMMISSION_PAG_HISTORY} component={HistoryPAGScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_COMMISSION_HISTORY + ':id'}
        component={HistoryDetailScreen}
        isLogin={isLogin}
      />
      <ProtectedRoute exact path={ROUTER_PATH.COMMISSION_WITHDRAW} component={WithDrawScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_COMMISSION_WITHDRAW + ':id'}
        component={WithDrawDetailScreen}
        isLogin={isLogin}
      />
      {/* Cộng hoa hồng */}
      <ProtectedRoute exact path={ROUTER_PATH.COMMISSION_PLUS} component={CommissionPlusScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.COMMISSION_PLUS_ADD}
        component={CommissionPlusDetailScreen}
        isLogin={isLogin}
      />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.COMMISSION_PLUS_EDIT + ':id'}
        component={CommissionPlusDetailScreen}
        isLogin={isLogin}
      />

      {/* Quản lý gói điểm */}
      <ProtectedRoute exact path={ROUTER_PATH.POINT_LIST} component={PointScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.POINT_DETAIL + ':id'} component={PointDetailScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.POINT_CREATE} component={PointDetailScreen} isLogin={isLogin} />

      {/* Lịch sử mua gói điểm */}
      <ProtectedRoute exact path={ROUTER_PATH.POINT_HISTORY_LIST} component={HistoryPointScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.USED_POINT_HISTORY_LIST}
        component={HistoryUsedPointScreen}
        isLogin={isLogin}
      />
      {/* Cộng điểm */}
      <ProtectedRoute exact path={ROUTER_PATH.POINT_PLUS} component={PointPlusScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_POINT_PLUS} component={PointPlusDetailScreen} isLogin={isLogin} />

      {/* Tin tức,sự kiện */}
      <ProtectedRoute exact path={ROUTER_PATH.NEWS} component={NewsSceen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.NEW_CREATE} component={NewsDetailScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.EDIT_NEWS + ':id'} component={NewsDetailScreen} isLogin={isLogin} />

      {/* Thông báo hệ thống */}
      <ProtectedRoute exact path={ROUTER_PATH.NOTIFICATION_SYSTEM} component={NotificationScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.ADD_NOTIFICATION_SYSTEM}
        component={NotificationDetailScreen}
        isLogin={isLogin}
      />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.EDIT_NOTIFICATION_SYSTEM + ':id'}
        component={NotificationDetailScreen}
        isLogin={isLogin}
      />
      {/* Danh sách hoạt động */}
      <ProtectedRoute exact path={ROUTER_PATH.ACTIVE} component={ActiveScreen} isLogin={isLogin} />
      {/* Hướng dẫn sử dụng */}
      <ProtectedRoute exact path={ROUTER_PATH.CONTENT_GUIDE} component={ContentGuideScreen} isLogin={isLogin} />
      {/* Liên hệ hỗ trợ */}
      <ProtectedRoute exact path={ROUTER_PATH.SUPPORT} component={SupportScreen} isLogin={isLogin} />
      <ProtectedRoute exact path={ROUTER_PATH.ADD_SUPPORT} component={DetailSupportScreen} isLogin={isLogin} />
      <ProtectedRoute
        exact
        path={ROUTER_PATH.DETAIL_SUPPORT + ':id'}
        component={DetailSupportScreen}
        isLogin={isLogin}
      />

      {/* Điều khoản sử dụng */}
      <ProtectedRoute exact path={ROUTER_PATH.TERMS_OF_USE} component={TermsOfUseScreen} isLogin={isLogin} />
      {/* Từ khóa cấm */}
      <ProtectedRoute
        exact
        path={ROUTER_PATH.FORBIDDEN_KEYWORDS}
        component={ForbiddenKeywordsScreen}
        isLogin={isLogin}
      />

      {/* Cây hệ thống */}
      <ProtectedRoute exact path={ROUTER_PATH.SYSTEM_TREE} component={SystemTreeScreen} isLogin={isLogin} />

      <Route exact path="*">
        <React.Suspense fallback={<PageLoading />}>
          <h1>404</h1>
        </React.Suspense>
      </Route>
    </Switch>
  );
};
export default renderRoutes;
