/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Input, Modal, Pagination, Row, Table, Tag, Typography } from 'antd';
import PageLoading from 'components/PageLoading';
import PaginateList from 'constants/PaginateList';
import { translate } from 'translate/language';
import { ROUTER_PATH } from 'routes/Path';
import usePhoneBookRemovedCustomerService from './service';

const { Text, Link } = Typography;

const PhoneBookRemovedCustomer = () => {
  const { onAddNewParent, data, total, loading, onGetData } = usePhoneBookRemovedCustomerService();
  const [page, setPage] = useState<number>(PaginateList.PAGE_DEFAULT);
  const [filter, setFilter] = useState<any>({
    page,
    limit: PaginateList.LIMIT_DEFAULT,
  });
  const [selectedUser, setSelectedUser] = useState<any>();
  const [visible, setVisible] = useState<boolean>(false);

  const [form] = Form.useForm();

  useEffect(() => {
    onGetData(filter);
  }, [filter]);

  const onPageChange = (p: number) => {
    setPage(p);
    setFilter({ ...filter, page: p });
  };

  const onClickButton = (user: any) => {
    setSelectedUser(user);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedUser(undefined);
  };

  const handleSubmit = async (values: any) => {
    if (!selectedUser) return;
    await onAddNewParent(selectedUser._id, { newReferralCode: values.newReferralCode });
    handleCancel();
    form.resetFields();
    setFilter({ ...filter });
  };

  const columns = [
    {
      title: translate('customer_project.stt'),
      key: '_id',
      render: (record: any, item: any, index: number) => {
        return <span>{Number(filter.page) > 1 ? (Number(filter.page) - 1) * 10 + (index + 1) : index + 1}</span>;
      },
      fixed: 'left' as any,
      width: '5%',
    },
    {
      title: 'Tên',
      key: '_id',
      render: (item: any) => {
        return (
          <Link href={ROUTER_PATH.EDIT_CUSTOMER + item._id} target="_blank">
            <Tag style={{ color: '#12a0c0', textDecoration: 'underline', cursor: 'pointer' }}>{item.name}</Tag>
          </Link>
        );
      },
      fixed: 'left' as any,
    },
    {
      title: 'Số điện thoại',
      key: '_id',
      render: (item: any) => {
        return <Text>{item.phone}</Text>;
      },
    },
    {
      title: 'Mã giới thiệu cấp cha trước đó',
      key: '_id',
      render: (item: any) => {
        return <Text>{item.oldReferralCode}</Text>;
      },
    },
    {
      title: 'Thông tin cấp cha trước đó',
      key: '_id',
      render: (item: any) => {
        return (
          <div>
            <Link href={ROUTER_PATH.EDIT_CUSTOMER + item.oldParent?._id} target="_blank">
              <Tag style={{ color: '#12a0c0', textDecoration: 'underline', cursor: 'pointer' }}>
                {item.oldParent.name}
              </Tag>
            </Link>
            <br />
            <Text>{item.oldParent.phone}</Text>
          </div>
        );
      },
    },
    {
      title: 'Tuỳ chỉnh',
      key: '_id',
      render: (item: any) => {
        return (
          <Button onClick={() => onClickButton(item)} type="primary">
            Đặt lại cha
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Row>
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <Col span={24}>
              <Table dataSource={data || []} columns={columns} pagination={false} scroll={{ x: 'auto' }} />
            </Col>
            <Col span={24} style={{ display: 'inline-flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <div className="d-flex justify-content-end pagination">
                <Pagination
                  showSizeChanger={false}
                  total={total}
                  current={page}
                  defaultCurrent={page}
                  defaultPageSize={PaginateList.LIMIT_DEFAULT}
                  onChange={onPageChange}
                ></Pagination>
              </div>
            </Col>
          </>
        )}
        {selectedUser && (
          <Modal footer={false} title={selectedUser.name} visible={visible} onCancel={handleCancel}>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                name="newReferralCode"
                label="Mã giới thiệu mới"
                rules={[{ required: true, message: 'Vui lòng nhập mã giới thiệu' }]}
              >
                <Input type="text" placeholder="Nhập mã giới thiệu" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Đồng ý
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Row>
    </>
  );
};

export default PhoneBookRemovedCustomer;
