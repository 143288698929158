import { ToastError, ToastSuccess } from 'components/Toast';
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { BASE_URL } from 'network/BaseURL';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTER_PATH } from 'routes/Path';
import { translate } from 'translate/language';

function useAddProject() {
    const [loadingProject, setLoading] = useState(false);
    const history = useHistory();

    const onCreateProject = async (request: any) => {
        try {
            setLoading(true)
            const res = await ApiHelper.post(BASE_URL.ADD_PROJECT, request);
            if (res && res.status == StatusResponse.PROJECT_DO_NOT_EXISTS) {
                setLoading(false);
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.PROJECT_DO_NOT_EXISTS"),
                });
                return;
            }
            if (res && res.status == StatusResponse.PROJECT_ALREADY_EXISTS) {
                setLoading(false);
                ToastError({
                    message: translate("message_type.failed"),
                    description: translate("messages.PROJECT_ALREADY_EXISTS"),
                });
                return;
            }
            if (res && res.status == StatusResponse.SUCCESS) {
                setLoading(false);
                history.push(ROUTER_PATH.PROJECT)
                ToastSuccess({
                    message: translate("message_type.success"),
                    description: translate("messages.add_new_success"),
                });
            }
        } catch (error) {
            setLoading(false)
        }
    }
    return { onCreateProject, loadingProject };
}

export default useAddProject;