/* eslint-disable consistent-return */
import { ToastError, ToastSuccess } from 'components/Toast';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { StatusResponse } from 'constants/Status';
import ApiHelper from 'network/ApiClient';
import { useState } from 'react';
import { translate } from 'translate/language';

const LIST_PROJECT_COMMISSION = '/real-estate-commission/';

function useCommissionRealService() {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataCommission, setDataCommission] = useState<any[] | any>([]);

  const onFetchProjectComission = async () => {
    try {
      setLoading(true);
      const res: any = await ApiHelper.fetch(LIST_PROJECT_COMMISSION);
      if (res.data && res.status === StatusResponse.SUCCESS) {
        setDataCommission(res.data);
        setLoading(false);
      }
    } catch (error) {
      setDataCommission([]);
      setLoading(false);
    }
  };

  const onUpdateProjectCommission = async (data: any) => {
    const res: any = await ApiHelper.put(LIST_PROJECT_COMMISSION, data);
    if (res && res.status === StatusResponse.SUM_OF_ELEMENTS_IN_ROW_MUST_BE_100) {
      return ToastError({
        message: translate('message_type.failed'),
        description: translate('project_commission.SUM_OF_ELEMENTS_IN_ROW_MUST_BE_100'),
      });
    }
    if (res && res.status === StatusResponse.SUCCESS) {
      ToastSuccess({
        message: translate('message_type.success'),
        description: translate('messages.update_success'),
      });
    } else {
      ToastError({
        message: translate('message_type.failed'),
        description: translate('messages.update_failed'),
      });
    }
  };

  return {
    onFetchProjectComission,
    dataCommission,
    loading,
    onUpdateProjectCommission,
  };
}

export default useCommissionRealService;
